<template>
    <div class="items">
        <div>
            <el-table :data="page.records">
                <el-table-column prop="title" label="项目名称">
                    <template slot-scope="scope">
                        <div class="img">
                            <img :src="
                  scope.row.cover === null
                    ? require('@/assets/images/xiaoxin.svg')
                    : scope.row.cover
                " alt="" style="width: 60px; height: 60px" />
                            <span>
                                {{ scope.row.title }}
                            </span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="nickname" label="昵称">
                    <template slot-scope="scope">
                        <div class="userName">
                            <img :src="
                  scope.row.portrait === null
                    ? require('@/assets/images/xiaoxin.svg')
                    : scope.row.portrait
                " alt="" />
                            <span>
                                {{ scope.row.nickname }}
                            </span>
                        </div>
                    </template>
                </el-table-column>

                <el-table-column label="项目状态" prop="status">
                    <template slot-scope="scope">
                        {{
                        scope.row.status == 0
                        ? "待开始"
                        : scope.row.status == 1
                        ? "进行中"
                        : scope.row.status == 2
                        ? "已完成"
                        : ""
                        }}
                    </template>
                </el-table-column>
                <el-table-column label="资金预算">
                    <template slot-scope="scope">
                        {{
                        scope.row.minMoney == null || scope.row.mixMoney == null
                        ? "暂无预算"
                        : scope.row.minMoney + "~" + scope.row.mixMoney
                        }}
                    </template>
                </el-table-column>
                <el-table-column label="上下架">
                    <template slot-scope="scope">
                        <el-switch v-model="scope.row.isFreeze" active-color="#13ce66" inactive-color="#ff4949"
                            :active-value="1" :inactive-value="0"
                            @change="ifFileUpload(scope.row.id, scope.row.isFreeze)">
                        </el-switch>
                    </template>
                </el-table-column>
                <el-table-column align="right">
                    <!-- slot-scope="scope" -->
                    <template slot="header" slot-scope="scope">
                        <div v-if="false">{{ scope.row }}</div>

                        <el-input v-model="search" size="mini" placeholder="输入关键字搜索" @change="findALLItems" />
                    </template>
                    <template slot-scope="scope">
                        <!-- <el-button
                            :disabled="!scope.row.isFreeze"
                            size="mini"
                            @click="
                                ifFileUpload(scope.row.id, scope.row.isFreeze)
                            "
                            >{{
                                scope.row.isFreeze == 0 ? "下架" : "上架"
                            }}</el-button
                        > -->
                        <el-button size="mini" @click="getDetail(scope.row.id)">项目详情</el-button>
                        <el-button size="mini" @click="getOneItem(scope.row.id)">任务详情</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="block">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page.sync="current" :page-size="size" layout="prev, pager, next, jumper" :total="page.total"
                :page-count="page.pages">
            </el-pagination>
        </div>
        <el-dialog title="项目详情" :visible.sync="showDetail" width="50%">
            <!-- <div v-html="textDetail"></div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showDetail = false">取 消</el-button>
        <el-button type="primary" @click="showDetail = false">确 定</el-button>
      </span> -->
            <!-- <iframe :src="'https://www.admin.huangling.xn--fiqs8s/creator-detail?id='+id" frameborder="0" style="width:800px;height:800px"></iframe> -->
            <iframe :src="'http://192.168.1.201:8080/creator-detail?id='+id" frameborder="0"
                style="width:100%;height:800px"></iframe>

        </el-dialog>
        <el-dialog title="任务详情" :visible.sync="showTask">
            <el-table :data="resTask.tasks">
                <el-table-column property="title" label="名称" width="150"></el-table-column>
                <el-table-column property="classify" label="分类" width="200"></el-table-column>
                <el-table-column label="资金预算">
                    <template slot-scope="scope">
                        {{
                        scope.row.minMoney == null || scope.row.mixMoney == null
                        ? "暂无预算"
                        : scope.row.minMoney + "~" + scope.row.mixMoney
                        }}
                    </template>
                </el-table-column>
                <el-table-column label="任务状态" prop="status">
                    <template slot-scope="scope">
                        {{
                        scope.row.status == 0
                        ? "待开始"
                        : scope.row.status == 1
                        ? "进行中"
                        : scope.row.status == 2
                        ? "已完成"
                        : ""
                        }}
                    </template>
                </el-table-column>
                <el-table-column label="任务状态" prop="isFreeze">
                    <template slot-scope="scope">
                        <el-switch v-model="scope.row.isFreeze" active-color="#13ce66" inactive-color="#ff4949"
                            :active-value="1" :inactive-value="0"
                            @change="ifUpload(scope.row.taskId, scope.row.isFreeze)">
                        </el-switch>
                    </template>
                </el-table-column>
                <el-table-column property="createTime" label="创建时间"></el-table-column>
            </el-table>
        </el-dialog>

    </div>
</template>
<script>
export default {
  data() {
    return {
      page: {
        total: 0,
        records: [],
        pages: 10,
      },
      search: "",
      size: 8,
      current: 1,
      showDetail: false,
      showTask: false,
      textDetail: "",
      resTask: {
          id:'',
        tasks: [],
      },
      id:''
    };
  },
  methods: {
    findALLItems() {
      this.$axios
        .get(
          `/api/creators/findItems?current=${this.current}&size=${this.size}&word=${this.search}`
        )
        .then((res) => {
          if (res.data.code == 200) {
            this.page = res.data.data;
          }
        });
    },
    ifUpload(data, data1) {
    
      this.$axios
        .post(
          `/api/creators/editItem?taskId=` +
            parseInt(data) +
            "&status=" +
            data1
        )
        .then((res) => {
          if (res.data.code == 200) {
            this.$message.success(res.data.message);
            this.getOneItem(this.resTask.id);
          }
        });
    },
    ifFileUpload(data, data1) {
      
      console.log(data1, data);
      this.$axios
        .post(
          `/api/creators/shelves?itemId=` + parseInt(data) + "&status=" + data1
        )
        .then((res) => {
          if (res.data.code == 200) {
            this.$message.success(res.data.message);
            this.showTask = false;
            this.findALLItems();
          }
        });
    },
    getOneItem(data) {
      this.$axios.get(`/api/creators/itemDetail?id=` + data).then((res) => {
        if (res.data.code == 200) {
          this.resTask = res.data.data;
          this.showTask = true;
        }
      });
      console.log(data);
    },
    handleSizeChange(val) {
      this.currentPage3 = 1;
      this.pageSize = val;
    },

    // 当前页变动的时候触发
    handleCurrentChange(val) {
      this.currentPage3 = val;
      this.findALLItems();
    },
    getDetail(data) {
    //   this.$axios
    //     .get(data)
    //     .then((res) => {
    //       this.textDetail = res.data;
    //       this.showDetail = true;
    //     })
    //     .catch((err) => console.log(err));
    this.showDetail=true;
    this.id=data
    },
  },
  created() {
    this.findALLItems();
  },
};
</script>
<style lang="less" scoped>
// 整个页面
.items {
  width: 100%;
  height: 100%;
}
// 搜索框
.el-input {
  margin: 8px 1.5%;
  width: 97%;
}
// 分页
.block {
  margin: 30px auto;
  text-align: center;
}
.userName {
  height: 100%;
  // width: 300px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }
  span {
    position: relative;
    top: -10px;
    margin-left: 10px;
  }
}
</style>