<template>
    <div>
        <div ref="box" class="normal_content">
            <div ref="image" class="cover">
                <img v-if="imgFlag" :src="selectImage" />
                <video
                    id="videoPlay"
                    v-if="videoFlag"
                    autoplay
                    controls
                    loop
                    width="100%"
                    height="100%"
                >
                    <source
                        :src="imagesList[imagesList.length - 1]"
                        type="video/mp4"
                    />
                </video>
            </div>
            <div class="picture_list">
                <!-- 已上传的图片的展示 -->
                <!-- 左箭头 -->
                <div
                    v-show="oneImg === true"
                    class="picture_list_inner"
                    id="picture_list_inner"
                >
                    <div class="inner" key="" ref="innerRef">
                        <div
                            class="picture_item has_image"
                            v-if="videos !== ''"
                            @click="(videoFlag = true), (imgFlag = false);"
                        >
                            <video width="100%" height="100%">
                                <source :src="videos" type="video/mp4" />
                            </video>
                        </div>
                        <div
                            class="picture_item has_image"
                            v-for="(item, index) in minVideo"
                            :key="index"
                            @click="changeImage(index)"
                        >
                            <img :src="item" />
                        </div>
                    </div>
                </div>
                <!-- 右箭头 -->
            </div>
        </div>
    </div>
</template>

<script>
//
export default {
    props: {
        imagesList: {
            default: () => [],
        },
    },
    data () {
        return {
            selectImage: null,
            imgInfo: {}, // 存图片的宽高信息
            oneImg: true,
            videos: '',
            // 视频的显示
            videoFlag: false,
            // 大图显示
            imgFlag: true,
            minVideo: [],
            // 视频的地址
            videoUrl: '',
            // 截取视频第一帧的图片地址
            viderIndex: null,
            imgSrc: '',
            domObj: null,
        }
    },
    // computed:{
    // activeUsers: function () {
    //       return this.users.filter(function (user) {
    //       return user.isActive
    //     },
    watch: {
        immediate: false,
        imagesList (newValue) {
            // console.log(newValue)
            if (newValue) {
                if (this.imagesList.length < 2) {
                    this.oneImg = false
                } else {
                    this.oneImg = true
                }
                // console.log(this.selectImage)
                // console.log(newValue)
                // console.log(newValue[newValue.length-1])
                // this.getImgInfo(this.selectImage)
                /***
                 * 在父组件已经判断有没有视频链接，如果有的话就添加到图片数据的后面
                 * 判断当前数组的最后一项是不是视频地址
                 */
                // console.log('执行了一遍')
                this.videos = ''
                this.videoFlag = false
                this.viderIndex = null
                this.minVideo = []
                if (newValue) {
                    // console.log(newValue)
                    let num = newValue[newValue.length - 1].search('video')
                    if (num != -1) {
                        this.viderIndex = newValue.length
                        // console.log('执行了一遍判断')
                        // this.videoUrl = newValue[newValue.length-1]
                        this.videoFlag = true
                        // this.imagesList.push('../assets/images/xiaoxin.svg')
                        this.videos = newValue[newValue.length - 1] // 把视频的地址取出来
                        // console.log(this.videos)
                        // console.log('视频找到啦')
                        // console.log(newValue[newValue.length-1].search("video"))
                        this.imgFlag = false // 图片显示
                        this.videoFlag = true // 视频隐藏
                        var minVideos = newValue
                        // console.log(this.minVideo)
                        this.minVideo = minVideos.slice(0, minVideos.length - 1) // 删除小图的最后一个视频
                        // console.log(this.minVideo)
                    } else {
                        this.minVideo = newValue
                        this.selectImage = newValue[0]
                        this.videoFlag = false
                        this.imgFlag = true
                        // console.log('视频未上传或者没找到')
                    }
                }
            }
            // console.log(this.viderIndex)
        },
    },
    /**
     * 现在父组件判断接口数据里有没有视频地址，
     * 如果有的话就添加到图片数组的末尾，
     * 然后子组件监听父组件传过来的图片数组，
     * 监听到以后会判断数组中的最后一项是不是视频地址，
     * 如果是的话就把视频的渲染到video标签里，
     * video标签的显示和隐藏是通过点击判断大图的url等不等于视频地址，如果等于就视频显示大图隐藏，否则反之
     *  */

    created () {
        this.changeImage(1)
    },
    methods: {
        changeImage (index) {
            this.selectImage = this.imagesList[index]
            if (this.selectImage === this.videoUrl) {
                // console.log('哈哈哈哈哈哈哈哈哈哈哈哈')
                this.imgFlag = false
                this.videoFlag = true
            } else {
                this.videoFlag = false
                this.imgFlag = true
            }
            // this.getImgInfo(this.selectImage)
            index = 0
        },
        scrollFunction () {
            this.domObj = document.getElementById('picture_list_inner') // 通过id获取要设置的div
            if (this.domObj.attachEvent) {
                // IE
                this.domObj.attachEvent('onmousewheel', this.mouseScroll)
            } else if (this.domObj.addEventListener) {
                this.domObj.addEventListener('DOMMouseScroll', this.mouseScroll, false)
            }
            this.domObj.onmousewheel = this.domObj.onmousewheel = this.mouseScroll
        },
        mouseScroll (event) {
            // google 浏览器下
            let detail = event.wheelDelta || event.detail
            let moveForwardStep = -1
            let moveBackStep = 1
            let step = 0
            step = detail > 0 ? moveForwardStep * 100 : moveBackStep * 100
            event.preventDefault() // 阻止浏览器默认事件
            this.domObj.scrollLeft = this.domObj.scrollLeft + step
        },
        closeVideo () {
            let videoElement = document.getElementById('videoPlay')
            videoElement.pause()
            console.log('视频关闭了')
            this.imagesList = []
            this.videoFlag = false
            this.imgFlag = true
        },
    },
    mounted () {
        this.scrollFunction()
        // this.getImgInfo()
    },
    destroyed () {
        console.log('111-----')
    },
    beforDestroy () {
        this.imagesList = []
        this.videoFlag = false
        this.imgFlag = true
        console.log('视频关闭了')
    },
}
</script>
<style lang="less" scoped>
.normal_content {
    box-sizing: content-box;
    padding: 0;
    // margin: 20px;
    overflow: hidden;
    > div {
        // background: #1f1f1f;
        // margin: 10px;
    }
    .cover {
        width: 100%;
        // height: 0px;
        margin: 0;
        padding-bottom: 9/16 * 100%;
        position: relative;
        // overflow: hidden;
        overflow-y: auto;
        overflow-x: auto;
        img {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            // overflow-x: auto;
            // overflow-y: auto;
            object-fit: contain;
        }
        video {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            overflow-x: auto;
            overflow-y: auto;
            object-fit: contain;
        }
    }
    .picture_list {
        display: flex;
        // background-color: #282828;
        align-items: center;
        // 左右滑动图片的按钮

        .picture_list_inner {
            width: 100%;
            height: 100%;
            margin-top: 10px;
            flex: 1;
            overflow-x: auto;
            .inner {
                height: 100%;
                position: relative;
                left: 0;
                display: flex;
                transition: all 0.3s ease-in-out;
            }
        }
        .picture_list_inner::-webkit-scrollbar {
            /*滚动条整体样式*/
            height: 12px;
        }
        .picture_list_inner::-webkit-scrollbar-thumb {
            /*滚动条里面小方块*/
            background: #363636;
        }
        .picture_list_inner::-webkit-scrollbar-track {
            /*滚动条里面轨道*/
            background: #cecece;
        }
    }
    .has_image {
        cursor: pointer;
        transition: opacity 0.3s linear;
    }
    .has_image:hover {
        opacity: 0.8;
    }
    .picture_item {
        padding-top: 9.375%;
        margin-bottom: 3%;
        width: calc(100% / 6 - 10px);
        min-width: calc(100% / 6 - 10px);
        position: relative;
        background-color: #1f1f1f;
        .delete {
            position: absolute;
            top: 6px;
            right: 6px;
            font-size: 14px;
            color: #dd5e5e;
            cursor: pointer;
        }
        .image {
            position: absolute;
            left: 50%;
            top: 50%;
            font-size: 30px;
            transform: translate(-50%, -50%);
        }
        img {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            object-fit: fill;
        }
        video {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            object-fit: fill;
        }
    }
    .picture_item + .picture_item {
        margin-left: 10px;
    }
    .button_box {
        text-align: center;
        overflow: hidden;
        border-radius: 2px;
        border: 2px dashed rgba(200, 200, 200, 0.4);
        background-color: #282828;
        div:nth-child(1) {
            margin-top: 45px;
            font-size: 18px;
            line-height: 25px;
        }
        div:nth-child(2) {
            font-size: 14px;
            line-height: 20px;
            color: #c8c8c8;
        }
        div:nth-child(3) {
            margin: 25px 0 12px;
        }
        div:nth-child(4) {
            font-size: 14px;
            line-height: 20px;
            color: #c8c8c8;
            margin-bottom: 45px;
        }
    }
}
</style>
