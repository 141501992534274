<template>
    <div class="index">
        <!-- 数据模块 -->
        <transition name="el-zoom-in-center">
            <div
                class="index_header"
                v-for="(item, index) in mainCount"
                :key="index"
            >
                <div class="production">
                    <p>作品数</p>
                    <span>{{ item.produce }}</span>
                </div>
                <div class="log">
                    <p>文章数</p>
                    <span>{{ item.tutorial }}</span>
                </div>
                <div class="resource">
                    <p>资源数</p>
                    <span>{{ item.resource }}</span>
                </div>
                <div class="course">
                    <p>课程数</p>
                    <span>{{ item.course == "null" ? 0 : item.course }}</span>
                </div>
                <div class="user">
                    <p>用户人数</p>
                    <span>{{ item.user }}</span>
                </div>
                <div class="member">
                    <p>会员人数</p>
                    <span>{{ item.role }}</span>
                </div>
            </div>
        </transition>
        <section class="chart_list">
            <el-card>
                <!-- 上传统计图 -->
                <div slot="header">
                    <h2>文章 | 作品 | 资源增长折线图</h2>
                    <el-select
                        v-model="uploading"
                        placeholder="请选择"
                        @command="getEcharts(value)"
                    >
                        <el-option
                            v-for="item in courseList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                            @click.native="getEcharts(item.value)"
                        >
                        </el-option>
                    </el-select>
                </div>

                <div id="uploading" style="width: 600px; height: 430px"></div>
            </el-card>
            <el-card>
                <div slot="header">
                    <h2>用户和会员数量折线图</h2>
                    <!-- 注册统计图 -->
                    <el-select
                        v-model="login"
                        placeholder="请选择"
                        @command="getLoginNum(value)"
                    >
                        <el-option
                            v-for="item in courseList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                            @click.native="getLoginNum(item.value)"
                        >
                        </el-option>
                    </el-select>
                </div>
                <div id="login" style="width: 600px; height: 430px"></div>
            </el-card>
            <el-card>
                <div slot="header">
                    <h2>免费课程 | 付费课程 | 会员专享课程</h2>
                    <el-select
                        v-model="login"
                        placeholder="请选择"
                        @command="getCourse(value)"
                    >
                        <el-option
                            v-for="item in courseList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                            @click.native="getCourse(item.value)"
                        >
                        </el-option>
                    </el-select>
                </div>
                <div id="course" style="width: 600px; height: 430px"></div>
            </el-card>
            <el-card>
                <div slot="header">
                    <h2>创作者中心图表</h2>
                </div>
                <div style="width: 600px; height: 430px">内容开发中。。。</div>
            </el-card>
        </section>
    </div>
</template>
<script>
import * as echarts from 'echarts'
export default {
    data () {
        return {
            uploadingList: [
                {
                    value: '7',
                    label: '近一周',
                },
                {
                    value: '30',
                    label: '近一个月',
                },
                {
                    value: '365',
                    label: '近一年',
                },
                {
                    value: '',
                    label: '成立以来',
                },
            ],
            uploading: '',
            loginList: [
                {
                    value: '7',
                    label: '近一周',
                },
                {
                    value: '30',
                    label: '近一个月',
                },
                {
                    value: '365',
                    label: '近一年',
                },
                {
                    value: '',
                    label: '成立以来',
                },
            ],
            courseList: [
                {
                    value: '7',
                    label: '近一周',
                },
                {
                    value: '30',
                    label: '近一个月',
                },
                {
                    value: '365',
                    label: '近一年',
                },
                {
                    value: '',
                    label: '成立以来',
                },
            ],
            loading: false,
            loadings: false,
            loadingss: false,
            login: '',
            mainCount: [],
            echartsData: [],
            echartsDatas: [],
            updateTime: '',
            allAges: '2021-05-01',
            msg: false,
            productionData: [],
            resourceData: [],
            tutorialData: [],
            userData: [],
            vipData: [],
            dateData: [],
            peopleDate: [],
            courseDate: [],
            date: [],
            qi: [],
            //课程数据
            free: [],
            pay: [],
            vipFree: [],
        }
    },
    mounted () {
        this.getEcharts(7)
        this.getLoginNum(7)
        this.getCourse(7)
        this.getIndexData()
    },
    created () {
        this.uploading = this.uploadingList[0].label
        this.login = this.loginList[0].label
        this.addDate(7)
        this.loginList[3].value = this.dateDiff(this.allAges)
        this.uploadingList[3].value = this.dateDiff(this.allAges)
    },
    methods: {
        //获取当前年月日
        addDate (time) {
            var myDate = new Date() // 获取今天日期
            // 获取到time天之前的日期
            myDate.setDate(myDate.getDate() - time)
            var dateArray = []
            var people = []
            var course = []
            var dateTemp
            var flag = 1
            // 循环判断 如果月份和日期小于10 就在前面加0
            for (var i = 0; i < time; i++) {
                var dateobj = {}
                var userobj = {}
                var courseobj = {}
                var month = myDate.getMonth() + 1
                var daydate = myDate.getDate()
                var year = myDate.getFullYear()
                if (daydate < 10) {
                    daydate = '0' + daydate
                } else {
                    daydate = String(daydate)
                }
                if (month < 10) {
                    month = '0' + month
                } else {
                    month = String(month)
                }
                dateTemp = year + '-' + month + '-' + daydate
                dateobj.date = dateTemp
                userobj.date = dateTemp
                courseobj.date = dateTemp

                dateobj.productionCount = 0
                dateobj.resourceCount = 0
                dateobj.tutorialCount = 0

                userobj.userCount = 0
                userobj.vipCount = 0

                courseobj.buyerCourse = 0
                courseobj.userCourse = 0
                courseobj.vipCourse = 0
                dateArray.push(dateobj)
                people.push(userobj)
                course.push(courseobj)
                myDate.setDate(myDate.getDate() + flag)
            }
            this.date = dateArray
            this.peopleDate = people
            this.courseDate = people
            // console.log(this.peopleDate)
        },
        // 获取所有的总数
        async getIndexData () {
            const that = this
            this.$axios.get(`/api/main/findData`).then(function (res) {
                const list = {
                    user: res.data.data[0].count,
                    role: res.data.data[1].count,
                    produce: res.data.data[2].count,
                    tutorial: res.data.data[3].count,
                    resource: res.data.data[4].count,
                    course: res.data.data[5].count,
                }
                that.mainCount.push(list)
                // console.log(that.mainCount)
            })
        },
        // 获取日志资源作品统计图的数据
        async getEcharts (value) {
            if (value > this.dateDiff(this.allAges)) {
                value = this.dateDiff(this.allAges)
            } else if (!value) {
                value = this.dateDiff(this.allAges)
            }
            this.addDate(value)
            // console.log(this.qi)
            this.loading = true
            this.addDate(value)
            this.productionData = []
            this.resourceData = []
            this.tutorialData = []
            this.echartsData = []
            const that = this
            this.$axios
                .get(`/api/main/findProChart?time=${value == undefined ? '' : value}`)
                .then(function (res) {
                    const list = res.data.data
                    // 循环比较自己生成的日期和数据里的日期，如果一样就把数据里的count给加进自己的数据里
                    for (let i = 0; i < list.production.length; i++) {
                        let Index = that.date.findIndex((item) => {
                            return item.date === list.production[i].date
                        })
                        if (that.date[Index].date === list.production[i].date) {
                            that.date[Index].productionCount = list.production[i].count
                        }
                    }
                    // 循环作品productionCount，如果为0就等于为0前一天的productionCount
                    for (let i = 0; i < that.date.length; i++) {
                        if (i > 1) {
                            if (that.date[i].productionCount === 0) {
                                that.date[i].productionCount = that.date[i - 1].productionCount
                            }
                        }
                    }
                    // 资源数据处理
                    for (let i = 0; i < list.resource.length; i++) {
                        let Index = that.date.findIndex((item) => {
                            return item.date === list.resource[i].date
                        })
                        if (that.date[Index].date === list.resource[i].date) {
                            that.date[Index].resourceCount = list.resource[i].count
                        }
                    }
                    for (let i = 0; i < that.date.length; i++) {
                        if (i > 1) {
                            if (that.date[i].resourceCount === 0) {
                                that.date[i].resourceCount = that.date[i - 1].resourceCount
                            }
                        }
                    }
                    // 日志数据处理
                    for (let i = 0; i < list.tutorial.length; i++) {
                        let Index = that.date.findIndex((item) => {
                            return item.date === list.tutorial[i].date
                        })
                        if (that.date[Index].date === list.tutorial[i].date) {
                            that.date[Index].tutorialCount = list.tutorial[i].count
                        }
                    }
                    for (let i = 0; i < that.date.length; i++) {
                        if (i > 1) {
                            if (that.date[i].tutorialCount === 0) {
                                that.date[i].tutorialCount = that.date[i - 1].tutorialCount
                            }
                        }
                    }
                    // console.log(that.date)
                    // 创建数组，循环date对象，取出对象里的date，push进创建的数组里，然后去掉前五个字符串
                    let date = []
                    that.date.forEach((element) => {
                        date.push(element.date)
                    })
                    for (let i = 0; i < date.length; i++) {
                        let work = date[i].substring(5)
                        that.echartsData.push(work)
                    }
                    // 处理作品数据
                    let productionCount = []
                    that.date.forEach((element) => {
                        productionCount.push(element.productionCount)
                    })
                    that.productionData = productionCount
                    // 处理资源数据
                    let resourceCount = []
                    that.date.forEach((element) => {
                        resourceCount.push(element.resourceCount)
                    })
                    that.resourceData = resourceCount
                    // 处理日志数据
                    let tutorialCount = []
                    that.date.forEach((element) => {
                        tutorialCount.push(element.tutorialCount)
                    })
                    that.tutorialData = tutorialCount
                    that.uploadingNum(
                        that.echartsData,
                        that.productionData,
                        that.resourceData,
                        that.tutorialData
                    )
                    that.loading = false
                })
        },
        // 作品日志资源的统计图
        uploadingNum (data, production, resource, tutorial) {
            var chartDom = echarts.init(document.getElementById('uploading'))
            chartDom.setOption({
                legend: {
                    data: ['作品', '文章', '资源'],
                },
                xAxis: {
                    type: 'category',
                    data: data,
                    name: '日期',
                    nameTextStyle: {
                        fontWeight: 600,
                        fontSize: 18,
                    },
                },
                yAxis: {
                    type: 'value',
                    name: '数量', // y轴名称
                    // y轴名称样式
                    nameTextStyle: {
                        fontWeight: 600,
                        fontSize: 18,
                    },
                },
                label: {},
                tooltip: {
                    trigger: 'axis',
                },
                series: [
                    {
                        name: '作品',
                        data: production,
                        type: 'line',
                    },
                    {
                        name: '文章',
                        data: tutorial,
                        type: 'line',
                    },
                    {
                        name: '资源',
                        data: resource,
                        type: 'line',
                    },
                ],
            })
            // option.xAxis.data = data
            // option && myChart.setOption(option)
        },
        // 获取指定日期至今的天数
        dateDiff: function (sDate1) {
            var date2 = new Date()
            var date1 = new Date(Date.parse(sDate1.replace(/-/g, '/')))
            var iDays = parseInt(
                Math.abs(date2.getTime() - date1.getTime()) / 1000 / 60 / 60 / 24
            )
            return iDays
        },
        // 课程统计图数据获取
        getCourse (value) {
            // console.log(this.dateDiff(this.allAges))
            if (value > this.dateDiff(this.allAges)) {
                value = this.dateDiff(this.allAges)
            } else if (!value) {
                value = this.dateDiff(this.allAges)
            }
            // console.log(value)
            this.addDate(value)
            this.loadingss = true
            this.free = []
            this.pay = []
            this.vipFree = []
            this.echartsDatas = []
            const that = this
            this.$axios
                .get(
                    `/api/main/findCourseChart?time=${value == undefined ? '' : value}`
                )
                .then((res) => {
                    const list = res.data.data
                    // console.log(list)
                    // console.log(that.date)
                    // 循环比较自己生成的日期和数据里的日期，如果一样就把数据里的count给加进自己的数据里
                    for (let i = 0; i < list.buyerCourse.length; i++) {
                        let Index = that.courseDate.findIndex((item) => {
                            return item.date === list.buyerCourse[i].date
                        })
                        // console.log(Index)
                        if (that.courseDate[Index].date === list.buyerCourse[i].date) {
                            that.courseDate[Index].buyerCourse = list.buyerCourse[i].count
                            // console.log(that.peopleDate)
                        }
                    }
                    // 循环注册userCount，如果为0就等于为0前一天的userCount
                    for (let i = 0; i < that.courseDate.length; i++) {
                        if (i > 1) {
                            if (that.courseDate[i].buyerCourse === 0) {
                                that.courseDate[i].buyerCourse =
                                    that.courseDate[i - 1].buyerCourse
                            }
                        }
                    }
                    // vip
                    for (let i = 0; i < list.userCourse.length; i++) {
                        let Index = that.courseDate.findIndex((item) => {
                            return item.date === list.userCourse[i].date
                        })
                        // console.log(Index)
                        if (that.courseDate[Index].date === list.userCourse[i].date) {
                            that.courseDate[Index].userCourse = list.userCourse[i].count
                            // console.log(that.courseDate)
                        }
                    }
                    // 循环注册userCount，如果为0就等于为0前一天的userCount
                    for (let i = 0; i < that.courseDate.length; i++) {
                        if (i > 1) {
                            if (that.courseDate[i].userCourse === 0) {
                                that.courseDate[i].userCourse =
                                    that.courseDate[i - 1].userCourse
                            }
                        }
                    }
                    // vip
                    for (let i = 0; i < list.vipCourse.length; i++) {
                        let Index = that.courseDate.findIndex((item) => {
                            return item.date === list.vipCourse[i].date
                        })
                        // console.log(Index)
                        if (that.courseDate[Index].date === list.vipCourse[i].date) {
                            that.courseDate[Index].vipCourse = list.vipCourse[i].count
                            // console.log(that.courseDate)
                        }
                    }
                    // 循环注册userCount，如果为0就等于为0前一天的userCount
                    for (let i = 0; i < that.courseDate.length; i++) {
                        if (i > 1) {
                            if (that.courseDate[i].vipCourse === 0) {
                                that.courseDate[i].vipCourse = that.courseDate[i - 1].vipCourse
                            }
                        }
                    }

                    // console.log(that.peopleDate)
                    let date = []
                    that.courseDate.forEach((element) => {
                        date.push(element.date)
                    })
                    for (let i = 0; i < date.length; i++) {
                        let work = date[i].substring(5)
                        that.echartsDatas.push(work)
                    }

                    let free = []
                    that.courseDate.forEach((element) => {
                        free.push(element.userCourse)
                    })
                    that.free = free
                    // console.log(that.userData)

                    let vip = []
                    that.courseDate.forEach((element) => {
                        vip.push(element.vipCourse)
                    })
                    that.vipFree = vip

                    let pay = []
                    that.courseDate.forEach((element) => {
                        pay.push(element.buyerCourse)
                    })
                    that.pay = pay
                    console.log(this.free)
                    console.log(this.vipFree)
                    console.log(this.pay)
                    that.courseEcharts(
                        that.echartsDatas,
                        that.free,
                        that.pay,
                        that.vipFree
                    )
                    that.loadingss = false
                })
        },
        // 课程统计图
        courseEcharts (data, free, pay, vipFree) {
            var chartDom = document.getElementById('course')
            var myChart = echarts.init(chartDom)
            var option = {
                legend: {
                    data: ['免费', '付费', '会员专享'],
                },
                xAxis: {
                    type: 'category',
                    data: data,
                    name: '日期',
                    nameTextStyle: {
                        fontWeight: 600,
                        fontSize: 18,
                    },
                },
                yAxis: {
                    type: 'value',
                    name: '数量', // y轴名称
                    // y轴名称样式
                    nameTextStyle: {
                        fontWeight: 600,
                        fontSize: 18,
                    },
                },
                label: {},
                tooltip: {
                    trigger: 'axis',
                },
                series: [
                    {
                        name: '免费',
                        data: free,
                        type: 'line',
                    },
                    {
                        name: '付费',
                        data: pay,
                        type: 'line',
                    },
                    {
                        name: '会员专享',
                        data: vipFree,
                        type: 'line',
                    },
                ],
            }
            option && myChart.setOption(option)
        },
        // 会员注册统计图数据获取
        getLoginNum (value) {
            // console.log(this.dateDiff(this.allAges))
            if (value > this.dateDiff(this.allAges)) {
                value = this.dateDiff(this.allAges)
            } else if (!value) {
                value = this.dateDiff(this.allAges)
            }
            // console.log(value)
            this.addDate(value)
            this.loadings = true
            this.userData = []
            this.vipData = []
            this.dateData = []
            const that = this
            this.$axios
                .get(`/api/main/findUserChart?time=${value == undefined ? '' : value}`)
                .then((res) => {
                    const list = res.data.data
                    // console.log(list)
                    // console.log(that.date)
                    // 循环比较自己生成的日期和数据里的日期，如果一样就把数据里的count给加进自己的数据里
                    for (let i = 0; i < list.user.length; i++) {
                        let Index = that.peopleDate.findIndex((item) => {
                            return item.date === list.user[i].date
                        })
                        // console.log(Index)
                        if (that.peopleDate[Index].date === list.user[i].date) {
                            that.peopleDate[Index].userCount = list.user[i].count
                            // console.log(that.peopleDate)
                        }
                    }
                    // 循环注册userCount，如果为0就等于为0前一天的userCount
                    for (let i = 0; i < that.peopleDate.length; i++) {
                        if (i > 1) {
                            if (that.peopleDate[i].userCount === 0) {
                                that.peopleDate[i].userCount = that.peopleDate[i - 1].userCount
                            }
                        }
                    }
                    // vip
                    for (let i = 0; i < list.vip.length; i++) {
                        let Index = that.peopleDate.findIndex((item) => {
                            return item.date === list.vip[i].date
                        })
                        // console.log(Index)
                        if (that.peopleDate[Index].date === list.vip[i].date) {
                            that.peopleDate[Index].vipCount = list.vip[i].count
                            // console.log(that.peopleDate)
                        }
                    }
                    // 循环注册userCount，如果为0就等于为0前一天的userCount
                    for (let i = 0; i < that.peopleDate.length; i++) {
                        if (i > 1) {
                            if (that.peopleDate[i].vipCount === 0) {
                                that.peopleDate[i].vipCount = that.peopleDate[i - 1].vipCount
                            }
                        }
                    }

                    // console.log(that.peopleDate)
                    let date = []
                    that.peopleDate.forEach((element) => {
                        date.push(element.date)
                    })
                    for (let i = 0; i < date.length; i++) {
                        let work = date[i].substring(5)
                        that.dateData.push(work)
                    }

                    let user = []
                    that.peopleDate.forEach((element) => {
                        user.push(element.userCount)
                    })
                    that.userData = user
                    // console.log(that.userData)

                    let vip = []
                    that.peopleDate.forEach((element) => {
                        vip.push(element.vipCount)
                    })
                    that.vipData = vip
                    that.loginNum(that.dateData, that.userData, that.vipData)
                    that.loadings = false
                })
        },
        // 会员、注册统计图
        loginNum (date, userData, vipData) {
            var chartDom = document.getElementById('login')
            var myChart = echarts.init(chartDom)
            var option = {
                legend: {
                    data: ['会员', '注册'],
                },
                xAxis: {
                    type: 'category',
                    data: date,
                    name: '日期',
                    nameTextStyle: {
                        fontWeight: 600,
                        fontSize: 18,
                    },
                },
                yAxis: {
                    type: 'value',
                    name: '数量', // y轴名称
                    // y轴名称样式
                    nameTextStyle: {
                        fontWeight: 600,
                        fontSize: 18,
                    },
                },
                label: {},
                tooltip: {
                    trigger: 'axis',
                },
                series: [
                    {
                        name: '注册',
                        data: userData,
                        type: 'line',
                    },
                    {
                        name: '会员',
                        data: vipData,
                        type: 'line',
                    },
                ],
            }
            option && myChart.setOption(option)
        },
        /***
         * 获取当前日期或指定日期的前后指定日期
         * num可以为正数或负数，分别对应日期之前和日期之后的天数
         * time是指定的日期，不传就是当前日期
         */
        getBeforeDate (num, time) {
            let n = num
            let d = ''
            if (time) {
                d = new Date(time)
            } else {
                d = new Date()
            }
            let year = d.getFullYear()
            let mon = d.getMonth() + 1
            let day = d.getDate()
            if (day <= n) {
                if (mon > 1) {
                    mon = mon - 1
                } else {
                    year = year - 1
                    mon = 12
                }
            }
            d.setDate(d.getDate() - n)
            year = d.getFullYear()
            mon = d.getMonth() + 1
            day = d.getDate()
            let s =
                year +
                '-' +
                (mon < 10 ? '0' + mon : mon) +
                '-' +
                (day < 10 ? '0' + day : day)
            return s
        },
    },
    computed: {},
}
</script>

<style lang="less" scoped>
.index {
    padding: 12px;
}
//头部八个数据栏
.index_header {
    width: 100%;
    display: flex;
    div {
        // float: left;
        margin: 24px 12px;
        p {
            margin: 20px 0 16px 16px;
            font-family: PingFang-SC-Bold, PingFang-SC;
            color: #ffffff;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }
        span {
            margin: 0px 0 0 16px;
            line-height: 16px;
            font-size: 32px;
            font-family: DINAlternate-Bold, DINAlternate;
            // font-weight: bold;
            color: #ffffff;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }
    }

    .production {
        width: 240px;
        height: 135px;
        background: linear-gradient(135deg, #fcc687 0%, #f286a0 100%);
        border-radius: 24px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    .log {
        width: 240px;
        height: 135px;
        background: linear-gradient(90deg, #d4fc79 0%, #96e6a1 100%);
        border-radius: 24px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    .resource {
        width: 240px;
        height: 135px;
        background: linear-gradient(90deg, #c2e9fb 0%, #a1c4fd 100%);
        border-radius: 24px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    .course {
        width: 240px;
        height: 135px;
        background: linear-gradient(90deg, #bb9bf1 0%, #887bf2 100%);
        border-radius: 24px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    .user {
        width: 240px;
        height: 135px;
        background: linear-gradient(90deg, #fbc2eb 0%, #a18cd1 100%);
        border-radius: 24px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    .member {
        width: 240px;
        height: 135px;
        background: linear-gradient(90deg, #7ffed8 0%, #09bdfe 100%);
        border-radius: 24px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
}
.el-select {
    width: 105px;
}
.chart_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    .el-card {
        margin-right: 24px;
        margin-bottom: 24px;
        flex-shrink: 0;
        flex-grow: 1;
        /deep/ .el-card__header {
            > div {
                display: flex;
                justify-content: space-between;
                align-items: center;
                > h2 {
                    margin: 0;
                }
            }
        }
    }
}
</style>
