<template>
  <div class="box">
    <div>
      <!-- <div class="input_box">
        <el-col :span='4'>
          <p>*当前密码</p>
          <el-input :span='1' placeholder="您当前的密码" v-model="oldInput" show-password></el-input>
        </el-col>
      </div> -->
      <div class="input_box">
        <el-col :span="4">
          <p>*请输入验证码</p>
          <el-input placeholder="请输入验证码" v-model="verifyCode"></el-input>
        </el-col>
        <el-button
          size="medium"
          :disabled="disabled"
          @click="getVerifyCode()"
          type="primary"
          >{{ valiBtn }}</el-button
        >
      </div>
      <div class="input_box">
        <el-col :span="4">
          <p>*新密码</p>
          <el-input
            placeholder="新密码"
            v-model="newInput"
            show-password
          ></el-input>
        </el-col>
      </div>
      <div class="input_box">
        <el-col :span="4">
          <p>*确认新密码</p>
          <el-input
            placeholder="确认密码"
            v-model="affirmNewInput"
            show-password
          ></el-input>
        </el-col>
      </div>
      <div style="margin-left: 800px">
        <el-button size="medium" @click="userSavePsw()" type="primary"
          >保存</el-button
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      delId: "",
      // 添加管理员二次确认框对话框
      centerDialogVisible: false,
      // 删除二次确认
      centerDialogVisibles: false,
      // 不同权限看到不同的页面
      flag: false,
      // 普通管理员修改密码
      oldInput: "",
      newInput: "",
      affirmNewInput: "",
      verifyCode: "",
      phone: "",
      // 添加管理员
      val: {
        username: "",
        password: "",
        phone: "",
      },
      // 渲染的数据
      tableData: [],
      // 搜索框
      input: "",
      // 输入框
      input1: "",
      // 控制对话框的显示和隐藏
      outerVisible: false,
      innerVisible: false,
      // 页数
      currentPage3: 1,
      total: 0, // 数据的总条数
      pageSize: 14,
      valiBtn: "获取验证码",
      disabled: false,
      // 管理员列表信息
      productionList: [],
      id:0
    };
  },
  created() {
    if(localStorage.getItem("role")==='super'){
 this.getProductionList();
    }
   
    this.id = this.$route.query.id;
    // this.checkoutUsername()
  },
  methods: {
    tackBtn() {
      //验证码倒数60秒
      let time = 60;
      let timer = setInterval(() => {
        if (time == 0) {
          clearInterval(timer);
          this.valiBtn = "获取验证码";
          this.disabled = false;
        } else {
          this.disabled = true;
          this.valiBtn = time + "秒后重试";
          time--;
        }
      }, 1000);
    },
    // 验证用户信息展示不同页面
    // async checkoutUsername () {
    //     const that = this
    //     await this.$axios.post(`/api/admin/currentRole`).then(function (res) {
    //         // console.log(res.data)
    //         if (res.data.data === 'general') {
    //             that.flag = true
    //         } else if (res.data.data === 'super') {
    //             that.flag = false
    //         } else {
    //             alert('请先登录')
    //         }
    //     })
    // },
    // 添加管理员
    addAdmin(val) {
      const that = this;
      // console.log(val)
      if (
        this.val.phone === "" ||
        this.val.password === "" ||
        this.val.username === ""
      ) {
        that.$message({
          type: "error",
          message: "请将信息填写完整",
        });
      } else {
        that.$axios.post("/api/admin/addAdmin", val).then(function (res) {
          // console.log(res)
          if (res.data.code === 200) {
            that.getProductionList();
            that.$message({
              type: "success",
              message: "添加成功!",
            });
          } else {
            that.$message({
              type: "error",
              message: "添加失败!",
            });
          }
        });
      }
    },
    // 获取管理员信息列表
    async getProductionList() {
      const that = this;
      await this.$axios
        .get(
          `/api/admin/findAllAdmin?current=${this.currentPage3}&size=${
            this.pageSize
          }&keyword=${this.input == "" ? "" : this.input}`
        )
        .then(function (res) {
          // console.log(res.data)
          that.tableData = res.data.data.records;
          // console.log( that.tableData)
          that.productionList = res.data.data;
          // console.log(that.tableData)
          // console.log(that.productionList)
          // console.log(that.total)
        });
    },
    // 删除管理员
    delAdmin() {
      const that = this;
      this.$axios
        .delete(`/api/admin/deleteAdmin?id=${this.delId}`)
        .then(function (res) {
          that.getProductionList();
          // console.log(res.data)
          that.delId = "";
          if (res.data.code === 200) {
            that.$message({
              type: "success",
              message: "操作成功!",
            });
          } else {
            that.$message({
              type: "error",
              message: "操作失败!",
            });
          }
        });
    },
    // 删除管理员
    getDelId(id) {
      this.delId = id;
    },
    // 页码大小变动的时候触发
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`)
      this.currentPage3 = 1;
      this.pageSize = val;
    },
    // 当前页变动的时候触发
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`)
      this.currentPage3 = val;
      this.getProductionList();
    },
    // 下架作品
    soldOutProduction() {},
    filterTag(value, row) {
      return row.tag === value;
    },
    filterHandler(value, row, column) {
      const property = column["property"];
      return row[property] === value;
    },
    thStyleFun() {
      return "text-align:center;background:#DCDFE6;color:#303133";
    },
    cellStyleFun() {
      return "text-align:center";
    },
    // 获取验证码等验证
    getVerifyCode() {
      this.tackBtn();
      this.$axios.post(`/api/admin/sendMsg`).then(function (res) {
        //  console.log(res.data)
        if (res.data.code === 200) {
          this.$message({
            type: "success",
            message: "发送验证码成功!",
          });
        } else {
          this.$message({
            type: "success",
            message: "发送验证码失败!",
          });
        }
      });
    },
    // 保存修改的密码
    userSavePsw() {
      // this.$axios.put(`/api/admin/resetPassword`)
      // console.log(this.oldInput,this.newInput,this.affirmNewInput)
      if (
        this.newInput === "" ||
        this.affirmNewInput === "" ||
        this.verifyCode === ""
      ) {
        this.$message({
          type: "error",
          message: "请将内容补充完整!",
        });
      } else if (this.newInput !== this.affirmNewInput) {
        this.$message({
          type: "error",
          message: "新密码和确认密码不符!",
        });
      } else {
        const data = {
          code: this.verifyCode,
          password: this.affirmNewInput,
          id: this.id,
        };
        this.$axios.put("/api/admin/setPassword", data).then( (res)=> {
           console.log(data)
          if (res.data.code === 200) {
            this.$message({
              type: "success",
              message: "密码修改成功",
            });
            if (this.id === 0) {
              localStorage.setItem("token", res.data.data.token);
            }
            this.$router.push("/");
          } else if (res.data.data === -1) {
            this.$message({
              type: "error",
              message: "验证码错误!",
            });
          }
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
.box {
  width: 100%;
  background-color: white;
}

.input_box {
  width: 80%;
  margin: 30px 500px 10px 500px;
  div {
    float: none;
    padding: 0px 20px;
    div {
      padding: 0;
    }
  }
  .el-button {
    position: absolute;
    left: 1051px;
    top: 165px;
  }
}
.el-input {
  margin: 8px 8px 8px 1.5%;
}
.oinput {
  width: 100%;
  margin: 0;
  padding: 0;
  .el-input {
    width: 89%;
  }
  .el-button {
    width: 120px;
    height: 40px;
    font-size: 14px;
    text-align: center;
  }
}
.block {
  margin: 30px auto;
  margin-bottom: 0;
  text-align: center;
}
.btn {
  margin-top: 20px;
  width: 68px;
  height: 36px;
  background: #1989fa;
  border-radius: 4px;
  text-align: center;
  line-height: 36px;
  margin: 20px 20px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.btn:hover {
  background: #449df7;
}
.btn:active {
  background: #166bc0;
}
</style>
