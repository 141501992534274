<template>
  <div class="user">
    <!-- 搜索框 -->
    <div class="oinput">
      <el-input
        placeholder="请输入任务名"
        prefix-icon="el-icon-search"
        v-model="input"
      >
      </el-input>
      <el-button
        size="medium"
        @click=";(outerVisible = true), (boxFlag = 1)"
        type="primary"
        >新增任务</el-button
      >
    </div>
    <!-- 表格 -->
    <el-main>
      <el-dialog
        width="30%"
        title="确定删除此任务吗？"
        :visible.sync="shanchu"
        append-to-body
      >
        <div slot="footer" class="dialog-footer">
          <el-button @click="shanchu = false">取 消</el-button>
          <el-button type="primary" @click="delTask(delId)">确定</el-button>
        </div>
      </el-dialog>
      <el-dialog
        width="600px"
        title="任务详情"
        :visible.sync="xiangqing"
        @close="closeBox()"
        append-to-body
      >
        <div class="content_boxs">
          <div class="left_boxs">
            <div class="rw_icon">
              <span>图标:</span>
              <img :src="iconimg[iconFlage].imgurls" alt="" />
            </div>
            <div class="rw_name">
              <span>
                任务名称:
              </span>
              {{ addList.name }}
              <!-- <el-input
                    style="width:75%"
                    v-model="addList.name"
                    placeholder="请输入任务名"
                  >
                  </el-input> -->
            </div>
            <div class="rw_describe">
              <span>
                任务描述:
              </span>
              {{ addList.describe }}
              <!-- <el-input
                    style="width:75%"
                    v-model="addList.describe"
                    placeholder="请输入任务描述"
                  >
                  </el-input> -->
            </div>
            <div class="rw_obj">
              <span>
                任务对象:
              </span>
              {{ addList.obj === 'user' ? '全体用户' : 'vip用户' }}
              <!-- <el-select style="width:75%" v-model="addList.obj" placeholder="请选择任务对象">
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select> -->
            </div>
            <div class="rw_type">
              <span>
                任务类型:
              </span>
              {{
                addList.type === 'long'
                  ? '长期任务'
                  : addList.type === 'daily'
                  ? '日常任务'
                  : '特定任务'
              }}
              <!-- <el-select style="width:75%" v-model="addList.type" placeholder="请选择任务类型">
                    <el-option
                      v-for="item in option"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select> -->
            </div>
            <div class="rw_award">
              <span>
                任务奖励:
              </span>
              {{ addList.gold }}金币
              <!-- <el-input style="width:75%" placeholder="请输入金币数" v-model="addList.gold">
                    <template slot="append">金币</template>
                  </el-input> -->
            </div>
            <div class="rw_period">
              <span>
                任务周期:
              </span>
              {{
                addList.periods === '0'
                  ? '仅一次'
                  : addList.periods === '1'
                  ? '日'
                  : addList.periods === '2'
                  ? '周'
                  : addList.periods === '3'
                  ? '月'
                  : addList.periods === '-1'
                  ? '长期'
                  : '错误'
              }}
              <!-- <el-select style="width:280px" v-model="addList.periods" placeholder="请选择任务周期">
                    <el-option
                      v-for="item in period"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select> -->
            </div>
            <div class="rw_next">
              <span>
                可完成次数:
              </span>
              {{ addList.next === -1 ? '不限次数' : addList.next }}
              <!-- <el-input @change="changeNext()" placeholder="请输入可完成次数" v-model="addList.next">
                    <template slot="append">次</template>
                  </el-input>
                  <el-checkbox @change="checkedBox()" v-model="addList.checked">不限次数</el-checkbox> -->
            </div>
            <div class="rw_next">
              <span>
                任务经验:
              </span>
              {{ addList.experience }}
              <!-- <el-input @change="changeNext()" placeholder="请输入可完成次数" v-model="addList.next">
                    <template slot="append">次</template>
                  </el-input>
                  <el-checkbox @change="checkedBox()" v-model="addList.checked">不限次数</el-checkbox> -->
            </div>
          </div>
          <!-- <div class="right_box"> -->

          <!-- </div> -->
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="xiangqing = false">取 消</el-button>
          <el-button type="primary" @click="xiangqing = false">确定</el-button>
        </div>
      </el-dialog>
      <el-dialog
        v-loading="dialogLoading"
        title="添加任务"
        center
        :visible.sync="outerVisible"
        @open="openDialog()"
        @close="closeBox()"
        width="1000px"
      >
        <el-dialog
          width="30%"
          title="确定添加此任务吗？"
          :visible.sync="innerVisible"
          append-to-body
        >
          <div slot="footer" class="dialog-footer">
            <el-button @click="innerVisible = false">取 消</el-button>
            <el-button type="primary" @click="submits()">确定</el-button>
          </div>
        </el-dialog>

        <el-dialog
          width="30%"
          title="确定修改此任务吗？"
          :visible.sync="xiugai"
          append-to-body
        >
          <div slot="footer" class="dialog-footer">
            <el-button @click="xiugai = false">取 消</el-button>
            <el-button type="primary" @click="alterTask()">确定</el-button>
          </div>
        </el-dialog>
        <div class="content_box">
          <div class="left_box">
            <div class="rw_icon">
              <span>选择图标</span>
              <ul>
                <li v-for="(item, index) in iconimg" :key="index">
                  <img
                    @click="clickIcon(item.icon, index), (iconFlage = index)"
                    class="imgs"
                    :class="{ icon_checked: iconFlage === index }"
                    :src="item.imgurls"
                    alt=""
                  />
                </li>
              </ul>
            </div>
            <div class="rw_name">
              <span>
                任务名称
              </span>
              <el-input
                style="width:75%"
                v-model="addList.name"
                placeholder="请输入任务名"
              >
              </el-input>
            </div>
            <div class="rw_describe">
              <span>
                任务描述
              </span>
              <el-input
                style="width:75%"
                v-model="addList.describe"
                placeholder="请输入任务描述"
              >
              </el-input>
            </div>
            <div class="rw_obj">
              <span>
                任务对象
              </span>
              <el-select
                style="width:75%"
                v-model="addList.obj"
                placeholder="请选择任务对象"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <div class="rw_type">
              <span>
                任务类型
              </span>
              <el-select
                style="width:75%"
                v-model="addList.type"
                placeholder="请选择任务类型"
              >
                <el-option
                  v-for="item in option"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <div class="rw_award">
              <span>
                任务奖励
              </span>
              <el-input
                style="width:75%"
                placeholder="请输入金币数"
                v-model="addList.gold"
              >
                <template slot="append">金币</template>
              </el-input>
            </div>
          </div>
          <div class="right_box">
            <div class="rw_experience">
              <span>
                任务经验
              </span>
              <el-input
                placeholder="请输入任务经验"
                v-model="addList.experience"
              >
              </el-input>
            </div>
            <div class="rw_period">
              <span>
                任务周期
              </span>
              <el-select
                style="width:280px"
                v-model="addList.periods"
                placeholder="请选择任务周期"
              >
                <el-option
                  v-for="item in period"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <div class="rw_next">
              <span>
                可完成次数
              </span>
              <el-input
                @change="changeNext()"
                placeholder="请输入可完成次数"
                v-model="addList.next"
              >
                <template slot="append">次</template>
              </el-input>
              <el-checkbox @change="checkedBox()" v-model="addList.checked"
                >不限次数</el-checkbox
              >
            </div>
          </div>
        </div>
        <div slot="footer" v-show="boxFlag === 1" class="dialog-footer">
          <el-button @click="outerVisible = false">取 消</el-button>
          <el-button type="primary" @click="innerVisible = true"
            >确定</el-button
          >
        </div>
        <div slot="footer" v-show="boxFlag === 2" class="dialog-footer">
          <el-button @click="outerVisible = false">取 消</el-button>
          <el-button type="warning" @click="xiugai = true">修改</el-button>
          <!-- <el-button type="danger" >删除</el-button> -->
        </div>
      </el-dialog>

      <el-table
        v-loading="loading"
        stripe
        :data="tableData"
        :header-cell-style="thStyleFun"
        :cell-style="cellStyleFun"
      >
        <el-table-column prop="indexs" label="图标">
          <template slot-scope="scope">
            <!-- <div class="new"></div> -->
            <div class="userName">
              <div class="imgbox">
                <img :src="iconimg[scope.row.indexs].imgurls" alt="" />
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="任务名称">
          <!-- <template slot-scope="scope">
          <div class="userName">
            <span> {{ scope.row.name }} </span>
          </div>
            </template> -->
        </el-table-column>
        <el-table-column
          prop="description"
          show-overflow-tooltip
          label="任务描述"
        >
        </el-table-column>
        <el-table-column
          prop="integral"
          label="任务对象"
          :filters="[
            { text: '全体用户', value: -2 },
            { text: 'vip用户', value: 1 },
          ]"
          :filter-method="filterTag"
          filter-placement="bottom-end"
        >
          <template slot="header">
            任务对象
            <i
              style="font-size:12px
            "
              class="icon iconfont icon-jiantou1"
            ></i>
          </template>
          <template slot-scope="scope">
            <span>{{ scope.row.role == 'user' ? '全体用户' : 'vip用户' }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="type"
          label="任务类型"
          :filters="[
            { text: '日常任务', value: 1 },
            { text: '特定任务', value: 2 },
            { text: '长期任务', value: 3 },
          ]"
          :filter-method="filterTag"
          filter-placement="bottom-end"
        >
          <template slot="header">
            任务类型
            <i
              style="font-size:12px
            "
              class="icon iconfont icon-jiantou1"
            ></i>
          </template>
          <template slot-scope="scope">
            <span>{{
              scope.row.type === 'long'
                ? '长期任务'
                : scope.row.type === 'daily'
                ? '日常任务'
                : '特定任务'
            }}</span>
          </template>
        </el-table-column>

        <el-table-column prop="cycle" label="任务周期">
          <template slot-scope="scope">
            {{
              scope.row.cycle === 0
                ? '仅一次'
                : scope.row.cycle === 1
                ? '日'
                : scope.row.cycle === 2
                ? '周'
                : scope.row.cycle === 3
                ? '月'
                : scope.row.cycle === -1
                ? '长期'
                : '错误'
            }}
          </template>
        </el-table-column>
        <el-table-column prop="integral" label="任务奖励">
          <template slot-scope="scope"> {{ scope.row.integral }}金币 </template>
        </el-table-column>
        <el-table-column prop="total" label="可完成次数">
          <template slot-scope="scope">
            {{ scope.row.total === -1 ? '不限' : scope.row.total + '次' }}
          </template>
        </el-table-column>
        <el-table-column prop="operation" label="操作">
          <template slot-scope="scope">
            <el-link
              :underline="false"
              type="primary"
              @click=";(xiangqing = true), getDetail(scope.row.id)"
              >详情</el-link
            >
            <span
              style="font-size:12px;margin:0 15.6px;color:#000000;opacity:0.3"
              >|</span
            >
            <el-link
              :underline="false"
              type="primary"
              @click="
                ;(outerVisible = true), (boxFlag = 2), getDetail(scope.row.id)
              "
              >修改</el-link
            >
            <span
              style="font-size:12px;margin:0 15.6px;color:#000000;opacity:0.3"
              >|</span
            >
            <el-link
              :underline="false"
              type="danger"
              @click=";(shanchu = true), (delId = scope.row.id)"
              >删除</el-link
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage3"
          :page-size="pageSize"
          layout="prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </el-main>
  </div>
</template>
<script>
export default {
  data() {
    // const item = {}
    return {
      imgIcon: [],
      iconimg: [
        {
          id: 1,
          icon: 'renwuguanli',
          imgurls: require('@/assets/images/icon1.png'),
        },
        {
          id: 2,
          icon: '25edit',
          imgurls: require('@/assets/images/icon2.png'),
        },
        {
          id: 3,
          icon: 'bianji',
          imgurls: require('@/assets/images/icon3.png'),
        },
        {
          id: 4,
          icon: 'chengxutiaodujiankong',
          imgurls: require('@/assets/images/icon4.png'),
        },
        {
          id: 5,
          icon: 'denglu-box-xian',
          imgurls: require('@/assets/images/icon5.png'),
        },
        {
          id: 6,
          icon: 'shangchuangerenrizhi',
          imgurls: require('@/assets/images/icon6.png'),
        },
        {
          id: 7,
          icon: 'shangchuangerenziyuan',
          imgurls: require('@/assets/images/icon7.png'),
        },
        {
          id: 8,
          icon: 'weiyingyong',
          imgurls: require('@/assets/images/icon8.png'),
        },
        {
          id: 9,
          icon: 'pinglun',
          imgurls: require('@/assets/images/icon9.png'),
        },
        {
          id: 10,
          icon: 'wanshanziliao',
          imgurls: require('@/assets/images/icon10.png'),
        },
        {
          id: 11,
          icon: 'yaoqing',
          imgurls: require('@/assets/images/icon11.png'),
        },
        {
          id: 12,
          icon: 'zuopin',
          imgurls: require('@/assets/images/icon12.png'),
        },
      ],
      // 任务对象
      options: [
        {
          value: 'vip',
          label: '会员用户',
        },
        {
          value: 'user',
          label: '普通用户',
        },
      ],
      value: '',
      // 任务周期
      period: [
        {
          value: '1',
          label: '日',
        },
        {
          value: '2',
          label: '周',
        },
        {
          value: '3',
          label: '月',
        },
        {
          value: '-1',
          label: '长期',
        },
        {
          value: '0',
          label: '仅一次',
        },
      ],
      periods: '',
      // 任务类型
      option: [
        {
          value: 'daily',
          label: '日常任务',
        },
        {
          value: 'special',
          label: '特定任务',
        },
        {
          value: 'long',
          label: '长期任务',
        },
      ],
      values: '',
      checked: false,
      // 任务奖金
      gold: '',
      // 对话框里的按钮
      boxFlag: 0,
      // 上传图片
      dialogImageUrl: '',
      dialogVisible: false,
      addList: {
        name: '',
        describe: '',
        obj: '',
        type: '',
        gold: '',
        id: '',
        company: '',
        periods: '',
        next: '',
        icon: '',
        experience: '',
        checked: false,
        flag: 0,
      },
      // 对话框
      outerVisible: false,
      innerVisible: false,
      shanchu: false,
      xiugai: false,
      xiangqing: false,
      // 任务页数据
      tableData: [],
      // 搜索框
      input: '',
      // 页数
      currentPage3: 1,
      total: 0, // 数据的总条数
      pageSize: 14,
      productionList: [],
      imgsFlag: false,
      productionData: [],
      loading: false,
      delId: '',
      dialogLoading: false,
      iconFlage: 0,
    }
  },
  created() {
    this.getProductionList()
  },
  methods: {
    // 修改
    alterTask() {
      this.dialogLoading = true
      // console.log(this.addList)
      const that = this
      const data = {
        cycle: this.addList.periods,
        description: this.addList.describe,
        integral: this.addList.gold,
        name: this.addList.name,
        role: this.addList.obj,
        total: this.addList.next,
        type: this.addList.type,
        icon: this.addList.icon,
        id: this.addList.id,
        experience: this.addList.experience,
      }
      // console.log(data)
      this.$axios.post('/api/task/updateTask', data).then((res) => {
        console.log(res)
        if (res.data.code === 200) {
          that.$message({
            message: '修改成功',
            type: 'success',
          })
        } else {
          that.$message({
            message: '修改失败',
            type: 'warning',
          })
        }
        that.getProductionList()
        that.xiugai = false
        that.outerVisible = false
        that.dialogLoading = false
      })
    },
    //  当对话框打开的时候判断次数和checked的状态
    openDialog() {
      this.clickIcon(this.iconimg[0].icon)
      if (this.addList.next === '') {
        this.addList.checked = true
      }
      if (this.addList.next !== '') {
        this.addList.checked = false
      }
    },
    // 删除
    delTask(id) {
      const that = this
      // console.log(id)
      this.$axios.delete(`/api/task/deleteTask?taskId=${id}`).then((res) => {
        // console.log(res.data)
        if (res.data.code === 200) {
          that.shanchu = false
          that.outerVisible = false
          that.getProductionList()
          that.$message({
            message: '删除成功',
            type: 'success',
          })
        }
      })
    },
    // 关闭对话框的时候
    closeBox() {
      this.addList.periods = ''
      this.addList.describe = ''
      this.addList.icon = ''
      this.addList.gold = ''
      this.addList.name = ''
      this.addList.obj = ''
      this.addList.next = ''
      this.addList.type = ''
      this.addList.experience = ''
    },
    // checked
    checkedBox() {
      // console.log(this.addList.checked)
      // console.log('11111')
      // const that = this
      if (this.addList.checked === true) {
        this.addList.next = ''
      }
      // console.log(this.addList.flag)
    },
    // 任务次数设置
    changeNext() {
      if (this.addList.next === '') {
        this.addList.checked = true
      } else {
        this.addList.checked = false
      }
      if (this.addList.checked === true) {
        this.addList.next = ''
      }
    },
    // 测试icon
    clickIcon(icon) {
      // console.log(icon)
      // console.log(this.iconFlage)
      this.addList.icon = icon
    },
    // 详情
    getDetail(id) {
      // console.log(this.periods)
      // console.log(id)
      let data = this.tableData.find((ele) => {
        return ele.id === id
      })
      // console.log(data)
      this.productionData = data
      this.addList.periods = String(data.cycle)
      this.addList.describe = data.description
      this.addList.icon = data.icon
      this.addList.company = data.company
      this.addList.gold = data.integral
      this.addList.name = data.name
      this.addList.id = data.id
      this.addList.obj = data.role
      this.addList.next = data.total
      this.addList.type = data.type
      this.addList.experience = data.experience
      let icons = this.iconimg.find((ele) => {
        return ele.icon === data.icon
      })
      // console.log(icons)
      this.iconFlage = icons.id - 1
    },
    //  添加任务
    submits() {
      const that = this
      this.innerVisible = false
      this.outerVisible = false
      const list = {
        cycle: this.addList.periods,
        description: this.addList.describe,
        icon: this.addList.icon,
        integral: this.addList.gold,
        name: this.addList.name,
        role: this.addList.obj,
        total: this.addList.checked === true ? -1 : this.addList.next,
        type: this.addList.type,
        experience: this.addList.experience,
      }
      // console.log(list)
      if (
        this.addList.periods === '' ||
        this.addList.describe === '' ||
        this.addList.icon === '' ||
        this.addList.gold === '' ||
        this.addList.name === '' ||
        this.addList.obj === '' ||
        this.addList.experience === '' ||
        this.addList.type === ''
      ) {
        that.$message({
          message: '请将内容补充完整!',
          type: 'error',
        })
      } else {
        that.$axios.post('/api/task/addTask', list).then((res) => {
          // console.log(res.data)
          this.getProductionList()
          if (res.data.code === 200) {
            that.$message({
              message: '添加成功!',
              type: 'success',
            })
          }
        })
      }
    },
    handleRemove(file, fileList) {
      console.log(file, fileList)
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    // 获取任务页信息
    async getProductionList() {
      this.loading = true
      const that = this
      await this.$axios.get(`/api/task/findTask`).then(function(res) {
        // console.log(res.data)
        that.tableData = res.data.data
        for (let i = 0; i < that.tableData.length; i++) {
          var index = that.iconimg.findIndex(function(item) {
            return item.icon === that.tableData[i].icon
          })
          // console.log(index)
          that.tableData[i].indexs = index
          // console.log(that.tableData)
          that.imgsFlag = true
          that.loading = false
        } //根据数组中的对象中的值，来获取当前对象在数组里的索引
      })
    },
    // 页码大小变动的时候触发
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`)
      this.currentPage3 = 1
      this.pageSize = val
    },
    // 当前页变动的时候触发
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`)
      this.currentPage3 = val
      this.getProductionList()
    },
    filterTag(value, row) {
      return row.tag === value
    },
    filterHandler(value, row, column) {
      const property = column['property']
      return row[property] === value
    },
    thStyleFun() {
      return 'text-align:center;background:#DCDFE6;color:#303133'
    },
    cellStyleFun() {
      return 'text-align:center'
    },
  },
}
</script>
<style lang="less" scoped>
.user {
  width: 100%;
  height: 100%;
}
// 对话框左侧
.content_box {
  min-height: 400px;
}

.left_box {
  float: left;
  width: 50%;
  // height: 100%;
  .rw_name,
  .rw_describe,
  .rw_obj,
  .rw_type,
  .rw_award {
    margin: 10px 0;
    span {
      display: inline-block;
      width: 70px;
      text-align: center;
    }
  }
  .rw_icon {
    height: 70px;
    span {
      display: inline-block;
      width: 70px;
      text-align: center;
    }
    ul {
      margin-left: 35px;
      margin-top: -30px;
      li {
        list-style: none;
        width: 36px;
        height: 36px;
        float: left;
        margin: 3px 3px;
        // border-radius: 5px;
        .imgs {
          width: 26px;
          border: 2px solid #1989fa;
          height: 26px;
          padding: 5px;
          border-radius: 5px;
          background: #1989fa;
        }
        .icon_checked {
          border: 2px solid #111;
        }
      }
    }
  }
}

.right_box {
  float: right;
  width: 50%;
  margin-top: 275px;
  // background: red;
  // margin-left: 50px;
  .uploading_img {
    margin-bottom: 20px;
  }
  .rw_period {
    width: 100%;
    .el-input {
      width: 280px;
    }
    span {
      margin-right: 20px;
    }
  }
  .rw_next {
    width: 100%;
    .el-input {
      width: 280px;
    }
    .el-checkbox {
      float: right;
      margin-top: 20px;
    }
  }
  .rw_experience {
    width: 100%;
    span {
      margin-right: 13px;
    }
    .el-input {
      width: 280px;
    }
  }
}
.left_boxs {
  .rw_icon {
    padding: 0;
    margin: 0;
    img {
      width: 34px;
      height: 34px;
      background: #1989fa;
      padding: 5px;
      border-radius: 5px;
      margin-left: 10px;
      // margin-top: 20px;
    }
  }
  width: 100%;
  div {
    margin: 5px 0;
  }
  span {
    font-weight: 700;
    font-size: 18px;
  }
}
.el-input {
  margin: 8px 1.5%;
  width: 97%;
}
.el-input {
  margin: 8px 8px 8px 1.5%;
}
.oinput {
  width: 100%;
  margin: 0;
  padding: 0;
  .el-input {
    width: calc(100% - 155px);
  }
  .el-button {
    width: 100px;
    height: 40px;
    font-size: 14px;
    text-align: center;
  }
}
.userName {
  height: 100%;
  // width: 300px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  .imgbox {
    display: inline-block;
    background: #1989fa;
    width: 32px;
    height: 32px;
    border-radius: 5px;
  }
  img {
    width: 20px;
    height: 20px;
    margin: 6px auto;
  }
  span {
    position: relative;
    top: -10px;
    margin-left: 10px;
  }
}
.block {
  margin: 30px auto;
  text-align: center;
}
.production_img {
  width: 100%;
  height: 482px;
  background: #dcdfe6;
  margin-bottom: 30px;
}
.tag_box {
  .el-tag {
    margin: 0 20px;
  }
}
/deep/ .el-table__column-filter-trigger {
  opacity: 0;
}
</style>
