<template>
    <div class="log">
        <!-- 搜索框 -->
        <div class="oinput">
            <el-input
                placeholder="请输入职位标题"
                prefix-icon="el-icon-search"
                v-model="keyword"
                @change="getProductionList()"
            >
            </el-input>
            <el-button
                size="medium"
                @click="(outerVisible = true), (imgFlag = 0), (btnFlag = 1);"
                type="primary"
                >发布职位</el-button
            >
        </div>

        <!-- 表格 -->
        <el-main>
            <el-table
                v-loading="loading"
                stripe
                :data="tableData"
                :header-cell-style="thStyleFun"
                :cell-style="cellStyleFun"
                @filter-change="filterChange"
            >
                <el-table-column prop="cover" label="封面">
                    <template slot-scope="scope">
                        <div class="demo-image">
                            <div
                                class="block"
                                style="
                                    margin: 0 auto;
                                    width: 178px;
                                    height: 100px;
                                "
                            >
                                <el-image
                                    style="width: 178px; height: 100px"
                                    :src="scope.row.cover"
                                    fit="scale-down"
                                ></el-image>
                            </div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="name" show-overflow-tooltip label="标题">
                </el-table-column>
                <el-table-column
                    prop="company"
                    show-overflow-tooltip
                    label="公司"
                >
                </el-table-column>
                <el-table-column
                    prop="position"
                    show-overflow-tooltip
                    label="职位"
                >
                    <template slot-scope="scope">
                        <div class="userName">
                            <span> {{ scope.row.position }} </span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="place"
                    show-overflow-tooltip
                    label="地点"
                >
                </el-table-column>
                <el-table-column
                    prop="weight"
                    show-overflow-tooltip
                    label="权重"
                >
                </el-table-column>
                <el-table-column
                    prop="status"
                    label="状态"
                    :filters="[
                        { text: '下架', value: 0 },
                        { text: '正常', value: 1 },
                    ]"
                    filter-placement="bottom-end"
                    :filter-multiple="false"
                    column-key="status"
                >
                    <template slot="header">
                        状态
                        <i
                            style="font-size: 12px"
                            class="icon iconfont icon-jiantou1"
                        ></i>
                    </template>
                    <template slot-scope="scope">
                        <el-switch
                            v-model="scope.row.status"
                            @change="shagnjia(scope.row.id, scope.row.status)"
                            :active-value="1"
                            :inactive-value="0"
                            active-color="#67C23A"
                            inactive-color="#cecece"
                        >
                        </el-switch>
                    </template>
                </el-table-column>
                <el-table-column prop="operation" label="操作">
                    <!-- <template slot-scope="scope">
            <el-link
              :underline="false"
              type="primary"
              @click="getParticulars(scope.row.id, 1)"
              >详情</el-link
            >
            <span
              style="font-size:12px;margin:0 15.6px;color:#000000;opacity:0.3"
              >|</span
            >
            <el-link
              :underline="false"
              type="primary"
              @click="getParticulars(scope.row.id, 2)"
              >修改</el-link
            >
            <span
              style="font-size:12px;margin:0 15.6px;color:#000000;opacity:0.3"
              >|</span
            >
            <el-link
              :underline="false"
              type="danger"
              @click="getParticulars(scope.row.id, 3)"
              >删除</el-link
            >
          </template> -->
                    <template slot-scope="scope">
                        <el-link
                            :underline="false"
                            type="primary"
                            @click="
                                (xiangqing = true), (clickIds = scope.row.id);
                            "
                            >详情</el-link
                        >
                        <span
                            style="
                                font-size: 12px;
                                margin: 0 15.6px;
                                color: #000000;
                                opacity: 0.3;
                            "
                            >|</span
                        >
                        <el-link
                            :underline="false"
                            type="primary"
                            @click="
                                (outerVisible = true),
                                    (clickIds = scope.row.id),
                                    (btnFlag = 2);
                            "
                            >修改</el-link
                        >
                        <span
                            style="
                                font-size: 12px;
                                margin: 0 15.6px;
                                color: #000000;
                                opacity: 0.3;
                            "
                            >|</span
                        >
                        <el-link
                            :underline="false"
                            type="danger"
                            @click="(delJob = true), (clickIds = scope.row.id);"
                            >删除</el-link
                        >
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div class="block">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page.sync="currentPage3"
                    :page-size="pageSize"
                    layout="prev, pager, next, jumper"
                    :total="total"
                >
                </el-pagination>
            </div>
        </el-main>
        <!-- 对话框 -->
        <el-dialog
            width="700px"
            title="职位详情"
            :visible.sync="xiangqing"
            @close="guanbi(2)"
            @open="getParticulars(clickIds)"
            v-loading.fullscreen.lock="loadingAll"
            :element-loading-text="uploadMessage"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)"
        >
            <div class="xiangqing_box">
                <div class="fengmian">
                    <span> 封面： </span>
                    <img :src="jobDetail.cover" alt="" />
                </div>
                <div>
                    <span> 权重： </span>
                    {{ jobDetail.weight }}
                </div>
                <div>
                    <span> 公司： </span>
                    {{ jobDetail.company }}
                </div>
                <div>
                    <span> 标题： </span>
                    {{ jobDetail.name }}
                </div>
                <div>
                    <span> 职位： </span>
                    {{ jobDetail.position }}
                </div>
                <div>
                    <span> 地点： </span>
                    {{ jobDetail.place }}
                </div>
                <div>
                    <span> 时间： </span>
                    {{ jobDetail.date }}
                </div>
                <div>
                    <span> 职位简介： </span>
                    <div class="text" v-html="log.detail"></div>
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="xiangqing = false">取 消</el-button>
            </div>
        </el-dialog>
        <el-dialog
            center
            @close="guanbi(1)"
            :visible.sync="outerVisible"
            :close-on-click-modal="false"
            width="1000px"
            @open="getParticulars(clickIds)"
            v-loading.fullscreen.lock="loadingAll"
            :element-loading-text="uploadMessage"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)"
        >
            <el-dialog
                width="30%"
                title="确定要添加此职位吗?"
                :visible.sync="innerVisibles"
                append-to-body
            >
                <span slot="footer" class="dialog-footer">
                    <el-button @click="innerVisibles = false">取消</el-button>
                    <el-button type="primary" @click="submit()">确定</el-button>
                    <!--postfile()-->
                </span>
            </el-dialog>
            <div class="issue_content">
                <div class="cover">
                    <span class="cover_span"> 封面 </span>
                    <div
                        v-show="headerUrl || jobDetail.cover"
                        class="coverBox"
                        style="
                            width: 219px;
                            height: 123.5px;
                            float: left;
                            margin-right: 20px;
                            border-radius: 10px;
                        "
                    >
                        <div class="yinyin" @click="clearHeader()">
                            <span>
                                <i class="iconfont icon-shanchu"></i>
                            </span>
                        </div>
                        <!-- <img style="width:100%;height:100%" :src="jobDetail.cover" alt="" /> -->
                        <img :src="headerUrl || jobDetail.cover" alt="" />
                    </div>
                    <!-- <el-upload
            accept="image/*"
            ref="upload"
            style="width:1000px"
            action="https://jsonplaceholder.typicode.com/posts/"
            list-type="picture-card"
            show-file-list
            :on-preview="handlePictureCardPreview"
            :auto-upload="false"
            :on-remove="handleRemove"
            :limit="1"
            :on-change="handleChansge"
          >
            <div class="icon"><i class="el-icon-plus"></i></div>
            <div class="el-upload__text">建议分辨率438x247</div>
          </el-upload> -->
                    <input
                        type="file"
                        accept=".jpg,.png"
                        name="activityHeader"
                        style="display: none"
                        @change="handleChansge"
                        id="header"
                    />
                    <label
                        for="header"
                        v-show="headerUrl == '' && !jobDetail.cover"
                    >
                        <div class="upLoaderHeaderBox">
                            <span><i class="iconfont icon-jiahaocu"></i></span>
                            <span>建议分辨率438×247</span>
                            <span>请选择上传jpg,png格式</span>
                        </div>
                    </label>
                </div>
                <div class="rw_describe">
                    <span> 权重 </span>
                    <el-popover placement="right" width="400" trigger="focus">
                        <el-input
                            slot="reference"
                            style="width: 30%"
                            v-model="addJob.weight"
                            placeholder="权重越大位置越靠前，可以不填"
                        >
                        </el-input>
                        <p>权重越大位置越靠前，可以不填</p>
                    </el-popover>
                </div>
                <div class="rw_describe">
                    <span> 公司 </span>
                    <el-input
                        style="width: 30%"
                        v-model="addJob.company"
                        placeholder="请输入公司名称"
                    >
                    </el-input>
                </div>
                <div class="rw_describe">
                    <span> 标题 </span>
                    <el-input
                        style="width: 90%"
                        v-model="addJob.name"
                        placeholder="请输入标题"
                    >
                    </el-input>
                </div>
                <div class="rw_describe">
                    <span> 职位 </span>
                    <el-input
                        style="width: 90%"
                        v-model="addJob.position"
                        placeholder="请输入职位"
                    >
                    </el-input>
                </div>
                <div class="rw_describe">
                    <span> 地点 </span>
                    <el-input
                        style="width: 90%"
                        v-model="addJob.place"
                        placeholder="请输入地点"
                    >
                    </el-input>
                </div>
                <div class="rw_describe">
                    <span> 时间 </span>
                    <el-input
                        style="width: 30%"
                        v-model="addJob.date"
                        placeholder="点击确定自动获取时间"
                    >
                    </el-input>
                    <el-button type="primary" @click="getDates()"
                        >点击获取当前时间</el-button
                    >
                </div>
                <div v-loading="richLoading" class="rich_text">
                    <rich
                        style="z-index: 1"
                        :detail="log.detail"
                        isClear="false"
                        ref="editorRef"
                        @change="changeLogDetail"
                    ></rich>
                </div>
            </div>
            <div slot="footer" v-show="btnFlag === 1" class="dialog-footer">
                <el-button @click="outerVisible = false">取 消</el-button>
                <el-button type="primary" @click="submit">确 定</el-button>
            </div>
            <div slot="footer" v-show="btnFlag === 2" class="dialog-footer">
                <el-button @click="outerVisible = false">取 消</el-button>
                <el-button type="warning" @click="alterPosition(jobDetail.id)"
                    >修 改</el-button
                >
                <!-- <el-button type="danger" @click="delJob = true"
          >删 除</el-button> -->
            </div>

            <!-- <el-dialog
        width="30%"
        title="确定修改吗？"
        :visible.sync="alter"
        append-to-body
      >
        <div slot="footer" class="dialog-footer">
          <el-button
            type="primary"
            @click="
              ;(alter = false),
                (outerVisible = false),
                alterPosition(jobDetail.id)
            "
            >修 改</el-button
          >
          <el-button @click="alter = false">取 消</el-button>
        </div>
      </el-dialog> -->
        </el-dialog>
        <el-dialog
            width="30%"
            title="确定删除吗？"
            :visible.sync="delJob"
            @close="guanbi(1)"
            append-to-body
        >
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="delDetails(clickIds)"
                    >确 定</el-button
                >
                <el-button @click="delJob = false">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import rich from '../zujian/richText.vue'
export default {
    components: {
        rich,
    },
    data () {
        return {
            tableData: [],
            // 富文本内容
            log: {
                detail: '',
            },
            // 搜索框
            keyword: '',
            // 请求详情接口传递的类型
            type: 'tutorial',
            // 时间、地点、职位、标题
            addJob: {
                date: '',
                place: '',
                position: '',
                name: '',
                text: '',
                company: '',
                weight: '',
            },
            alterJob: {
                date: '',
                place: '',
                position: '',
                name: '',
            },
            // 富文本flag
            flags: false,
            // 页数
            currentPage3: 1,
            total: 0, // 数据的总条数
            pageSize: 7,
            // 上传图片
            dialogImageUrl: '',
            dialogVisible: false,
            // 封面的显示和隐藏
            imgFlag: 0,
            // 对话框
            outerVisible: false,
            xiangqing: false,
            // innerVisible: false,
            innerVisibles: false,
            // 详情对话框
            alter: false,
            details: false,
            delJob: false,
            alterpositions: false,
            // 数据数组
            productionList: [],
            // 上传的文件名
            file: '',
            flag: false,
            jobId: '',
            // 点击的职位的详情信息
            jobDetail: [],
            files: '',
            loading: false,
            richLoading: false,
            // 控制按钮的切换
            btnFlag: 0,
            headerUrl: '',
            loadingAll: false,
            uploadMessage: '拼命加载中...',
            uploadStyle: {
                width: '0%',
            },
            status: '',
            clickIds: '',
        }
    },
    mounted () {
        // this.$refs.editorRef.updateEditorData()
    },
    created () {
        this.getProductionList()
    },
    methods: {
        // 筛选
        filterChange (filterObj) {
            // console.log(filterObj)
            if (Object.keys(filterObj)[0] == 'status') {
                this.getProductionList(filterObj, 'status')
            }
        },
        // 清除封面/背景
        clearHeader () {
            this.headerUrl = ''
            document.getElementById('header').value = null
            this.jobDetail.cover = null
        },
        // 关闭对话框时候执行的
        guanbi (i) {
            if (i == 1) {
                document.getElementById('header').value = null
            }
            this.imgFlag = 0
            this.btnFlag = 0
            this.addJob.name = ''
            this.jobDetail.cover = ''
            this.addJob.date = ''
            this.addJob.place = ''
            this.addJob.company = ''
            this.addJob.position = ''
            this.addJob.weight = ''
            this.log.detail = ''
            this.$refs.editorRef.clearTxt()
            this.file = ''
            this.clickIds = ''
            this.headerUrl = ''
        },
        // 修改职位信息
        alterPosition (id) {
            const that = this
            const data = {
                id: id,
                name: this.addJob.name,
                place: this.addJob.place,
                position: this.addJob.position,
                date: this.addJob.date,
                company: this.addJob.company,
                status: this.jobDetail.status,
                weight: this.addJob.weight,
            }
            if (
                this.addJob.date === '' &&
                this.addJob.place === '' &&
                this.addJob.company === '' &&
                this.addJob.position === '' &&
                this.addJob.weight === '' &&
                this.addJob.name === ''
            ) {
                console.log('meiyougaibian-----------')
                that.$message({
                    type: 'error',
                    message: '请将内容补充完整，顺序不能有重复噢！',
                })
                return
            } else {
                this.loadingAll = true
                this.$axios
                    .put('/api/position/updatePosition', data)
                    .then(function (res) {
                        // console.log(res.data)
                        if (res.data.code === 200) {
                            Promise.all([that.postRich(id), that.postfile(id)]).then(
                                (ress) => {
                                    console.log(ress)
                                    that.$message({
                                        type: 'success',
                                        message: '修改成功',
                                    })
                                    that.outerVisible = false
                                    that.loadingAll = false
                                    that.getProductionList()
                                }
                            )
                        } else {
                            that.loadingAll = false
                            that.$message({
                                type: 'error',
                                message: '修改失败',
                            })
                        }
                    })
            }
            // if (this.file === '') {
            //   // console.log('没有上传文件')
            // } else {
            //   // this.postfile(id)
            // }
        },
        // 删除职位
        delDetails (id) {
            const that = this
            // console.log(id)
            this.$axios
                .delete(`/api/position/deletePosition?id=${id}`)
                .then(function (res) {
                    // console.log(res.data)
                    if (res.data.code === 200) {
                        that.delJob = false
                        that.$message({
                            type: 'success',
                            message: '删除成功！',
                        })
                        that.getProductionList()
                    } else {
                        that.delJob = false
                        that.$message({
                            type: 'error',
                            message: res.data.message,
                        })
                    }
                })
        },
        // 清空富文本
        clearRich () {
            // if (this.log.detail) {
            //   this.$refs.editorRef.clearTxt()
            // }
        },
        // 上传富文本
        postRich (id) {
            return new Promise((ress, rej) => {
                const that = this
                const data = {
                    id: id,
                    text: this.log.detail,
                }
                let config = {
                    onUploadProgress: function (e) {
                        //属性lengthComputable主要表明总共需要完成的工作量和已经完成的工作是否可以被测量
                        //如果lengthComputable为false，就获取不到e.total和e.loaded
                        if (e.lengthComputable) {
                            var rate = (that.uploadRate = e.loaded / e.total) //已上传的比例
                            if (rate < 1) {
                                //这里的进度只能表明文件已经上传到后台，但是后台有没有处理完还不知道
                                //因此不能直接显示为100%，不然用户会误以为已经上传完毕，关掉浏览器的话就可能导致上传失败
                                //等响应回来时，再将进度设为100%
                                that.uploadRate = rate
                                that.uploadStyle.width = (rate * 100).toFixed(2) + '%'
                                // console.log('进度：', that.uploadStyle.width, that.uploadRate)
                                that.uploadMessage =
                                    '上传富文本中' + that.uploadStyle.width + '请稍等...'
                            }
                        }
                    },
                }
                this.$axios
                    .post('/api/position/uploadText', data, config)
                    .then(function (res) {
                        // console.log(res.data.message + '富文本上传成功')
                        if (res.data.code === 200) {
                            ress(true)
                            that.log.detail = ''
                            that.$refs.editorRef.clearTxt()
                        } else {
                            that.$message({
                                type: 'error',
                                message: res.data.message,
                            })
                            rej(false)
                        }
                    })
            })
        },
        // 上传基本信息
        submit () {
            const that = this
            // console.log(
            //   this.addJob.name,
            //   this.addJob.place,
            //   this.addJob.position,
            //   this.file,
            //   this.addJob.date
            // )
            if (
                this.name === '' ||
                this.place === '' ||
                this.position === '' ||
                this.date === ''
            ) {
                that.innerVisibles = false
                that.$message({
                    type: 'error',
                    message: '请将内容补充完整，封面也记得上传噢！',
                })
            } else {
                this.loadingAll = true
                that.$axios
                    .post('/api/position/addPosition', this.addJob)
                    .then(function (res) {
                        // console.log(res.data)
                        if (res.data.code === 200) {
                            that.jobId = res.data.data
                            Promise.all([
                                that.postfile(that.jobId),
                                that.postRich(that.jobId),
                            ]).then((ress) => {
                                console.log(ress)
                                that.loadingAll = false

                                that.$message({
                                    type: 'success',
                                    message: '添加完成',
                                })
                                // console.log(that.jobId)
                                // if (that.jobId !== '') {
                                that.getProductionList()
                                // }
                                that.innerVisibles = false
                                that.outerVisible = false
                            })
                        } else {
                            that.loadingAll = false
                            that.$message({
                                type: 'error',
                                message: res.data.message,
                            })
                        }
                    })
            }
        },
        // 上传文件
        postfile (id) {
            // console.log(id+'测试i')
            return new Promise((res, rej) => {
                let that = this
                if (this.file == '') {
                    res(true)
                    // that.$notify.error({
                    //   title: '错误',
                    //   message: '图片未选择',
                    // })
                } else {
                    // 文件形式的需要用 formData形式
                    let formData = new FormData()
                    formData.append('file', this.file)
                    // console.log(formData)
                    let config = {
                        headers: { 'Content-Type': 'multipart/form-data' },
                        onUploadProgress: function (e) {
                            //属性lengthComputable主要表明总共需要完成的工作量和已经完成的工作是否可以被测量
                            //如果lengthComputable为false，就获取不到e.total和e.loaded
                            if (e.lengthComputable) {
                                var rate = (that.uploadRate = e.loaded / e.total) //已上传的比例
                                if (rate < 1) {
                                    //这里的进度只能表明文件已经上传到后台，但是后台有没有处理完还不知道
                                    //因此不能直接显示为100%，不然用户会误以为已经上传完毕，关掉浏览器的话就可能导致上传失败
                                    //等响应回来时，再将进度设为100%
                                    that.uploadRate = rate
                                    that.uploadStyle.width = (rate * 100).toFixed(2) + '%'
                                    // console.log('进度：', that.uploadStyle.width, that.uploadRate)
                                    that.uploadMessage =
                                        '上传封面中' + that.uploadStyle.width + '请稍等...'
                                }
                            }
                        },
                    }
                    // this.btn.disable = true;
                    // this.btn.message = "上传中，请等待";
                    this.$axios
                        .post(`/api/position/uploadCover?id=${id}`, formData, config)
                        .then(function (response) {
                            // console.log(response)
                            if (response.data.code == 200) {
                                res(true)
                                that.$message({
                                    type: 'success',
                                    message: '上传成功!',
                                })
                                that.filelist = []
                                // that.getProductionList()
                                // that.btn.disable = false;
                                // that.btn.message = "上传服务器";
                                // that.$refs.upload.clearFiles()
                                formData.delete('file')
                                that.file = ''
                            } else {
                                rej(false)
                                that.$message({
                                    type: 'warning',
                                    message: '上传过程出错啦!',
                                })
                            }
                        })
                }
            })
        },
        // 文件格式验证
        handleChansge (e) {
            this.file = e.target.files[0]
            this.headerUrl = this.getImgUrl(this.file)
        },
        handleRemove () {
            // console.log(file, fileList)
        },
        handlePictureCardPreview (file) {
            this.dialogImageUrl = file.url
            // console.log(this.dialogImageUrl)
            this.dialogVisible = true
        },

        // 生成本地地址预览图片
        getImgUrl (file) {
            let url = null
            if (window.createObjectURL != undefined) {
                // basic
                url = window.createObjectURL(file)
            } else if (window.webkitURL != undefined) {
                // webkit or chrome
                url = window.webkitURL.createObjectURL(file)
            } else if (window.URL != undefined) {
                // mozilla(firefox)
                url = window.URL.createObjectURL(file)
            }
            return url
        },
        submitUpload () {
            this.$refs.upload.submit()
        },

        // 图片上传成功后，后台返回图片的路径
        onSuccess: function (res) {
            // console.log(res);
            if (res.status == 200) {
                this.imgUrl = res.data.imgUrl
            }
        },
        //上架下架
        shagnjia (id, status) {
            const that = this
            this.$axios.put(`/api/position/banPosition?id=${id}`).then(function (res) {
                // console.log(res.data)
                if (res.data.code === 200) {
                    if (status === true) {
                        that.$message({
                            type: 'success',
                            message: '上架成功!',
                        })
                    } else if (status === false) {
                        that.$message({
                            type: 'success',
                            message: '下架成功!',
                        })
                    }
                } else {
                    that.$message({
                        type: 'error',
                        message: '操作失败!',
                    })
                }
            })
        },
        // 富文本
        changeLogDetail (val) {
            this.log.detail = val
        },
        // 获取当前时间
        getDates () {
            var aData = new Date()
            // console.log(aData)
            this.addJob.date =
                aData.getFullYear() +
                '-' +
                (aData.getMonth() + 1) +
                '-' +
                aData.getDate() +
                ' ' +
                aData.getHours() +
                ':' +
                aData.getMinutes() +
                ':' +
                aData.getSeconds()
            // console.log(this.addJob.date)
        },
        // 点击获取资源详情
        getParticulars (e) {
            if (e == '') {
                return
            }
            // if (i == 1) {
            //   this.xiangqing = true
            // } else if (i == 2) {
            //   this.outerVisible = true
            //   this.btnFlag = 2
            // } else {
            //   this.delJob = true
            // }
            const that = this
            this.loadingAll = true
            // console.log(e)
            this.$axios
                .get(`/api/position/findPositionDetail?id=${e}`)
                .then(function (res) {
                    // console.log(res.data)
                    that.jobDetail = res.data.data
                    // console.log(that.jobDetail)
                    if (res.data.code === 200) {
                        that.addJob.name = res.data.data.name
                        that.addJob.date = res.data.data.date
                        that.addJob.company = res.data.data.company
                        that.addJob.place = res.data.data.place
                        that.addJob.position = res.data.data.position
                        that.addJob.weight = res.data.data.weight
                        that.addJob.text = res.data.data.text
                        that.imgFlag = 1
                        fetch(res.data.data.text, {
                            method: 'GET',
                            mode: 'cors',
                        })
                            .then((res) => {
                                // that.loadingAll = false
                                return res.text()
                            })
                            .then((result) => {
                                that.log.detail = result
                                // that.loadingAll = false

                                that.$nextTick(() => {
                                    // 调用子组件的方法，更新里面的数据
                                    // console.log(that.richLoading)
                                    that.loadingAll = false
                                    // console.log(that.richLoading)
                                    that.$refs.editorRef.updateEditorData()
                                })
                            })
                    }
                })
        },
        // 获取资源页面数据
        getProductionList (e, type) {
            this.loading = true
            if (type == 'status') {
                if (e.status.length > 0) {
                    this.status = e.status[0]
                } else {
                    this.status = ''
                }
            }
            const data = {
                current: this.currentPage3,
                size: this.pageSize,
                keyword: this.keyword,
                status: this.status,
            }
            const that = this
            this.$axios.post('/api/position/findPosition', data).then(function (res) {
                // console.log(res.data.data)
                that.total = res.data.data.total
                // console.log(that.total)
                that.tableData = res.data.data.records
                // let msgs = false
                // for (let i = 0; i < res.data.data.records.length; i++) {
                // if (res.data.data.records[i].status === 1) {
                //   res.data.data.records[i].status = true
                //   // console.log(res.data.data.records[i].status)
                // } else {
                //   res.data.data.records[i].status = false
                //   // console.log(res.data.data.records[i].status)
                // }
                // }
                that.loading = false
                // that.productionList = res.data.data
                // console.log(that.tableData)
                // console.log(that.productionList)
                // that.total = res.data.data[0].page
                // console.log(that.total)
            })
        },
        // 页码大小变动的时候触发
        handleSizeChange (val) {
            // console.log(`每页 ${val} 条`)
            this.currentPage3 = 1
            this.pageSize = val
        },
        // 当前页变动的时候触发
        handleCurrentChange (val) {
            // console.log(`当前页: ${val}`)
            this.currentPage3 = val
            this.getProductionList()
        },
        // 状态筛选
        // filterTag(value, row) {
        //   return row.isPublic === value
        // },
        filterHandler (value, row, column) {
            const property = column['property']
            return row[property] === value
        },
        // 表格内容居中
        thStyleFun () {
            return 'text-align:center;background:#DCDFE6;color:#303133'
        },
        cellStyleFun () {
            return 'text-align:center'
        },
    },
}
</script>
<style lang="less" scoped>
// 整个页面
.log {
    width: 100%;
    height: 100%;
}
// 搜索框
.el-input {
    margin: 8px 8px 8px 1.5%;
}
.oinput {
    width: 100%;
    margin: 0;
    padding: 0;
    .el-input {
        width: calc(100% - 170px);
    }
    .el-button {
        width: 120px;
        height: 40px;
        font-size: 14px;
        text-align: center;
    }
}
// 用户名和头像
.userName {
    height: 100%;
    // width: 300px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    img {
        width: 32px;
        height: 32px;
        border-radius: 50%;
    }
}
.issue_content {
    .cover {
        width: 100%;
        height: 135px;
        .cover_span {
            float: left;
            margin-right: 20px;
            line-height: 135px;
        }
        /deep/ .el-upload {
            width: 240px;
            height: 135px;
            background: #1989fa;
            i {
                color: #fff;
            }
            .el-upload__text {
                // float: left;
                height: 0;
                text-align: center;
                margin-top: -120px;
                color: #fff;
            }
        }
        .coverBox {
            width: 219px;
            height: 123.5px;
            float: left;
            margin-right: 20px;
            border-radius: 10px;
            position: relative;
            .yinyin {
                width: 100%;
                height: 100%;
                position: absolute;
                background: rgba(0, 0, 0, 0.4);
                border-radius: 10px;
                display: none;
                span {
                    width: 100%;
                    height: 100%;
                    display: none;
                }
            }
            img {
                width: 100%;
                height: 100%;
                border-radius: 10px;
            }
        }
        .upLoaderHeaderBox {
            width: 438px /2;
            height: 247px /2;
            background: rgb(25, 137, 250);
            border-radius: 10px;
            // margin-left: 50px;
            float: left;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            span {
                display: inline-block;
                line-height: 30px;
                color: #fff;
            }
        }
        .upLoaderHeaderBox:hover {
            cursor: pointer;
        }
        .coverBox:hover {
            .yinyin {
                cursor: pointer;
                display: block;
                span {
                    width: 219px;
                    height: 123.5px;
                    display: inline-block;
                    color: #fff;
                    text-align: center;
                    i {
                        font-size: 30px;
                        line-height: 123.5px;
                    }
                }
            }
        }
    }
    .rich_text {
        z-index: 10000;
        width: 100%;
        height: 350px;
        margin-bottom: 50px;
    }
}
// 分页
.block {
    margin: 30px auto;
    text-align: center;
}
.xiangqing_box {
    .fengmian {
        span {
            line-height: 100px;
            float: left;
        }
    }
    div {
        margin: 5px 0;
        span {
            font-weight: 700;
            font-size: 18px;
        }
        img {
            width: 150px;
            height: 100px;
        }
        .text {
            width: 100%;
            height: 200px;
            border: 1px #cecece solid;
            overflow-y: auto;
        }
    }
}
// 详情的图片
.production_img {
    width: 100%;
    height: 482px;
    background: #dcdfe6;
    margin-bottom: 30px;
}
// 详情标签
.tag_box {
    .el-tag {
        margin: 0 20px;
    }
}
/deep/ .el-table__column-filter-trigger {
    opacity: 0;
}
</style>
