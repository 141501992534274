<template>
  <div id="operatingWorkCenter">
    <section class="content">
      <h2>财务运营工作台</h2>
      <div class="go_work">
        <h3>
          <!-- 我要办事 <el-button>表格批量导入用户</el-button>
          <el-button>首页banner管理</el-button>
          <el-button>创作者项目排序</el-button>
          <el-button>课程排序</el-button>
          <el-button>文章排序</el-button>
          <el-button>发送系统消息</el-button> -->
        </h3>
      </div>
      <div class="wait">
        <div class="list_detail">
          <section class="task_list">
            <h3>待您处理</h3>
            <el-badge :value="messages.checkUserCount" >
              <el-button @click="handCilck(0)" type="primary" style="width:150px"
                >打款</el-button
              >
            </el-badge>
            <!-- <el-badge :value="12" type="primary">
                 <el-button @click="handCilck(0)" type="primary">申请企业认证</el-button>
            </el-badge> -->
            <!-- <el-badge :value="messages.artCount" >
              <el-button @click="handCilck(1)" type="primary"
                >申请文章违规</el-button
              >
            </el-badge>
            <el-badge :value="messages.commentCount" >
              <el-button @click="handCilck(2)" type="primary"
                >申请评论违规</el-button
              >
            </el-badge>
            <el-badge :value="messages.courseCount" >
              <el-button @click="handCilck(3)" type="primary"
                >申请课程违规</el-button
              >
            </el-badge>
            <el-badge :value="messages.prouceCount" >
              <el-button @click="handCilck(4)" type="primary"
                >申请作品违规</el-button
              >
            </el-badge>
            <el-badge :value="messages.rescourCount" >
              <el-button @click="handCilck(5)" type="primary"
                >申请资源违规</el-button
              >
            </el-badge> -->
          </section>
          <section class="detail_box">
            <proCost v-show="id == 0"></proCost>
            <!-- <myarticle v-show="id==1"></myarticle>
            <comment v-show="id == 2"></comment>
            <course v-show="id == 3"></course>
            <resources v-show="id == 5"></resources>
            <produce v-show="id == 4"></produce> -->

          </section>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import proCost from "../../illegal/center/proCost.vue";

export default {
  components: {
    proCost
  },
  data() {
    return {
      title: "",
      messages: {
        checkUserCount: 0,
 
      },
      id: 0,
    };
  },
  created() {
    this.getMessage();
  },
  mounted() {},
  methods: {
    getMessage() {
     
      const userId = localStorage.getItem("userId");
      this.monitorSSE(userId);
    },
    handCilck(data) {
      this.id = data;
    },
    monitorSSE(data) {
      if (window.EventSource) {
        // 建立连接

        this.source = new EventSource(`/api/common/connect?userId=${data}`);
        this.source.addEventListener("open", function () { console.log('e: ');}, false);
        this.source.addEventListener("message", (e) => {

          const mess = JSON.parse(e.data);

          if (mess.title === "financial") {
            this.messages = mess.message;
          }
        });

        this.source.addEventListener(
          "error",
          function (e) {
            // console.log(e);

            if (e.readyState === EventSource.CLOSED) {
              this.monitorSSE(data);
            } else {
              // console.log(e);
            }
          },
          false
        );
      } else {
        alert("你的浏览器不支持SSE");
      }
    },
  },
};
</script>

<style lang="less" scoped>
#operatingWorkCenter {
  display: flex;
  width: 100%;
}
.content {
  flex-basis: 500px;
  flex-grow: 1;
  h2 {
    text-align: center;
  }
  .wait {
    border: 1px solid #ccc;
    .list_detail {
      height: 650px;
      display: flex;
      .task_list {
        display: flex;
        flex-direction: column;
        width: 150px;
        padding-right: 12px;
        padding-left: 12px;
        color: #fff;
        border-right: 1px solid #ccc;
        background-color: rgba(0, 0, 0, 0.3);
        min-height: 100%;
        h3 {
          text-align: center;
        }
        .el-badge {
          margin: 6px;
          div {
            height: 40px;
            line-height: 40px;
            border-radius: 4px;
            padding-left: 4px;
            background-color: #fff;
            color: #000;
          }
          .avtive {
            background-color: #409eff;
          }
        }
      }
      .detail_box {
        overflow-y: auto;
        background-color: #fff;
        flex-grow: 1;
        background-color: rgba(0, 0, 0, 0.1);
        margin: 6px 12px;
      }
    }
  }
  .go_work {
    color: #000;
    padding: 12px 12px;
    margin: 12px 0;
    background-color: #fff;
  }
}
.right_illustration {
  margin-top: 15vh;
  margin-left: 12px;
  margin-right: 12px;
  width: 300px;
  height: auto;
  border-radius: 10px;
  overflow: hidden;
  img {
    width: 100%;
  }
}
</style>
