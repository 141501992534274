import axios from 'axios'
import { Message } from 'element-ui'
import router from '@/router/router.js'

axios.defaults.baseURL = process.env.VUE_APP_API
axios.defaults.withCredentials = false

// 全局请求预处理
axios.interceptors.request.use(config => {
    // 每次请求 在请求头里面加入token
    config.headers.token = window.localStorage.getItem('user')
    return config
}, erroror => {
    return Promise.reject(erroror)
})
// 全局响应拦截器
axios.interceptors.response.use(res => {
    if (res.status == 200) {
        if (res.data.code == 200) {
            return res
        } else if (res.data.code == 401) {
            Message.error({ message: '登录异常，请重新登录' })
            window.localStorage.removeItem('user')
            router.push('/login')
            return res
        }
    } else {
        return Promise.reject(res)
    }
}, err => {
    if (err.response.status == 504 || err.response.status == 404) {
        Message.error({ message: '服务器错误' })
    } else if (err.response.status == 403) {
        Message.error({ message: '权限不足,请联系管理员!' })
    } else {
        Message.error({ message: '未知错误' })
    }
    return Promise.reject(err)
})

export default axios