<template>
    <div class="creator">
        <el-input
            placeholder="请输入作品名"
            prefix-icon="el-icon-search"
            v-model="input"
            @change="filterData()"
        >
        </el-input>
        <el-table
            v-loading="loading"
            stripe
            :data="tableData"
            :header-cell-style="thStyleFun"
            :cell-style="cellStyleFun"
            @filter-change="filterChange"
        >
            <el-table-column prop="nickname" label="昵称">
                <template slot-scope="scope">
                    <div class="userName">
                        <img
                            :src="
                                scope.row.portrait === null
                                    ? require('@/assets/images/xiaoxin.svg')
                                    : scope.row.portrait
                            "
                            alt=""
                        />
                        <span>
                            {{ scope.row.nickname }}
                        </span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="name" label="查看作品">
                <template slot-scope="scope">
                    <el-link
                        type="primary"
                        target="_blank"
                        :href="'https://' + scope.row.url"
                        >查看作品</el-link
                    >
                </template>
            </el-table-column>
            <el-table-column prop="type" label="类型">
                <!-- <template slot="header">
            类型
            <i style="font-size:12px
            "
               class="icon iconfont icon-jiantou1"></i>
          </template>
          <template slot-scope="scope">
            {{scope.row[types]?scope.row[types]:'无'}}
          </template> -->
            </el-table-column>
            <el-table-column prop="fangshi" label="联系方式">
                <template scope="scope">
                    {{ scope.row.fangshi ? scope.row.fangshi : "" }}
                </template>
            </el-table-column>
            <el-table-column prop="isFreeze" label="个人站">
                <template slot-scope="scope">
                    <el-link
                        type="primary"
                        target="_blank"
                        @click="gocommonly(scope.row.commonly)"
                        >立即查看</el-link
                    >
                </template>
            </el-table-column>
            <!-- <el-table-column prop="isFreeze" label="审核状态">
                <template slot-scope="scope">
                    {{
                        scope.row.status == 3
                            ? "未审核"
                            : scope.row.status == 1
                            ? "已通过"
                            : scope.row.status == 0
                            ? "未通过"
                            : scope.row.status == 2
                            ? "已禁用"
                            : "错误"
                    }}
                </template>
            </el-table-column> -->
            <el-table-column prop="operation" label="操作">
                <template slot-scope="scope">
                    <el-link
                        @click="clickItem(scope.row.id, 1)"
                        :disabled="
                            scope.row.status == 1 || scope.row.status == 2
                        "
                        type="primary"
                        >通过</el-link
                    >

                    <span
                        style="
                            font-size: 12px;
                            margin: 0 15.6px;
                            color: #000000;
                            opacity: 0.3;
                        "
                        >|</span
                    >

                    <el-link
                        @click="clickItem(scope.row.id, 2)"
                        :disabled="
                            scope.row.status == 1 || scope.row.status == 2
                        "
                        type="primary"
                        >不通过</el-link
                    >

                    <span
                        style="
                            font-size: 12px;
                            margin: 0 15.6px;
                            color: #000000;
                            opacity: 0.3;
                        "
                        >|</span
                    >

                    <el-link
                        v-if="scope.row.status == 1 || scope.row.status == 3"
                        @click="clickItem(scope.row.id, 3)"
                        :disabled="scope.row.status == 3"
                        type="danger"
                        >禁用</el-link
                    >

                    <el-link
                        v-else
                        @click="clickItem(scope.row.id, 4)"
                        :disabled="false"
                        type="danger"
                        >取消禁用</el-link
                    >
                </template>
            </el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination
            layout="total, prev, pager, next, jumper"
            background
            @current-change="pageChange"
            :current-page="current"
            :page-size="size"
            :total="total"
        >
        </el-pagination>
        <!-- 对话框 -->
        <el-dialog
            width="30%"
            title="确定通过此用户的审核吗？"
            :visible.sync="pass"
            append-to-body
        >
            <div>审核通过后，此用户可以在创作中心投标项目</div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="pass = false">取消</el-button>
                <el-button type="primary" @click="clickPass()">确定</el-button>
            </span>
        </el-dialog>

        <el-dialog
            width="30%"
            title="确定不通过此用户的审核吗？"
            :visible.sync="nopass"
            append-to-body
        >
            <el-input type="text" v-model="text" placeholder="请输入原因" />
            <span slot="footer" class="dialog-footer">
                <el-button @click="nopass = false">取消</el-button>
                <el-button type="primary" @click="clickNoPass()"
                    >确定</el-button
                >
            </span>
        </el-dialog>
        <el-dialog
            width="30%"
            title="确定禁用此用户的权限吗？"
            :visible.sync="forbidden"
            append-to-body
        >
            <div>禁用后，此用户不可以在创作中心投标项目</div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="forbidden = false">取消</el-button>
                <el-button type="primary" @click="clickForbidden()"
                    >确定</el-button
                >
            </span>
        </el-dialog>

        <el-dialog
            width="30%"
            title="确定取消禁用此用户的权限吗？"
            :visible.sync="noforbidden"
            append-to-body
        >
            <div>取消禁用后，此用户将可以在创作中心投标项目</div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="noforbidden = false">取消</el-button>
                <el-button type="primary" @click="clickNoForbidden()"
                    >确定</el-button
                >
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data () {
        return {
            current: 1,
            size: 10,
            total: 0, // 数据的总条数
            input: '',
            loading: false, //
            tableData: [],
            clickId: null, //
            pass: false,
            nopass: false,
            forbidden: false,
            noforbidden: false,
            text: '',
            types: 'phone'
        }
    },
    created () {
        this.getUserData()
    },
    methods: {
        gocommonly (url) {
            if (url) {
                window.open(url, '_blank')
            } else {
                this.$message({
                    type: 'warning',
                    message: '该用户没有绑定个人站！',
                })
            }
        },
        clickNoForbidden () {
            this.$axios.post(`/api/creators/audit?userId=${this.clickId}&type=${1}&comment=${this.text}`).then(res => {
        
                if (res.data.code == 200) {
                    this.$message({
                        type: 'success',
                        message: '取消禁用成功！',
                    })
                    this.noforbidden = false
                    this.getUserData()
                } else {
                    this.$message({
                        type: 'error',
                        message: res.data.message,
                    })
                }
            })
        },
        clickForbidden () {
            this.$axios.post(`/api/creators/audit?userId=${this.clickId}&type=${2}&comment=${this.text}`).then(res => {
                if (res.data.code == 200) {
                    this.$message({
                        type: 'success',
                        message: '禁用成功！',
                    })
                    this.forbidden = false
                    this.getUserData()
                } else {
                    this.$message({
                        type: 'error',
                        message: res.data.message,
                    })
                }
            })
        },
        clickNoPass () {
            this.$axios.post(`/api/creators/audit?userId=${this.clickId}&type=${0}&comment=${this.text}`).then(res => {
        
                if (res.data.code == 200) {
                    this.$message({
                        type: 'success',
                        message: '操作成功！',
                    })
                    this.nopass = false
                    this.getUserData()
                } else {
                    this.$message({
                        type: 'error',
                        message: res.data.message,
                    })
                }
            })
        },
        clickPass () {
            this.$axios.post(`/api/creators/audit?userId=${this.clickId}&type=${1}&comment=${this.text}`).then(res => {
                
                if (res.data.code == 200) {
                    this.$message({
                        type: 'success',
                        message: '通过成功',
                    })
                    this.pass = false
                    this.getUserData()
                } else {
                    this.$message({
                        type: 'error',
                        message: res.data.message,
                    })
                }
            })
        },
        clickItem (id, i) {
            this.clickId = id
            switch (i) {
                case 1:
                    this.pass = true
                    break
                case 2:
                    this.nopass = true
                    break
                case 3:
                    this.forbidden = true
                    break
                case 4:
                    this.noforbidden = true
            }
        },
        getUserData () {
            this.$axios.get(`/api/creators/findAllAuditStatus?current=${this.current}&size=${this.size}`).then(res => {
                console.log(res.data)
                if (res.data.code == 200) {
                    this.tableData = res.data.data.records
                    this.total = res.data.data.total
                }
            })
        },
        filterData () { },
        pageChange (current) {
            console.log(current)
        },
        filterChange (filterObj) {
            let keys = Object.values(filterObj)[0][0]
            console.log(keys)
            if (keys) {
                this.types = keys
            } else {
                this.types = 'phone'
            }
        },
        // 表格内容居中
        thStyleFun () {
            return 'text-align:center;background:#DCDFE6;color:#303133'
        },
        cellStyleFun () {
            return 'text-align:center'
        },

    }
}
</script>

<style lang="less" scoped>
// 整个页面
.creator {
    width: 100%;
    height: 100%;
}
// 搜索框
.el-input {
    padding-bottom: 12px;
}
.userName {
    height: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: left;
    padding-left: 12px;
    img {
        width: 32px;
        height: 32px;
        border-radius: 50%;
    }
    span {
        position: relative;
        top: -10px;
        margin-left: 10px;
    }
}
/deep/ .el-table__column-filter-trigger {
    opacity: 0;
}
</style>