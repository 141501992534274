<template>
    <div class="log">
        <!-- 搜索框 -->
        <div class="oinput">
            <el-input
                placeholder="请输入活动标题"
                prefix-icon="el-icon-search"
                v-model="keyword"
                @change="getProductionList()"
            >
            </el-input>
            <el-button
                size="medium"
                @click="(outerVisible = true), (btnFlag = 1), clearRich();"
                type="primary"
                >发布活动</el-button
            >
        </div>

        <!-- 表格 -->
        <el-main>
            <el-table
                v-loading="loading"
                stripe
                :data="tableData"
                :header-cell-style="thStyleFun"
                :cell-style="cellStyleFun"
                @filter-change="filterChange"
                @sort-change="sortChange"
            >
                <el-table-column prop="cover" label="封面">
                    <template slot-scope="scope">
                        <div class="demo-image">
                            <div
                                class="block"
                                style="
                                    margin: 0 auto;
                                    width: 178px;
                                    height: 100px;
                                "
                            >
                                <el-image
                                    style="width: 178px; height: 100px"
                                    :src="scope.row.cover"
                                    fit="scale-down"
                                ></el-image>
                            </div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="name"
                    show-overflow-tooltip
                    label="标题"
                ></el-table-column>
                <el-table-column
                    prop="type"
                    show-overflow-tooltip
                    label="类型"
                    :filters="allType"
                    column-key="type"
                    filter-placement="bottom-end"
                    :filter-multiple="false"
                >
                    <template slot="header">
                        <!-- 类型
             <i icon-class="icon-daoxuxia"></i> 
            <i
              style="font-size:12px
            "
              class="icon iconfont icon-jiantou1"
            ></i> -->
                        <span class="el-dropdown-link">
                            类型
                            <i
                                style="font-size: 12px"
                                class="icon iconfont icon-jiantou1"
                            ></i>
                        </span>
                        <!-- <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>黄金糕</el-dropdown-item>
              <el-dropdown-item>狮子头</el-dropdown-item>
              <el-dropdown-item>螺蛳粉</el-dropdown-item>
            </el-dropdown-menu> -->
                        <!-- {{ scope }} -->
                    </template>
                </el-table-column>

                <el-table-column
                    prop="time"
                    show-overflow-tooltip
                    label="时间"
                    sortable="custom"
                >
                    <template slot="header">
                        <span class="time">时间 </span>
                        <i
                            v-show="iconFlags == 1"
                            style="font-size: 14px; color: #1989fa"
                            class="icon iconfont icon-zuobian"
                        ></i>

                        <i
                            v-show="iconFlags == 0 || iconFlags == 2"
                            style="font-size: 14px"
                            class="icon iconfont icon-zuobian"
                        ></i>
                        <i
                            v-show="iconFlags == 2"
                            style="font-size: 14px; color: #1989fa"
                            class="icon iconfont icon-youbian"
                        ></i>
                        <i
                            v-show="iconFlags == 0 || iconFlags == 1"
                            style="font-size: 14px"
                            class="icon iconfont icon-youbian"
                        ></i>
                    </template>
                    <template slot-scope="scope">
                        {{ scope.row.startTime }}至{{ scope.row.endTime }}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="weight"
                    show-overflow-tooltip
                    label="权重"
                    sortable="custom"
                >
                    <template slot="header">
                        <span>权重 </span>
                        <i
                            v-show="iconFlag == 1"
                            style="font-size: 14px; color: #1989fa"
                            class="icon iconfont icon-zuobian"
                        ></i>

                        <i
                            v-show="iconFlag == 0 || iconFlag == 2"
                            style="font-size: 14px"
                            class="icon iconfont icon-zuobian"
                        ></i>
                        <i
                            v-show="iconFlag == 2"
                            style="font-size: 14px; color: #1989fa"
                            class="icon iconfont icon-youbian"
                        ></i>
                        <i
                            v-show="iconFlag == 0 || iconFlag == 1"
                            style="font-size: 14px"
                            class="icon iconfont icon-youbian"
                        ></i>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="showProduction"
                    show-overflow-tooltip
                    label="作品展示"
                    :filters="showType"
                    column-key="showProduction"
                    filter-placement="bottom-end"
                    :filter-multiple="false"
                >
                    <template slot="header">
                        作品展示
                        <i
                            style="font-size: 12px"
                            class="icon iconfont icon-jiantou1"
                        ></i>
                    </template>
                    <template slot-scope="scope">
                        <!-- {{ scope.row.showProduction == 0 ? '否' : '是' }} -->
                        <el-switch
                            v-model="scope.row.showProduction"
                            :active-value="1"
                            :inactive-value="0"
                            @change="worksShow(scope.row)"
                        ></el-switch>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="isFreeze"
                    show-overflow-tooltip
                    label="状态"
                    :filters="stateType"
                    column-key="state"
                    filter-placement="bottom-end"
                    :filter-multiple="false"
                >
                    <template slot-scope="scope">
                        <!-- {{ scope.row.isFreeze === 1 ? '进行中' : '下架' }} -->
                        <el-switch
                            v-model="scope.row.isFreeze"
                            :active-value="1"
                            :inactive-value="0"
                            @change="shangxiajia(scope.row)"
                        ></el-switch>
                    </template>
                    <template slot="header">
                        状态
                        <i
                            style="font-size: 12px"
                            class="icon iconfont icon-jiantou1"
                        ></i>
                    </template>
                </el-table-column>
                <el-table-column prop="operation" label="操作">
                    <template slot-scope="scope">
                        <el-link
                            :underline="false"
                            type="primary"
                            @click="
                                (xiangqing = true),
                                    (btnFlag = 2),
                                    (clickId = scope.row.id);
                            "
                            >详情</el-link
                        >
                        <span
                            style="
                                font-size: 12px;
                                margin: 0 15.6px;
                                color: #000000;
                                opacity: 0.3;
                            "
                            >|</span
                        >
                        <el-link
                            :underline="false"
                            type="primary"
                            @click="
                                (outerVisible = true),
                                    (btnFlag = 2),
                                    (clickId = scope.row.id);
                            "
                            >修改</el-link
                        >
                        <span
                            style="
                                font-size: 12px;
                                margin: 0 15.6px;
                                color: #000000;
                                opacity: 0.3;
                            "
                            >|</span
                        >
                        <!-- <el-link
              :underline="false"
              v-if="scope.row.isFreeze === 1"
              type="primary"
              @click=";(delJob = true), getId(scope.row)"
              >下架</el-link
            > -->
                        <!-- <el-link
              :underline="false"
              v-else
              type="primary"
              @click=";(upJob = true), getId(scope.row)"
              >上架</el-link
            > -->
                        <!-- &nbsp; -->
                        <el-link
                            :underline="false"
                            type="danger"
                            @click="(deleteJob = true), getId(scope.row);"
                            >删除</el-link
                        >
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div class="block">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page.sync="currentPage3"
                    :page-size="pageSize"
                    layout="prev, pager, next, jumper"
                    :total="total"
                >
                </el-pagination>
            </div>
        </el-main>
        <!-- 对话框 -->
        <!-- 详情对话框 -->
        <el-dialog
            width="700px"
            title="活动详情"
            @open="getParticulars(clickId)"
            @close="guanbi(1)"
            :visible.sync="xiangqing"
            v-loading.fullscreen.lock="addDetail"
            element-loading-text="拼命加载中"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)"
        >
            <div class="issue_contents">
                <div class="cover">
                    <span class="cover_span"> 封面： </span>
                    <div
                        style="
                            width: 219px;
                            height: 123.5px;
                            float: left;
                            margin-right: 20px;
                            border-radius: 10px;
                        "
                    >
                        <img
                            style="width: 100%; height: 100%"
                            :src="jobDetail.cover"
                            alt=""
                        />
                    </div>
                </div>
                <div class="cover">
                    <span class="cover_span"> 背景： </span>
                    <div
                        style="
                            width: 219px;
                            height: 123.5px;
                            float: left;
                            margin-right: 20px;
                            border-radius: 10px;
                        "
                    >
                        <img
                            style="width: 100%; height: 100%"
                            :src="jobDetail.picture"
                            alt=""
                        />
                    </div>
                </div>
                <div class="rw_describe">
                    <span> 标题： </span>
                    {{ addJob.name }}
                </div>
                <div class="rw_describe">
                    <span> 时间： </span>
                    {{ addJob.date[0] }} - {{ addJob.date[1] }}
                </div>
                <div class="rw_describe">
                    <span> 类型： </span>
                    {{ value }}
                </div>
                <div class="rw_describe">
                    <span> 展示作品： </span>
                    {{ showProduction == 0 ? "否" : "是" }}
                </div>
                <div class="rw_describe">
                    <span> 权重： </span>
                    {{ addJob.weight == "" ? 0 : addJob.weight }}
                </div>
                <div class="rich_text">
                    <span> 活动内容： </span>
                    <template v-if="weLanguageForm.length > 0">
                        <div
                            class="fuwenben"
                            v-for="(item, index) in weLanguageForm"
                            :key="index"
                        >
                            <span
                                >{{ item.title }}(顺序位置：{{
                                    item.sequence
                                }})</span
                            >
                            <div class="content_text" v-html="item.text"></div>
                        </div>
                    </template>
                    <template v-else> 暂无内容 </template>
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="xiangqing = false"
                    >确 定</el-button
                >
                <el-button @click="xiangqing = false">取 消</el-button>
            </div>
        </el-dialog>
        <!-- 新增对话框 -->
        <el-dialog
            center
            @open="getParticulars(clickId)"
            @close="guanbi(2)"
            :visible.sync="outerVisible"
            :close-on-click-modal="false"
            width="1000px"
            v-loading.fullscreen.lock="addDetail"
            :element-loading-text="uploadMessage"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)"
        >
            <div class="issue_content">
                <div class="cover">
                    <span class="cover_span"> 封面 </span>
                    <div
                        v-show="headerUrl || jobDetail.cover"
                        class="headerBox"
                    >
                        <div class="yinyin" @click="clearHeader(1)">
                            <span>
                                <i class="iconfont icon-shanchu"></i>
                            </span>
                        </div>
                        <img :src="headerUrl || jobDetail.cover" alt="" />
                    </div>
                    <!-- <el-upload
            accept="image/*"
            ref="upload"
            style="width:1000px"
            action="https://jsonplaceholder.typicode.com/posts/"
            list-type="picture-card"
            show-file-list
            :on-preview="handlePictureCardPreview"
            :auto-upload="false"
            :on-remove="handleRemove"
            :limit="1"
            :on-change="handleChansge"
          >
            <div class="icon"><i class="el-icon-plus"></i></div>
            <div class="el-upload__text">建议分辨率438x247</div>
          </el-upload> -->
                    <div>
                        <input
                            type="file"
                            accept=".jpg,.png"
                            name="activityHeader"
                            style="display: none"
                            @change="hangleHeader"
                            id="header"
                        />
                        <label
                            for="header"
                            v-show="headerUrl == '' && !jobDetail.cover"
                        >
                            <div class="upLoaderHeaderBox">
                                <span
                                    ><i class="iconfont icon-jiahaocu"></i
                                ></span>
                                <span>建议分辨率438×247</span>
                                <span>请选择上传jpg,png格式</span>
                            </div>
                        </label>
                    </div>
                </div>
                <div class="cover">
                    <span class="cover_span"> 背景 </span>
                    <div
                        class="backgroundBox"
                        v-show="backgroundUrl || jobDetail.picture"
                    >
                        <div class="yinyin">
                            <span @click="clearHeader(2)">
                                <i class="iconfont icon-shanchu"></i>
                            </span>
                        </div>
                        <img :src="backgroundUrl || jobDetail.picture" alt="" />
                    </div>
                    <!-- <el-upload
            accept="image/*"
            ref="upload"
            style="width:1000px"
            action="https://jsonplaceholder.typicode.com/posts/"
            list-type="picture-card"
            show-file-list
            :on-preview="handlePictureCardPreviews"
            :auto-upload="false"
            :on-remove="handleRemove"
            :limit="1"
            :on-change="handleChansges"
          >
            <div class="icon"><i class="el-icon-plus"></i></div>
            <div class="el-upload__text">建议分辨率438x247</div>
          </el-upload> -->
                    <div>
                        <input
                            type="file"
                            accept=".jpg,.png"
                            name="activityBackground"
                            style="display: none"
                            @change="hangleBackground"
                            id="background"
                        />
                        <label
                            for="background"
                            v-show="backgroundUrl == '' && !jobDetail.picture"
                        >
                            <div class="upLoaderBackgroundBox">
                                <span
                                    ><i class="iconfont icon-jiahaocu"></i
                                ></span>
                                <span>建议分辨率438×247</span>
                                <span>请选择上传jpg,png格式</span>
                            </div>
                        </label>
                    </div>
                </div>
                <div class="rw_describe">
                    <span> 标题 </span>
                    <el-input
                        style="width: 90%"
                        v-model="addJob.name"
                        placeholder="请输入标题"
                    >
                    </el-input>
                </div>
                <div class="rw_time">
                    <span> 时间 </span>
                    <el-date-picker
                        v-model="addJob.date"
                        type="daterange"
                        value-format="yyyy-MM-dd"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                    >
                    </el-date-picker>
                </div>
                <div class="rw_downlist">
                    <span> 类型 </span>
                    <el-select
                        v-model="value"
                        placeholder="请选择活动类型"
                        @change="checkedCard(value)"
                    >
                        <el-option
                            v-for="item in option"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </div>
                <div class="rw_isShow">
                    <span> 展示作品 </span>
                    <el-select
                        v-model="showProduction"
                        placeholder="请选择是否展示作品"
                        @change="checkedCard(value)"
                    >
                        <el-option
                            v-for="item in isShow"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </div>
                <div class="rw_describe">
                    <span> 权重 </span>
                    <el-popover placement="right" width="400" trigger="focus">
                        <el-input
                            slot="reference"
                            style="width: 30%"
                            v-model="addJob.weight"
                            placeholder="权重越大位置越靠前，可以不填"
                        >
                        </el-input>
                        <p>权重越大位置越靠前，可以不填</p>
                    </el-popover>
                </div>
                <div class="tabBox">
                    <div>
                        <div class="WeLanguage">
                            <el-form ref="weLanguageForm">
                                <el-form-item>
                                    <div
                                        class="weLanguageTemWrap"
                                        v-for="(item, index) in weLanguageForm"
                                        :key="index"
                                    >
                                        <div class="weLanguageTemTitle">
                                            <span class="welcomeLange"
                                                >请输入标题</span
                                            >
                                            <span>
                                                <el-input
                                                    v-model="item.title"
                                                ></el-input>
                                            </span>
                                            <span class="welcomeLange"
                                                >请输位置顺序</span
                                            >
                                            <span>
                                                <el-input
                                                    v-model="item.sequence"
                                                ></el-input>
                                            </span>
                                        </div>
                                        <editor-bar
                                            v-model="item.text"
                                            :isClear="isClear"
                                            :value="values"
                                            :index="index"
                                            @change="change"
                                            @deleteIndex="del"
                                        ></editor-bar>
                                    </div>
                                </el-form-item>
                                <el-form-item>
                                    <el-button @click="addWelLange"
                                        >添加</el-button
                                    >
                                </el-form-item>
                            </el-form>
                        </div>
                    </div>
                </div>
            </div>
            <template> </template>
            <div slot="footer" v-show="btnFlag === 1" class="dialog-footer">
                <el-button @click="outerVisible = false">取 消</el-button>
                <el-button type="primary" @click="submit()"> 确 定</el-button>
            </div>
            <div slot="footer" v-show="btnFlag === 2" class="dialog-footer">
                <el-button @click="outerVisible = false">取 消</el-button>
                <el-button type="warning" @click="alterPositions(jobDetail.id)">
                    修 改</el-button
                >
            </div>
        </el-dialog>
        <el-dialog
            width="30%"
            title="确定下架吗？"
            :visible.sync="delJob"
            append-to-body
        >
            <div slot="footer" class="dialog-footer">
                <el-button
                    type="primary"
                    @click="(delJob = false), shangjia(jobDetail.id);"
                    >确 定</el-button
                >
                <el-button @click="delJob = false">取 消</el-button>
            </div>
        </el-dialog>
        <el-dialog
            width="30%"
            title="确定上架吗？"
            :visible.sync="upJob"
            append-to-body
        >
            <div slot="footer" class="dialog-footer">
                <el-button
                    type="primary"
                    @click="
                        (upJob = false),
                            xiajia(jobDetail.id, jobDetail.isFreeze);
                    "
                    >确 定</el-button
                >
                <el-button @click="upJob = false">取 消</el-button>
            </div>
        </el-dialog>
        <!-- <el-dialog
      width="30%"
      title="确定修改吗？"
      :visible.sync="alter"
      append-to-body
    >
      <div slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="
            ;(alter = false),
              (outerVisible = false),
              alterPositions(jobDetail.id)
          "
          >修 改</el-button
        >
        <el-button @click="alter = false">取 消</el-button>
      </div>
    </el-dialog> -->
        <el-dialog
            width="30%"
            title="确定删除此活动吗？"
            :visible.sync="deleteJob"
            append-to-body
        >
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="deleteActivity"
                    >确 定</el-button
                >
                <el-button @click="deleteJob = false">取 消</el-button>
            </div>
        </el-dialog>
        <el-dialog
            width="30%"
            title="确定展示该活动的作品展示吗？"
            :visible.sync="openWorksShow"
            append-to-body
        >
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="showProductionChange"
                    >确 定</el-button
                >
                <el-button @click="openWorksShow = false">取 消</el-button>
            </div>
        </el-dialog>
        <el-dialog
            width="30%"
            title="确定关闭该活动的作品展示吗？"
            :visible.sync="closeWorksShow"
            append-to-body
        >
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="showProductionChange"
                    >确 定</el-button
                >
                <el-button @click="closeWorksShow = false">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
// import rich from '../zujian/richText.vue'
import EditorBar from './richText/activityRichText.vue'
// import { Loading } from 'element-ui'
export default {
    components: {
        // rich
        EditorBar,
    },
    data () {
        return {
            sequenceMsg: false,
            weLanguageForm: [
                {
                    title: '', // 第1个欢迎语 选择组
                    text: '', // 第1个欢迎语的初始化值
                    sequence: '',
                },
            ],
            isClear: false,
            weLanguageOptions: '',
            activeName: null,
            tableData: [],
            // 富文本内容
            log: {
                detail: '',
            },
            // 搜索框
            keyword: '',
            // 类型下拉菜单
            option: [
                {
                    value: '职业技能等级证书',
                    label: '职业技能等级证书',
                },
                {
                    value: '线上活动',
                    label: '线上活动',
                },
                {
                    value: '线下活动',
                    label: '线下活动',
                },
                {
                    value: '赛事活动',
                    label: '赛事活动',
                },
            ],
            allType: [],
            stateType: [
                { text: '下架', value: 0 },
                { text: '进行中', value: 1 },
            ],
            showType: [
                { text: '未展示', value: 0 },
                { text: '已展示', value: 1 },
            ],
            value: '',
            // 请求详情接口传递的类型
            type: 'tutorial',
            // 时间、地点、职位、标题
            values: '',
            addJob: {
                date: '',
                name: '', //
                cover: '',
                picture: '',
                weight: '',
                tab: '',
                text: '',
            },
            alterJob: {
                date: '',
                place: '',
                position: '',
                name: '',
            },
            // 富文本flag
            flags: false,
            // 页数
            currentPage3: 1,
            total: 0, // 数据的总条数
            pageSize: 7,
            // 上传图片
            dialogImageUrl: '',
            dialogVisible: false,
            // 对话框
            outerVisible: false,
            // innerVisible: false,
            innerVisibles: false,
            // 详情对话框
            alter: false,
            details: false,
            delJob: false,
            upJob: false,
            deleteJob: false,
            alterposition: false,
            xiangqing: false,
            caozuoId: null,
            // 数据数组
            productionList: [],
            // 上传的文件名
            file: '',
            flag: false,
            jobId: '',
            // 点击的职位的详情信息
            jobDetail: [],
            files: '',
            loading: false,
            loadingDetail: false,
            richLoading: false,
            // 作品展示对话框
            openWorksShow: false,
            closeWorksShow: false,
            // 控制按钮的切换
            btnFlag: 0,
            showProduction: '',
            isShow: [
                {
                    value: 1,
                    label: '是',
                },
                {
                    value: 0,
                    label: '否',
                },
            ],
            // 当前点击的那一项
            clickItem: {},
            ckickType: '',
            ckickTime: '',
            orderBy: '',
            iconFlag: 0,
            iconFlags: 0,
            state: '',
            production: '',
            // 上传进度
            uploadType: '',
            uploadRate: 0,
            uploadStyle: {
                width: '0%',
            },
            headerUrl: '',
            backgroundUrl: '',
            addDetail: false,
            uploadMessage: '拼命加载中...',
            clickId: '',
        }
    },
    mounted () {
        // this.$refs.editorRef.updateEditorData()
    },
    created () {
        this.getProductionList()
        this.getAllActivityType()
    },
    methods: {
        // 清除封面/背景
        clearHeader (i) {
            if (i == 1) {
                this.headerUrl = ''
                document.getElementById('header').value = null
                this.jobDetail.cover = null
            } else {
                this.backgroundUrl = ''
                document.getElementById('background').value = null
                this.jobDetail.picture = null
            }
        },
        sortChange (e) {
            this.currentPage3 = 1
            // console.log('1111------')
            // console.log(e.column)
            // 权重排序
            if (e.column.label == '权重') {
                this.ckickTime = 'weight'
                if (e.column.order == 'ascending') {
                    this.orderBy = -1
                    this.iconFlag = 1
                    // console.log('倒序')
                    this.getProductionList()
                    //降序
                }
                if (e.column.order == 'descending') {
                    //降序
                    this.orderBy = 1
                    this.iconFlag = 2
                    this.getProductionList()
                    // console.log('正序')
                }
                if (!e.column.order) {
                    //默认
                    this.ckickTime = ''
                    this.orderBy = ''
                    this.iconFlag = 0
                    this.getProductionList()
                    // console.log('默认')
                }
            }
            // 时间排序
            if (e.column.label == '时间') {
                this.ckickTime = 'start_time'
                if (e.column.order == 'ascending') {
                    this.orderBy = -1
                    this.iconFlags = 1
                    // console.log('倒序1')
                    this.getProductionList()
                    //降序
                }
                if (e.column.order == 'descending') {
                    //降序
                    this.orderBy = 1
                    this.iconFlags = 2
                    this.getProductionList()
                    // console.log('正序1')
                }
                if (!e.column.order) {
                    //默认
                    this.ckickTime = ''
                    this.orderBy = ''
                    this.iconFlags = 0
                    this.getProductionList()
                    // console.log('默认1')
                }
            }
        },
        // 获取全部的活动类型
        getAllActivityType () {
            this.$axios.get('/api/activity/getActivityType').then((res) => {
                if (res.data.code == 200) {
                    // console.log(res.data.data)
                    for (let i = 0; i < res.data.data.length; i++) {
                        let data = {
                            text: res.data.data[i],
                            value: res.data.data[i],
                        }
                        this.allType.push(data)
                    }
                    // console.log(this.allType)
                }
            })
        },
        // 修改作品展示请求
        showProductionChange () {
            let showProduction = 0
            if (this.clickItem.showProduction == 0) {
                showProduction = 1
            } else {
                showProduction = 0
            }
            let data = {
                id: this.clickItem.id,
                showProduction: showProduction,
            }
            this.$axios.post('/api/activity/updateActivity', data).then((res) => {
                if (res.data.code == 200) {
                    // console.log(res.data)
                    this.openWorksShow = false
                    this.closeWorksShow = false
                    this.getProductionList()
                }
            })
        },
        //上下架切换
        shangxiajia (item) {
            this.clickItem = item
            if (item.isFreeze == 1) {
                item.isFreeze = 0
                this.upJob = true
            } else if (item.isFreeze == 0) {
                item.isFreeze = 1
                this.delJob = true
            }
        },
        // 作品展示切换
        worksShow (item) {
            this.clickItem = item
            if (item.showProduction == 1) {
                item.showProduction = 0
                this.openWorksShow = true
            } else if (item.showProduction == 0) {
                item.showProduction = 1
                this.closeWorksShow = true
            }
        },
        // 确定删除
        deleteActivity () {
            this.$axios
                .delete(`/api/activity/deleteActivity?id=${this.caozuoId}`)
                .then((res) => {
                    console.log(res.data.data)
                    if (res.data.data == 1) {
                        this.$message({ type: 'success', message: '删除成功' })
                        this.deleteJob = false
                        this.getProductionList()
                    } else {
                        this.$message({ type: 'error', message: res.data.message })
                    }
                })
        },
        del (index) {
            if (index !== 0) {
                this.weLanguageForm.splice(index, 1)
            }
        },
        change (val) {
            console.log(val)
            // let biaoqian = document.getElementsByTagName('img')[7]
            // let urls = biaoqian.getAttribute('data-href')
            // console.log('url------------------')
            // console.log(biaoqian)
            // console.log(urls)
        },
        addWelLange () {
            this.weLanguageForm.push({ title: '', text: '' })
            // console.log(this.weLanguageForm)
        },
        // 删除tab选项
        removeTab (targetName) {
            this.tabList.splice(this.tabList.indexOf(targetName), 1)
        },
        filterChange (filterObj) {
            // console.log('111--------------111')
            // console.log(filterObj)
            // console.log(Object.keys(filterObj)[0])
            // if (Object.keys(filterObj)[0] == 'time') {
            //   this.getProductionList(filterObj, 'time')
            // }
            console.log(filterObj)
            if (Object.keys(filterObj)[0] == 'state') {
                this.getProductionList(filterObj, 'state')
            }
            if (Object.keys(filterObj)[0] == 'type') {
                this.getProductionList(filterObj, 'type')
            }
            if (Object.keys(filterObj)[0] == 'showProduction') {
                this.getProductionList(filterObj, 'showProduction')
            }

            // if (filterObj.time[0] === undefined || filterObj.type[0] === undefined) {
            //   filterObj.time[0] = ''
            //   filterObj.type[0] = ''
            // }
            // var data = filterObj.time[0]
            // console.log(data)
            // this.getProductionList(data)
        },
        checkedCard (item) {
            console.log(item)
        },

        // 关闭对话框时候执行的
        guanbi (i) {
            // 关闭的时候判断当前对话框是详情还是添加，如果是添加就清除一下input file 的value的值
            if (i == 2) {
                document.getElementById('header').value = null
                document.getElementById('background').value = null
            }
            this.addDetail = false
            console.log('--*---*-*-*-*-*-*-*-*-')
            this.btnFlag = 0
            this.addJob.name = ''
            this.addJob.date = ''
            this.addJob.weight = ''
            this.value = ''
            this.jobDetail = []
            this.showProduction = ''
            this.headerUrl = ''
            this.backgroundUrl = ''
            this.weLanguageForm = [
                {
                    title: '',
                    text: '',
                },
            ]
            this.file = ''
            this.files = ''
            this.clickId = ''
        },
        // 修改职位信息
        async alterPositions (id) {
            const that = this
            const data = {
                id: id,
                startTime: this.addJob.date[0],
                endTime: this.addJob.date[1],
                type: this.value,
                showProduction: this.showProduction,
                weight: this.addJob.weight,
                name: this.addJob.name,
            }
            if (
                this.addJob.name === '' ||
                this.value === '' ||
                this.addJob.date[0] === undefined ||
                this.addJob.date[1] === undefined
            ) {
                // console.log('meiyougaibian------------------')
                that.$message({
                    type: 'error',
                    message: '请将内容补充完整，顺序不能有重复噢！',
                })
                return
            } else {
                this.addDetail = true
                // console.log(data)
                this.$axios
                    .post('/api/activity/updateActivity', data)
                    .then(function (res) {
                        console.log(res.data)
                        if (res.data.code === 200) {
                            Promise.all([
                                that.postRich(id),
                                that.postfile(id),
                                that.postpicture(id),
                            ]).then((ress) => {
                                console.log(ress)
                                let flag = ress.indexOf(false)
                                if (flag == -1) {
                                    that.$message({
                                        type: 'success',
                                        message: '修改成功',
                                    })
                                    that.addDetail = false
                                    that.getProductionList()
                                    that.innerVisibles = false
                                    that.outerVisible = false
                                    // console.log('修改---完成-')
                                }
                            })
                        } else {
                            this.addDetail = false
                            that.$message({
                                type: 'error',
                                message: '修改失败',
                            })
                        }
                    })
            }

            // }
        },
        // 清空富文本
        clearRich () {
            this.weLanguageForm = [
                {
                    title: '',
                    text: '',
                },
            ]
        },
        // 上传富文本
        postRich (id) {
            return new Promise((ress, rej) => {
                let that = this
                let data = {
                    id: id,
                    titleAndTexts: this.weLanguageForm,
                }
                let config = {
                    onUploadProgress: function (e) {
                        //属性lengthComputable主要表明总共需要完成的工作量和已经完成的工作是否可以被测量
                        //如果lengthComputable为false，就获取不到e.total和e.loaded
                        if (e.lengthComputable) {
                            var rate = (that.uploadRate = e.loaded / e.total) //已上传的比例
                            if (rate < 1) {
                                //这里的进度只能表明文件已经上传到后台，但是后台有没有处理完还不知道
                                //因此不能直接显示为100%，不然用户会误以为已经上传完毕，关掉浏览器的话就可能导致上传失败
                                //等响应回来时，再将进度设为100%
                                that.uploadRate = rate
                                that.uploadStyle.width = (rate * 100).toFixed(2) + '%'
                                // console.log('进度：', that.uploadStyle.width, that.uploadRate)
                                that.uploadMessage =
                                    '上传富文本中' + that.uploadStyle.width + '请稍等...'
                            }
                        }
                    },
                }
                this.$axios
                    .post('/api/activity/uploadText', data, config)
                    .then(function (res) {
                        // console.log(res.data.message + '富文本上传成功')
                        if (res.data.code == 200) {
                            ress(true)
                            that.$message({
                                type: 'success',
                                message: '富文本上传成功！',
                            })
                            that.weLanguageForm = [
                                {
                                    title: '',
                                    sequence: '',
                                    text: '',
                                },
                            ]
                        } else {
                            that.$message({
                                type: 'error',
                                message: res.data.message,
                            })
                            rej(false)
                        }
                    })
            })
        },
        // 上传基本信息
        submit () {
            let that = this
            // console.log(this.addJob)
            // console.log(this.file)
            // console.log(this.value)
            // console.log(this.weLanguageForm.text)
            // console.log(this.sequenceMsg)
            if (
                this.addJob.name === '' ||
                this.value === '' ||
                this.addJob.date[0] === undefined ||
                this.addJob.date[1] === undefined ||
                this.weLanguageForm.text === '' ||
                this.file == '' ||
                this.files == ''
            ) {
                that.innerVisibles = false
                // console.log(this.addJob.name,)
                that.$message({
                    type: 'error',
                    message: '请将内容补充完整，顺序不能有重复噢！',
                })
                return
            }
            this.addDetail = true

            const data = {
                startTime: this.addJob.date[0],
                endTime: this.addJob.date[1],
                name: this.addJob.name,
                showProduction: this.showProduction,
                type: this.value,
                weight: this.addJob.weight,
                isFreeze: 0,
            }
            this.$axios.post('/api/activity/addActivity', data).then(function (res) {
                // console.log(res.data)
                if (res.data.code === 200) {
                    that.jobId = res.data.data
                    Promise.all([
                        that.postfile(that.jobId),
                        that.postRich(that.jobId),
                        that.postpicture(that.jobId),
                    ]).then((ress) => {
                        let flag = ress.indexOf(false)
                        console.log(flag, '---------flag-----------')
                        if (flag == -1) {
                            that.$message({
                                type: 'success',
                                message: '添加完成',
                            })
                            that.addDetail = false
                            that.getProductionList()
                            that.innerVisibles = false
                            that.outerVisible = false
                        } else {
                            that.addDetail = false
                            that.$message({
                                type: 'error',
                                message: res.data.message,
                            })
                        }
                    })

                    console.log('-------111111-----------')
                }
            })
        },
        // 上传文件
        postfile (id) {
            return new Promise((res, rej) => {
                // console.log(id+'测试i')
                this.uploadRate = 0
                this.uploadStyle.width = '0%'
                let that = this
                if (this.file == '') {
                    res(true)
                    // this.$message({
                    //   type: 'error',
                    //   message: '图片未选择',
                    // })
                } else {
                    // 文件形式的需要用 formData形式
                    let formData = new FormData()
                    formData.append('file', this.file)
                    let config = {
                        headers: { 'Content-Type': 'multipart/form-data' },
                        onUploadProgress: function (e) {
                            //属性lengthComputable主要表明总共需要完成的工作量和已经完成的工作是否可以被测量
                            //如果lengthComputable为false，就获取不到e.total和e.loaded
                            if (e.lengthComputable) {
                                var rate = (that.uploadRate = e.loaded / e.total) //已上传的比例
                                if (rate < 1) {
                                    //这里的进度只能表明文件已经上传到后台，但是后台有没有处理完还不知道
                                    //因此不能直接显示为100%，不然用户会误以为已经上传完毕，关掉浏览器的话就可能导致上传失败
                                    //等响应回来时，再将进度设为100%
                                    that.uploadRate = rate
                                    that.uploadStyle.width = (rate * 100).toFixed(2) + '%'
                                    // console.log('进度：', that.uploadStyle.width, that.uploadRate)
                                    that.uploadMessage =
                                        '上传封面中' + that.uploadStyle.width + '请稍等...'
                                }
                            }
                        },
                    }
                    // this.btn.disable = true;
                    // this.btn.message = "上传中，请等待";
                    this.$axios
                        .post(`/api/activity/uploadCover?id=${id}`, formData, config)
                        .then(function (response) {
                            console.log(response)
                            if (response.data.code == 200) {
                                res(true)
                                // that.uploadRate = 1
                                // that.uploadStyle.width = '100.00%'
                                // console.log(that.uploadRate)
                                // console.log(that.uploadStyle.width)
                                // that.uploadMessage = '上传封面中'+that.uploadStyle.width+'请稍等...'
                                that.$message({
                                    type: 'success',
                                    message: '上传成功!',
                                })
                                // that.getProductionList()
                                formData.delete('file')
                                that.file = ''
                            }

                            // that.btn.disable = false;
                            // that.btn.message = "上传服务器";
                            // that.$refs.upload.clearFiles()
                            // console.log( formData.get('file'))
                            // console.log( formData.get('file'))
                        })
                        .catch((err) => {
                            console.log(err)
                            rej(false)
                            // that.btn.disable = false;
                            // that.btn.message = "上传服务器";
                            that.$message({
                                type: 'warning',
                                message: '上传过程出错啦!',
                            })
                        })
                }
            })
        },
        // 上传首页图片
        postpicture (id) {
            return new Promise((res, rej) => {
                this.uploadRate = 0
                this.uploadStyle.width = '0%'
                console.log(this.uploadRate)
                console.log(this.uploadStyle.width)
                let that = this
                if (this.files === '') {
                    res(true)
                } else {
                    // 文件形式的需要用 formData形式
                    let formData = new FormData()
                    formData.append('file', this.files)
                    let config = {
                        headers: { 'Content-Type': 'multipart/form-data' },
                        onUploadProgress: function (e) {
                            //属性lengthComputable主要表明总共需要完成的工作量和已经完成的工作是否可以被测量
                            //如果lengthComputable为false，就获取不到e.total和e.loaded
                            if (e.lengthComputable) {
                                var rate = (that.uploadRate = e.loaded / e.total) //已上传的比例
                                if (rate < 1) {
                                    //这里的进度只能表明文件已经上传到后台，但是后台有没有处理完还不知道
                                    //因此不能直接显示为100%，不然用户会误以为已经上传完毕，关掉浏览器的话就可能导致上传失败
                                    //等响应回来时，再将进度设为100%
                                    that.uploadRate = rate
                                    that.uploadStyle.width = (rate * 100).toFixed(2) + '%'
                                    // console.log('进度：', that.uploadStyle.width, that.uploadRate)
                                    that.uploadMessage =
                                        '上传背景图中' + that.uploadStyle.width + '请稍等...'
                                }
                            }
                        },
                    }
                    // this.btn.disable = true;
                    // this.btn.message = "上传中，请等待";
                    this.$axios
                        .post(`/api/activity/uploadPicture?id=${id}`, formData, config)
                        .then(function (response) {
                            // console.log(that.uploadRate)
                            // console.log(that.uploadStyle.width)
                            console.log(response)
                            if (response.data.code == 200) {
                                that.$message({
                                    type: 'success',
                                    message: '上传成功!',
                                })
                                res(true)
                                // that.getProductionList()
                                // that.btn.disable = false;
                                // that.btn.message = "上传服务器";
                                // that.$refs.upload.clearFiles()
                                // console.log( formData.get('file'))
                                formData.delete('file')
                                // console.log( formData.get('file'))
                                that.files = ''
                            } else {
                                that.$message({
                                    type: 'error',
                                    message: response.data.message,
                                })
                                rej(false)
                            }
                        })
                }
            })
            // console.log(id+'测试i')
        },
        // 文件格式验证
        handleChansge (file) {
            // console.log(file)
            this.file = file.raw
        },
        // headerImg
        hangleHeader (e) {
            this.file = e.target.files[0]
            // this.jobDetail.cover = this.getImgUrl(this.file)
            this.headerUrl = this.getImgUrl(this.file)
            // console.log(this.headerUrl, '-----------headerImg-----------')
        },
        // backgroundImg
        hangleBackground (e) {
            this.files = e.target.files[0]
            // this.jobDetail.picture = this.getImgUrl(this.files)
            this.backgroundUrl = this.getImgUrl(this.files)
            // console.log(this.backgroundUrl, '----------backgroundImg-----------')
        },
        // 生成本地地址预览图片
        getImgUrl (file) {
            let url = null
            if (window.createObjectURL != undefined) {
                // basic
                url = window.createObjectURL(file)
            } else if (window.webkitURL != undefined) {
                // webkit or chrome
                url = window.webkitURL.createObjectURL(file)
            } else if (window.URL != undefined) {
                // mozilla(firefox)
                url = window.URL.createObjectURL(file)
            }
            return url
        },
        handleChansges (file) {
            // console.log(file)
            this.files = file.raw
        },
        handleRemove (file, fileList) {
            console.log(file, fileList)
        },
        handlePictureCardPreview (file) {
            this.dialogImageUrl = file.url
            // console.log(this.dialogImageUrl)
            this.dialogVisible = true
        },
        handlePictureCardPreviews (file) {
            this.dialogImageUrl = file.url
            // console.log(this.dialogImageUrl)
            this.dialogVisible = true
        },
        submitUpload () {
            this.$refs.upload.submit()
        },

        // 图片上传成功后，后台返回图片的路径
        onSuccess: function (res) {
            // console.log(res);
            if (res.status == 200) {
                this.imgUrl = res.data.imgUrl
            }
        },
        //下架
        shangjia () {
            const data = {
                id: this.clickItem.id,
                isFreeze: 0,
            }
            // console.log(data)
            const that = this
            this.$axios
                .post(
                    `/api/activity/banActivity?id=${data.id}&isFreeze=${data.isFreeze}`
                )
                .then(function (res) {
                    // console.log(res.data)
                    if (res.data.code === 200) {
                        that.getProductionList()
                        that.outerVisible = false
                        if (data.isFreeze === 0) {
                            that.$message({
                                type: 'success',
                                message: '上架成功!',
                            })
                        } else if (data.isFreeze === 1) {
                            that.$message({
                                type: 'success',
                                message: '下架成功!',
                            })
                        }
                    } else {
                        that.$message({
                            type: 'error',
                            message: '操作失败!',
                        })
                    }
                })
        },
        getId (item) {
            this.clickItem = item
            this.caozuoId = item.id
        },
        //上架
        xiajia () {
            const data = {
                id: this.clickItem.id,
                isFreeze: 1,
            }
            // console.log(data)
            const that = this
            this.$axios
                .post(
                    `/api/activity/banActivity?id=${data.id}&isFreeze=${data.isFreeze}`
                )
                .then(function (res) {
                    // console.log(res.data)
                    if (res.data.code === 200) {
                        that.getProductionList()
                        that.outerVisible = false
                        if (data.isFreeze === 0) {
                            that.$message({
                                type: 'success',
                                message: '上架成功!',
                            })
                        } else if (data.isFreeze === 1) {
                            that.$message({
                                type: 'success',
                                message: '下架成功!',
                            })
                        }
                    } else {
                        that.$message({
                            type: 'error',
                            message: '操作失败!',
                        })
                    }
                })
        },
        // 富文本
        changeLogDetail (val) {
            this.weLanguageForm.text = val
        },
        // 点击获取资源详情
        getParticulars (e) {
            if (e == '') {
                return
            }
            this.addDetail = true
            console.log(this.addDetail)
            let that = this
            this.$axios.post(`/api/activity/findDetail?id=${e}`).then(function (res) {
                // console.log(res.data)
                that.jobDetail = res.data.data
                // console.log(that.jobDetail)
                if (res.data.code === 200) {
                    const list = []
                    list.push(res.data.data.startTime)
                    list.push(res.data.data.endTime)
                    that.addJob.name = res.data.data.name
                    that.addJob.date = list
                    that.showProduction = res.data.data.showProduction
                    // that.addJob.date[1] = res.data.data[0].end
                    that.addJob.cover = res.data.data.cover
                    that.addJob.picture = res.data.data.picture
                    that.addJob.text = res.data.data.text
                    that.addJob.weight =
                        res.data.data.weight == 0 ? '' : res.data.data.weight
                    that.value = res.data.data.type
                    // console.log(that.addJob)
                    // console.log(that.jobDetail)
                    that.weLanguageForm = []
                    // console.log(that.weLanguageForm)
                    console.log(that.addDetail)
                    console.log(res.data.data.contentList.length)
                    if (res.data.data.contentList == 0) {
                        that.addDetail = false
                        console.log(that.addDetail)
                        return
                    }
                    // let richtxt
                    for (let i = 0; i < res.data.data.contentList.length; i++) {
                        fetch(res.data.data.contentList[i].contentUrl, {
                            method: 'GET',
                            mode: 'cors',
                        })
                            .then((res) => {
                                // console.log(res)
                                // that.addDetail = false
                                return res.text()
                            })
                            .then((result) => {
                                // console.log(result)
                                // that.weLanguageForm[i].text = result
                                const data = {
                                    title: res.data.data.contentList[i].title,
                                    sequence: res.data.data.contentList[i].sequence,
                                    text: result,
                                }
                                that.weLanguageForm.push(data)
                                if (i + 1 == res.data.data.contentList.length) {
                                    that.addDetail = false
                                    console.log(that.addDetail)
                                    console.log('-----------------***')
                                }
                                // var parser = new DOMParser()
                                // let doc = parser.parseFromString(data.text, 'text/xml')
                                // console.log(data)
                                // console.log(doc)
                                // // console.log(that.weLanguageForm)

                                // let biaoqian = doc.getElementsByTagName('img')[0]
                                // console.log(biaoqian)
                                // let urls = biaoqian.getAttribute('data-href')
                                // console.log('url------------------')
                                // console.log(urls)
                            })
                    }
                    // fetch(res.data.data.contentList[0].contentUrl, {
                    //     method: 'GET',
                    //     mode: 'cors'
                    // })
                    //     .then(res => {
                    //       console.log(res)
                    //         return res.text()
                    //     })
                    //     .then(result => {
                    //       console.log(result)
                    //         // that.weLanguageForm.text = result
                    //         // that.$nextTick(() => {
                    //         //     // 调用子组件的方法，更新里面的数据
                    //         //     that.$refs.editorRef.updateEditorData()
                    //         //     that.richLoading = false
                    //     })
                    // })
                } else {
                    this.$message.error(res.data.message)
                    this.addDetail = false
                    console.log(this.addDetail)
                }
            })
        },
        // 获取资源页面数据
        async getProductionList (e, type) {
            const that = this
            // if (type == 'time') {
            //   this.ckickTime = e.time[0]
            // }
            // 筛选类型
            if (type == 'type') {
                this.ckickType = e.type[0]
            }
            // 筛选权重
            if (type == 'state') {
                if (e.state.length > 0) {
                    this.state = e.state[0]
                } else {
                    this.state = ''
                }
            }
            // 筛选作品展示
            if (type == 'showProduction') {
                if (e.showProduction.length > 0) {
                    this.production = e.showProduction[0]
                } else {
                    this.production = ''
                }
            }
            // console.log(this.state)
            // console.log(this.production)
            // console.log(e, type)
            // console.log(type)
            this.loading = true
            const data = {
                current: this.currentPage3,
                size: this.pageSize,
                keyword: this.keyword,
                sortKey: this.ckickTime,
                isFreeze: this.state,
                orderBy: this.orderBy,
                showProduction: this.production,
                type: this.ckickType,
            }
            await this.$axios
                .post('/api/activity/findList', data)
                .then(function (res) {
                    // console.log(res.data.data)
                    that.total = res.data.data.total
                    // console.log(that.total)
                    that.tableData = res.data.data.records
                    for (let i = 0; i < that.tableData.length; i++) {
                        that.tableData[i].endTime = that.tableData[i].endTime.substring(
                            0,
                            10
                        )
                        that.tableData[i].startTime = that.tableData[i].startTime.substring(
                            0,
                            10
                        )
                    }
                    that.loading = false
                })
        },
        // 页码大小变动的时候触发
        handleSizeChange (val) {
            // console.log(`每页 ${val} 条`)
            this.currentPage3 = 1
            this.pageSize = val
        },
        // 当前页变动的时候触发
        handleCurrentChange (val) {
            // console.log(`当前页: ${val}`)
            this.currentPage3 = val
            this.getProductionList()
        },
        // 表格内容居中
        thStyleFun () {
            return 'text-align:center;background:#DCDFE6;color:#303133'
        },
        cellStyleFun () {
            return 'text-align:center'
        },
    },

    destroyed () {
        window.removeEventListener('scroll', this.scrollToTop)
    },
}
</script>
<style lang="less" scoped>
.WeLanguage {
    width: 100%;
    height: auto;
    margin: 0 auto;
    border: 1px solid #ccc;
    position: relative;
    font-size: 14px;
    h1 {
        font-size: 16px;
    }
    .weLanguageTemWrap {
        margin-top: 20px;
    }
    .weLanguageTemTitle {
        width: 100%;
        height: 50px;
        line-height: 39px;
        background: rgba(245, 247, 250, 1);
        border-top: 1px solid #ccc;
        border-radius: 2px 2px 0px 0px;
        justify-content: space-between;
        .el-input {
            width: 300px;
        }
        .welcomeLange {
            padding-left: 12px;
        }

        .el-select {
            width: 448px !important;
            height: 28px;

            .el-input {
                line-height: 28px !important;
            }

            .el-input__inner {
                @extend .el-select;
            }
        }
    }

    .addWelWords {
        width: 100%;
        height: 32px;
        line-height: 32px;
        text-align: center;
        color: rgba(87, 87, 102, 1);
        background: rgba(255, 255, 255, 1);
        border-radius: 2px;
        border: 1px solid rgba(220, 223, 230, 1);
        cursor: pointer;
    }
}
// 整个页面
.log {
    width: 100%;
    height: 100%;
}
// 搜索框
.el-input {
    margin: 8px 8px 8px 1.5%;
}
.oinput {
    width: 100%;
    margin: 0;
    padding: 0;
    .el-input {
        width: calc(100% - 170px);
    }
    .el-button {
        width: 120px;
        height: 40px;
        font-size: 14px;
        text-align: center;
    }
}
.fuwenben {
    span {
        display: inline-block;
        width: 100%;
        height: 40px;
        text-align: center;
        line-height: 40px;
        background: #cecece;
    }
    margin-bottom: 30px;
    width: 100%;
    min-height: 100px;
    border: 1px solid #cecece;
    // overflow-y: auto;
}
.content_text {
    width: 100%;
    min-height: 100px;
}
// 用户名和头像
.userName {
    height: 100%;
    // width: 300px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    img {
        width: 32px;
        height: 32px;
        border-radius: 50%;
    }
}
.issue_contents {
    .cover {
        width: 100%;
        height: 135px;
        .cover_span {
            float: left;
            // margin-right: 20px;
            line-height: 135px;
        }
        /deep/ .el-upload {
            width: 240px;
            height: 135px;
            background: #1989fa;
            i {
                color: #fff;
            }
            .el-upload__text {
                // float: left;
                height: 0;
                text-align: center;
                margin-top: -120px;
                color: #fff;
            }
        }
    }
    .rich_text {
        width: 100%;
        min-height: 50px;
        margin-top: 20px;
        margin-bottom: 50px;
    }
    span {
        font-size: 16px;
        font-weight: 700;
    }
}
.issue_content {
    .cover {
        width: 100%;
        height: 135px;
        .cover_span {
            float: left;
            margin-right: 20px;
            line-height: 135px;
        }
        /deep/ .el-upload {
            width: 240px;
            height: 135px;
            background: #1989fa;
            i {
                color: #fff;
            }
            .el-upload__text {
                // float: left;
                height: 0;
                text-align: center;
                margin-top: -120px;
                color: #fff;
            }
        }
    }
}
/deep/.el-tabs__header {
    margin: 0;
}
.is-top {
    margin-bottom: 0;
    padding-bottom: 0;
}
.rw_downlist {
    margin-top: 8px;
}
.rw_downlist,
.rw_time {
    span {
        margin-right: 12px;
    }
}
// 分页
.block {
    margin: 30px auto;
    text-align: center;
}
// 详情的图片
.production_img {
    width: 100%;
    height: 482px;
    background: #dcdfe6;
    margin-bottom: 30px;
}
// 详情标签
.tag_box {
    .el-tag {
        margin: 0 20px;
    }
}
.rw_isShow {
    margin-top: 10px;
}
/deep/ .el-table__column-filter-trigger {
    opacity: 0;
}
/deep/ .caret-wrapper {
    opacity: 0;
}

// 上传样式
.upLoaderHeaderBox {
    width: 438px /2;
    height: 247px /2;
    background: rgb(25, 137, 250);
    border-radius: 10px;
    // margin-left: 50px;
    float: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    span {
        display: inline-block;
        color: #fff;
        line-height: 30px;
    }
}
.upLoaderBackgroundBox {
    width: 438px /2;
    height: 247px /2;
    background: rgb(25, 137, 250);
    border-radius: 10px;
    // margin-left: 50px;
    float: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    span {
        display: inline-block;
        line-height: 30px;
        color: #fff;
    }
}
.upLoaderHeaderBox:hover {
    cursor: pointer;
}
.upLoaderBackgroundBox:hover {
    cursor: pointer;
}
.headerBox {
    width: 219px;
    height: 123.5px;
    float: left;
    margin-right: 20px;
    border-radius: 10px;
    position: relative;
    .yinyin {
        width: 100%;
        height: 100%;
        position: absolute;
        background: rgba(0, 0, 0, 0.4);
        border-radius: 10px;
        display: none;
        span {
            width: 100%;
            height: 100%;
            display: none;
        }
    }
    img {
        width: 100%;
        height: 100%;
        border-radius: 10px;
    }
}
.backgroundBox {
    width: 219px;
    height: 123.5px;
    float: left;
    margin-right: 20px;
    border-radius: 10px;
    position: relative;
    .yinyin {
        width: 100%;
        height: 100%;
        position: absolute;
        background: rgba(0, 0, 0, 0.4);
        border-radius: 10px;
        display: none;
        span {
        }
    }
    img {
        width: 100%;
        height: 100%;
        border-radius: 10px;
    }
}
.headerBox:hover {
    .yinyin {
        cursor: pointer;
        display: block;
        span {
            width: 219px;
            height: 123.5px;
            display: inline-block;
            color: #fff;
            text-align: center;
            i {
                font-size: 30px;
                line-height: 123.5px;
            }
        }
    }
}
.backgroundBox:hover {
    .yinyin {
        cursor: pointer;
        display: block;
        span {
            width: 219px;
            height: 123.5px;
            display: inline-block;
            color: #fff;
            text-align: center;
            i {
                font-size: 30px;
                line-height: 123.5px;
            }
        }
    }
}
</style>
