<template>
    <div id="superWorkCenter">
        <section class="content"></section>
        <section class="right_illustration">
            <img
                src="https://i.pinimg.com/564x/d3/ed/84/d3ed84c563d194c06f4976c0d25ada9a.jpg"
            />
        </section>
    </div>
</template>

<script>
export default {
    data () {
        return {

        }
    },
    created () {

    },
    mounted () {

    },
    methods: {

    }
}
</script>

<style lang="less" scoped>
#superWorkCenter {
    display: flex;
    width: 100%;
}
.content {
    flex-basis: 500px;
    flex-grow: 1;
}
.right_illustration {
    margin-top: 15vh;
    margin-left: 12px;
    margin-right: 12px;
    width: 300px;
    height: auto;
    border-radius: 10px;
    overflow: hidden;
    img {
        width: 100%;
    }
}
</style>
