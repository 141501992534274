<template>
  <!-- 需要登录才会渲染 -->
  <div>
    <div class="index_view" v-if="$store.getters.isLogin">
      <!-- 左侧 -->
      <transition name="el-zoom-in-center">
        <section class="menu_box" :class="{ isCollapse: isCollapse }">
          <h5 class="logo_box">
            <img src="@/assets/images/logo.png" />
          </h5>

          <el-menu
            :router="true"
            class="el-menu-vertical"
            text-color="#909399"
            active-text-color="#1890FF"
          >
            <!-- 根据路由是否带子路由，渲染成一级菜单或者二级菜单 外面包一个div -->
            <div v-for="item in menuList" :key="item.path">
              <!-- 有子路由的情况 -->
              <el-submenu
                v-if="item.children && item.children.length > 0"
                :index="item.name"
              >
                <template slot="title">
                  <i :class="item.meta.icon"></i>
                  <span>{{ item.meta.title }}</span>
                </template>
                <el-menu-item
                  v-for="subItem in item.children"
                  :key="subItem.name"
                  :index="subItem.path"
                >
                  <i :class="subItem.meta.icon"></i>
                  <span slot="title">{{ subItem.meta.title }}</span>
                </el-menu-item>
              </el-submenu>
              <!-- 没有子路由的情况 -->
              <el-menu-item v-else :index="item.path">
                <!-- <icon-svg :icon-class="item.meta.icon"></icon-svg> -->
                <i :class="item.meta.icon"></i>
                <span slot="title">{{ item.meta.title }}</span>
              </el-menu-item>
            </div>
          </el-menu>
        </section>
      </transition>
      <section class="right_module">
        <div class="userfunction_bar">
          <div>
          <el-switch
            v-model="isCollapse"
            active-color="#13ce66"
            inactive-color="#ff4949"
          >
          </el-switch>
          <router-link to="/index">
            <el-button style="margin:0 0 0 15px">工作台</el-button>
          </router-link>
          </div>
          <div class="right_box">
            <img src="@/assets/images/xiaoxin.svg" class="avatar" />
            <span class="user_name">你好,{{ username }}</span>
            <el-button
              @click="goEditPassword"
              class="edit_password"
              type="primary"
              >修改密码</el-button
            >
            <el-button @click="logOut" type="warning" class="logout_button"
              >退出
            </el-button>
          </div>
        </div>
        <el-card class="mian_content">
          <router-view></router-view>
        </el-card>
      </section>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isCollapse: false,
      username: "",
      msg: false,
      menuList: [],
      role: "",
      userId: "",
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      if (this.$store.getters.isLogin) {
        this.checkoutUsername();

        this.menuList = this.filterMenu(this.$store.getters.routes[1].children);
       

      } else {
        this.$router.push("/login");
      }
    },
    filterMenu(list) {
      let menu = [];
      list.forEach((element) => {
        if (element.meta.useInMenu) {
          menu.push(element);
        }
      });
      return menu;
    },
    handleSelect(index, indexPath) {
      // console.log(index)
      // console.log(indexPath)
      //  找到index 的菜单
      const chooseMenu = this.menuList.find(
        (item) => item.index == indexPath[0]
      );
      const choosePage = chooseMenu.children.find(
        (item) => item.index === indexPath[1]
      );
      // console.log(chooseMenu)
      // console.log(choosePage)
      if (this.openPageList.includes(choosePage)) {
        // 激活当前页面
      } else {
        this.openPageList.push(choosePage);
      }
    },
    logOut() {
      this.$axios.get("/api/admin/logout").then((res) => {
        if (res.data.data) {
          this.$store.dispatch("loginOut");
          localStorage.clear()
          this.closeSse();
        }
      });
    },
    closeSse() {
      // this.source.close();
      const httpRequest = new XMLHttpRequest();
      httpRequest.open("GET", `/api/common/close?userId=${this.userId}`);
      httpRequest.send();
      // console.log('close');
    },
    goEditPassword() {
      this.$router.push("/alterPsw");
    },
    checkoutUsername() {
      //   const that = this;
      this.$axios.post(`/api/admin/currentRole`).then((res) => {
        // console.log(res.data.data.role, "----");
        this.role = res.data.data.role || "";
        this.userId = res.data.data.userId || "";
        if (res.data.data.role === "super") {
        
          this.msg = true;
        } else {
       
          this.msg = false;
        }
          this.username = res.data.data.username;
          localStorage.setItem("username",this.username)
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-menu-vertical:not(.el-menu--collapse) {
  width: 240px;
  min-height: 400px;
}
// 组件根元素
.index_view {
  height: 100vh;
  display: flex;
  overflow: hidden;
  background-color: #eee;
}

// 组件二级元素
.menu_box {
  width: 150px;
  flex-basis: 235px;
  flex-shrink: 0;
  flex-grow: 0;
  height: 100%;
  background-color: #fff;
  transition: all 0.3s linear;
  overflow: hidden;
  .logo_box {
    padding: 12px 36px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    height: 80px;
    box-sizing: border-box;
    img {
      width: 100%;
    }
  }
  .el-menu {
    height: calc(100vh - 80px);
    padding-bottom: 100px;
    box-sizing: border-box;
    overflow-y: auto;
    transition: all 0.3s linear;
  }
}
.isCollapse {
  width: 0;
  flex-basis: 0;
}
// 组件二级元素
.right_module {
  width: 500px;
  padding-top: 72px;
  height: 100vh;
  overflow-y: auto;
  flex-grow: 1;
  .userfunction_bar {
    height: 60px;
    border: 1px solid #ebeef5;
    background-color: #fff;
    color: #303133;
    transition: 0.3s;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    z-index: 100;
    top: 0;
    width: calc(100vw - 250px);
    box-sizing: border-box;
    padding: 0 0 0 12px;
    // background-image: url("~@/assets/images/home/erciyuan.png");
    // background-size: auto 80%;
    // background-repeat: no-repeat;
    // background-position: center;
    .right_box {
      display: flex;
      align-items: center;
      height: 100%;
      .avatar {
        height: 70%;
        margin-right: 12px;
      }
      .edit_password {
        margin-left: 12px;
      }
      .logout_button {
        margin-left: 12px;
        margin-right: 12px;
      }
    }
  }
  .mian_content {
    padding-bottom: 96px;
    background-color: #fff;
    margin-left: 12px;
    min-height: calc(100vh - 100px);
    // background-image: url("~@/assets/images/workCenter/linglong.jpeg");
    // background-image: url("~@/assets/images/home/yuanshen.jpg");
    background-image: url("../assets/images/back.jpg");
    background-size: 100%;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }
}
</style>
