let websock = null
let globalCallback = null

const token = window.localStorage.getItem('www.suczone.com')

function initWebSocket () { // 初始化weosocket
    // 处理地址转化ws
    // if(url.indexOf('https')!=-1){
    //   url='ws'+url.substring(5)
    // }else if(url.indexOf('http')!=-1){
    //   url='ws'+url.substring(4)
    // }
    const url = 'ws://192.168.1.29:9006/chat'
    // ws地址
    websock = new WebSocket(url + '/param=' + token)
    websock.onmessage = function (e) {
        websocketonmessage(e)
    }
    websock.onclose = function (e) {
        websocketclose(e)
    }
    // console.log(url)
    websock.onopen = function () {
        websocketOpen()
    }

    // 连接发生错误的回调方法
    websock.onerror = function () {
        console.log('WebSocket连接发生错误')
    }
}

// 实际调用的方法
function sendSock (agentData, callback) {
    globalCallback = callback
    if (websock.readyState === websock.OPEN) {
        // 若是ws开启状态
        websocketsend(agentData)
    } else if (websock.readyState === websock.CONNECTING) {
        // 若是 正在开启状态，则等待1s后重新调用
        setTimeout(function () {
            sendSock(agentData, callback)
        }, 1000)
    } else {
        // 若未开启 ，则等待1s后重新调用
        setTimeout(function () {
            sendSock(agentData, callback)
        }, 1000)
    }
}

// 数据接收
function websocketonmessage (e) {
    console.log(e)
    console.log('↑ 为接收到的数据')
    globalCallback(e.data)
    // globalCallback(JSON.parse(e.data));
}

// 数据发送
function websocketsend (agentData) {
    const nowDate = new Date()
    const date = {
        year: nowDate.getFullYear(),
        month: nowDate.getMonth() + 1,
        date: nowDate.getDate()
    }
    const newmonth = date.month > 10 ? date.month : '0' + date.month
    const day = date.date > 10 ? date.date : '0' + date.date
    const updateTime = date.year + '-' + newmonth + '-' + day
    // 处理为后端期待的数据
    const data = {
        token: token,
        to: agentData.toId,
        date: updateTime,
        message: agentData.message
    }
    websock.send(JSON.stringify(data))
}

// 关闭
function websocketclose (e) {
    console.log(e)
    websock.close()
    console.log('connection closed (' + e + ')')
}

function websocketOpen (e) {
    console.log('连接成功')
    console.log(e)
}
// 使用
// this.$socket.initWebSocket() 连接websocket
/** this.$socket.sendSock(data, this.wsMessage) 发送消息
 *  sendSock两个参数
 * data:{
 *  toId:'接收消息的用户id',
 *  message:'发送的消息内容',
 * }
 * wsMessage（res）：是一个回调函数，需写在methods中，有res参数，为接收到的消息和发送者的id
 *  */
//
// this.$socket.websocketclose() 断开连接
export default { sendSock, initWebSocket, websocketclose }
