<template>
  <div class="production">
    <!-- 搜索框 -->
    <el-input
      placeholder="请输入作品名"
      prefix-icon="el-icon-search"
      v-model="input"
      @change="filterData()"
    >
    </el-input>
    <!-- 表格 -->
    <el-main>
      <el-table
        v-loading="loading"
        stripe
        :data="tableData"
        :header-cell-style="thStyleFun"
        :cell-style="cellStyleFun"
        @filter-change="filterChange"
      >
        <el-table-column prop="cover" label="作品封面">
          <template slot-scope="scope">
            <div class="demo-image">
              <!-- 封面图片 -->
              <div
                class="block"
                style="margin:0 auto;width: 100px; height: 100px;"
              >
                <el-image
                  style="width: 100px; height: 100px;"
                  :src="scope.row.cover"
                  fit="cover"
                ></el-image>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="作品名称"> </el-table-column>
        <el-table-column prop="userId" label="昵称">
          <template slot-scope="scope">
            <div class="userName" @click="goUser(scope.row.userId)">
              <img
                :src="
                  scope.row.headPicture === null
                    ? require('@/assets/images/xiaoxin.svg')
                    : scope.row.headPicture
                "
                alt=""
              />
              <span> {{ scope.row.nickname }} </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="isFreeze"
          label="状态"
          :filters="[
            { text: '下架', value: 0 },
            { text: '正常', value: 1 },
          ]"
          column-key="isFreeze"
          filter-placement="bottom-end"
          :filter-multiple="false"
        >
          <template slot="header">
            状态
            <i
              style="font-size:12px
            "
              class="icon iconfont icon-jiantou1"
            ></i>
          </template>
          <template slot-scope="scope">
            <!-- <span>{{ scope.row.isFreeze == 0 ? '下架' : '正常' }}</span> -->
            <el-switch
              v-model="scope.row.isFreeze"
              :active-value="1"
              :inactive-value="0"
              @change="shangxiajia(scope.$index, scope.row)"
            ></el-switch>
            <!-- {{ scope.row.isFreeze }} -->
          </template>
        </el-table-column>
        <el-table-column prop="operation" label="操作">
          <template slot-scope="scope">
            <el-link
              :underline="false"
              type="primary"
              @click=";(outerVisible = true), getParticulars(scope.row.id)"
              >详情</el-link
            >
            <!-- <el-link
              :underline="false"
              v-if="scope.row.isFreeze === 1"
              type="primary"
              @click=";(innerVisible = true), getId(scope.row)"
              style="margin:10px"
              >下架</el-link
            >
            <el-link
              :underline="false"
              v-if="scope.row.isFreeze === 0"
              type="primary"
              @click=";(innerVisibles = true), getId(scope.row)"
              style="margin:10px"
              >取消下架</el-link
            > -->
            <span
              style="font-size:12px;margin:0 15.6px;color:#000000;opacity:0.3"
              >|</span
            >
            <el-link
              :underline="false"
              v-if="scope.row.recommend === 0"
              type="primary"
              @click=";(isRecommendVisible = true), getId(scope.row)"
              >上推荐</el-link
            >
            <el-link
              :underline="false"
              v-else
              type="danger"
              @click=";(unRecommendVisible = true), getId(scope.row)"
              >取消推荐</el-link
            >
          </template>
          <!-- <template slot-scope="scope"> -->
          <!-- <el-link :underline="false"  v-if="productionData.isFreeze===1" type="primary"  @click="innerVisible = true">下 架</el-link>
            <el-link :underline="false"  v-if="productionData.isFreeze===0" type="primary"  @click="innerVisibles = true">取 消 下 架</el-link> -->
          <!-- <el-button v-if="productionData.isFreeze===1" type="danger" @click="innerVisible = true">下 架</el-button>
            <el-button v-if="productionData.isFreeze===0" type="primary" @click="innerVisibles = true">取 消 下 架</el-button> -->
          <!-- </template> -->
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage3"
          :page-size="pageSize"
          layout="prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </el-main>
    <!-- 对话框 -->
    <el-dialog
      width="30%"
      title="确定要下架吗?"
      :visible.sync="innerVisible"
      append-to-body
    >
      <div>
        <span>下架原因:</span>
        <el-input placeholder="请输入下架原因" v-model="cause"> </el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="innerVisible = false">取消</el-button>
        <el-button type="primary" @click="soldOutProduction()">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      width="30%"
      title="确定要取消下架吗?"
      :visible.sync="innerVisibles"
      append-to-body
    >
      <span slot="footer" class="dialog-footer">
        <el-button @click="innerVisibles = false">取消</el-button>
        <el-button type="primary" @click="soldOutProduction()">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      v-loading="dialogLoading"
      @close="guanbi()"
      :title="productionData.name"
      :visible.sync="outerVisible"
    >
      {{ productionData.description }}
      <div class="production_img">
        <banner :imagesList="imagesLists"></banner>
      </div>
      <div class="tag_box">
        <el-tag v-for="(item, index) in label" :key="index">{{ item }}</el-tag>
      </div>
      <div
        class="cause"
        v-if="productionData.reason !== null && productionData.reason !== ''"
      >
        <span>下架原因:</span>
        {{ productionData.reason }}
      </div>
      <table class="xiangxixinxi">
        <tr>
          <span>类型:</span>
          <th>{{ productionData.mold }}</th>
        </tr>
        <tr>
          <span>发布时间:</span>
          <th>{{ productionData.createTime }}</th>
        </tr>
        <tr>
          <span>更新时间:</span>
          <th>{{ productionData.updateTime }}</th>
        </tr>
        <tr>
          <span>热度:</span>
          <th>{{ productionData.heat }}</th>
        </tr>
        <tr>
          <span>途径:</span>
          <th>{{ productionData.type }}</th>
        </tr>
        <!-- <tr>
        <span>点赞数量:</span>
        <th>{{ productionData.thumbLevel }}</th>
      </tr> -->
        <tr>
          <span>适用软件:</span>
          <th>{{ productionData.software }}</th>
        </tr>
      </table>

      <div slot="footer" class="dialog-footer">
        <!-- <template slot-scope="scope"> -->
        <el-button @click="outerVisible = false">取 消</el-button>
        <!-- <el-button v-if="productionData.isFreeze===1" type="danger" @click="innerVisible = true">下 架</el-button>
        <el-button v-if="productionData.isFreeze===0" type="primary" @click="innerVisibles = true">取 消 下 架</el-button> -->
        <!-- </template> -->
      </div>
    </el-dialog>
    <!-- 点击用户名出来的用户信息对话框 -->
    <el-dialog title="用户详情" :visible.sync="dialogVisible" width="750px">
      <el-dialog
        title="确定要封禁此用户吗？"
        :visible.sync="dialogFormVisible"
        append-to-body
      >
        <span>
          当前用户名：
        </span>
        <span>
          <span>{{ useredMessage.username }}</span>
        </span>
        <br />
        <br />
        <span>
          当前状态：
        </span>
        <span>
          <span>{{ useredMessage.isFreeze === 0 ? '黑名单' : '正常' }}</span>
        </span>
        <br />
        <span>
          封号时长:
        </span>
        <el-input
          style="width:200px"
          placeholder="请输入封多少天"
          v-model="input2"
        >
          <template slot="append">天</template>
        </el-input>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="confirmBanned()">确 定</el-button>
        </div>
      </el-dialog>
      <el-dialog
        title="确定要解封此用户吗？"
        :visible.sync="jiefeng"
        append-to-body
      >
        <span>
          当前用户名：
        </span>
        <span>
          <span>{{ useredMessage.username }}</span>
        </span>
        <br />
        <br />
        <span>
          当前状态：
        </span>
        <span>
          <span>{{ useredMessage.isFreeze === 0 ? '黑名单' : '正常' }}</span>
        </span>
        <div slot="footer" class="dialog-footer">
          <el-button @click="jiefeng = false">取 消</el-button>
          <el-button type="primary" @click=";(jiefeng = false), userjiefeng()"
            >确 定</el-button
          >
        </div>
      </el-dialog>
      <div class="userBox">
        <div class="user_message">
          <div class="header">
            <span>用户头像：</span>
            <img
              :src="
                useredMessage.headPicture === null
                  ? require('@/assets/images/xiaoxin.svg')
                  : useredMessage.headPicture
              "
              alt=""
            />
          </div>
          <div class="box">
            <p>注册时间：{{ useredMessage.createTime }}</p>
            <p>权限：{{ useredMessage.role === 'vip' ? '会员' : '普通' }}</p>
            <p>等级：{{ useredMessage.level }}</p>
            <p>金币数：{{ useredMessage.integral }}</p>
            <p>状态：{{ useredMessage.isFreeze === 0 ? '黑名单' : '正常' }}</p>
          </div>
        </div>
        <div class="right">
          <p>
            作品数量：{{
              useredMessage.proUpload === null ? 0 : useredMessage.proUpload
            }}
          </p>
          <p>
            文章数量：{{
              useredMessage.tutUpload === null ? 0 : useredMessage.tutUpload
            }}
          </p>
          <p>
            资源数量：{{
              useredMessage.resUpload === null ? 0 : useredMessage.resUpload
            }}
          </p>
          <p>
            近一周作品浏览数量：{{
              useredMessage.proVisit === null ? 0 : useredMessage.proVisit
            }}
          </p>
          <p>
            近一周日志浏览数量：{{
              useredMessage.tutVisit === null ? 0 : useredMessage.tutVisit
            }}
          </p>
          <p>
            近一周资源浏览数量：{{
              useredMessage.resVisit === null ? 0 : useredMessage.resVisit
            }}
          </p>
          <p>
            近一周资源下载数量：{{
              useredMessage.resDownload === null ? 0 : useredMessage.resDownload
            }}
          </p>
        </div>
        <div style="width:50%;float:right">
          <div id="main1" style="float:right;width:100%;height: 300px"></div>
        </div>
        <div style="width:50%,float:left">
          <div id="main" style="float:left;width:50%;height: 300px"></div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button
          type="danger"
          v-if="useredMessage.isFreeze === 1"
          @click="dialogFormVisible = true"
          >封 号</el-button
        >
        <el-button type="warning" v-else @click="jiefeng = true"
          >解 封</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      width="30%"
      title="确定推荐此作品吗?"
      :visible.sync="isRecommendVisible"
      append-to-body
    >
      <span slot="footer" class="dialog-footer">
        <el-button @click="isRecommendVisible = false">取消</el-button>
        <el-button type="primary" @click="isRecommend()">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      width="30%"
      title="确定要取消推荐此作品吗?"
      :visible.sync="unRecommendVisible"
      append-to-body
    >
      <span slot="footer" class="dialog-footer">
        <el-button @click="unRecommendVisible = false">取消</el-button>
        <el-button type="primary" @click="isRecommend()">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import banner from '../zujian/banner.vue'
export default {
  components: { banner },
  data() {
    return {
      imagesLists: [],
      tableData: [],
      // 搜索框
      input: '',
      // 请求接口传递的类型
      type: 'produce',
      // 页数
      currentPage3: 1, // 默认显示的页数
      total: 0, // 数据的总条数
      pageSize: 7, // 每页多少条
      // 对话框
      outerVisible: false,
      innerVisible: false,
      dialogVisible: false,
      innerVisibles: false,
      dialogFormVisible: false,
      isRecommendVisible: false,
      unRecommendVisible: false,
      jiefeng: false,
      // 数据数组
      productionList: [],
      // 当前点击作品的详情
      productionData: [],
      loading: false,
      dialogLoading: false,
      useredMessage: {},
      input2: '',
      label: [],
      clickId: '',
      // 下架原因
      cause: '',
      isFreeze: null,
    }
  },
  created() {
    this.getProductionList()
  },
  methods: {
    // 上下推荐
    isRecommend() {
      if (this.clickId.recommend == 0) {
        this.$axios
          .put(`/api/production/recommendProduction?id=${this.clickId.id}`)
          .then((res) => {
            if (res.data.code == 200) {
              this.$message.success('推荐成功！')
              this.isRecommendVisible = false
              this.getProductionList()
            } else {
              this.$message.error(res.data.message)
            }
          })
      } else {
        this.$axios
          .put(`/api/production/recommendProduction?id=${this.clickId.id}`)
          .then((res) => {
            if (res.data.code == 200) {
              this.$message.success('取消推荐成功！')
              this.unRecommendVisible = false
              this.getProductionList()
            } else {
              this.$message.error(res.data.message)
            }
          })
      }
    },
    //点击上下架的滑块
    shangxiajia(index, item) {
      this.clickId = item
      console.log(index)
      console.log(item)
      if (item.isFreeze == 1) {
        item.isFreeze = 0
        this.innerVisibles = true
      } else if (item.isFreeze == 0) {
        item.isFreeze = 1
        this.innerVisible = true
      }
    },
    getId(e) {
      // if (e.isFreeze == '1') {
      //   this.innerVisible = true
      // } else {
      //   this.innerVisiblesthis = true
      // }
      this.clickId = e
      // this.innerVisible = true
      // console.log(this.clickId)
    },
    // 用户上传统计图
    initData(data) {
      // 基于准备好的dom，初始化echarts实例
      var myChart = this.$echarts.init(document.getElementById('main1'))
      // 绘制图表
      myChart.setOption({
        title: {
          text: '用户上传量', //主标题
          subtext: '近一周', //副标题
          x: 'center', //x轴方向对齐方式
        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)',
        },
        legend: {
          orient: 'vertical',
          bottom: 'bottom',
          data: ['作品', '文章', '资源'],
        },
        series: [
          {
            name: '近一周占比',
            type: 'pie',
            radius: '55%',
            center: ['50%', '60%'],
            data: [
              {
                value: data.proUpload,
                name: '(' + data.proUpload + ')' + '作品',
              },
              {
                value: data.tutUpload,
                name: '(' + data.tutUpload + ')' + '文章',
              },
              {
                value: data.resUpload,
                name: '(' + data.resUpload + ')' + '资源',
              },
            ],
            itemStyle: {
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
          },
        ],
      })
    },
    // 用户浏览统计图
    initDatas(data) {
      // 基于准备好的dom，初始化echarts实例
      var myChart = this.$echarts.init(document.getElementById('main'))
      // 绘制图表
      myChart.setOption({
        title: {
          text: '用户浏览量', //主标题
          subtext: '近一周', //副标题
          x: 'center', //x轴方向对齐方式
        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)',
        },
        legend: {
          orient: 'vertical',
          bottom: 'bottom',
          data: ['作品', '文章', '资源下载量'],
        },
        series: [
          {
            name: '近一周占比',
            type: 'pie',
            radius: '55%',
            center: ['50%', '60%'],
            data: [
              {
                value: data.proVisit,
                name: '(' + data.proVisit + ')' + '作品',
              },
              {
                value: data.tutVisit,
                name: '(' + data.tutVisit + ')' + '文章',
              },
              {
                value: data.resDownload,
                name: '(' + data.resDownload + ')' + '资源下载量',
              },
            ],
            itemStyle: {
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
          },
        ],
      })
    },
    // 解封
    userjiefeng() {
      const that = this
      this.$axios
        .put(`/api/user/unblockUser?id=${this.clickId.userId}`)
        .then(function(res) {
          console.log(res.data)
          if (res.data.code === 200) {
            that.dialogVisible = false
            that.getProductionList()
            that.$message({
              type: 'success',
              message: '解封成功',
            })
          }
        })
    },
    // 封号
    confirmBanned() {
      const that = this
      this.input2 = this.input2.replace(/[^\d]/g, '')
      if (this.input2 === '') {
        this.$message({
          type: 'error',
          message: '请输入封号时长且只能输入纯数字!',
        })
      } else {
        const data = {
          date: this.input2,
          id: this.clickId.userId,
        }
        this.$axios.put('/api/user/blockUser', data).then((res) => {
          if (res.data.code === 200) {
            this.dialogFormVisible = false
            this.dialogVisible = false
            that.getProductionList()
            that.input2 = ''
            this.$message({
              type: 'success',
              message: '封号成功!',
            })
          }
        })
      }
    },
    getUser(data) {
      this.useredMessage = ''
      this.useredMessage = data
      console.log(this.useredMessage)
      // this.$axios.post(`/api/user/findOne?userId=${}`)
    },
    guanbi() {
      this.imagesLists = []
      this.productionData = []
    },
    goUser(id) {
      this.dialogVisible = true
      const that = this
      console.log(id)
      this.$axios.post(`/api/user/findOne?userId=${id}`).then(function(res) {
        console.log(res.data)
        if (res.data.code === 200) {
          that.useredMessage = res.data.data
          that.initData(that.useredMessage)
          that.initDatas(that.useredMessage)
        }
      })
    },
    // 搜索筛选
    filterData() {
      this.loading = true
      this.productionList = []
      const that = this
      // console.log(this.input)
      const data = {
        current: this.currentPage3,
        keyword: this.input,
        size: this.pageSize,
        isFreeze: this.isFreeze,
      }
      this.$axios.post('/api/production/findList', data).then(function(res) {
        // console.log(res.data.data)
        that.tableData = res.data.data.records
        that.productionList = res.data.data.records
        // console.log(that.tableData)
        // console.log(that.productionList)
        that.total = res.data.data.total
        that.loading = false
        // console.log(that.total)
      })
    },
    // 上下架筛选 filter-change是当筛选内容改变的时候触发的
    filterChange(filterObj) {
      this.loading = true
      this.tableData = []
      this.productionList = []
      const that = this
      console.log('111', filterObj)
      this.isFreeze = filterObj.isFreeze[0]
      const data = {
        current: this.currentPage3,
        keyword: this.input,
        size: this.pageSize,
        isFreeze: this.isFreeze,
      }
      this.$axios.post('/api/production/findList', data).then(function(res) {
        // console.log(res.data.data)
        that.tableData = res.data.data.records
        that.productionList = res.data.data.records
        // console.log(that.tableData)
        // console.log(that.productionList)
        that.total = res.data.data.total
        that.loading = false
        // console.log(that.total)
      })
    },
    // 获取作品管理信息
    async getProductionList() {
      this.loading = true
      const that = this
      const data = {
        current: this.currentPage3,
        keyword: this.input,
        size: this.pageSize,
        isFreeze: this.isFreeze,
      }
      await this.$axios
        .post('/api/production/findList', data)
        .then(function(res) {
          // console.log(res.data.data)
          that.tableData = res.data.data.records
          that.productionList = res.data.data.records
          // console.log(that.tableData)
          // console.log(that.productionList)
          that.total = res.data.data.total
          // console.log(that.total)
          that.loading = false
        })
    },
    // 点击获取作品详情
    getParticulars(e) {
      this.imagesLists = []
      this.label = []
      this.dialogLoading = true
      const that = this
      // console.log(e)
      this.$axios
        .get(`/api/production/findDetails?id=${e}`)
        .then(function(res) {
          // console.log(res.data)
          if (res.data.code === 200) {
            that.productionData = res.data.data
            that.dialogLoading = false
            // that.productionData.forEach(item=>{
            //   console.log(item.url)
            // })
            that.imagesLists = that.productionData.picture.map(
              (item) => item.url
            )
            if (that.productionData.video !== null) {
              that.imagesLists.push(that.productionData.video.url)
            }
            // console.log(that.productionData)
            // console.log(that.imagesLists)
            // console.log(that.imagesLists.length)
            if (that.productionData.label === null) {
              return
            } else if (that.productionData.label.indexOf(',') != -1) {
              that.label = that.productionData.label.split(',')
              // console.log(that.label)
            } else {
              that.label.push(that.productionData.label)
              // console.log(that.label[0])
            }
            // that.getImgInfo(that.imagesLists)
          }
        })
    },
    // 页码大小变动的时候触发
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`)
      this.currentPage3 = 1
      this.pageSize = val
    },
    // 当前页变动的时候触发
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`)
      this.currentPage3 = val
      this.getProductionList()
    },
    // 上、下架作品
    soldOutProduction() {
      const that = this
      console.log(this.clickId.isFreeze)
      if (this.clickId.isFreeze === 1) {
        if (that.cause === '') {
          that.$message({
            type: 'warning',
            message: '请输入下架原因',
          })
        } else {
          that.$axios
            .put(
              `/api/production/banProduction?id=${this.clickId.id}&reason=${this.cause}`
            )
            .then(function(res) {
              // console.log(res.data)
              if (res.data.code === 200) {
                that.innerVisibles = false
                that.outerVisible = false
                that.getProductionList()
                that.$message({
                  message: '操作成功!',
                  type: 'success',
                })
                that.innerVisible = false
                that.cause = ''
              } else {
                that.$message.error('下架失败!')
              }
            })
        }
      } else if (this.clickId.isFreeze === 0) {
        that.$axios
          .put(
            `/api/production/banProduction?id=${this.clickId.id}&reason=${null}`
          )
          .then(function(res) {
            // console.log(res.data)
            if (res.data.code === 200) {
              that.innerVisibles = false
              that.outerVisible = false
              that.getProductionList()
              that.$message({
                message: '操作成功!',
                type: 'success',
              })
              that.cause = ''
            } else {
              that.$message.error('取消下架失败!')
            }
          })
      }
    },
    // 表格内容居中
    thStyleFun() {
      return 'text-align:center;background:#DCDFE6;color:#303133'
    },
    cellStyleFun() {
      return 'text-align:center'
    },
  },
}
</script>
<style lang="less" scoped>
// 整个页面
.production {
  width: 100%;
  height: 100%;
}
// 搜索框
.el-input {
  margin: 8px 1.5%;
  width: 97%;
}
// 用户名和头像
.userName {
  height: 100%;
  // width: 300px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }
  span {
    position: relative;
    top: -10px;
    margin-left: 10px;
  }
}
.userName:hover {
  cursor: pointer;
}
.cause {
  // margin-top: 20px;
  span {
    font-size: 18px;
    font-weight: 600;
    color: red;
  }
}
// 详情对话框里的文字
.xiangxixinxi {
  tr {
    span {
      word-break: keep-all; /* 不换行 */
      white-space: nowrap;
    }
    font-weight: 600;
    th {
      font-weight: 400;
      text-indent: 10px;
      text-align: left;
    }
  }
}
.miaoshu {
  font-size: 14px;
  span {
    font-weight: 600;
    text-align: right;
  }
}
// 分页
.block {
  margin: 30px auto;
  text-align: center;
}
// 作品详情作品图片
.production_img {
  // width: 100%;
  // height: 56.25%;
  // margin-bottom: 30px;
  // img{
  //   // width: 100%;
  //   // height: 100%;
  // }
}
// 标签
.tag_box {
  margin-top: 20px;
  .el-tag {
    margin: 0 10px;
  }
}
// 用户对话框样式
.userBox {
  width: 750px;
  min-height: 600px;
}
.user_message {
  // position: relative;
  float: left;
  .header {
    span {
      display: inline-block;
      // background: red;
      position: relative;
      top: -20px;
    }
    img {
      width: 44px;
      height: 44px;
    }
  }
  .box {
    font-size: 16px;
  }
}
.right {
  float: right;
  font-size: 16px;
  margin-right: 100px;
  margin-top: -26px;
}
/deep/ .el-table__column-filter-trigger {
  opacity: 0;
}
</style>
