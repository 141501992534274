import Vue from 'vue'
import Vuex from 'vuex'
import router, { fullRoutes, resetRouter, originalRoutes, page404 } from '@/router/router.js'

// 判断是否有权限
function hasPermission (roles, route) {
    // 如果存在meta.role 数组而且长度大于1（空数组表示公共路由） ，需要验证权限
    if (route.meta && route.meta.roles.length > 0) {
        return roles.some(role => route.meta.roles.includes(role))
    } else {
        // 如果不存在，则是所有用户都有的路由
        return true
    }
}

//  导出一个路由的过滤器，传入路由数组和角色，返回用户最终拥有的路由
export function filterFullRoutes (routes, roles) {

    const res = []
    routes.forEach(route => {
        const tmp = { ...route }
        if (hasPermission(roles, tmp)) {
            if (tmp.children) {
                // 如果有子路由，递归处理子路由
                tmp.children = filterFullRoutes(tmp.children, roles)
            }
            res.push(tmp)
        }
    })
    return res
}
Vue.use(Vuex)
export default new Vuex.Store({
    // 每一个state 的属性里面保存的都是一个独立的数据
    state: {
        //  是否初始化
        hasInit: false,
        // 是否登录的标记
        isLogin: false,
        role: '',
        routes: [],
        operNum: {}

    },
    // 通过getters 可以获取state里面的数据
    getters: {
        hasInit: state => state.hasInit,
        isLogin: state => state.isLogin,
        role: state => state.role,
        routes: state => state.routes

    },
    // mutations 必须同步执行，分发mutations 可以修改state里面的数据
    mutations: {
        saveIsLogin (state, isLogin) {
            state.isLogin = isLogin
        },
        saveHasInit (state, hasInit) {
            state.hasInit = hasInit
        },
        saveRole (state, role) {
            state.role = role
        },
        saveRoutes (state, routes) {
            state.routes = routes
        },
        operating (state, operNum) {
            state.operNum = operNum
        },
    },
    // actions 提交的是mutations 而且可以异步执行
    actions: {
        login ({ commit }, data) {
            const { role, token } = data
            window.localStorage.setItem('user', token)
            window.localStorage.setItem('role', role)
            commit('saveRole', role)
            return new Promise(resolve => {
                // 处理传过来的是数组还是字符串，兼容单个角色和多个角色
                let newRoles = []
                if (Array.isArray(role)) {
                    newRoles = role
                } else {
                    newRoles.push(role)
                }
                // 根据角色生产一个完整最终的路由
                const finalRouter = filterFullRoutes(fullRoutes, newRoles)
                commit('saveRoutes', finalRouter)
                commit('saveIsLogin', true)
                commit('saveHasInit', true)
                resetRouter(finalRouter)
                //  返回菜单里面的第一个可用路由
                resolve(finalRouter[1].children[0].path)
            })
        },
        // 根据角色生成路由 | 角色是一个数组
        generateRoutes ({ commit }, roles) {
            let newRoles = []
            if (Array.isArray(roles) === false) {
                newRoles.push(roles)
            } else {
                newRoles = roles
            }
            return new Promise(resolve => {
                // 根据角色生产一个完整最终的路由
                const finalRouter = filterFullRoutes(fullRoutes, newRoles)
                commit('saveRoutes', finalRouter)
                commit('saveRole', roles)
                resetRouter(finalRouter)
                // 4.0 以后的添加。路由是单个添加，原来的不推荐
                // finalRouter.forEach(route => {
                //     router.addRoute(route)
                // })
                resolve(finalRouter)
            })
        },
        // 登出并且跳转到登录页面方法异步
        loginOut ({ commit }) {
            const routes = originalRoutes.concat([page404])
            return new Promise(resolve => {
                window.localStorage.setItem('user', '')
                resetRouter(routes)
                commit('saveRole', '')
                commit('saveRoutes', routes)
                commit('saveIsLogin', false)
                router.push('/login')
                resolve()
            })
        },
        // 退出登录并且停留在当前页面
        loginOutStayThisPage ({ commit }) {
            const routes = originalRoutes.concat([page404])
            return new Promise(resolve => {
                window.localStorage.setItem('user', '')
                resetRouter(routes)
                commit('saveRole', [])
                commit('saveRoutes', routes)
                commit('saveIsLogin', false)
                resolve()
            })
        }
    },
    // modules 当项目比较大，store 比较臃肿的时候可以将store 分解成更小的模块
    modules: {
    }
})


