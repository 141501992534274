<template lang="html">
  <div class="editor">
    <div ref="toolbar" class="toolbar"></div>
    <div ref="editor" class="text"></div>
    <el-button type="danger" @click="deleteStudent">删除</el-button>
     <el-dialog
            :visible.sync="dialogVisible"
            class="normal_dialog progress_dialog"
            width="598px"
            title="提示"
            :modal="true"
            :close-on-press-escape="false"
            :show-close="false"
        >
            <div class="info">
                <span>正在上传文件中，请勿关闭窗口！</span>
            </div>
            <div class="text">{{ progress }}</div>
            <div class="progress_box">
                <div class="progress" :style="{ width: progress }"></div>
            </div>
        </el-dialog>
  </div>
</template>

<script>
import E from 'wangeditor'
import OSS from 'ali-oss'
import { guid, getFileType } from '@/utils/utils.js'
export default {
    name: 'editoritem',
    data () {
        return {
            // uploadPath,
            editor: null,
            info_: null,
            editorData: '',
            client: null,
            dialogVisible: false,
            progress: '0%'
        }
    },
    model: {
        prop: 'value',
        event: 'change',
    },
    props: {
        index: {
            type: Number,
            required: true,
        },
        value: {
            type: String,
            default: '',
        },
        isClear: {
            type: Boolean,
            default: false,
        },
    },
    watch: {
        isClear (val) {
            // 触发清除文本域内容
            if (val) {
                this.editor.txt.clear()
                this.info_ = null
            }
        },
        // editorData(currentVal, oldVal) {
        //   console.log(currentVal)
        //   console.log(oldVal)
        //   this.noteDetailChange()
        // },
        editorData () {
            this.noteDetailChange()
        },
        value: function (value) {
            if (value !== this.editor.txt.html()) {
                this.editor.txt.html(this.value)
            }
        },
    },
    created () {
        this.getOssUploadUrl()
    },
    mounted () {
        this.seteditor()
        this.editor.txt.html(this.value)
    },
    methods: {
        // 获取资源上传的临时url
        async getOssUploadUrl () {
            const { data: res } = await this.$axios.get('/api/common/getSTS')
            if (res.code !== 200) return false
            this.client = new OSS({
                accessKeyId: res.data.accessKeyId,
                accessKeySecret: res.data.accessKeySecret,
                region: res.data.region, // 地区
                stsToken: res.data.stsToken, // sts认证token
                // 功能公开的bucket  可以直接访问，文件地址永不过期
                bucket: 'ccas-default'
            })
        },
        seteditor () {
            // http://192.168.2.125:8080/admin/storage/create
            const editor = new E(this.$refs.toolbar, this.$refs.editor)
            editor.config.onchange = (newHtml) => {
                this.editorData = newHtml
            }
            editor.config.colors = [
                '#000000',
                '#eeece0',
                '#1c487f',
                '#4d80bf',
                '#c24f4a',
                '#8baa4a',
                '#7b5ba1',
                '#46acc8',
                '#f9963b',
                '#000000',
            ]
            editor.config.menus = [
                'head',
                'bold',
                'fontSize',
                'fontName',
                'italic',
                'underline',
                'strikeThrough',
                'indent',
                'lineHeight',
                'foreColor',
                'backColor',
                'link',
                'list',
                'todo',
                'justify',
                'quote',
                // 不打开
                // 'emoticon',
                'image',
                // 不打开
                // 'video',
                // 不打开
                // 'table',
                // 不打开
                // 'code',
                'splitLine',
                'undo',
                'redo',
            ]
            // 打开本地上传
            editor.config.uploadImgShowBase64 = true
            // 配置图片上传到阿里云
            editor.config.customUploadImg = (resultFiles, insertImgFn) => {
                // resultFiles 是 input 中选中的文件列表
                // insertImgFn 是获取图片 url 后，插入到编辑器的方法
                this.progress = '0%'
                this.dialogVisible = true
                const length = resultFiles.length
                let count = 0
                resultFiles.forEach(element => {
                    const date = new Date()
                    const yyyyMMdd = date.getFullYear() + '年' + (date.getMonth() + 1) + '月' + date.getDate() + '日' + '/'
                    const suffix = getFileType(element.name)
                    const uniqueFileName = 'log/img/' + yyyyMMdd + guid() + '.' + suffix
                    this.client.put(uniqueFileName, element).then((res) => {
                        // 上传图片，返回结果，将图片插入到编辑器中
                        insertImgFn(res.url)
                        count++
                        this.progress = count / length * 100 + '%'
                        if (count === length) {
                            this.dialogVisible = false
                        }
                    }).catch((err) => {
                        console.log(err)
                        this.dialogVisible = false
                    })
                })
            }
            // 支持上传的图片格式
            editor.config.uploadImgAccept = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp']
            // 一次最多选5张
            editor.config.uploadImgMaxLength = 5
            // 配置网络图片
            editor.config.showLinkImg = true
            editor.config.uploadVideoMaxSize = 1024 * 1024 * 50 // 50m
            editor.config.customUploadVideo = (resultFiles, insertVideoFn) => {
                this.progress = '0%'
                this.dialogVisible = true
                const date = new Date()
                const yyyyMMdd = date.getFullYear() + '年' + (date.getMonth() + 1) + '月' + date.getDate() + '日' + '/'
                const suffix = getFileType(resultFiles[0].name)
                const uniqueFileName = 'log/video/' + yyyyMMdd + guid() + '.' + suffix
                // resultFiles 是 input 中选中的文件列表
                // insertVideoFn 是获取视频 url 后，插入到编辑器的方法
                this.client.multipartUpload(uniqueFileName, resultFiles[0], {
                    // eslint-disable-next-line no-unused-vars
                    progress: (p, checkpoint) => { this.progress = p * 100 + '%' },
                    mime: resultFiles[0].type
                }).then(res => {
                    console.log(res)
                    const url = `http://ccas-default.oss-cn-hangzhou.aliyuncs.com/${res.name}`
                    // 上传视频，返回结果，将视频插入到编辑器中
                    insertVideoFn(url)
                    this.dialogVisible = false
                }).catch((err) => {
                    console.log(err)
                    this.dialogVisible = false
                })
            }
            editor.create()
            this.editor = editor
        },
        noteDetailChange () {
            // 调用父组件传递的方法
            this.$emit('change', this.editorData)
        },
        updateEditorData () {
            this.editor.txt.html(this.detail)
        },
        deleteStudent () {
            // console.log(this.index)
            this.$emit('deleteIndex', this.index)
        },
    },
}
</script>

<style lang="less" scoped>
.editor {
    width: 100%;
    margin: 0 auto;
    position: relative;
    z-index: 0;
}
.toolbar {
    border: 1px solid #ccc;
}
.text {
    border: 1px solid #ccc;
    height: 300px;
    // background: red;
}
.w-e-text-container {
    height: 300px !important;
}
</style>
