<template>
  <div class="index">
    <transition name="el-zoom-in-center">
      <div class="index_header">
        <el-tooltip
          class="item"
          effect="dark"
          content="所有已完结任务约定费用的5%"
          placement="bottom"
        >
          <div class="production shake-slow">
            <p>手续费</p>
            <span>{{ Count.procedure }}</span>
          </div>
        </el-tooltip>
        <el-tooltip
          class="item"
          effect="dark"
          content="所有用户充值的金额之和"
          placement="bottom"
        >
          <div class="log shake-slow">
            <p>充值总金额</p>
            <span>{{ Count.recharge }}</span>
          </div>
        </el-tooltip>
        <el-tooltip
          class="item"
          effect="dark"
          content="财务线下打款的金额之和"
          placement="bottom"
        >
          <div class="log shake-slow">
            <p>打款总金额</p>
            <span>{{ Count.givTotal }}</span>
          </div>
        </el-tooltip>
        <el-tooltip
          class="item"
          effect="dark"
          content="所有已完结任务约定费用总和"
          placement="bottom"
        >
          <div class="resource shake-slow">
            <p>成交总金额</p>
            <span>{{ Count.remittanceMon }}</span>
          </div>
        </el-tooltip>
        <el-tooltip
          class="item"
          effect="dark"
          content="充值总金额-已选定支付总和（已开工和已完结任务金额总和）"
          placement="bottom"
        >
          <div class="resource shake-slow">
            <p>账户充值总余额</p>
            <span>{{ Count.moneyTotal }}</span>
          </div>
        </el-tooltip>
        <el-tooltip
          class="item"
          effect="dark"
          content="所有已完结任务约定费用的95%"
          placement="bottom"
        >
          <div class="resource shake-slow">
            <p>账户可提现总金额</p>
            <span>{{ Count.meney }}</span>
          </div>
        </el-tooltip>
      </div>
    </transition>
    <div class="box" style="display: flex">
      <div
        v-loading="loadings"
        class="login"
        style="flex: 1; background: '#F5F7FA'"
      >
        <el-select
          class="electlogin"
          v-model="login"
          placeholder="请选择"
          @command="getLoginNum(value)"
        >
          <el-option
            v-for="item in loginList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
            @click.native="getLoginNum(item.value)"
          >
          </el-option>
        </el-select>
          <div
        id="login"
        style="
          width: 800px;
          height: 430px;
          float: left;
   
          background-color: white;
        "
      ></div>
      </div>
    
      <div
        v-loading="loadings"
        class="uploading"
        style="flex: 1; background: '#F5F7FA'"
      >
        <el-select
          class="electlogin1"
          v-model="uploading"
          placeholder="请选择"
          @command="getEcharts(value)"
        >
          <el-option
            v-for="item in uploadingList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
            @click.native="getEcharts(item.value)"
          >
          </el-option>
        </el-select>
        <div
          id="uploading"
          style="
            width: 800px;
            height: 430px;
            float: left;
            margin: 0 16px 0 40px;
            background-color: white;
          "
        ></div>
      </div>
    </div>
  </div>
</template>
<style lang="less" scoped>
.box {
  width: 746px;
  height: 430px;
  float: left;
  margin: 0 16px 0 57px;
}
.electlogin {
  left: 690px;
  width: 105px;
  position: relative;
  top: 0px;
  z-index: 100;
}
.electlogin1 {
  left: 730px;
  width: 105px;
  position: relative;
  top: 0px;
  z-index: 100;
}
.index_header {
  width: 100%;
  display: flex;
  div {
    // float: left;
    margin: 24px 12px;
    p {
      margin: 20px 0 16px 16px;
      font-family: PingFang-SC-Bold, PingFang-SC;
      color: #ffffff;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
  }

  .production,
  .log,
  .resource,
  .course,
  .user,
  .member {
    position: relative;
    width: 240px;
    height: 135px;
    background: linear-gradient(135deg, #fcc687 0%, #f286a0 100%);
    border-radius: 24px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    span {
      font-size: 32px;
      font-family: DINAlternate-Bold, DINAlternate;
      // font-weight: bold;
      color: #ffffff;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .log {
    background: linear-gradient(90deg, #d4fc79 0%, #96e6a1 100%);
  }
  .resource {
    background: linear-gradient(90deg, #c2e9fb 0%, #a1c4fd 100%);
  }
  .course {
    background: linear-gradient(90deg, #bb9bf1 0%, #887bf2 100%);
  }
  .user {
    background: linear-gradient(90deg, #fbc2eb 0%, #a18cd1 100%);
  }
  .member {
    background: linear-gradient(90deg, #7ffed8 0%, #09bdfe 100%);
  }
}

.courseEcharts {
  width: 746px;
  height: 430px;
  float: left;
  margin: 0 16px 0 57px;
}
.index_header {
  width: 100%;
  display: flex;
  div {
    // float: left;
    margin: 24px 12px;
    p {
      margin: 20px 0 16px 16px;
      font-family: PingFang-SC-Bold, PingFang-SC;
      color: #ffffff;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
  }

  .production,
  .log,
  .resource,
  .course,
  .user,
  .member {
    position: relative;
    width: 240px;
    height: 135px;
    background: linear-gradient(135deg, #fcc687 0%, #f286a0 100%);
    border-radius: 24px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    span {
      font-size: 32px;
      font-family: DINAlternate-Bold, DINAlternate;
      // font-weight: bold;
      color: #ffffff;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .log {
    background: linear-gradient(90deg, #d4fc79 0%, #96e6a1 100%);
  }
  .resource {
    background: linear-gradient(90deg, #c2e9fb 0%, #a1c4fd 100%);
  }
  .course {
    background: linear-gradient(90deg, #bb9bf1 0%, #887bf2 100%);
  }
  .user {
    background: linear-gradient(90deg, #fbc2eb 0%, #a18cd1 100%);
  }
  .member {
    background: linear-gradient(90deg, #7ffed8 0%, #09bdfe 100%);
  }
}
</style>
<script>
import "csshake";
import * as echarts from "echarts";
export default {
  data() {
    return {
      Count: {},
      login: "",
      uploadingList: [
        {
          value: "7",
          label: "近一周",
        },
        {
          value: "30",
          label: "近一个月",
        },
        {
          value: "365",
          label: "近一年",
        },
        {
          value: "",
          label: "成立以来",
        },
      ],
      uploading: "",
      loginList: [
        {
          value: "7",
          label: "近一周",
        },
        {
          value: "30",
          label: "近一个月",
        },
        {
          value: "365",
          label: "近一年",
        },
        {
          value: "",
          label: "成立以来",
        },
      ],
      loginListing: [
        {
          value: "7",
          label: "近一周",
        },
        {
          value: "30",
          label: "近一个月",
        },
        {
          value: "365",
          label: "近一年",
        },
        {
          value: "",
          label: "成立以来",
        },
      ],
      loadings: false,
      loginging: "",
      allAges: "2021-05-01",
      msg: false,
      userData: [],
      vipData: [],
      busData: [],
      creData: [],
      dateData: [],
      peopleDate: [],
      date: [],
      productionData: [],
      resourceData: [],
    };
  },
  methods: {
    Costing() {
      this.$axios.get(`/api/creators/itemCost`).then((res) => {
        console.log(res.data);
        if (res.data.code == 200) {
          this.Count = res.data.data;
        }
      });
    },
    addDate(time) {
      var myDate = new Date(); // 获取今天日期

      // 获取到time天之前的日期
      myDate.setDate(myDate.getDate() - time);
      var dateArray = [];
      var people = [];
      var dateTemp;
      var flag = 1;

      // 循环判断 如果月份和日期小于10 就在前面加0
      for (var i = 0; i < time; i++) {
        var userobj = {};
        var dateobj = {};
        var month = myDate.getMonth() + 1;
        var daydate = myDate.getDate();
        var year = myDate.getFullYear();
        if (daydate < 10) {
          daydate = "0" + daydate;
        } else {
          daydate = String(daydate);
        }
        if (month < 10) {
          month = "0" + month;
        } else {
          month = String(month);
        }
        dateTemp = year + "-" + month + "-" + daydate;

        dateobj.date = dateTemp;
        dateobj.productionCount = 0;

        userobj.date = dateTemp;
        userobj.userCount = 0;
        people.push(userobj);
        dateArray.push(dateobj);
        myDate.setDate(myDate.getDate() + flag);
      }
      this.date = dateArray;
      this.peopleDate = people;
      // console.log(this.peopleDate)
    },
    // 获取指定日期至今的天数
    dateDiff: function (sDate1) {
      var date2 = new Date();
      var date1 = new Date(Date.parse(sDate1.replace(/-/g, "/")));
      var iDays = parseInt(
        Math.abs(date2.getTime() - date1.getTime()) / 1000 / 60 / 60 / 24
      );
      return iDays;
    },
    async getEcharts(value) {
      if (value > this.dateDiff(this.allAges)) {
        value = this.dateDiff(this.allAges);
      } else if (!value) {
        value = this.dateDiff(this.allAges);
      }
      this.addDate(value);
      // console.log(this.qi)
      this.loading = true;
      this.addDate(value);
      this.productionData = [];
      this.resourceData = [];
      this.echartsData = [];
      const that = this;
      this.$axios
        .get(`/api/creators/getTotal?time=${value == undefined ? "" : value}`)
        .then(function (res) {
          const list = res.data.data;
          // 循环比较自己生成的日期和数据里的日期，如果一样就把数据里的count给加进自己的数据里
          for (let i = 0; i < list.procore.length; i++) {
            let Index = that.date.findIndex((item) => {
              return item.date === list.procore[i].date;
            });
            if (that.date[Index].date === list.procore[i].date) {
              that.date[Index].productionCount = list.procore[i].count;
            }
          }
          // 循环作品productionCount，如果为0就等于为0前一天的productionCount
          for (let i = 0; i < that.date.length; i++) {
            if (i > 1) {
              if (that.date[i].productionCount === 0) {
                that.date[i].productionCount = that.date[i - 1].productionCount;
              }
            }
          }

          // console.log(that.date)
          // 创建数组，循环date对象，取出对象里的date，push进创建的数组里，然后去掉前五个字符串
          let date = [];
          that.date.forEach((element) => {
            date.push(element.date);
          });
          for (let i = 0; i < date.length; i++) {
            let work = date[i].substring(5);
            that.echartsData.push(work);
          }
          // 处理作品数据
          let productionCount = [];
          that.date.forEach((element) => {
            productionCount.push(element.productionCount);
          });
          that.productionData = productionCount;

          that.uploadingNum(that.echartsData, that.productionData);
          that.loading = false;
        });
    },
    async getLoginNum(value) {
      // console.log(this.dateDiff(this.allAges))
      if (value > this.dateDiff(this.allAges)) {
        value = this.dateDiff(this.allAges);
      } else if (!value) {
        value = this.dateDiff(this.allAges);
      }
      // console.log(value)
      this.addDate(value);
      this.loadings = true;
      this.busData = [];
      this.dateData = [];
      const that = this;
      this.$axios
        .get(`/api/creators/getProcore?time=${value == undefined ? "" : value}`)
        .then((res) => {
          const list = res.data.data;

          // bustask
          for (let i = 0; i < list.procore.length; i++) {
            let Index = that.peopleDate.findIndex((item) => {
              return item.date === list.procore[i].date;
            });
            // console.log(Index)
            if (that.peopleDate[Index].date === list.procore[i].date) {
              that.peopleDate[Index].busCount = list.procore[i].count;
               //console.log(that.peopleDate)
            }
          }
          // 循环注册userCount，如果为0就等于为0前一天的userCount
          for (let i = 0; i < that.peopleDate.length; i++) {
            if (i > 1) {
              if (that.peopleDate[i].busCount === 0) {
                that.peopleDate[i].busCount = that.peopleDate[i - 1].busCount;
              }
            }
            if(that.peopleDate[i].busCount===undefined){
that.peopleDate[i].busCount=0
            }
          }

          // console.log(that.peopleDate)
          let date = [];
          that.peopleDate.forEach((element) => {
            date.push(element.date);
          });
          for (let i = 0; i < date.length; i++) {
            let work = date[i].substring(5);
            that.dateData.push(work);
          }

          let bus = [];
          that.peopleDate.forEach((element) => {
            bus.push(element.busCount);
          });
          that.busData = bus;
          that.loginNum(that.dateData, that.busData);
          that.loadings = false;
        });
    },
    loginNum(date, busData) {
      console.log(busData)
      var chartDom = document.getElementById("login");
      var myChart = echarts.init(chartDom);
      var option = {
        legend: {
          data: ["手续费"],
        },
        xAxis: {
          type: "category",
          data: date,
          name: "日期",
          nameTextStyle: {
            fontWeight: 600,
            fontSize: 18,
          },
        },
        yAxis: {
          type: "value",
          name: "费用", // y轴名称
          // y轴名称样式
          nameTextStyle: {
            fontWeight: 600,
            fontSize: 18,
          },
        },
        label: {},
        tooltip: {
          trigger: "axis",
        },
        series: [
          {
            name: "手续费",
            data: busData,
            type: "line",
          },
        ],
      };
      option && myChart.setOption(option);
    },
    uploadingNum(date, production) {
      var chartDom = echarts.init(document.getElementById("uploading"));
      chartDom.setOption({
        legend: {
          data: ["成交金额"],
        },
        xAxis: {
          type: "category",
          data: date,
          name: "日期",
          nameTextStyle: {
            fontWeight: 600,
            fontSize: 18,
          },
        },
        yAxis: {
          type: "value",
          name: "费用", // y轴名称
          // y轴名称样式
          nameTextStyle: {
            fontWeight: 600,
            fontSize: 18,
          },
        },
        label: {},
        tooltip: {
          trigger: "axis",
        },
        series: [
          {
            name: "成交金额",
            data: production,
            type: "line",
          },
        ],
      });
      // option.xAxis.data = data
      // option && myChart.setOption(option)
    },
  },
  created() {
    this.Costing();
    this.login = this.loginList[0].label;
    this.addDate(7);
    this.uploading = this.uploadingList[0].label;
  },
  mounted() {
    this.getLoginNum(7);
    this.getEcharts(7);
  },
};
</script>