<template>
  <div class="index">
    <div class="box" style="display: flex">
      <div
        v-loading="loadings"
        class="login"
        style="flex: 1; background: '#F5F7FA'"
      >
        <el-select
          class="name"
          v-model="login"
          placeholder="请选择"
          @command="getLoginNum(value)"
        >
          <el-option
            v-for="item in loginList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
            @click.native="getLoginNum(item.value)"
          >
          </el-option>
        </el-select>
        <div
          id="login"
          style="
             width: 746px;
            height: 430px;
            float: left;
            margin: 0 16px 0 40px;
            background-color: white;
          "
        ></div>

        <div
          v-loading="loadings"
          class="uploading"
          style="flex: 1; background: '#F5F7FA'"
        >
          <el-select
            class="name"
            v-model="uploading"
            placeholder="请选择"
            @command="getEcharts(value)"
          >
            <el-option
              v-for="item in uploadingList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              @click.native="getEcharts(item.value)"
            >
            </el-option>
          </el-select>
          <div
            id="uploading"
            style="
              width: 746px;
            height: 430px;
            float: left;
            margin: 0 16px 0 40px;
            background-color: white;
            "
          ></div>
        </div>
      </div>
      <div
        v-loading="loadings"
        class="loginging"
        style="flex: 1; background: '#F5F7FA'"
      >
        <el-select
          class="name"
          v-model="loginging"
          placeholder="请选择"
          @command="getLogin(value)"
        >
          <el-option
            v-for="item in loginListing"
            :key="item.value"
            :label="item.label"
            :value="item.value"
            @click.native="getLogin(item.value)"
          >
          </el-option>
        </el-select>
        <div
          id="loginging"
          style="
            width: 746px;
            height: 430px;
            float: left;
            margin: 0 16px 0 40px;
            background-color: white;
          "
        ></div>
      </div>
    </div>
  </div>
</template>
<style lang="less" scoped>
.box {
  width: 746px;
  height: 430px;
  float: left;
  margin: 0 16px 0 57px;
}
.index_header {
  width: 100%;
  display: flex;
  div {
    // float: left;
    margin: 24px 12px;
    p {
      margin: 20px 0 16px 16px;
      font-family: PingFang-SC-Bold, PingFang-SC;
      color: #ffffff;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
  }

  .production,
  .log,
  .resource,
  .course,
  .user,
  .member {
    position: relative;
    width: 240px;
    height: 135px;
    background: linear-gradient(135deg, #fcc687 0%, #f286a0 100%);
    border-radius: 24px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    span {
      font-size: 32px;
      font-family: DINAlternate-Bold, DINAlternate;
      // font-weight: bold;
      color: #ffffff;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .log {
    background: linear-gradient(90deg, #d4fc79 0%, #96e6a1 100%);
  }
  .resource {
    background: linear-gradient(90deg, #c2e9fb 0%, #a1c4fd 100%);
  }
  .course {
    background: linear-gradient(90deg, #bb9bf1 0%, #887bf2 100%);
  }
  .user {
    background: linear-gradient(90deg, #fbc2eb 0%, #a18cd1 100%);
  }
  .member {
    background: linear-gradient(90deg, #7ffed8 0%, #09bdfe 100%);
  }
}
.name {
  width: 105px;
  position: relative;
  top: 0px;
  z-index: 100;
  left: 75%;
}
</style>
<script>
import * as echarts from "echarts";
import "csshake";
export default {
  data() {
    return {
      uploadingList: [
        {
          value: "7",
          label: "近一周",
        },
        {
          value: "30",
          label: "近一个月",
        },
        {
          value: "365",
          label: "近一年",
        },
        {
          value: "",
          label: "成立以来",
        },
      ],
      uploading: "",
      mainCount: {},
      loginList: [
        {
          value: "7",
          label: "近一周",
        },
        {
          value: "30",
          label: "近一个月",
        },
        {
          value: "365",
          label: "近一年",
        },
        {
          value: "",
          label: "成立以来",
        },
      ],
      loginListing: [
        {
          value: "7",
          label: "近一周",
        },
        {
          value: "30",
          label: "近一个月",
        },
        {
          value: "365",
          label: "近一年",
        },
        {
          value: "",
          label: "成立以来",
        },
      ],
      loadings: false,
      login: "",
      loginging: "",
      allAges: "2021-05-01",
      msg: false,
      userData: [],
      vipData: [],
      busData: [],
      creData: [],
      dateData: [],
      dateing: [],
      peopleDate: [],
      date: [],
      productionData: [],
      resourceData: [],
    };
  },
  methods: {
    addDate(time) {
      var myDate = new Date(); // 获取今天日期

      // 获取到time天之前的日期
      myDate.setDate(myDate.getDate() - time+1);
      var dateArray = [];
      var people = [];
      var dateTemp;
      var flag = 1;

      // 循环判断 如果月份和日期小于10 就在前面加0
      for (var i = 0; i < time; i++) {
        var userobj = {};
        var dateobj = {};
        var month = myDate.getMonth() +1;
        var daydate = myDate.getDate();
        var year = myDate.getFullYear();
        if (daydate < 10) {
          daydate = "0" + daydate;
        } else {
          daydate = String(daydate);
        }
        if (month < 10) {
          month = "0" + month;
        } else {
          month = String(month);
        }
        dateTemp = year + "-" + month + "-" + daydate;

        dateobj.date = dateTemp;
        dateobj.productionCount = 0;
        dateobj.resourceCount = 0;

        userobj.date = dateTemp;
        userobj.userCount = 0;
        userobj.vipCount = 0;
        userobj.busCount = 0;
        userobj.creCount = 0;
        people.push(userobj);
        dateArray.push(dateobj);
        myDate.setDate(myDate.getDate() + flag);
      }
      this.date = dateArray;
      this.peopleDate = people;
      // console.log(this.peopleDate)
    },
    // 获取指定日期至今的天数
    dateDiff: function (sDate1) {
      var date2 = new Date();
      var date1 = new Date(Date.parse(sDate1.replace(/-/g, "/")));
      var iDays = parseInt(
        Math.abs(date2.getTime() - date1.getTime()) / 1000 / 60 / 60 / 24
      );
      return iDays;
    },
    getLogin(value) {
      // console.log(this.dateDiff(this.allAges))
      if (value > this.dateDiff(this.allAges)) {
        value = this.dateDiff(this.allAges);
      } else if (!value) {
        value = this.dateDiff(this.allAges);
      }
      // console.log(value)
      this.addDate(value);
      this.loadings = true;
      this.userData = [];
      this.vipData = [];
      this.dateing = [];
      const that = this;
      this.$axios
        .get(`/api/creators/getItemNum?time=${value == undefined ? "" : value}`)
        .then((res) => {
          const list = res.data.data;
          // console.log(that.date)
          // 循环比较自己生成的日期和数据里的日期，如果一样就把数据里的count给加进自己的数据里
          for (let i = 0; i < list.business.length; i++) {
            let Index = that.peopleDate.findIndex((item) => {
              return item.date === list.business[i].date;
            });

            if (that.peopleDate[Index].date === list.business[i].date) {
              that.peopleDate[Index].userCount = list.business[i].count;
              // console.log(that.peopleDate)
            }
          }
          // 循环注册userCount，如果为0就等于为0前一天的userCount
          for (let i = 0; i < that.peopleDate.length; i++) {
            if (i > 1) {
              if (that.peopleDate[i].userCount === 0) {
                that.peopleDate[i].userCount = that.peopleDate[i - 1].userCount;
              }
            }
          }
          // vip
          for (let i = 0; i < list.creative.length; i++) {
            let Index = that.peopleDate.findIndex((item) => {
              return item.date === list.creative[i].date;
            });
            // console.log(Index)
            if (that.peopleDate[Index].date === list.creative[i].date) {
              that.peopleDate[Index].vipCount = list.creative[i].count;
              // console.log(that.peopleDate)
            }
          }
          // 循环注册userCount，如果为0就等于为0前一天的userCount
          for (let i = 0; i < that.peopleDate.length; i++) {
            if (i > 1) {
              if (that.peopleDate[i].vipCount === 0) {
                that.peopleDate[i].vipCount = that.peopleDate[i - 1].vipCount;
              }
            }
          }

          // console.log(that.peopleDate)
          let date = [];
          that.peopleDate.forEach((element) => {
            date.push(element.date);
          });
          for (let i = 0; i < date.length; i++) {
            let work = date[i].substring(5);
            that.dateing.push(work);
          }

          let user = [];
          that.peopleDate.forEach((element) => {
            user.push(element.userCount);
          });
          that.userData = user;
          // console.log(that.userData)

          let vip = [];
          that.peopleDate.forEach((element) => {
            vip.push(element.vipCount);
          });
          that.vipData = vip;

          that.loginN(that.dateing, that.userData, that.vipData);
          that.loadings = false;
        });
    },
    loginN(date, userData, vipData) {
      var chartDom = document.getElementById("loginging");
      var myChart = echarts.init(chartDom);
      var option = {
        legend: {
          data: ["商业任务", "创意任务"],
        },
        xAxis: {
          type: "category",
          data: date,
          name: "日期",
          nameTextStyle: {
            fontWeight: 600,
            fontSize: 18,
          },
        },
        yAxis: {
          type: "value",
          name: "数量", // y轴名称
          // y轴名称样式
          nameTextStyle: {
            fontWeight: 600,
            fontSize: 18,
          },
        },
        label: {},
        tooltip: {
          trigger: "axis",
        },
        series: [
          {
            name: "商业任务",
            data: userData,
            type: "line",
          },
          {
            name: "创意任务",
            data: vipData,
            type: "line",
          },
        ],
      };
      option && myChart.setOption(option);
    },
    // 会员、注册统计图
    loginNum(date, busData, creData) {
      var chartDom = document.getElementById("login");
      var myChart = echarts.init(chartDom);
      var option = {
        legend: {
          data: ["商业项目", "创意项目"],
        },
        xAxis: {
          type: "category",
          data: date,
          name: "日期",
          nameTextStyle: {
            fontWeight: 600,
            fontSize: 18,
          },
        },
        yAxis: {
          type: "value",
          name: "数量", // y轴名称
          // y轴名称样式
          nameTextStyle: {
            fontWeight: 600,
            fontSize: 18,
          },
        },
        label: {},
        tooltip: {
          trigger: "axis",
        },
        series: [
          {
            name: "商业项目",
            data: busData,
            type: "line",
          },
          {
            name: "创意项目",
            data: creData,
            type: "line",
          },
        ],
      };
      option && myChart.setOption(option);
    },
    getLoginNum(value) {
      // console.log(this.dateDiff(this.allAges))
      if (value > this.dateDiff(this.allAges)) {
        value = this.dateDiff(this.allAges);
      } else if (!value) {
        value = this.dateDiff(this.allAges);
      }
      // console.log(value)
      this.addDate(value);
      this.loadings = true;
      this.busData = [];
      this.creData = [];
      this.dateData = [];
      const that = this;
      this.$axios
        .get(`/api/creators/getItemNum?time=${value == undefined ? "" : value}`)
        .then((res) => {
          const list = res.data.data;

          // bustask
          for (let i = 0; i < list.busTask.length; i++) {
            let Index = that.peopleDate.findIndex((item) => {
              return item.date === list.busTask[i].date;
            });
            // console.log(Index)
            if (that.peopleDate[Index].date === list.busTask[i].date) {
              that.peopleDate[Index].busCount = list.busTask[i].count;
              // console.log(that.peopleDate)
            }
          }
          // 循环注册userCount，如果为0就等于为0前一天的userCount
          for (let i = 0; i < that.peopleDate.length; i++) {
            if (i > 1) {
              if (that.peopleDate[i].busCount === 0) {
                that.peopleDate[i].busCount = that.peopleDate[i - 1].busCount;
              }
            }
          }

          // cretask
          for (let i = 0; i < list.creTask.length; i++) {
            let Index = that.peopleDate.findIndex((item) => {
              return item.date === list.creTask[i].date;
            });
            // console.log(Index)
            if (that.peopleDate[Index].date === list.creTask[i].date) {
              that.peopleDate[Index].creCount = list.creTask[i].count;
              // console.log(that.peopleDate)
            }
          }
          // 循环注册userCount，如果为0就等于为0前一天的userCount
          for (let i = 0; i < that.peopleDate.length; i++) {
            if (i > 1) {
              if (that.peopleDate[i].creCount === 0) {
                that.peopleDate[i].creCount = that.peopleDate[i - 1].creCount;
              }
            }
          }

          // console.log(that.peopleDate)
          let date = [];
          that.peopleDate.forEach((element) => {
            date.push(element.date);
          });
          for (let i = 0; i < date.length; i++) {
            let work = date[i].substring(5);
            that.dateData.push(work);
          }

          let bus = [];
          that.peopleDate.forEach((element) => {
            bus.push(element.busCount);
          });
          that.busData = bus;

          let cre = [];
          that.peopleDate.forEach((element) => {
            cre.push(element.creCount);
          });
          that.creData = cre;
          that.loginNum(that.dateData, that.busData, that.creData);
          that.loadings = false;
        });
    },
    // 会员、注册统计图

    async getEcharts(value) {
      if (value > this.dateDiff(this.allAges)) {
        value = this.dateDiff(this.allAges);
      } else if (!value) {
        value = this.dateDiff(this.allAges);
      }
      this.addDate(value);
      // console.log(this.qi)
      this.loading = true;
      this.addDate(value);
      this.productionData = [];
      this.resourceData = [];
      this.echartsData = [];
      const that = this;
      this.$axios
        .get(`/api/creators/getFinish?time=${value == undefined ? "" : value}`)
        .then(function (res) {
          const list = res.data.data;
          // 循环比较自己生成的日期和数据里的日期，如果一样就把数据里的count给加进自己的数据里
          for (let i = 0; i < list.production.length; i++) {
            let Index = that.date.findIndex((item) => {
              return item.date === list.production[i].date;
            });
            if (that.date[Index].date === list.production[i].date) {
              that.date[Index].productionCount = list.production[i].count;
            }
          }
          // 循环作品productionCount，如果为0就等于为0前一天的productionCount
          for (let i = 0; i < that.date.length; i++) {
            if (i > 1) {
              if (that.date[i].productionCount === 0) {
                that.date[i].productionCount = that.date[i - 1].productionCount;
              }
            }
          }
          // 资源数据处理
          for (let i = 0; i < list.resource.length; i++) {
            let Index = that.date.findIndex((item) => {
              return item.date === list.resource[i].date;
            });
            if (that.date[Index].date === list.resource[i].date) {
              that.date[Index].resourceCount = list.resource[i].count;
            }
          }
          for (let i = 0; i < that.date.length; i++) {
            if (i > 1) {
              if (that.date[i].resourceCount === 0) {
                that.date[i].resourceCount = that.date[i - 1].resourceCount;
              }
            }
          }

          // console.log(that.date)
          // 创建数组，循环date对象，取出对象里的date，push进创建的数组里，然后去掉前五个字符串
          let date = [];
          that.date.forEach((element) => {
            date.push(element.date);
          });
          for (let i = 0; i < date.length; i++) {
            let work = date[i].substring(5);
            that.echartsData.push(work);
          }
          // 处理作品数据
          let productionCount = [];
          that.date.forEach((element) => {
            productionCount.push(element.productionCount);
          });
          that.productionData = productionCount;
          // 处理资源数据
          let resourceCount = [];
          that.date.forEach((element) => {
            resourceCount.push(element.resourceCount);
          });
          that.resourceData = resourceCount;

          that.uploadingNum(
            that.echartsData,
            that.productionData,
            that.resourceData
          );
          that.loading = false;
        });
    },
    uploadingNum(data, production, resource) {
      var chartDom = echarts.init(document.getElementById("uploading"));
      chartDom.setOption({
        legend: {
          data: ["已完结商业任务", "已完结创业任务"],
        },
        xAxis: {
          type: "category",
          data: data,
          name: "日期",
          nameTextStyle: {
            fontWeight: 600,
            fontSize: 18,
          },
        },
        yAxis: {
          type: "value",
          name: "数量", // y轴名称
          // y轴名称样式
          nameTextStyle: {
            fontWeight: 600,
            fontSize: 18,
          },
        },
        label: {},
        tooltip: {
          trigger: "axis",
        },
        series: [
          {
            name: "已完结商业任务",
            data: production,
            type: "line",
          },
          {
            name: "已完结创业任务",
            data: resource,
            type: "line",
          },
        ],
      });
      // option.xAxis.data = data
      // option && myChart.setOption(option)
    },
    /***
     * 获取当前日期或指定日期的前后指定日期
     * num可以为正数或负数，分别对应日期之前和日期之后的天数
     * time是指定的日期，不传就是当前日期
     */
    getBeforeDate(num, time) {
      let n = num;
      let d = "";
      if (time) {
        d = new Date(time);
      } else {
        d = new Date();
      }
      let year = d.getFullYear();
      let mon = d.getMonth() + 1;
      let day = d.getDate();
      if (day <= n) {
        if (mon > 1) {
          mon = mon - 1;
        } else {
          year = year - 1;
          mon = 12;
        }
      }
      d.setDate(d.getDate() - n);
      year = d.getFullYear();
      mon = d.getMonth() + 1;
      day = d.getDate();
      let s =
        year +
        "-" +
        (mon < 10 ? "0" + mon : mon) +
        "-" +
        (day < 10 ? "0" + day : day);
      return s;
    },
  },
  created() {
    this.login = this.loginList[0].label;
    this.loginging = this.loginListing[0].label;
    this.addDate(7);
    this.loginList[3].value = this.dateDiff(this.allAges);
    this.uploading = this.uploadingList[0].label;
  },
  mounted() {
    this.getLogin(7);
    this.getLoginNum(7);
    this.getEcharts(7);
  },
};
</script>