<template>
    <div class="user">
        <!-- 搜索框 -->
        <div class="headerBox">
            <el-input
                placeholder="请输入用户名"
                prefix-icon="el-icon-search"
                v-model="input"
                @change="getProductionList"
            >
            </el-input>
            <el-button type="primary" @click="openAllGold"
                >金币操作记录</el-button
            >
            <el-button type="primary" @click="uploadExcelShow = true">
                生成用户
            </el-button>
            <el-button type="primary" @click="importUserDialogVisible = true">
                导入用户
            </el-button>
        </div>

        <!-- 表格 -->
        <el-table
            v-loading="loading"
            stripe
            :data="tableData"
            :header-cell-style="thStyleFun"
            :cell-style="cellStyleFun"
            @filter-change="filterChange"
            @sort-change="sortChange"
        >
            <el-table-column prop="id" label="用户名" width="200">
                <template slot-scope="scope">
                    <div class="userName">
                        <img
                            :src="
                                scope.row.headPicture === null
                                    ? require('@/assets/images/xiaoxin.svg')
                                    : scope.row.headPicture
                            "
                            alt=""
                        />
                        <span> {{ scope.row.username }} </span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                prop="role"
                label="权限"
                :filters="[
                    { text: '普通用户', value: 'user' },
                    { text: 'VIP用户', value: 'vip' },
                ]"
                column-key="role"
                filter-placement="bottom-end"
                :filter-multiple="false"
                width="120"
            >
                <template slot="header">
                    权限
                    <i
                        style="font-size: 12px"
                        class="icon iconfont icon-jiantou1"
                    ></i>
                </template>
                <template slot-scope="scope">
                    <span v-for="(item, index) in scope.row.role" :key="index">
                        {{ item == "user" ? "普通" : "" }}
                        {{ item == "vip" ? "vip" : "" }}
                    </span>
                </template>
            </el-table-column>
            <el-table-column
                prop="isEducate"
                label="视频号"
                width="120"
                :filters="[{ text: '视频号', value: 'educate' }]"
                column-key="role"
                :filter-multiple="false"
                filter-placement="bottom-end"
            >
                <template slot="header">
                    视频号
                    <i
                        style="font-size: 12px"
                        class="icon iconfont icon-jiantou1"
                    ></i>
                </template>
                <template slot-scope="scope">
                    <!-- <span>{{ scope.row.isFreeze == 0 ? '黑名单' : '正常' }}</span> -->
                    <el-switch
                        v-model="scope.row.isEducate"
                        :active-value="1"
                        :inactive-value="0"
                        @change="worksShow(scope.row, 'sp')"
                    ></el-switch>
                </template>
            </el-table-column>
            <el-table-column
                prop="identity"
                label="账号"
                :filters="[
                    { text: '学生', value: 'student' },
                    { text: '老师', value: 'teacher' },
                    { text: '职业用户', value: 'profession' },
                    { text: '企业用户', value: 'enterprise' },
                    { text: '创作者', value: 'creator' },
                    { text: '未认证', value: '' },
                ]"
                column-key="identity"
                filter-placement="bottom-end"
                :filter-multiple="false"
                width="120"
            >
                <template slot="header">
                    角色
                    <i
                        style="font-size: 12px"
                        class="icon iconfont icon-jiantou1"
                    ></i>
                </template>
                <template slot-scope="scope">
                    <span
                        v-for="(item, index) in scope.row.identity"
                        :key="index"
                    >
                        {{ item == "student" ? "学生" : "" }}
                        {{ item == "teacher" ? "老师" : "" }}
                        {{ item == "enterprise" ? "企业用户" : "" }}
                        {{ item == "profession" ? "职业用户" : "" }}
                        {{ item == "creator" ? "创作者" : "" }}</span
                    >
                    <span v-show="scope.row.identity.length < 1">未认证</span>
                </template>
            </el-table-column>
            <el-table-column
                prop="identity"
                label="身份审核"
                :filters="[
                    { text: '已审核', value: 1 },
                    { text: '未审核', value: 0 },
                ]"
                column-key="roleStatus"
                filter-placement="bottom-end"
                :filter-multiple="false"
                width="120"
            >
                <template slot="header">
                    身份审核
                    <i
                        style="font-size: 12px"
                        class="icon iconfont icon-jiantou1"
                    ></i>
                </template>
                <template slot-scope="scope">
                    <!-- <span v-for="(item, index) in scope.row.identity" :key="index">
              {{ item == 'student' ? '学生' : '' }}
              {{ item == 'teacher' ? '老师' : '' }}
              {{ item == 'enterprise' ? '企业用户' : '' }}
              {{ item == 'profession' ? '职业用户' : '' }}</span
            > -->
                    <span v-show="scope.row.roleStatus == 1">已审核</span>
                    <span v-show="scope.row.roleStatus == 0">未审核</span>
                </template>
            </el-table-column>
            <el-table-column
                prop="level"
                label="等级"
                sortable="custom"
                width="120"
            >
                <template slot="header">
                    <span>等级 </span>
                    <i
                        v-show="levelFlag == 1"
                        style="font-size: 14px; color: #1989fa"
                        class="icon iconfont icon-zuobian"
                    ></i>

                    <i
                        v-show="levelFlag == 0 || levelFlag == 2"
                        style="font-size: 14px"
                        class="icon iconfont icon-zuobian"
                    ></i>
                    <i
                        v-show="levelFlag == 2"
                        style="font-size: 14px; color: #1989fa"
                        class="icon iconfont icon-youbian"
                    ></i>
                    <i
                        v-show="levelFlag == 0 || levelFlag == 1"
                        style="font-size: 14px"
                        class="icon iconfont icon-youbian"
                    ></i>
                </template>
            </el-table-column>
            <el-table-column
                prop="createTime"
                label="注册时间"
                sortable="custom"
                width="180"
            >
                <template slot="header">
                    <span>注册时间 </span>
                    <i
                        v-show="createFlag == 1"
                        style="font-size: 14px; color: #1989fa"
                        class="icon iconfont icon-zuobian"
                    ></i>

                    <i
                        v-show="createFlag == 0 || createFlag == 2"
                        style="font-size: 14px"
                        class="icon iconfont icon-zuobian"
                    ></i>
                    <i
                        v-show="createFlag == 2"
                        style="font-size: 14px; color: #1989fa"
                        class="icon iconfont icon-youbian"
                    ></i>
                    <i
                        v-show="createFlag == 0 || createFlag == 1"
                        style="font-size: 14px"
                        class="icon iconfont icon-youbian"
                    ></i>
                </template>
            </el-table-column>
            <el-table-column
                prop="gold"
                label="钻石数"
                sortable="custom"
                width="150"
            >
                <template slot="header">
                    <span>钻石数 </span>
                    <i
                        v-show="goldFlag == 1"
                        style="font-size: 14px; color: #1989fa"
                        class="icon iconfont icon-zuobian"
                    ></i>

                    <i
                        v-show="goldFlag == 0 || goldFlag == 2"
                        style="font-size: 14px"
                        class="icon iconfont icon-zuobian"
                    ></i>
                    <i
                        v-show="goldFlag == 2"
                        style="font-size: 14px; color: #1989fa"
                        class="icon iconfont icon-youbian"
                    ></i>
                    <i
                        v-show="goldFlag == 0 || goldFlag == 1"
                        style="font-size: 14px"
                        class="icon iconfont icon-youbian"
                    ></i>
                </template>
            </el-table-column>
            <el-table-column
                prop="integral"
                label="金币数"
                sortable="custom"
                width="150"
            >
                <template slot="header">
                    <span>金币数 </span>
                    <i
                        v-show="integralFlag == 1"
                        style="font-size: 14px; color: #1989fa"
                        class="icon iconfont icon-zuobian"
                    ></i>

                    <i
                        v-show="integralFlag == 0 || integralFlag == 2"
                        style="font-size: 14px"
                        class="icon iconfont icon-zuobian"
                    ></i>
                    <i
                        v-show="integralFlag == 2"
                        style="font-size: 14px; color: #1989fa"
                        class="icon iconfont icon-youbian"
                    ></i>
                    <i
                        v-show="integralFlag == 0 || integralFlag == 1"
                        style="font-size: 14px"
                        class="icon iconfont icon-youbian"
                    ></i>
                </template>
            </el-table-column>
            <!-- <el-table-column
          prop="isCourseUser"
          label="视频号"
          :filters="[
            { text: '视频号', value: 1 },
            { text: '非视频号', value: 0 },
          ]"
          column-key="isCourseUser"
          filter-placement="bottom-end"
          :filter-multiple="false"
        >
          <template slot="header">
            视频号
            <i
              style="font-size:12px
            "
              class="icon iconfont icon-jiantou1"
            ></i>
            <span>{{ scope.row.isFreeze == 0 ? '黑名单' : '正常' }}</span>
          </template>
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.isCourseUser"
              :active-value="1"
              :inactive-value="0"
              @change="worksShow(scope.row)"
            ></el-switch>
          </template>
        </el-table-column> -->
            <el-table-column
                prop="isFreeze"
                label="状态"
                :filters="[
                    { text: '黑名单', value: 0 },
                    { text: '正常', value: 1 },
                ]"
                column-key="isFreeze"
                filter-placement="bottom-end"
                :filter-multiple="false"
                width="150"
            >
                <template slot="header">
                    状态
                    <i
                        style="font-size: 12px"
                        class="icon iconfont icon-jiantou1"
                    ></i>
                    <!-- <span>{{ scope.row.isFreeze == 0 ? '黑名单' : '正常' }}</span> -->
                </template>
                <template slot-scope="scope">
                    <!-- <span>{{ scope.row.isFreeze == 0 ? '黑名单' : '正常' }}</span> -->
                    <el-switch
                        v-model="scope.row.isFreeze"
                        :active-value="1"
                        :inactive-value="0"
                        @change="worksShow(scope.row, 'fh')"
                    ></el-switch>
                </template>
            </el-table-column>
            <el-table-column prop="operation" label="操作">
                <template slot-scope="scope">
                    <!-- <el-link
              :underline="false"
              type="primary"
              @click=";(dialogVisible = true), goUser(scope.row)"
              style="margin:10px"
              >详情</el-link
            >
            <el-link
              :underline="false"
              type="primary"
              v-if="scope.row.isFreeze === 1"
              @click=";(dialogFormVisible = true), goUser(scope.row)"
              style="margin:10px"
              >封号</el-link
            >
            <el-link
              :underline="false"
              type="primary"
              v-else
              @click=";(jiefeng = true), goUser(scope.row)"
              style="margin:10px"
              >取消封号</el-link
            >
            <el-link
              :underline="false"
              type="primary"
              @click=";(kecheng = true), goUser(scope.row)"
              style="margin:10px"
              >{{
                scope.row.isCourseUser == 0 ? '升为课程号' : '取消课程号'
              }}</el-link
            > -->
                    <!-- <el-link :underline="false" type="primary" @click="kecheng=true,goUser(scope.row.userId)" style="margin:10px">升为课程号</el-link> -->
                    <!-- <el-button type="danger" v-if="useredMessage.isFreeze === 1" @click="dialogFormVisible = true">封 号</el-button>
              <el-button type="warning" v-else @click="jiefeng = true">解 封</el-button> -->
                    <!-- <el-dropdown>
            <span class="el-dropdown-link">
              详情
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>黄金糕</el-dropdown-item>
              <el-dropdown-item>狮子头</el-dropdown-item>
              <el-dropdown-item>螺蛳粉</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown> -->

                    <el-link
                        :underline="false"
                        type="primary"
                        @click="(dialogVisible = true), goUser(scope.row)"
                        >详情</el-link
                    >
                    <span
                        style="
                            font-size: 12px;
                            margin: 0 15.6px;
                            color: #000000;
                            opacity: 0.3;
                        "
                        >|</span
                    >
                    <el-dropdown
                        trigger="click"
                        @click.native="getItem(scope.row)"
                        @command="handleCommand"
                    >
                        <span class="el-dropdown-link">
                            <el-link :underline="false" type="primary"
                                >账号操作</el-link
                            >
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <!-- <el-dropdown-item>设为企业号</el-dropdown-item>
                <el-dropdown-item>设为教师号</el-dropdown-item> -->
                            <el-dropdown-item command="5"
                                >审核通过</el-dropdown-item
                            >
                            <!-- <el-dropdown-item :command="1" v-show="!scope.row.role[1]"
                  >升级课程号</el-dropdown-item
                >
                <el-dropdown-item v-show="scope.row.isFreeze == 1" :command="2"
                  >封号</el-dropdown-item
                >
                <el-dropdown-item
                  v-show="scope.row.role[1] && scope.row.role[1] == 'educate'"
                  :command="3"
                  >取消课程号</el-dropdown-item
                >
                <el-dropdown-item v-show="scope.row.isFreeze == 0" :command="4"
                  >取消封号</el-dropdown-item
                > -->
                        </el-dropdown-menu>
                    </el-dropdown>
                    <span
                        style="
                            font-size: 12px;
                            margin: 0 15.6px;
                            color: #000000;
                            opacity: 0.3;
                        "
                        >|</span
                    >

                    <el-dropdown
                        trigger="click"
                        @click.native="getItem(scope.row)"
                        @command="handleCommandGold"
                    >
                        <span class="el-dropdown-link">
                            <!-- <el-link :underline="false" type="primary">账号操作</el-link> -->
                            <el-link :underline="false" type="primary"
                                >金币操作</el-link
                            >
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command="add"
                                >加金币</el-dropdown-item
                            >
                            <el-dropdown-item command="reduce"
                                >减金币</el-dropdown-item
                            >
                            <el-dropdown-item command="record"
                                >操作记录</el-dropdown-item
                            >
                        </el-dropdown-menu>
                    </el-dropdown>
                </template>
            </el-table-column>
        </el-table>
        <!-- 封号对话框 -->
        <el-dialog
            title="确定要封禁此用户吗？"
            :visible.sync="dialogFormVisible"
            @close="closeGold()"
            append-to-body
            width="33%"
        >
            <span> 当前用户名： </span>
            <span>
                <span>{{ clickItem.username }}</span>
            </span>
            <br />
            <br />
            <span> 当前状态： </span>
            <span>
                <span
                    >{{ zhuangtai == 0 ? "黑名单" : "正常"
                    }}{{ zhuangtai }}</span
                >
            </span>
            <br />
            <span> 封号时长: </span>
            <el-input
                style="width: 200px"
                placeholder="请输入封多少天"
                v-model="input2"
            >
                <template slot="append">天</template>
            </el-input>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="confirmBanned()"
                    >确 定</el-button
                >
            </div>
        </el-dialog>
        <el-dialog
            title="确定要解封此用户吗？"
            @close="closeGold()"
            :visible.sync="jiefeng"
            append-to-body
        >
            <span> 当前用户名： </span>
            <span>
                <span>{{ clickItem.username }}</span>
            </span>
            <br />
            <br />
            <span> 当前状态： </span>
            <span>
                <span>{{ clickItem.isFreeze === 0 ? "黑名单" : "正常" }}</span>
            </span>
            <div slot="footer" class="dialog-footer">
                <el-button @click="jiefeng = false">取 消</el-button>
                <el-button
                    type="primary"
                    @click="(jiefeng = false), userjiefeng()"
                    >确 定</el-button
                >
            </div>
        </el-dialog>
        <el-dialog
            :title="vidoeTitle"
            :visible.sync="kecheng"
            @close="closeGold()"
            @open="openDialog()"
            append-to-body
        >
            <span> 当前用户名： </span>
            <span>
                <span>{{ clickItem.username }}</span>
            </span>
            <br />
            <br />
            <span> 当前状态： </span>
            <span>
                <span>{{ clickItem.isFreeze === 0 ? "黑名单" : "正常" }}</span>
            </span>
            <div slot="footer" class="dialog-footer">
                <el-button @click="kecheng = false">取 消</el-button>
                <el-button type="primary" @click="userkecheng()"
                    >确 定</el-button
                >
            </div>
        </el-dialog>
        <el-dialog title="用户详情" :visible.sync="dialogVisible" width="750px">
            <div class="userBox">
                <div class="user_message">
                    <div class="header">
                        <span>用户头像：</span>
                        <img
                            :src="
                                useredMessage.headPicture === null
                                    ? require('@/assets/images/xiaoxin.svg')
                                    : useredMessage.headPicture
                            "
                            alt=""
                        />
                    </div>
                    <div class="box">
                        <p>用户名：{{ useredMessage.username }}</p>
                        <p>注册时间：{{ useredMessage.createTime }}</p>
                        <p>
                            权限：{{
                                useredMessage.role === "vip" ? "会员" : "普通"
                            }}
                        </p>
                        <p>等级：{{ useredMessage.level }}</p>
                        <p>金币数：{{ useredMessage.integral }}</p>
                        <p>钻石数：{{ useredMessage.gold }}</p>
                        <p>
                            状态：{{
                                useredMessage.isFreeze === 0 ? "黑名单" : "正常"
                            }}
                        </p>
                    </div>
                </div>
                <div class="right">
                    <p>
                        作品数量：{{
                            useredMessage.proUpload === null
                                ? 0
                                : useredMessage.proUpload
                        }}
                    </p>
                    <p>
                        文章数量：{{
                            useredMessage.tutUpload === null
                                ? 0
                                : useredMessage.tutUpload
                        }}
                    </p>
                    <p>
                        资源数量：{{
                            useredMessage.resUpload === null
                                ? 0
                                : useredMessage.resUpload
                        }}
                    </p>
                    <p>
                        近一周作品浏览数量：{{
                            useredMessage.proVisit === null
                                ? 0
                                : useredMessage.proVisit
                        }}
                    </p>
                    <p>
                        近一周文章浏览数量：{{
                            useredMessage.tutVisit === null
                                ? 0
                                : useredMessage.tutVisit
                        }}
                    </p>
                    <p>
                        近一周资源浏览数量：{{
                            useredMessage.resVisit === null
                                ? 0
                                : useredMessage.resVisit
                        }}
                    </p>
                    <p>
                        近一周资源下载数量：{{
                            useredMessage.resDownload === null
                                ? 0
                                : useredMessage.resDownload
                        }}
                    </p>
                </div>
                <div style="width: 100%; float: right">
                    <div
                        id="main1"
                        style="float: right; width: 50%; height: 300px"
                    ></div>
                    <div
                        id="main"
                        style="float: left; width: 50%; height: 300px"
                    ></div>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
            </span>
        </el-dialog>
        <el-dialog
            title="增加金币"
            :visible.sync="addGold"
            @close="closeGold()"
            @open="openGold()"
            append-to-body
            width="33.3%"
        >
            <el-form
                label-position="right"
                label-width="100px"
                :model="formLabelAlign"
            >
                <el-form-item label="用户名:">
                    {{ clickItem.username }}
                </el-form-item>
                <el-form-item label="现有金币数:">
                    {{ clickItem.integral }}
                </el-form-item>
                <el-form-item label="增加金币数:">
                    <el-input
                        @input="
                            formLabelAlign.num = formLabelAlign.num.replace(
                                /^\.+|[^\d.]/g,
                                ''
                            )
                        "
                        style="width: 200px"
                        v-model="formLabelAlign.num"
                    >
                        <template slot="append">金币</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="*原因:">
                    <el-input v-model="formLabelAlign.cause"></el-input>
                </el-form-item>
                <!-- <el-form-item label="*操作者:">
            <el-input
              style="width:200px"
              v-model="formLabelAlign.people"
            ></el-input>
          </el-form-item> -->
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="addGold = false">取 消</el-button>
                <el-button type="primary" @click="addUserGold()"
                    >确 定</el-button
                >
            </div>
        </el-dialog>
        <el-dialog
            title="减少金币"
            :visible.sync="reduceGold"
            @open="openGold()"
            @close="closeGold()"
            append-to-body
            width="33.3%"
        >
            <el-form
                label-position="right"
                label-width="100px"
                :model="formLabelAlign"
            >
                <el-form-item label="用户名:">
                    {{ clickItem.username }}
                </el-form-item>
                <el-form-item label="现有金币数:">
                    {{ clickItem.integral }}
                </el-form-item>
                <el-form-item label="减少金币数:">
                    <el-input
                        @input="
                            formLabelAlign.num = formLabelAlign.num.replace(
                                /^\.+|[^\d.]/g,
                                ''
                            )
                        "
                        style="width: 200px"
                        v-model="formLabelAlign.num"
                    >
                        <template slot="append">金币</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="*原因:">
                    <el-input v-model="formLabelAlign.cause"></el-input>
                </el-form-item>
                <!-- <el-form-item label="*操作者:">
            <el-input
              style="width:200px"
              v-model="formLabelAlign.people"
            ></el-input>
          </el-form-item> -->
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="reduceGold = false">取 消</el-button>
                <el-button type="primary" @click="reduceUserGole()"
                    >确 定</el-button
                >
            </div>
        </el-dialog>
        <el-dialog
            title="金币记录"
            @close="closeGold()"
            :visible.sync="recordGold"
            @open="openGold()"
            append-to-body
        >
            <el-table
                class="sbody"
                :header-cell-style="thStyleFun"
                :cell-style="cellStyleFun"
                :data="dialogTableData"
                @filter-change="filterChangeTable"
                @sort-change="tableSortChange"
            >
                <el-table-column property="username" label="用户名">
                    <!-- <template slot-scope="scope">
              <img :src="scope.row.headPicture" alt="">
            </template> -->
                </el-table-column>
                <el-table-column
                    property="operate"
                    label="操作详情"
                    :filters="[
                        { text: '增加', value: '+' },
                        { text: '减少', value: '-' },
                    ]"
                    column-key="operate"
                    filter-placement="bottom-end"
                    :filter-multiple="false"
                >
                    <template slot="header">
                        操作详情
                        <i
                            style="font-size: 12px"
                            class="icon iconfont icon-jiantou1"
                        ></i>
                    </template>
                    <template slot-scope="scope">
                        <span
                            style="color: #489e94"
                            v-if="scope.row.operate[0] == '-'"
                            >{{ scope.row.operate }}</span
                        >
                        <span style="color: #df5435" v-else>{{
                            scope.row.operate
                        }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    property="reason"
                    label="原因"
                ></el-table-column>
                <el-table-column
                    property="operaterName"
                    label="操作者"
                ></el-table-column>
                <el-table-column
                    property="createTime"
                    label="操作时间"
                    sortable="custom"
                >
                    <template slot="header">
                        <span>操作时间 </span>
                        <i
                            v-show="tableTimeFlag == 1"
                            style="font-size: 14px; color: #1989fa"
                            class="icon iconfont icon-zuobian"
                        ></i>

                        <i
                            v-show="tableTimeFlag == 0 || tableTimeFlag == 2"
                            style="font-size: 14px"
                            class="icon iconfont icon-zuobian"
                        ></i>
                        <i
                            v-show="tableTimeFlag == 2"
                            style="font-size: 14px; color: #1989fa"
                            class="icon iconfont icon-youbian"
                        ></i>
                        <i
                            v-show="tableTimeFlag == 0 || tableTimeFlag == 1"
                            style="font-size: 14px"
                            class="icon iconfont icon-youbian"
                        ></i>
                    </template>
                </el-table-column>
            </el-table>

            <div slot="footer" class="dialog-footer">
                <div class="tabelDialog">
                    <el-pagination
                        @size-change="tableChange"
                        @current-change="tableCurrent"
                        :current-page.sync="currentTable"
                        :page-size="sizeTable"
                        layout="prev, pager, next, jumper"
                        :total="totalTable"
                    >
                    </el-pagination>
                </div>
            </div>

            <!-- <div slot="footer" class="dialog-footer">
          <el-button @click="recordGold = false">取 消</el-button>
          <el-button type="primary" @click="userkecheng()">确 定</el-button>
        </div> -->
        </el-dialog>
        <el-dialog
            title="确定通过此用户提交的申请吗？"
            @close="closeGold()"
            :visible.sync="passflag"
            append-to-body
        >
            <span> 当前用户名： </span>
            <span>
                <span>{{ clickItem.username }}</span>
            </span>
            <br />
            <br />
            <span> 当前状态： </span>
            <span>
                <span>{{ clickItem.isFreeze === 0 ? "黑名单" : "正常" }}</span>
            </span>
            <div slot="footer" class="dialog-footer">
                <el-button @click="jiefeng = false">取 消</el-button>
                <el-button type="primary" @click="passChange()"
                    >确 定</el-button
                >
            </div>
        </el-dialog>
        <!-- 分页 -->
        <div class="block">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page.sync="currentPage3"
                :page-size="pageSize"
                layout="prev, pager, next, jumper"
                :total="total"
            >
            </el-pagination>
        </div>
        <!-- 账号自动生成 -->
        <el-dialog
            title="生成用户（账号自动生成）"
            :visible.sync="uploadExcelShow"
            width="600px"
            center
        >
            <el-upload
                class="upload-demo"
                drag
                action=""
                multiple
                :on-change="getFile"
                :auto-upload="false"
            >
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">
                    将文件拖到此处，或<em>点击上传</em>
                </div>
            </el-upload>
            <span slot="footer" class="dialog-footer">
                <el-button @click="uploadExcelShow = false">取 消</el-button>
                <el-button type="primary" @click="upload">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 导入用户（需提供账号） -->
        <el-dialog
            width="800px"
            :visible.sync="importUserDialogVisible"
            center
            class="import_dialog"
        >
            <section>
                <h2>请先下载模板</h2>
                <div class="download_box">
                    <a
                        href="/static/xlsx/index.xlsx"
                        download="用户导入模板.xlsx"
                    >
                        <el-button icon="el-icon-download">
                            用户导入模板
                        </el-button>
                    </a>
                </div>
            </section>
            <section>
                <h2>导入数据</h2>
                <div class="upload_box">
                    <el-upload
                        drag
                        :action="importUserPath"
                        :show-file-list="false"
                        name="file"
                        ref="economicUpload"
                        accept=".xlsx"
                        :before-upload="beforExcelFileUpload"
                        :on-success="importUserSuccess"
                        :headers="{
                            token: token,
                        }"
                    >
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">
                            将文件拖到此处，或<em>点击上传</em>
                        </div>
                    </el-upload>
                </div>
            </section>
        </el-dialog>
    </div>
</template>
<script>
export default {
    data () {
        // const item = {}
        return {
            uploadExcelShow: false,
            tableData: [],
            // 搜索框
            input: "",
            // 封号时长
            input2: "",
            // 当前状态
            zhuangtai: null,
            // 页数
            currentPage3: 1,
            total: 0, // 数据的总条数
            pageSize: 14,
            isFreeze: null,
            productionList: [],
            userid: null,
            dialogFormVisible: false,
            jiefeng: false,
            kecheng: false,
            // dialogFormVisibles:false,
            dialogVisible: false,
            loading: false,
            useredMessage: {},
            oneUser: [],
            clickData: [],
            clickItem: {},
            dataFlag: false,
            // 排序icon状态
            levelFlag: "",
            createFlag: "",
            integralFlag: "",
            goldFlag: "",
            // 排序的关键字
            filterTitle: "",
            orderBy: 1,
            ckickRole: "",
            identitys: null,
            vidoeTitle: "",
            addGold: false,
            reduceGold: false,
            recordGold: false,
            formLabelAlign: {
                num: null,
                cause: "",
            },
            // 表格分页
            currentTable: 1,
            sizeTable: 8,
            totalTable: 0,
            dialogTableData: [],
            tableTimeFlag: false,
            operate: "",
            filterTabTitle: "",
            tableSortRule: "",
            roleStatus: null,
            passflag: false,
            file: null,
            // 导入用户的对话框是否显示
            importUserDialogVisible: false,
            // 导入用户的请求地址
            importUserPath: '/api/user/importUserListBySheetFile',
            token: window.localStorage.getItem('user')
        }
    },
    created () {
        const that = this
        if (this.$route.params.id !== undefined) {
            this.userid = this.$route.params.id
            // console.log(this.userid)
            // const data = {
            //   userId:this.userid
            // }
            this.$axios
                .post(`/api/user/findOne?userId=${this.userid}`)
                .then(function (res) {
                    // console.log(res.data.data)
                    const data = []
                    data.push(res.data.data)
                    // console.log(data)
                    that.tableData = data
                    // console.log(that.tableData)
                    that.userid = null
                })
        } else {
            that.getProductionList()
        }
    },
    methods: {
        getFile (data) {
            console.log("data: ", data)
            this.file = data.raw
        },
        // 上传生成用户的表格
        upload () {
            let formData = new FormData()
            formData.append("file", this.file)
            fetch(`/api/user/batchUser`, {
                responseType: "blob",
                method: "post",
                headers: {
                    token: window.localStorage.getItem("user")
                },
                body: formData,
            })
                .then((res) => res.blob())
                .then((res) => {
                    console.log(res, "dd")
                    const blob = new Blob([res], {
                        type: "application/vnd.ms-excel",
                    })
                    console.log(blob)
                    if (window.navigator.msSaveOrOpenBlob) {
                        navigator.msSaveOrOpenBlob(blob, "用户" + new Date().getTime())
                    } else {
                        this.uploadExcelShow = false
                        const link = document.createElement("a")
                        // const evt = document.createEvent('HTMLEvents')
                        // evt.initEvent('click', false, false)
                        link.href = URL.createObjectURL(blob)
                        link.download = "用户" + new Date().getTime()
                        link.style.display = "none"
                        document.body.appendChild(link)
                        link.click()
                        window.URL.revokeObjectURL(link.href)

                    }
                })
                .catch((e) => {
                    console.log("e: ", e)
                })
        },
        // 在表格上传前做一些事情
        beforExcelFileUpload (file) {
            console.log(file)
            this.$loading({
                lock: true,
                text: '用户导入中，请稍后',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            return true
        },
        // 表格导入用户数据成功的回调
        importUserSuccess (response) {
            this.$loading().close()
            if (response.code === 200) {
                this.$message.success(`成功导入${response.data}个用户`)
            } else {
                this.$message.success("表格数据有误，请检查数据")
            }
        },
        passChange () {
            this.$axios
                .put(`/api/user/checkedAgree?id=${this.clickItem.userId}`)
                .then((res) => {
                    console.log(res)
                    if (res.data.code == 200) {
                        this.$message({
                            type: "success",
                            message: "操作成功",
                        })
                        this.passflag = false
                    } else {
                        this.$message({
                            type: "error",
                            message: res.data.message,
                        })
                    }
                })
        },
        // 查看全部金币记录
        openAllGold () {
            this.clickItem = {}
            this.recordGold = true
        },
        allGoldList () { },
        // 关闭金币记录
        closeGold () {
            this.operate = ""
            this.filterTabTitle = ""
            this.tableSortRule = ""
            this.clickItem = {}
            // let filterObj = {
            //   operate: [''],
            // }
            // this.openGold(filterObj, 'operate')
        },
        // 筛选金币方式
        filterChangeTable (filterObj) {
            this.currentTable = 1
            if (Object.keys(filterObj)[0] == "operate") {
                this.openGold(filterObj, "operate")
            }
        },
        // 切换金币时间排序方式
        tableSortChange (e) {
            this.currentTable = 1
            console.log(e)
            if (e.column.label == "操作时间") {
                this.filterTabTitle = "create_time"
                if (e.column.order == "ascending") {
                    this.tableSortRule = -1
                    this.tableTimeFlag = 1
                    // console.log('倒序')
                    this.openGold()
                    //降序
                }
                if (e.column.order == "descending") {
                    //降序
                    this.tableSortRule = 1
                    this.tableTimeFlag = 2
                    this.openGold()
                    // console.log('正序')
                }
                if (!e.column.order) {
                    //默认
                    this.filterTabTitle = ""
                    this.tableSortRule = ""
                    this.tableTimeFlag = 0
                    this.openGold()
                    // console.log('默认')
                }
            }
        },
        //执行删减金币操作
        addUserGold () {
            if (this.formLabelAlign.num == null || this.formLabelAlign.num == "") {
                this.$message.warning("请输入金币个数")
                return
            }
            if (this.formLabelAlign.cause == "") {
                this.$message.warning("请输入原因")
                return
            }

            let data = {
                integral: parseInt(this.formLabelAlign.num),
                reason: this.formLabelAlign.cause,
                userId: this.clickItem.userId,
            }
            this.$axios.put("/api/user/addIntegral", data).then((res) => {
                if (res.data.code == 200) {
                    this.$message.success("添加成功！")
                    this.getProductionList()
                    this.addGold = false
                } else {
                    this.$message.error(res.data.message)
                }
            })
        },
        reduceUserGole () {
            if (this.formLabelAlign.num == null || this.formLabelAlign.num == "") {
                this.$message.warning("请输入金币个数")
                return
            }
            if (this.formLabelAlign.cause == "") {
                this.$message.warning("请输入原因")
                return
            }

            let data = {
                integral: -this.formLabelAlign.num,
                reason: this.formLabelAlign.cause,
                userId: this.clickItem.userId,
            }
            this.$axios.put("/api/user/addIntegral", data).then((res) => {
                if (res.data.code == 200) {
                    this.$message.success("减少成功！")
                    this.getProductionList()
                    this.reduceGold = false
                } else {
                    this.$message.error(res.data.message)
                }
            })
        },
        // 打开添加金币记录对话框
        openGold (filterObj, type) {
            this.formLabelAlign.num = null
            this.formLabelAlign.cause = ""
            if (type == "operate") {
                this.operate = filterObj.operate[0]
            }
            let data = {
                current: this.currentTable,
                size: this.sizeTable,
                operate: this.operate,
                sortKey: this.filterTabTitle,
                sortRule: this.tableSortRule,
                userId: this.clickItem.userId,
            }
            if (this.recordGold == true) {
                this.$axios.post("/api/user/getRecordPage", data).then((res) => {
                    console.log(res)
                    if (res.data.code == 200) {
                        this.dialogTableData = res.data.data.records
                        this.totalTable = res.data.data.total
                    }
                })
            }
        },
        // 点击金币下拉
        handleCommandGold (e) {
            console.log(e)
            if (e == "add") {
                this.addGold = true
            } else if (e == "reduce") {
                this.reduceGold = true
            } else {
                this.recordGold = true
            }
        },
        // 打开视频号对话框的时候
        openDialog () {
            // console.log(this.clickItem.role.length)
            let i = this.clickItem.isEducate
            if (i == 0) {
                this.vidoeTitle = "确定要将此用户设置为课程号吗？"
            } else {
                this.vidoeTitle = "确定取消此用户为课程号吗？"
            }
            console.log()
        },
        // 获取点击的那一项
        getItem (e) {
            // console.log(e)
            this.clickItem = e
            console.log(this.clickItem)
            // console.log(this.clickItem.role.length)
        },
        // 点击操作下拉
        handleCommand (i) {
            // console.log(i)
            if (i == 1) {
                this.kecheng = true
            } else if (i == 2) {
                this.dialogFormVisible = true
            } else if (i == 3) {
                this.kecheng = true
            } else if (i == 4) {
                this.jiefeng = true
            } else if (i == 5) {
                this.passflag = true
            }
        },
        // 切换用户视频号权限的时候
        worksShow (item, value) {
            this.clickItem = item
            if (value == "fh") {
                if (item.isFreeze == 1) {
                    item.isFreeze = 0
                    this.jiefeng = true
                } else if (item.isFreeze == 0) {
                    item.isFreeze = 1
                    this.dialogFormVisible = true
                }
            } else {
                if (item.isEducate == 1) {
                    item.isEducate = 0
                    this.kecheng = true
                } else {
                    item.isEducate = 1
                    this.kecheng = true
                }
            }
        },
        // 筛选
        filterChange (filterObj) {
            let that = this
            this.currentPage3 = 1
            console.log(filterObj)
            if (Object.keys(filterObj)[0] == "role") {
                this.ckickRole = filterObj.role[0]
                this.getProductionList()
            }
            if (Object.keys(filterObj)[0] == "identity") {
                if (filterObj.identity.length < 1) {
                    that.getProductionList()
                    that.identitys = null
                } else {
                    that.identitys = filterObj.identity[0]
                    that.getProductionList()
                }
            }
            if (Object.keys(filterObj)[0] == "isFreeze") {
                // this.getProductionList(filterObj, 'isFreeze')
                this.isFreeze = filterObj.isFreeze[0]
                this.getProductionList()
            }
            if (Object.keys(filterObj)[0] == "roleStatus") {
                // console.log('1111111111111111')
                // this.getProductionList(filterObj, 'roleStatus')
                this.roleStatus = filterObj.roleStatus[0]
                this.getProductionList()
            }
        },
        // 当表格的排序发生变化时
        sortChange (e) {
            this.currentPage3 = 1
            // console.log(e)
            if (e.column.label == "等级") {
                this.filterTitle = "level"
                if (e.column.order == "ascending") {
                    this.orderBy = -1
                    this.levelFlag = 1
                    // console.log('倒序')
                    this.getProductionList()
                    //降序
                }
                if (e.column.order == "descending") {
                    //降序
                    this.orderBy = 1
                    this.levelFlag = 2
                    this.getProductionList()
                    // console.log('正序')
                }
                if (!e.column.order) {
                    //默认
                    this.filterTitle = ""
                    this.orderBy = ""
                    this.levelFlag = 0
                    this.getProductionList()
                    // console.log('默认')
                }
            }
            // 时间排序
            if (e.column.label == "注册时间") {
                this.filterTitle = "create_time"
                if (e.column.order == "ascending") {
                    this.orderBy = -1
                    this.createFlag = 1
                    // console.log('倒序1')
                    this.getProductionList()
                    //降序
                }
                if (e.column.order == "descending") {
                    //降序
                    this.orderBy = 1
                    this.createFlag = 2
                    this.getProductionList()
                    // console.log('正序1')
                }
                if (!e.column.order) {
                    //默认
                    this.filterTitle = ""
                    this.orderBy = ""
                    this.createFlag = 0
                    this.getProductionList()
                    // console.log('默认1')
                }
            }
            if (e.column.label == "钻石数") {
                this.filterTitle = "gold"
                if (e.column.order == "ascending") {
                    this.orderBy = -1
                    this.goldFlag = 1
                    // console.log('倒序1')
                    this.getProductionList()
                    //降序
                }
                if (e.column.order == "descending") {
                    //降序
                    this.orderBy = 1
                    this.goldFlag = 2
                    this.getProductionList()
                    // console.log('正序1')
                }
                if (!e.column.order) {
                    //默认
                    this.filterTitle = ""
                    this.orderBy = ""
                    this.goldFlag = 0
                    this.getProductionList()
                    // console.log('默认1')
                }
            }
            if (e.column.label == "金币数") {
                this.filterTitle = "integral"
                if (e.column.order == "ascending") {
                    this.orderBy = -1
                    this.integralFlag = 1
                    // console.log('倒序1')
                    this.getProductionList()
                    //降序
                }
                if (e.column.order == "descending") {
                    //降序
                    this.orderBy = 1
                    this.integralFlag = 2
                    this.getProductionList()
                    // console.log('正序1')
                }
                if (!e.column.order) {
                    //默认
                    this.filterTitle = ""
                    this.orderBy = ""
                    this.integralFlag = 0
                    this.getProductionList()
                    // console.log('默认1')
                }
            }
        },
        // 设置用户成为课程账号
        userkecheng () {
            // alert('设置成功')
            // kecheng = false
            console.log(this.clickItem.userId)
            this.$axios
                .put(`/api/user/up2CourseRole?id=${this.clickItem.userId}`)
                .then((res) => {
                    console.log(res)
                    if (res.data.code == 200) {
                        this.$message({
                            type: "success",
                            message: "该用户已成为视频号",
                        })
                        this.getProductionList()
                        this.kecheng = false
                    } else {
                        this.$message({
                            type: "error",
                            message: res.data.message,
                        })
                    }
                })
        },
        // 用户上传统计图
        initData (data) {
            // 基于准备好的dom，初始化echarts实例
            var myChart = this.$echarts.init(document.getElementById("main1"))
            // 绘制图表
            myChart.setOption({
                title: {
                    text: "用户上传量", //主标题
                    subtext: "近一周", //副标题
                    x: "center", //x轴方向对齐方式
                },
                tooltip: {
                    trigger: "item",
                    formatter: "{a} <br/>{b} : {c} ({d}%)",
                },
                // legend: {
                //     orient: 'vertical',
                //     bottom: 'bottom',
                //     data: ['作品','日志','资源']
                // },
                series: [
                    {
                        name: "近一周占比",
                        type: "pie",
                        radius: "55%",
                        center: ["50%", "60%"],
                        data: [
                            {
                                value: data.proUpload,
                                name: "(" + data.proUpload + ")" + "作品",
                            },
                            {
                                value: data.tutUpload,
                                name: "(" + data.tutUpload + ")" + "文章",
                            },
                            {
                                value: data.resUpload,
                                name: "(" + data.resUpload + ")" + "资源",
                            },
                        ],
                        itemStyle: {
                            emphasis: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: "rgba(0, 0, 0, 0.5)",
                            },
                        },
                    },
                ],
            })
        },
        // 用户浏览统计图
        initDatas (data) {
            // 基于准备好的dom，初始化echarts实例
            var myChart = this.$echarts.init(document.getElementById("main"))
            // 绘制图表
            myChart.setOption({
                title: {
                    text: "用户浏览量", //主标题
                    subtext: "近一周", //副标题
                    x: "center", //x轴方向对齐方式
                },
                tooltip: {
                    trigger: "item",
                    formatter: "{a} <br/>{b} : {c} ({d}%)",
                },
                // legend: {
                //     orient: 'vertical',
                //     bottom: 'bottom',
                //     data: ['作品','日志','资源下载量']
                // },
                series: [
                    {
                        name: "近一周占比",
                        type: "pie",
                        radius: "55%",
                        center: ["50%", "60%"],
                        data: [
                            {
                                value: data.proVisit,
                                name: "(" + data.proVisit + ")" + "作品",
                            },
                            {
                                value: data.tutVisit,
                                name: "(" + data.tutVisit + ")" + "文章",
                            },
                            {
                                value: data.resDownload,
                                name: "(" + data.resDownload + ")" + "资源下载量",
                            },
                            // {value:10, name:'作品' + data.proVisit},
                            // {value:20, name:'日志' + data.tutVisit},
                            // {value:30, name:'资源下载量' + data.resVisit},
                        ],
                        itemStyle: {
                            emphasis: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: "rgba(0, 0, 0, 0.5)",
                            },
                        },
                    },
                ],
            })
        },
        // 解封
        userjiefeng () {
            const that = this
            this.$axios
                .put(`/api/user/unblockUser?id=${this.clickItem.userId}`)
                .then(function (res) {
                    // console.log(res.data)
                    if (res.data.code === 200) {
                        that.dialogVisible = false
                        that.getProductionList()
                        that.$message({
                            type: "success",
                            message: "解封成功",
                        })
                    } else {
                        that.$message({
                            type: "error",
                            message: res.data.message,
                        })
                    }
                })
        },
        // 封号
        confirmBanned () {
            const that = this
            this.input2 = this.input2.replace(/[^\d]/g, "")
            if (this.input2 === "") {
                this.$message({
                    type: "error",
                    message: "请输入封号时长且只能输入纯数字!",
                })
            } else {
                const data = {
                    date: this.input2,
                    id: this.clickItem.userId,
                }
                this.$axios.put("/api/user/blockUser", data).then((res) => {
                    if (res.data.code === 200) {
                        this.dialogFormVisible = false
                        this.dialogVisible = false
                        that.getProductionList()
                        that.input2 = ""
                        this.$message({
                            type: "success",
                            message: "封号成功!",
                        })
                    } else {
                        this.$message({
                            type: "error",
                            message: res.data.message,
                        })
                    }
                })
            }
        },
        goUser (item) {
            this.clickItem = item
            this.useredMessage = {}
            const that = this
            // console.log(id)
            this.$axios
                .post(`api/user/findOne?userId=${item.userId}`)
                .then(function (res) {
                    console.log(res.data)
                    if (res.data.code === 200) {
                        that.useredMessage = res.data.data
                        that.initData(that.useredMessage)
                        that.initDatas(that.useredMessage)
                    }
                })
        },
        //  点击封号
        fenghao (data) {
            this.clickData = data
            this.zhuangtai = data.isFreeze
            this.dialogFormVisible = true
        },
        // 获取列表数据
        async getProductionList () {
            this.loading = true
            const that = this
            const data = {
                current: this.currentPage3,
                keyword: this.input,
                size: this.pageSize,
                status: this.isFreeze,
                sortKey: this.filterTitle,
                sortRule: this.orderBy,
                role: this.ckickRole,
                identity: this.identitys,
                ckickRole: this.ckickRole,
                roleStatus: this.roleStatus,
            }
            // console.log(this.isFreeze)
            await this.$axios.post("/api/user/findUser", data).then(function (res) {
                that.tableData = res.data.data.records
                // console.log(res.data.data)
                that.productionList = res.data.data.records
                that.total = res.data.data.total
                that.loading = false
            })
        },
        // 页码大小变动的时候触发
        handleSizeChange (val) {
            this.currentPage3 = 1
            this.pageSize = val
        },
        tableChange (v) {
            this.currentTable = 1
            this.pageSize = v
        },
        // 当前页变动的时候触发
        handleCurrentChange (val) {
            this.currentPage3 = val
            this.getProductionList()
        },
        tableCurrent (v) {
            this.currentTable = v
            this.openGold()
        },
        filterTag (value, row) {
            return row.tag === value
        },
        filterHandler (value, row, column) {
            const property = column["property"]
            return row[property] === value
        },
        thStyleFun () {
            return "text-align:center;background:#DCDFE6;color:#303133"
        },
        cellStyleFun () {
            return "text-align:center"
        },
    },
};
</script>
<style lang="less" scoped>
.user {
    width: 100%;
    height: 100%;
    .headerBox {
        width: 100%;
        margin-bottom: 12px;
        display: flex;
        align-items: center;
    }
}
.userName {
    height: 100%;
    // width: 300px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    img {
        width: 32px;
        height: 32px;
        border-radius: 50%;
    }
    span {
        position: relative;
        top: -10px;
        margin-left: 10px;
    }
}
.block {
    margin: 30px auto;
    text-align: center;
}
.userBox {
    width: 750px;
    min-height: 600px;
}
.user_message {
    // position: relative;
    float: left;
    .header {
        span {
            display: inline-block;
            // background: red;
            position: relative;
            top: -20px;
        }
        img {
            width: 44px;
            height: 44px;
        }
    }
    .box {
        font-size: 16px;
    }
}
.right {
    float: right;
    font-size: 16px;
    margin-right: 100px;
    margin-top: -26px;
}
.production_img {
    width: 100%;
    height: 482px;
    background: #dcdfe6;
    margin-bottom: 30px;
}
.tag_box {
    .el-tag {
        margin: 0 20px;
    }
}
/deep/ .el-table__column-filter-trigger {
    opacity: 0;
}
/deep/ .caret-wrapper {
    opacity: 0;
}
.el-form-item {
    margin-bottom: 0;
}
.sbody {
    min-height: 435px;
}
.tabelDialog {
    text-align: center;
}

// 导入用户的对话框样式
.import_dialog {
    /deep/ .el-dialog__header {
        height: 0;
        padding: 0;
    }

    /deep/ .el-dialog__body {
        display: flex;

        > section {
            flex-basis: 50%;

            > h2 {
                text-align: center;
            }
        }
    }

    .download_box {
        width: 280px;
        height: 160px;
        border-radius: 5px;
        margin: 12px 50px;
        background-color: #eee;

        .el-button {
            height: 100%;
            width: 100%;
            box-sizing: content-box;
            padding: 0;
            background-color: rgb(248, 248, 248);
            border: 0;
            color: #409eff;

            /deep/ i {
                font-size: 60px;
                color: #ccc;
            }

            /deep/ span {
                display: block;
                font-size: 20px;
            }
        }
    }

    .upload_box {
        width: 280px;
        height: 160px;
        border-radius: 5px;
        margin: 12px 50px;
        background-color: #eee;

        /deep/ .el-upload {
            height: 160px;
            width: 100%;
            box-sizing: content-box;
            // padding: 12px 20px;
            background-color: rgb(248, 248, 248);
            border: 0;

            .el-upload-dragger {
                width: 100%;
                height: 100%;
                border: 0;
                background-color: inherit;
            }

            /deep/ i {
                font-size: 60px;
                color: #ccc;
            }

            /deep/ span {
                display: block;
                font-size: 20px;
            }
        }
    }
}
</style>
