<template>
  <div class="illegal">
    <!-- 搜索框 -->
    <el-input placeholder="请输入被举报用户名"
              prefix-icon="el-icon-search"
              v-model="input">
    </el-input>
    <!-- 表格 -->
    <el-main>
      <el-table stripe
                :data="tableDatas"
                ref="filterTable"
                :header-cell-style="thStyleFun"
                :cell-style="cellStyleFun">
        <el-table-column v-if="
            type == 'article' ||
              type == 'produce' ||
              type == 'resource' ||
              type == 'course'
          "
                         prop="date"
                         label="封面">
          <template slot-scope="scope">
            <!-- 封面图片 -->
            <div class="demo-image">
              <div class="block"
                   style="margin:0 auto;width: 178px; height: 100px;">
                <el-image style="width: 178px; height: 100px;"
                          :src="scope.row.cover"
                          fit="scale-down"></el-image>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="username"
                         label="被举报的用户名">
          <!-- <template slot-scope="scope">
            <div class="userName">
              <img src="@/assets/images/xiaoxin.svg" alt="" />
              <span> {{ scope.row.username }} </span>
            </div> -
          </template> -->
        </el-table-column>
        <el-table-column v-if="type === 'produce'"
                         prop="name"
                         label="作品名称"></el-table-column>
        <el-table-column v-if="type === 'resource'"
                         prop="name"
                         label="资源名称"></el-table-column>
        <el-table-column v-if="type === 'article'"
                         prop="name"
                         label="文章名称"></el-table-column>
        <el-table-column :show-overflow-tooltip="showOverflow"
                         v-if="type === 'comment'"
                         prop="comment"
                         label="评论内容"></el-table-column>
        <el-table-column prop="reason"
                         label="违规标签"> </el-table-column>
        <el-table-column prop="createTime"
                         label="时间"> </el-table-column>
        <el-table-column prop="isRead"
                         label="状态"
                         :filters="[
            { text: '已读', value: 1 },
            { text: '未读', value: 0 },
          ]"
                         :filter-method="filterTag"
                         filter-placement="bottom-end">
          <template slot-scope="scope">
            <span>{{ scope.row.isRead == 0 ? '未读' : '已读' }}</span>
          </template>
        </el-table-column>

        <el-table-column prop="operation"
                         label="操作">
          <template slot-scope="scope">
            <el-link :underline="false"
                     type="primary"
                     @click="getParticulars(scope.row)">详情</el-link>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="block">
        <el-pagination @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       :current-page.sync="currentPage3"
                       :page-size="pageSize"
                       layout="prev, pager, next, jumper"
                       :total="total">
        </el-pagination>
      </div>
    </el-main>
    <!-- 对话框 -->
    <el-dialog :title="clickItem.pname"
               center
               @open="openDetail"
               @close="closeBox"
               :visible.sync="outerVisible">
      <!-- 下架对话框 -->
      <el-dialog width="30%"
                 height="480px"
                 title="请输入下架原因"
                 @close="closexiajia"
                 :visible.sync="innerVisible"
                 append-to-body>
        <el-input v-model="cause"></el-input>
        <span slot="footer"
              class="dialog-footer">
          <el-button @click="innerVisible = false">取消</el-button>
          <el-button type="primary"
                     @click="soldOutProduction()">确定</el-button>
        </span>
      </el-dialog>
      <!-- 取消下架对话框 -->
      <el-dialog width="30%"
                 height="480px"
                 title="确定要取消下架吗?"
                 :visible.sync="innerVisibles"
                 append-to-body>
        <span slot="footer"
              class="dialog-footer">
          <el-button @click="innerVisible = false">取消</el-button>
          <el-button type="primary"
                     @click="
              ;(innerVisibles = false),
                (outerVisible = false),
                soldOutProduction()
            ">确定</el-button>
        </span>
      </el-dialog>
      <!-- {{ particularsData.content }} -->
      <!-- 文章内容 -->
      <div v-show="type == 'article'">
        <!-- <div class="clickItemCover">
          <span>封面：</span>
          <img class="clickItemCover" :src="clickItem.cover" alt="" />
        </div> -->
        <div class="production_img">
          <!-- <img :src="particularsData.img" alt="" /> -->
          <div v-if="type == 'article'">
            <!-- 封面：<img class="clickItemCover" :src="clickItem.cover" alt="" /> -->
            <div v-html="articleText"></div>
          </div>
        </div>
      </div>
      <!-- 作品内容区域 -->
      <div v-if="type == 'produce'">
        <div class="production_img">
          <banner ref="banner"
                  :imagesList="imagesLists"></banner>
        </div>
        <div class="tag_box">
          作品标签:<el-tag v-for="(item, index) in label"
                  :key="index">{{
            item
          }}</el-tag>
        </div>
        <div class="cause"
             v-if="productionData.reason !== null && productionData.reason !== ''">
          <span>下架原因:</span>
          {{ productionData.reason }}
        </div>
        <table class="xiangxixinxi">
          <tr>
            <span>类型:</span>
            <th>{{ productionData.mold }}</th>
          </tr>
          <tr>
            <span>发布时间:</span>
            <th>{{ productionData.createTime }}</th>
          </tr>
          <tr>
            <span>更新时间:</span>
            <th>{{ productionData.updateTime }}</th>
          </tr>
          <tr>
            <span>热度:</span>
            <th>{{ productionData.heat }}</th>
          </tr>
          <tr>
            <span>途径:</span>
            <th>{{ productionData.type }}</th>
          </tr>
          <!-- <tr>
        <span>点赞数量:</span>
        <th>{{ productionData.thumbLevel }}</th>
      </tr> -->
          <tr>
            <span>适用软件:</span>
            <th>{{ productionData.software }}</th>
          </tr>
        </table>
      </div>
      <!-- 资源内容区域 -->
      <div v-if="type == 'resource'">
        {{ productionData.content }}
        <div class="production_img">
          <bannars :imagesList="imagesLists"></bannars>
        </div>
        <div class="tag_box">
          <el-tag v-for="(item, index) in label"
                  :key="index">{{
            item
          }}</el-tag>
        </div>
        <div class="cause"
             v-if="productionData.reason !== null && productionData.reason !== ''">
          <span>下架原因:</span>

          <p></p>
        </div>
        <table class="xiangxixinxi">
          <tr>
            <span>类型:</span>
            <th>{{ productionData.classify }}</th>
          </tr>
          <tr>
            <span>发布时间:</span>
            <th>{{ productionData.createTime }}</th>
          </tr>
          <tr>
            <span>更新时间:</span>
            <th>{{ productionData.updateTime }}</th>
          </tr>
          <tr>
            <span>资源描述:</span>
            <th>{{ productionData.description }}</th>
          </tr>
          <tr>
            <span>价格:</span>
            <th>
              {{
                productionData.price === 0
                  ? '免费'
                  : productionData.price + '金币'
              }}
            </th>
          </tr>
          <tr>
            <span>购买次数:</span>
            <th>
              {{
                productionData.boughtCount === 0
                  ? '暂无'
                  : productionData.boughtCount + '次'
              }}
            </th>
          </tr>
          <tr>
            <span>点赞数量:</span>
            <th>{{ productionData.thumbLevel }}</th>
          </tr>
          <tr>
            <span>适用软件:</span>
            <th>{{ productionData.software }}</th>
          </tr>
          <tr>
            <span>文件名称:</span>
            <th>{{ productionData.name }}</th>
          </tr>
          <tr>
            <span>文件类型:</span>
            <th>
              {{
                productionData.fileClassify === ''
                  ? '暂无'
                  : productionData.fileClassify
              }}
            </th>
          </tr>
        </table>

        <div class="miaoshu">
          <span>文件下载地址:</span>
          <el-input style="width:200px"
                    placeholder="文件下载地址"
                    id="form_tone"
                    v-model="productionData.zip">
            <!-- <template slot="append">天</template> -->
          </el-input>
          <el-button @click="fuzhi()">点击复制</el-button>
          <!-- <v-input v-model="productionData.zip"></v-input> -->
          <!-- {{ productionData.zip }} -->
        </div>
      </div>
      <div v-if="type == 'comment'">
        <div class="production_img">
          <div style="margin:10px 10px;">评论内容:{{ clickItem.comment }}</div>
        </div>
      </div>
      <div class="tag_box">
        举报标签: <el-tag>{{ clickItemLabel }}</el-tag>
      </div>
      <p class="report_name">{{ particularsData.name }}</p>
      <div class="report_box">
        <div class="report_inner_box">
          {{ clickItem.intro }}
        </div>
      </div>
      <div class="reply_box">
        <textarea placeholder="回复举报人的用户"
                  v-model="replyContent"
                  rows="行数"
                  cols="列数"
                  value="取值"></textarea>
      </div>
      <div slot="footer"
           class="dialog-footer">
        <el-button type="success"
                   @click="outerVisible, replyInformer(particularsData)"
                   slot="reference">回 复</el-button>
        <el-button v-if="type !== 'comment'"
                   v-show="productionList.isFreeze == 1"
                   type="danger"
                   @click="innerVisible = true">下 架</el-button>
        <el-button v-if="type !== 'comment'"
                   v-show="productionList.isFreeze == 0"
                   type="primary"
                   @click="innerVisibles = true">取 消 下 架</el-button>
        <el-button type="primary"
                   @click="userBanned = true">封 号</el-button>
        <el-button type="danger"
                   v-if="type == 'comment'"
                   @click="delComment">删 除</el-button>
        <el-button @click="outerVisible = false"
                   type="info">取 消</el-button>
      </div>
    </el-dialog>
    <!-- 封号 -->
    <el-dialog title="确定要封禁此用户吗？"
               :visible.sync="dialogFormVisible"
               @close="closeGold()"
               append-to-body
               width="33%">
      <span>
        当前用户名：
      </span>
      <span>
        <span>{{ clickItem.username }}</span>
      </span>
      <br />
      <br />
      <span>
        当前状态：
      </span>
      <span>
        <span>{{ zhuangtai == 0 ? '黑名单' : '正常' }}{{ zhuangtai }}</span>
      </span>
      <br />
      <span>
        封号时长:
      </span>
      <el-input style="width:200px"
                placeholder="请输入封多少天"
                v-model="input2">
        <template slot="append">天</template>
      </el-input>
      <div slot="footer"
           class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary"
                   @click="confirmBanned()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import banner from '@/components/zujian/banner.vue'
import bannars from '@/components/zujian/banner.vue'
export default {
  components: {
    bannars,
    banner,
  },
  data () {
    // const item = {}
    return {
      // tableData: [],
      // 搜索框
      input: '',
      // 页数
      currentPage3: 1,
      total: 0, // 数据的总条数
      pageSize: 7,
      // 对话框
      outerVisibles: false,
      outerVisible: false,
      innerVisibles: false,
      innerVisible: false,
      dialogVisible: false,
      userBanned: false,
      dialogFormVisible: false,
      showOverflow: true,
      // 回复的内容
      replyContent: '',
      // 数据数组
      productionList: [],
      // 详情数据
      particularsData: [],
      // 点击的当前项
      clickItem: [],
      //举报详情内容
      productionData: {},
      //举报详情里的标签
      clickItemLabel: '',
      // 文章内容
      articleText: '',
      //作品的图片
      imagesLists: [],
      label: [],
      types: '',
      // 封号时长
      input2: '',
      zhuangtai: null,
      cause: '',
    }
  },
  props: {
    type: {
      type: String,
    },
    tableDatas: {
      type: Array,
    },
    updateTableData: {
      type: Function
      // require: true
    }
  },
  watch: {
    type (newData, oldData) {
      this.types = newData
      console.log(newData)
      console.log(oldData)
    },
  },
  created () {
  },
  methods: {
    // 关闭下架
    closexiajia () {
      this.cause = ''
    },

    //点击封号
    fenghao (data) {
      this.clickData = data
      this.zhuangtai = data.isFreeze
      this.dialogFormVisible = true
    },
    // 封号
    confirmBanned () {
      const that = this
      this.input2 = this.input2.replace(/[^\d]/g, '')
      if (this.input2 === '') {
        this.$message({
          type: 'error',
          message: '请输入封号时长且只能输入纯数字!',
        })
      } else {
        const data = {
          date: this.input2,
          id: this.clickItem.reportedUserId,
        }
        this.$axios.put('/api/user/blockUser', data).then((res) => {
          if (res.data.code === 200) {
            this.dialogFormVisible = false
            this.dialogVisible = false
            that.updateTableData()
            that.input2 = ''
            this.$message({
              type: 'success',
              message: '封号成功!',
            })
          } else {
            this.$message({
              type: 'error',
              message: res.data.message,
            })
          }
        })
      }
    },
    // 删除评论
    delComment () { },
    // 关闭对话框的时候
    closeBox () {
      if (this.productionData.imgs) {
        // this.$refs.banner.closeVideo()
      }
    },
    //打开对话框的时候
    openDetail () {
      // console.log('111')
      this.$axios
        .post(`/api/report/read?id=${this.clickItem.id}`)
        .then((res) => {
          if (res.data.code == 200) {
            // console.log('111')
          }
        })
    },
    // 点击违规详情
    getParticulars (e) {
      this.clickItem = e
      this.outerVisible = true
      this.clickItemLabel = e.reason[0]

      this.imagesLists = []
      this.label = []
      console.log(this.type)
      console.log(this.types)
      const that = this
      // 不同类型调不同的接口
      if (this.type == 'article') {
        this.$axios
          .get(`/api/tutorial/findDetails?id=${e.resourceId}`)
          .then((res) => {
            if (res.data.code == 200) {
              console.log(res.data.data)
              that.productionData = res.data.data
              fetch(res.data.data.text, {
                method: 'GET',
                mode: 'cors',
              })
                .then((res) => {
                  console.log(res)
                  return res.text()
                })
                .then((result) => {
                  that.articleText = result
                  // console.log(that.text)
                })
            }
          })
      }
      // if (this.type == 'comment') {
      //   this.$axios
      //     .get(`/api/tutorial/findDetails?id=${e.resourceId}`)
      //     .then((res) => {
      //       if (res.data.code == 200) {
      //         console.log(res.data.data)
      //         fetch(res.data.data.text, {
      //           method: 'GET',
      //           mode: 'cors',
      //         })
      //           .then((res) => {
      //             console.log(res)
      //             return res.text()
      //           })
      //           .then((result) => {
      //             that.articleText = result
      //             console.log(that.text)
      //           })
      //       }
      //     })
      // }
      if (this.type == 'resource') {
        this.$axios
          .get(`/api/resource/findDetails?id=${e.resourceId}`)
          .then(function (res) {
            if (res.data.code === 200) {
              that.productionData = res.data.data
              that.imagesLists = that.productionData.imgs.map(
                (item) => item.url
              )
              that.dialogLoading = false
              if (that.productionData.label === null) {
                return
              } else if (that.productionData.label.indexOf(',') != -1) {
                that.label = that.productionData.label.split(',')
                console.log(that.label)
              } else {
                that.label.push(that.productionData.label)
                console.log(that.label[0])
              }
            }
          })
      }
      if (this.type == 'produce') {
        this.$axios
          .get(`/api/production/findDetails?id=${e.resourceId}`)
          .then(function (res) {
            if (res.data.code === 200) {
              that.productionData = res.data.data
              that.imagesLists = that.productionData.picture.map(
                (item) => item.url
              )
              if (that.productionData.video !== null) {
                that.imagesLists.push(that.productionData.video.url)
              }
              if (that.productionData.label === null) {
                return
              } else if (that.productionData.label.indexOf(',') != -1) {
                that.label = that.productionData.label.split(',')
              } else {
                that.label.push(that.productionData.label)
              }
            }
          })
      }
      //课程
      // if (this.type == 'course') {
      // }

      // this.$axios.get(`/api/report/details?id=${e}`).then(function(res) {
      //   // console.log(res.data.data[0])
      //   if (res.data.data[0]) {
      //     that.particularsData = res.data.data[0]
      //     // console.log(that.particularsData)
      //   }
      // })
    },
    // 页码大小变动的时候触发
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
      this.currentPage3 = 1
      this.pageSize = val
    },
    // 当前页变动的时候触发
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`)
      this.currentPage3 = val
      this.updateTableData()
    },
    // 下架作品
    soldOutProduction () {
      const that = this
      let art = `/api/tutorial/banTutorial?id=${this.clickItem.resourceId}&reason=${this.cause}`
      let pro = `/api/production/banProduction?id=${this.clickItem.resourceId}&reason=${this.cause}`
      let res = `/api/resource/banResource?id=${this.clickItem.resourceId}&reason=${this.cause}`
      let url =
        this.type == 'article'
          ? art
          : this.type == 'produce'
            ? pro
            : this.type == 'resource'
              ? res
              : ``
      if (this.productionData.isFreeze == 1) {
        // let url =
        //   this.type == 'article'
        //     ? art
        //     : this.type == 'produce'
        //     ? pro
        //     : this.type == 'resource'
        //     ? res
        //     : ``
        // if(this.type=='article'){
        //   url=art
        // }else if(this.type == 'produce'){
        //   url=art
        // }else if(this.type == 'resource'){
        //   url=art
        // }
        that.$axios
          .put(
            url
            // `/api/production/banProduction?id=${this.clickItem.resourceId}&reason=${this.cause}`
          )
          .then(function (res) {
            // console.log(res.data)
            if (res.data.code === 200) {
              that.$message({
                message: '下架成功!',
                type: 'success',
              })
              this.innerVisible = false
              this.outerVisible = false
            } else {
              that.$message.error('下架失败!')
            }
          })
      } else if (this.productionData.isFreeze == 0) {
        that.$axios
          .put(
            url
            // `/api/production/banProduction?id=${
            //   this.clickItem.resourceId
            // }&reason=${null}`
          )
          .then(function (res) {
            // console.log(res.data)
            if (res.data.code === 200) {
              that.innerVisibles = false
              that.outerVisible = false
              that.$message({
                message: '操作成功!',
                type: 'success',
              })
              that.cause = ''
            } else {
              that.$message.error('取消下架失败!')
            }
          })
      }
    },
    // 取消下架
    // offSoldOutProduction() {
    //   const that = this
    //   // console.log(e)
    //   this.$axios
    //     .put(
    //       `/api/status/update?id=${this.clickItem.resourceId}&type=${
    //         e.type
    //       }&status=${1}`
    //     )
    //     .then(function(res) {
    //       // console.log(res.data)
    //       if (res.data.code === 200) {
    //         that.getProductionList()
    //         that.$message({
    //           message: '取消下架成功!',
    //           type: 'success',
    //         })
    //       } else {
    //         that.$message.error('取消下架失败!')
    //       }
    //     })
    // },
    // 状态下拉筛选
    filterTag (value, row) {
      // console.log(value, row)
      return row.isPublic === value
    },
    filterHandler (value, row, column) {
      // console.log(value, row, column)
      const property = column['property']
      return row[property] === value
    },
    // 回复举报人
    replyInformer () {
      const that = this
      if (this.replyContent === '') {
        this.$message({
          message: '请输入回复内容!',
          type: 'warning',
        })
      } else {
        // console.log(this.replyContent)
        // console.log(e)
        let data = {
          content: this.replyContent,
          target: this.clickItem.reportedUserId,
          userId: this.clickItem.userId,
        }
        console.log(data)
        this.$axios.post('/api/report/message', data).then(function (res) {
          console.log(res.data.data)
          if (res.data.code === 200) {
            that.$message({
              message: '回复成功!',
              type: 'success',
            })
            that.replyContent = ''
            that.outerVisible = false
            that.updateTableData()
          
          } else {
            that.$message.error('回复评论失败!')
          }
        })
      }
    },
    blockUser () { },
    // 表格内容居中
    thStyleFun () {
      return 'text-align:center;background:#DCDFE6;color:#303133'
    },
    cellStyleFun () {
      return 'text-align:center'
    },
  },
}
</script>
<style lang="less" scoped>
// 整个页面
.illegal {
  width: 100%;
  height: 100%;
}
// 对话框
.el-dialog {
  .report_name {
    font-size: 24px;
    font-family: PingFang-SC-Bold, PingFang-SC;
    color: #303133;
    line-height: 24px;
  }
  .report_box {
    width: 100%;
    height: 172px;
    background: #dcdfe6;
    overflow-y: auto;
    .report_inner_box {
      width: 96%;
      margin: 2% 2%;
      font-size: 16px;
    }
  }
  .reply_box {
    margin-top: 20px;
    width: 100%;
    height: 172px;
    background: #dcdfe6;
    textarea {
      width: 96%;
      min-height: 132px;
      border: none;
      margin: 2% 2%;
      padding: 0;
      outline: none;
      resize: none;
      background: #dcdfe6;
      font-size: 16px;
    }
  }
}
// 搜索框
.el-input {
  margin: 8px 1.5%;
  width: 97%;
}
//分页
.block {
  margin: 30px auto;
  text-align: center;
}
// 举报内容里面的图片
.production_img {
  width: 100%;
  height: 500px;
  overflow: auto;
  background: #dcdfe6;
  margin-bottom: 30px;
  img {
    width: 50%;
  }
}
//被举报的用户名和头像
.userName {
  height: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }
  span {
    position: relative;
    top: -10px;
    margin-left: 10px;
  }
}
// 标签
.tag_box {
  .el-tag {
    margin: 0 20px;
  }
}
.clickItemCover {
  background: #dcdfe6;
  margin-bottom: 20px;
  span {
    position: relative;
    top: -300px;
  }
  img {
    width: 50%;
    height: 50%;
  }
}
.xiangxixinxi {
  text-align: left;
}
</style>
