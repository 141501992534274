import { Message } from 'element-ui'
// 压缩图片的方法
export const compress = (img) => {
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    const initSize = img.src.length
    console.log('原始图片大小:' + initSize)
    const width = img.width
    const height = img.height
    canvas.width = 208
    canvas.height = (height / width) * 208
    // 铺底色
    ctx.fillStyle = '#fff'
    ctx.fillRect(0, 0, canvas.width, canvas.height)
    ctx.drawImage(img, 0, 0, canvas.width, canvas.height)

    // 进行最小压缩
    const ndata = canvas.toDataURL('image/jpeg', 1)
    return ndata
}

// 用户点击上传图片 进行预验证
export const beforeAvatarUpload = (file) => {
    const isJPG = (file.type === 'image/jpeg' || file.type === 'image/png')
    const isLt2M = file.size / 1024 / 1024 < 0.5
    if (!isJPG) {
        Message.error('上传头像图片只能是 JPG 或者PNG 格式!')
    }
    if (!isLt2M) {
        Message.error('上传头像图片大小不能超过 500KB!')
    }
    // 图片格式和大小 两者都 符合要求才返回 true 否则返回false
    return isJPG && isLt2M
}

// url转base64
export function getBase64Image (image, width, height) { // width、height调用时传入具体像素值，控制大小 ,不传则默认图像大小
    const canvas = document.createElement('canvas')
    canvas.width = width !== undefined ? width : image.width
    canvas.height = height !== undefined ? height : image.height
    const ctx = canvas.getContext('2d')
    ctx.drawImage(image, 0, 0, canvas.width, canvas.height)
    const ext = image.src.substring(image.src.lastIndexOf('.') + 1).toLowerCase()
    const dataURL = canvas.toDataURL('image/' + ext)
    return dataURL
}

// base64转file
export function dataURLtoFile (dataurl, filename) {
    const arr = dataurl.split(',')
    const mime = arr[0].match(/:(.*?);/)[1]
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
    }
    return new File([u8arr], filename, { type: mime })
}

// url 转 file
export const imgUrlToFile = (url, callback) => {
    console.log(url)
    // const image = document.createElement('img')
    const image = new Image()
    image.crossOrigin = 'Anonymous' // 必须有这个
    image.src = url
    // image.setAttribute('crossOrigin', 'Anonymous')
    image.onload = () => {
        // 图片加载完成后，调用getBase64Image方法
        const base64 = getBase64Image(image)
        console.log(base64)
        const filename = Date.parse(new Date()) + '.png'
        const file = dataURLtoFile(base64, filename)
        console.log(file)
        // 回调函数，参数是最终生成的文件
        callback(file)
    }
}
// ************************
function S4 () {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
}
// 生成唯一值的 工具类
export const guid = () => {
    return (S4() + S4() + '-' + S4() + '-' + S4() + '-' + S4() + '-' + S4() + S4() + S4())
}
// ************************************
// 工具类 获取文件扩展名 （不含点）
export const getFileType = (filePath) => {
    var startIndex = filePath.lastIndexOf('.')
    if (startIndex !== -1) { return filePath.substring(startIndex + 1, filePath.length).toLowerCase() } else return ''
}
// ****************************************
// 复制文本
export const copyText = (content) => {
    const textarea = document.createElement('textarea')
    textarea.value = content
    document.body.appendChild(textarea)
    textarea.select()
    if (document.execCommand('copy')) {
        document.execCommand('copy')
        document.body.removeChild(textarea)
        return true
    }
    return false
}
// ****************************************
/**
 * 获取 blob
 * url 目标文件地址
 */
function getBlob (url) {
    return fetch(url, {
        method: 'GET',
        mode: 'no-cors'
    })
        .then(res => {
            return res.blob()
        })
}
export const downloadAndRename = (url, filename) => {
    try {
        getBlob(url).then(blob => {
            if (window.navigator.msSaveOrOpenBlob) {
                navigator.msSaveBlob(blob, filename)
            } else {
                const link = document.createElement('a')
                const body = document.querySelector('body')
                link.href = window.URL.createObjectURL(blob)
                link.download = filename
                // fix Firefox
                link.style.display = 'none'
                body.appendChild(link)
                link.click()
                body.removeChild(link)
                window.URL.revokeObjectURL(link.href)
            }
        })
    } catch (error) {
        console.log(error)
    }
}
// 回到顶部的方法
export const goTop = () => {
    // 设置定时器
    const timer = setInterval(function () {
        // 获取滚动条距离顶部高度
        const osTop = document.documentElement.scrollTop || document.body.scrollTop
        const ispeed = Math.floor(-osTop / 7)
        document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed
        // 到达顶部，清除定时器
        if (osTop === 0) {
            clearInterval(timer)
        }
    }, 30)
    // 监听鼠标滚动事件，清除定时器
    window.onmousewheel = document.body.onmousewheel = function () {
        window.clearInterval(timer)
    }
}
