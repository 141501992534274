<template>
  <div class="index">
    <transition name="el-zoom-in-center">
      <div class="index_header">
        <div class="production shake-slow">
          <p>项目总数</p>
          <span>{{ mainCount.projectTotal }}</span>
        </div>
        <div class="log shake-slow">
          <p>任务总数</p>
          <span>{{ mainCount.itemTotal }}</span>
        </div>
        <div class="resource shake-slow">
          <p>项目新增</p>
          <span>{{ mainCount.projectAdd }}</span>
        </div>
        <div class="user shake-slow">
          <p>任务新增</p>
          <span>{{ mainCount.itemAdd }}</span>
        </div>
        <div class="member shake-slow">
          <p>项目完成数</p>
          <span>{{ mainCount.completeProject }}</span>
        </div>
        <div class="member shake-slow">
          <p>任务完成数</p>
          <span>{{ mainCount.completeTask }}</span>
        </div>
      </div>
    </transition>
  </div>
</template>
<style lang="less" scoped>
.box {
  width: 746px;
  height: 430px;
  float: left;
  margin: 0 16px 0 57px;
}
.index_header {
  width: 100%;
  display: flex;
  div {
    // float: left;
    margin: 24px 12px;
    p {
      margin: 20px 0 16px 16px;
      font-family: PingFang-SC-Bold, PingFang-SC;
      color: #ffffff;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
  }

  .production,
  .log,
  .resource,
  .course,
  .user,
  .member {
    position: relative;
    width: 240px;
    height: 135px;
    background: linear-gradient(135deg, #fcc687 0%, #f286a0 100%);
    border-radius: 24px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    span {
      font-size: 32px;
      font-family: DINAlternate-Bold, DINAlternate;
      // font-weight: bold;
      color: #ffffff;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .log {
    background: linear-gradient(90deg, #d4fc79 0%, #96e6a1 100%);
  }
  .resource {
    background: linear-gradient(90deg, #c2e9fb 0%, #a1c4fd 100%);
  }
  .course {
    background: linear-gradient(90deg, #bb9bf1 0%, #887bf2 100%);
  }
  .user {
    background: linear-gradient(90deg, #fbc2eb 0%, #a18cd1 100%);
  }
  .member {
    background: linear-gradient(90deg, #7ffed8 0%, #09bdfe 100%);
  }
}
.name {
  width: 105px;
  position: relative;
  top: 0px;
  z-index: 100;
  left: 75%;
}
</style>
<script>
import "csshake";
export default {
  data() {
    return {
      mainCount: {},
    };
  },
  methods: {
    Costing() {
      this.$axios.get(`/api/creators/getProjectDetail`).then((res) => {
        if (res.data.code == 200) {
          this.mainCount = res.data.data;
        }
      });
    }, 


 
  },
  created() {
  },
  mounted() {
    this.Costing();
  },
};
</script>