import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/store.js'
import { Notification } from 'element-ui'
import axios from '@/axios/axios.js'

//  下面是组件 和 视图
import Login from '@/views/Login.vue'
import Index from '@/views/Index.vue'
import Center from '@/components/center/center.vue'
import AlterPsw from '@/components/alterPsw/alterPsw.vue'
import Production from '@/components/production/production.vue'
import Job from '@/components/job/job.vue'
import Log from '@/components/log/log.vue'
import Mess from '@/components/message/mess.vue'
import Message from '@/components/message/message.vue'
import SystemMessage from '@/components/message/systemMessage.vue'
import Resource from '@/components/resource/resource.vue'
import User from '@/components/user/user.vue'
// import Demo from '@/components/demo/demo.vue'
import Illegal from '@/components/illegal/illegal.vue'
import Article from '@/components/illegal/article/article.vue'
import Comment from '@/components/illegal/comment/comment.vue'
import Course from '@/components/illegal/course/course.vue'
import Produce from '@/components/illegal/produce/produce.vue'
import Resources from '@/components/illegal/resource/resource.vue'
import Sets from '@/components/set/set.vue'
import Examinee from '@/components/examinee/examinee.vue'
import Home from '@/components/home/home.vue'
import Topic from '@/components/topic/topic.vue'
import Task from '@/components/task/task.vue'
// import Activity from '@/components/activity/activity.vue';
import Activitys from '@/components/activitys/activitys.vue'
import Creator from '@/components/creator/creator.vue'
import CreatorApply from '@/components/creator/creatorApply.vue'
import Items from '@/components/creator/Items.vue'
import Statis from '../components/creator/statis.vue'
import Prostatis from '../components/creator/prostatis.vue'
import ProCost from '../components/creator/proCost.vue'
import Itemtask from '../components/creator/itemtask.vue'
Vue.use(VueRouter)

//  初始路由, 未登录的情况
export const originalRoutes = [
    {
        path: '/',
        redirect: '/index',
        name: 'root'
    },
    {
        path: '/login',
        name: 'login',
        component: Login
    },
    {
        path: '/index',
        name: 'index',
        component: Index,
        redirect: '/home',
        children: [
            {
                path: '/home',
                name: 'home',
                component: Home
            }
        ]
    }
]
//  全部路由信息， 可根据角色生成对应角色的路由
export const fullRoutes = [
    {
        path: '/',
        redirect: '/index',
        name: 'root',
        meta: {
            //  页面菜单栏 标题
            title: '根页面',
            // 菜单栏图表class
            icon: 'el-icon-user',
            // 是否在菜单中用到
            useInMenu: false,
            // 超管: super  | 运营: operating | 客服: service | 财务: financial | 考务: take
            // ['super', 'financial', 'take', 'operating', 'service']
            //  空组数代表 所有用户都有这个权限
            roles: [
            ]
        },
    },

    {
        path: '/index',
        name: 'index',
        component: Index,
        redirect: '/center',
        meta: {
            title: 'index页面',
            icon: 'el-icon-user',
            useInMenu: true,
            roles: [

            ]
        },
        children: [
            {
                path: '/center',
                name: 'center',
                component: Center,
                meta: {
                    title: '工作台',
                    icon: 'el-icon-user',
                    useInMenu: false,
                    roles: [
                    ]
                }
            },
            {
                path: '/home',
                name: 'home',
                component: Home,
                meta: {
                    title: '平台运营报表',
                    // icon: 'el-icon-user',
                    useInMenu: true,
                    roles: [
                        'super'
                    ]
                }
            },
            {
                path: '/set',
                name: 'set',
                component: Sets,
                meta: {
                    title: '控制台账号管理',
                    // icon: 'el-icon-user',
                    useInMenu: true,
                    roles: [
                        'super'
                    ]
                }
            },
            {
                path: '/user',
                name: 'user',
                component: User,
                meta: {
                    title: '用户管理',
                    icon: 'iconfont icon-yonghufill',
                    useInMenu: true,
                    roles: [
                        'operating'
                    ]
                }
            },
            {
                path: '/creator',
                name: 'creator',
                component: Creator,
                meta: {
                    title: '创作者中心',
                    icon: 'iconfont icon-chuangzuo',
                    useInMenu: true,
                    roles: [
                        'financial',
                        'service',
                        'operating'
                    ]
                },
                children: [
                    {
                        path: '/creator/apply',
                        name: 'apply',
                        component: CreatorApply,
                        meta: {
                            title: '创作者审核',
                            icon: 'iconfont icon-shenhe',
                            roles: [
                                'service',
                                'operating'
                            ]
                        },
                    },
                    {
                        path: '/creator/items',
                        name: 'items',
                        component: Items,
                        meta: {
                            title: '上下架管理',
                            icon: 'iconfont icon-shangxiazhankai',
                            roles: [
                                'service',
                                'operating'
                            ]
                        }
                    },
                    {
                        path: '/creator/statis',
                        name: 'statis',
                        component: Statis,
                        meta: {
                            title: '财务管理',
                            icon: 'iconfont icon-caiwu',
                            roles: [
                                'financial',
                            ]
                        }
                    },
                    {
                        path: '/creator/prostatis',
                        name: 'prostatis',
                        component: Prostatis,
                        meta: {
                            title: '任务管理',
                            icon: 'iconfont icon-renwu',
                            roles: [

                                'service',
                                'operating'
                            ]
                        }
                    },
                    {
                        path: '/creator/itemtask',
                        name: 'itemtask',
                        component: Itemtask,
                        meta: {
                            title: '项目明细',
                            icon: 'iconfont icon-xiaofeimingxidan',
                            roles: [

                                'service',
                                'operating'
                            ]
                        }
                    },
                    {
                        path: '/creator/proCost',
                        name: 'proCost',
                        component: ProCost,
                        meta: {
                            title: '收入明细',
                            icon: 'iconfont icon-xiaofeimingxidan',
                            roles: [
                                'financial',
                            ]
                        }
                    }
                ]
            },
            {
                path: '/activitys',
                name: 'activitys',
                component: Activitys,
                meta: {
                    title: '活动管理',
                    icon: 'iconfont icon-huodongxiangqu',
                    useInMenu: true,
                    roles: [
                        'service',
                        'operating'
                    ]
                }
            },
            {
                path: '/task',
                name: 'task',
                component: Task,
                meta: {
                    title: '任务管理',
                    icon: 'iconfont icon-renwu',
                    useInMenu: true,
                    roles: [
                        'service',
                        'operating'
                    ]
                }
            },
            {
                path: '/mess',
                name: 'mess',
                component: Mess,
                meta: {
                    title: '系统消息管理',
                    icon: 'iconfont icon-shouye',
                    useInMenu: true,
                    roles: [
                        'service',
                        'operating'
                    ]
                },
                children: [
                    {
                        path: '/mess/systemMessage',
                        name: 'systemMessage',
                        component: SystemMessage,
                        meta: {
                            title: '系统发送消息',
                            icon: 'iconfont icon-shouye',
                            roles: [
                                'service',
                                'operating'
                            ]
                        }
                    },
                    {
                        path: '/mess/message',
                        name: 'message',
                        component: Message,
                        meta: {
                            title: '系统消息管理',
                            icon: 'iconfont icon-shouye',
                            roles: [
                                'service',
                                'operating'
                            ]
                        }
                    }

                ]
            },
            {
                path: '/production',
                name: 'production',
                component: Production,
                meta: {
                    title: '作品管理',
                    icon: 'iconfont icon-zuopin',
                    useInMenu: true,
                    roles: [
                        'service',
                        'operating'
                    ]
                }
            },
            {
                path: '/log',
                name: 'log',
                component: Log,
                meta: {
                    title: '文章管理',
                    icon: 'iconfont icon-16',
                    useInMenu: true,
                    roles: [
                        'service',
                        'operating'
                    ]
                }
            },
            {
                path: '/resource',
                name: 'resource',
                component: Resource,
                meta: {
                    title: '资源管理',
                    icon: 'iconfont icon-ziyuan',
                    useInMenu: true,
                    roles: [
                        'service',
                        'operating'
                    ]
                }
            },
            {
                path: '/alterPsw',
                name: 'alterPsw',
                component: AlterPsw,
                meta: {
                    title: '修改密码',
                    icon: 'iconfont',
                    //  修改密码不用加入到 菜单中
                    useInMenu: false,
                    roles: [

                    ]
                }
            },
            {
                path: '/illegal',
                name: 'illegal',
                component: Illegal,
                meta: {
                    title: '违规管理',
                    icon: 'iconfont icon-weiguiwenzhangpuguangtai ',
                    useInMenu: true,
                    roles: [
                        'service',
                        'operating'
                    ]
                },
                children: [
                    // 违规管理
                    {
                        path: '/illegal/article',
                        name: 'article',
                        component: Article,
                        meta: {
                            title: '文章违规',
                            icon: 'iconfont icon-weiguiwenzhangpuguangtai',
                            roles: [
                                'service',
                                'operating'
                            ]
                        }
                    },
                    {
                        path: '/illegal/comment',
                        name: 'comment',
                        component: Comment,
                        meta: {
                            title: '评论违规',
                            icon: 'iconfont icon-weiguiwenzhangpuguangtai',
                            roles: [
                                'service',
                                'operating'
                            ]
                        }
                    },
                    {
                        path: '/illegal/course',
                        name: 'course',
                        component: Course,
                        meta: {
                            title: '课程违规',
                            icon: 'iconfont icon-weiguiwenzhangpuguangtai',
                            roles: [
                                'service',
                                'operating'
                            ]
                        }
                    },
                    {
                        path: '/illegal/produce',
                        name: 'produce',
                        component: Produce,
                        meta: {
                            title: '作品违规',
                            icon: 'iconfont icon-weiguiwenzhangpuguangtai',
                            roles: [
                                'service',
                                'operating'
                            ]
                        }
                    },
                    {
                        path: '/illegal/resources',
                        name: 'resources',
                        component: Resources,
                        meta: {
                            title: '资源违规',
                            icon: 'iconfont icon-weiguiwenzhangpuguangtai',
                            roles: [
                                'service',
                                'operating'
                            ]
                        }
                    }
                ]
            },
            {
                path: '/job',
                name: 'job',
                component: Job,
                meta: {
                    title: '职位管理',
                    icon: 'iconfont icon-zhiwei',
                    useInMenu: true,
                    roles: [
                        'service',
                        'operating'
                    ]
                }
            },
            {
                path: '/topic',
                name: 'topic',
                component: Topic,
                meta: {
                    title: '1-x理论题目管理',
                    icon: 'iconfont icon-jiatimianban_timu',
                    useInMenu: true,
                    roles: [
                        'take'
                    ]
                }
            },
            {
                path: '/examinee',
                name: 'examinee',
                component: Examinee,
                meta: {
                    title: '考生管理',
                    icon: 'iconfont icon-weiguiwenzhangpuguangtai',
                    useInMenu: true,
                    roles: [
                        'take'
                    ]
                }
            }
        ]
    },
    {
        path: '*',
        name: '404',
        component: () => import('@/views/Page404.vue'),
        meta: {
            roles: []
        }
    }
]
export const page404 = {
    path: '*',
    name: '404',
    component: () => import('@/views/Page404.vue'),
    meta: {
        roles: []
    }
}
const router = new VueRouter({
    mode: 'history',
    scrollBehavior: () => ({ y: 0 }),
    routes: originalRoutes
})

// 重置路由实例的方法
export function resetRouter (routes) {
    const newRouter = new VueRouter({
        mode: 'history',
        scrollBehavior: () => ({ y: 0 }),
        routes: routes
    })
    // 使用addRouter 方法进行路由的更新，是不会更新 相同path 和name的路由的，所以需要重新 new 一个 Router 实例来获取新的matcher
    router.matcher = newRouter.matcher // reset router
}
// 导航守卫
router.beforeEach(async (to, from, next) => {
    const token = window.localStorage.getItem('user')
    if (store.getters.hasInit === false && token !== '' && token !== null) {
        //  有token 并且未初始化，验证一下token 尝试登录
        console.log('未初始化')
        console.log(store.getters.hasInit)
        axios.get('api/admin/Resshlogin').then(res => {
            console.log(res)
            if (res.data.code === 200) {
                store.dispatch('login', res.data.data).then((path) => {
                    router.push(path)
                })
            } else {
                // token 过期或者不正确
                const routes = originalRoutes.concat([page404])
                router.addRoute(page404)
                store.commit('saveRoutes', routes)
                store.commit('saveHasInit', true)
                store.commit('saveIsLogin', false)
                // 需要重新进行路由定位
                // next({ ...to, replace: true })
                Notification({
                    title: '重新登录',
                    message: '登录过期',
                    type: 'info'
                })
                router.push('/login')
            }
        }).catch(err => {
            console.log(err)
            const routes = originalRoutes.concat([page404])
            router.addRoute(page404)
            store.commit('saveRoutes', routes)
            store.commit('saveHasInit', true)
            store.commit('saveIsLogin', false)
            // 需要重新进行路由定位
            // next({ ...to, replace: true })
            Notification({
                title: '重新登录',
                message: '登录过期',
                type: 'info'
            })
            router.push('/login')
        })
    } else if (store.getters.hasInit === false) {
        const routes = originalRoutes.concat([page404])
        router.addRoute(page404)
        store.commit('saveRoutes', routes)
        store.commit('saveHasInit', true)
        store.commit('saveIsLogin', false)
        // 需要重新进行路由定位
        // next({ ...to, replace: true })
        Notification({
            title: '请先登录',
            message: '未登录',
            type: 'info'
        })
        router.push('/login')
    } else {
        // 已经初始化，放行
        next()
    }
})

export default router