<template>
  <div>
    <div>
      <el-table :data="page.items" stripe style="width: 100%">
        <el-table-column label="序号" width="100">
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column prop="nickname" label="昵称" width="180">
        </el-table-column>
        <el-table-column label="头像" width="120">
          <template slot-scope="scope">
            <el-avatar
              size="medium"
              :src="
                scope.row.imgurl === null
                  ? require('@/assets/images/xiaoxin.svg')
                  : scope.row.imgurl
              "
            ></el-avatar>
          </template>
        </el-table-column>
        <el-table-column prop="money" label="应打款金额" width="180">
        </el-table-column>
        <el-table-column prop="cards" label="银行卡号" width="180">
        </el-table-column>
        <el-table-column prop="realname" label="真实姓名" width="180">
        </el-table-column>
        <el-table-column label="状态" width="180">
          <template slot-scope="scope">
            {{
              scope.row.status === 0
                ? "未处理"
                : scope.row.status == 1
                ? "已到账"
                : ""
            }}
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="提交时间" width="180">
        </el-table-column>
        <el-table-column label="操作" width="300" fixed="right">
          <template slot-scope="scope">
            <el-button
              size="mini"
              :disabled="scope.row.status == 1"
              @click="handSubmit(scope.$index, scope.row.id)"
              >{{ scope.row.status == 0 ? "立即打款" : "已打款" }}</el-button
            >
            <el-button
              size="mini"
              @click="
                showDetail = true;
                handleDetail(scope.row.userId);
              "
              >账户详情</el-button
            >
            <el-button
              size="mini"
              v-if="scope.row.img !== '0'"
              @click="
                showImg = true;
                img = scope.row.img;
              "
              >打款截图</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="block">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="current"
        :page-size="size"
        layout="prev, pager, next, jumper"
        :total="page.total"
        :page-count="page.totalPage"
      >
      </el-pagination>
    </div>
    <el-dialog title="打款上传记录" :visible.sync="show">
      <el-form :model="form">
        <el-form-item label="订单截图" label-width="120px">
          <el-upload
            class="avatar-uploader"
            action=""
            fit="fill"
            :show-file-list="false"
            :http-request="upload"
          >
            <img v-if="imageUrl" :src="imageUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="show = false">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="账户详情"
      :visible.sync="showDetail"
      @close="com.current = 1"
    >
      <el-table :data="pageDeatil.items" style="width: 100%">
        <el-table-column prop="time" label="时间" width="180">
        </el-table-column>
        <el-table-column label="类型" width="180">
          <template slot-scope="scope">
            {{
              scope.row.type === 0
                ? "提现"
                : scope.row.type === 2
                ? "项目收入"
                : ""
            }}
          </template>
        </el-table-column>
        <el-table-column label="本次金额" width="180">
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.type === 0"
              >-{{ scope.row.money }}</el-tag
            >
            <el-tag type="danger" v-if="scope.row.type === 2"
              >+{{ scope.row.money }}</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column prop="canMoney" label="可提现余额"> </el-table-column>
      </el-table>
      <el-pagination
        @current-change="handleCurrentChange1"
        :current-page.sync="com.current"
        :page-size="com.size"
        layout="prev, pager, next, jumper"
        :total="pageDeatil.total"
        :page-count="pageDeatil.totalPage"
      >
      </el-pagination>
    </el-dialog>
    <el-dialog title="打款截图" :visible.sync="showImg">
      <img :src="img" />
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tableData: [],
      size: 10,
      current: 1,
      page: {},
      com: {
        current: 1,
        size: 10,
      },
      show: false,
      form: {
        img: "",
        id: "",
      },
      imageUrl: "",
      flag: true,
      pageDeatil: {
        items: [],
      },
      showDetail: false,
      id: "",
      showImg: false,
      img: "",
    };
  },
  created() {
    this.hetOrder();
  },
  methods: {
    handSubmit(data, data1) {
      this.show = true;
      this.form.id = data1;
      console.log(data, data1);
    },
    handleDetail(data) {
      this.id = data;
      this.$axios
        .get(
          "/api/creators/getUserDeatial?id=" +
            data +
            "&current=" +
            this.com.current +
            "&size=" +
            this.com.size
        )
        .then((res) => {
          if (res.data.code === 200) {
            this.pageDeatil = res.data.data;
          }
        });
    },
    handleSizeChange(val) {
      this.current = 1;
      this.pageSize = val;
      this.hetOrder();
    },

    // 当前页变动的时候触发
    handleCurrentChange(val) {
      this.current = val;
      this.hetOrder();
    },

    // 当前页变动的时候触发
    handleCurrentChange1(val) {
      this.com.current = val;
      this.handleDetail(this.id);
    },
    upload(file) {
      const fd = new FormData();
      var configs = {
        headers: { "Content-Type": "multipart/form-data" },
      };
      fd.append("imgFile", file.file);
      this.$axios.post("/api/common/myupload", fd, configs).then((res) => {
        if (res.data.code === 200) {
          this.form.img = res.data.data.name;
          this.imageUrl = res.data.data.img;
        }
      });
    },
    save() {
      if (this.flag) {
        if (this.form === {}) {
          return;
        }
        this.flag = false;

        if (this.form.img === "") {
          this.$message.error("请上传订单截图");
        } else {
          this.$axios
            .post("/api/creators/getUpload", this.form)
            .then((res) => {
              if (res.data.code === 200) {
                this.show = false;
                this.$message.success(res.data.message);
                this.hetOrder();
                this.form = {};
                this.imageUrl = "";
              }
            })
            .finally(() => {
              this.flag = true;
            });
        }
      }
    },

    hetOrder() {
      this.$axios
        .get(
          "/api/creators/getCost?current=" +
            this.current +
            "&size=" +
            this.size +
            "&type=1"
        )
        .then((res) => {
          if (res.data.code == 200) {
            this.page = res.data.data;
          }
        });
    },
  },
};
</script>
<style lang="less">
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>