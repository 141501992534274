import Vue from 'vue'
import ElementUI from 'element-ui'
// 样式初始化
import 'normalize.css/normalize.css'
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/css/index.less'
import './assets/icon/iconfont.css'
import router from '@/router/router.js'
import axios from '@/axios/axios.js'
import store from '@/store/store.js'
import App from '@/App.vue'
import * as echarts from 'echarts'
import socket from '@/socket/socket.js'

// Vue.directive('throttle', {
//     bind: (el, binding) => {
//         let throttleTime = binding.value // 节流时间
//         if (!throttleTime) { // 用户若不设置节流时间，则默认2s
//             throttleTime = 1000
//         }
//         let cbFun
//         el.addEventListener('click', event => {
//             if (!cbFun) { // 第一次执行
//                 cbFun = setTimeout(() => {
//                     cbFun = null
//                 }, throttleTime)
//             } else {
//                 event && event.stopImmediatePropagation()
//             }
//         }, true)
//     },
// })

Vue.prototype.$echarts = echarts
Vue.prototype.$socket = socket
// Vue.prototype.$store = store
Vue.prototype.$axios = axios
Vue.config.productionTip = false
Vue.use(ElementUI)

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
