<template>
    <div id="takeWorkCenter">
        <section class="content">
            <h2>考务工作台</h2>
             <h3>暂时无快办事项，请等待~~</h3>
        </section>
    </div>
</template>

<script>
export default {
    data () {
        return {

        }
    },
    created () {

    },
    mounted () {

    },
    methods: {

    }
}
</script>

<style lang="less" scoped>
#takeWorkCenter {
    display: flex;
    width: 100%;
}
.content {
    flex-basis: 500px;
    flex-grow: 1;
    h2 {
        text-align: center;
    }
}
.right_illustration {
    margin-top: 15vh;
    margin-left: 12px;
    margin-right: 12px;
    width: 300px;
    height: auto;
    border-radius: 10px;
    overflow: hidden;
    img {
        width: 100%;
    }
}
</style>
