<template>
  <div class="examinee">
    <div class="oinput">
      <el-input
        class="inputs"
        placeholder="请输入考生号"
        prefix-icon="el-icon-search"
        v-model="input"
        @keyup.enter.native="filterData"
      >
      </el-input>
      <el-button type="primary" @click="filterData">搜索</el-button>
      <el-button type="primary" @click="postFlag = true"
        >导入考生信息</el-button
      >
      <el-button
        size="medium"
        @click="deriveperformance = true"
        style="width: 6.25%; height: 40px"
        type="primary"
        >导出成绩</el-button
      >
      <el-button type="danger" @click="delFilter = true">删除选中</el-button>
    </div>

    <el-main>
      <el-table
        v-loading="loading"
        :data="tableData"
        :header-cell-style="thStyleFun"
        @selection-change="handleSelectionChange"
        :cell-style="cellStyleFun"
        @filter-change="filterChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column prop="examineeName" label="学生姓名" width="80">
        </el-table-column>
        <el-table-column prop="examineeNumber" label="考生编号" width="185">
        </el-table-column>
        <el-table-column prop="inspectionSite" label="所属考核站点">
        </el-table-column>
        <el-table-column
          prop="pilotCollege"
          label="所属试点院校"
          :filters="pilotColleges"
          :filter-multiple="false"
          filter-placement="bottom-end"
          column-key="pilotColleges"
          width="155"
        >
          <template slot="header">
            所属试点院校
            <i style="font-size: 12px" class="icon iconfont icon-jiantou1"></i>
          </template>
        </el-table-column>
        <el-table-column prop="roomName" label="考场名称" width="155">
        </el-table-column>
        <el-table-column prop="status" label="排考状态" width="155">
        </el-table-column>
        <el-table-column
          prop="examinationPlan"
          label="考试计划"
          :filters="examinationPlans"
          :filter-multiple="false"
          filter-placement="bottom-end"
          column-key="examinationPlan"
          width="155"
        >
          <template slot="header">
            考试计划
            <i style="font-size: 12px" class="icon iconfont icon-jiantou1"></i>
          </template>
        </el-table-column>
        <el-table-column prop="seatNumber" label="座位号" width="155">
        </el-table-column>
        <el-table-column prop="grade" label="证书等级" width="155">
        </el-table-column>
        <el-table-column
          prop="subjects"
          label="报考科目"
          width="155"
          :filters="subjects"
          :filter-multiple="false"
          filter-placement="bottom-end"
          column-key="subjects"
        >
          <template slot="header">
            报考科目
            <i style="font-size: 12px" class="icon iconfont icon-jiantou1"></i>
          </template>
        </el-table-column>
        <el-table-column prop="score" label="分数" width="155">
        </el-table-column>
        <el-table-column prop="certificate" label="证书" width="155">
        </el-table-column>
        <el-table-column prop="examNumber" label="准考证号" width="155">
        </el-table-column>
        <el-table-column prop="assessmentUnit" label="考核单位" width="155">
        </el-table-column>
        <el-table-column prop="idNumber" label="身份证号" width="155">
        </el-table-column>
        <el-table-column prop="startTime" label="开始时间" width="155">
        </el-table-column>
        <el-table-column prop="endTime" label="结束时间" width="155">
        </el-table-column>
        <el-table-column
          prop="operation"
          label="操作"
          width="185"
          fixed="right"
        >
          <template slot-scope="scope">
            <el-link :underline="false" type="primary" @click="alter(scope.row)"
              >修改</el-link
            >
            <span
              style="
                font-size: 12px;
                margin: 0 15.6px;
                color: #000000;
                opacity: 0.3;
              "
              >|</span
            >
            <el-link
              :underline="false"
              type="primary"
              @click="detailItem(scope.row)"
              >查看</el-link
            >
            <span
              style="
                font-size: 12px;
                margin: 0 15.6px;
                color: #000000;
                opacity: 0.3;
              "
              >|</span
            >
            <el-link :underline="false" type="danger" @click="del(scope.row.id)"
              >删除</el-link
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 50, 100, 200, 500]"
          :current-page.sync="currentPage3"
          :page-size="pageSize"
          layout="total,sizes,prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
      <!-- <div class="block">
    <span class="demonstration">调整每页显示条数</span>
    <el-pagination
      @current-change="handleCurrentChange"
      :current-page.sync="currentPage2"
      :page-sizes="[100, 200, 300, 400]"
      :page-size="100"
      layout="sizes, prev, pager, next"
      :total="1000">
    </el-pagination>
  </div> -->
    </el-main>
    <!-- 查看对话框 -->
    <el-dialog title="考生详情" :visible.sync="detail" append-to-body>
      <div class="content">
        <div class="left">
          <div>
            学生姓名:<span>{{ detailData.examineeName }}</span>
          </div>
          <div>
            考生编号:<span>{{ detailData.examineeNumber }}</span>
          </div>
          <div>
            所属考核站点:<span>{{ detailData.inspectionSite }}</span>
          </div>
          <div>
            所属试点院校:<span>{{ detailData.pilotCollege }}</span>
          </div>
          <div>
            考场名称:<span>{{ detailData.roomName }}</span>
          </div>
          <div>
            排考状态:<span>{{ detailData.status }}</span>
          </div>
          <div>
            考试计划:<span>{{ detailData.examinationPlan }}</span>
          </div>
          <div>
            座位号:<span>{{ detailData.seatNumber }}</span>
          </div>
          <div>
            证书等级:<span>{{ detailData.grade }}</span>
          </div>
        </div>
        <div class="right">
          <div>
            报考科目:<span>{{ detailData.subjects }}</span>
          </div>
          <div>
            分数:<span>{{ detailData.score }}</span>
          </div>
          <div>
            证书:<span>{{ detailData.certificate }}</span>
          </div>
          <div>
            准考证号:<span>{{ detailData.examNumber }}</span>
          </div>
          <div>
            测评单位:<span>{{ detailData.assessmentUnit }}</span>
          </div>
          <div>
            身份证号:<span>{{ detailData.idNumber }}</span>
          </div>
          <div>
            开始时间:<span>{{ detailData.startTime }}</span>
          </div>
          <div>
            考试时间:<span>{{ detailData.endTime }}</span>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="detail = false">取 消</el-button>
        <el-button type="primary" @click="detail = false">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 修改对话框 -->
    <el-dialog title="修改考生信息" :visible.sync="alterFlag" append-to-body>
      <div class="alter">
        <div class="left">
          <div>
            <span>学生姓名:</span>
            <el-input v-model="alterInput.examineeName"></el-input>
          </div>
          <div>
            <span>考生编号:</span>
            <el-input v-model="alterInput.examineeNumber"></el-input>
          </div>
          <div>
            <span>所属考核站点:</span>
            <el-input v-model="alterInput.inspectionSite"></el-input>
          </div>
          <div>
            <span>所属试点院校:</span>
            <el-input v-model="alterInput.pilotCollege"></el-input>
          </div>
          <div>
            <span>考场名称:</span>
            <el-input v-model="alterInput.roomName"></el-input>
          </div>
          <div>
            <span>排考状态:</span>
            <el-input v-model="alterInput.status"></el-input>
          </div>
          <div>
            <span>考试计划:</span>
            <el-input v-model="alterInput.examinationPlan"></el-input>
          </div>
          <div>
            <span>座位号:</span>
            <el-input v-model="alterInput.seatNumber"></el-input>
          </div>
          <div>
            <span>证书等级:</span>
            <el-input v-model="alterInput.grade"></el-input>
          </div>
        </div>
        <div class="right">
          <div>
            <span>报考科目:</span>
            <el-input v-model="alterInput.subjects"></el-input>
          </div>
          <div>
            <span>分数:</span>
            <el-input v-model="alterInput.score"></el-input>
          </div>
          <div>
            <span>证书:</span>
            <el-input v-model="alterInput.certificate"></el-input>
          </div>
          <div>
            <span>准考证号: </span>
            <el-input v-model="alterInput.examNumber"></el-input>
          </div>
          <div>
            <span>测评单位:</span>
            <el-input v-model="alterInput.assessmentUnit"></el-input>
          </div>
          <div>
            <span>身份证号:</span>
            <el-input v-model="alterInput.idNumber"></el-input>
          </div>
          <div>
            <span>开始时间:</span>
            <el-input v-model="alterInput.startTime"></el-input>
          </div>
          <div>
            <span>考试时间:</span>
            <el-input v-model="alterInput.endTime"></el-input>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="alterFlag = false">取 消</el-button>
        <el-button type="primary" @click="alterData">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 删除 -->
    <el-dialog title="删除考生信息" :visible.sync="delflag" append-to-body>
      <div class="del">确定删除此学生的考试信息吗？</div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="delflag = false">取 消</el-button>
        <el-button type="primary" @click="delOne">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="导入考生信息" :visible.sync="postFlag" append-to-body>
      <div class="post">
        请选择文件
        <!-- accept="" -->
        <el-upload
          id="uploadImage"
          class="upload-demo"
          drag
          action="https://jsonplaceholder.typicode.com/posts/"
          :on-preview="handlePictureCardPreview"
          :auto-upload="false"
          :on-remove="handleRemove"
          :limit="1"
          :on-change="handleChansge"
        >
          <div class="el-upload__text">
            将文件拖到此处，或点击上传<br />
            <span>支持表格格式</span><br />
          </div>
          <!-- <div class="el-upload__tip" slot="tip">
          只能上传jpg/png文件，且不超过500kb
        </div> -->
        </el-upload>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="postFlag = false">取 消</el-button>
        <el-button type="primary" @click="postFile">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 导出成绩 -->
    <el-dialog
      width="800px"
      title="导出成绩"
      :visible.sync="deriveperformance"
      @open="findExamPlan"
      @close="closeExamPlan"
      append-to-body
    >
      <span class="examTitle">请选择考试场次:</span>
      <template>
        <el-select
          class="exam"
          v-model="alterInput.examvalue"
          placeholder="请选择考试场次"
        >
          <el-option
            v-for="item in alterInput.exam"
            :key="item.value"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
      </template>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="derive">确 定</el-button>
        <el-button @click="deriveperformance = false">取 消</el-button>
      </div>
    </el-dialog>
    <!-- 删除全选 -->
    <el-dialog
      width="1000px"
      title="确定"
      :visible.sync="delFilter"
      append-to-body
    >
      <span class="examTitle">确定删除选中的考生吗？</span>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="delAll">确 定</el-button>
        <el-button @click="delFilter = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [],
      detailData: {},
      currentPage3: 1,
      pageSize: 10,
      total: 0,
      input: "",
      loading: false,
      alterFlag: false,
      detail: false,
      delflag: false,
      postFlag: false,
      delFilter: false,
      //导出成绩对话框
      deriveperformance: false,
      alterInput: {
        examineeName: "",
        examNumber: "",
        inspectionSite: "",
        pilotCollege: "",
        roomName: "",
        status: "",
        examinationPlan: "",
        seatNumber: "",
        grade: "",
        subjects: "",
        score: "",
        certificate: "",
        examineeNumber: "",
        assessmentUnit: "",
        idNumber: "",
        startTime: "",
        endTime: "",
        id: "",
        delId: null,
        file: "",
        //选择考试场次的下拉菜单
        exam: [],
        examvalue: "",
        excel: null,
      },
      // filterName:[]
      examinationPlans: [],
      pilotColleges: [],
      subjects: [],
      // 删除多选选中的数据
      multipleSelection: [],
      findDatas: {
        pilotCollege: "",
        examinationPlan: "",
        subjects: "",
      },
    };
  },
  created() {
    this.getTableData();
    this.getFilterData();
  },
  methods: {
    /** 获取筛选的数值 */
    getFilterData() {
      this.$axios.get("/api/exam/screenKeywords").then((res) => {
        // console.log(res);
        if (res.data.code === 200) {
          // this.filterName = res.data.data
          // console.log(this.filterName);
          for (let i = 0; i < res.data.data.examinationPlans.length; i++) {
            res.data.data.examinationPlans[i];
            let item = {
              text: res.data.data.examinationPlans[i],
              value: res.data.data.examinationPlans[i],
            };
            this.examinationPlans.push(item);
            // console.log(this.examinationPlans);
          }
          for (let i = 0; i < res.data.data.pilotColleges.length; i++) {
            res.data.data.pilotColleges[i];
            let item = {
              text: res.data.data.pilotColleges[i],
              value: res.data.data.pilotColleges[i],
            };
            this.pilotColleges.push(item);
            // console.log(this.pilotColleges);
          }
          for (let i = 0; i < res.data.data.subjects.length; i++) {
            res.data.data.subjects[i];
            let item = {
              text: res.data.data.subjects[i],
              value: res.data.data.subjects[i],
            };
            this.subjects.push(item);
            // console.log(this.subjects);
          }
        }
      });
    },
    // 关闭导出成绩对话框的时候把选中的证书清空
    closeExamPlan() {
      this.alterInput.examvalue = "";
    },
    // 导出成绩单
    derive() {
      // console.log(this.alterInput.examvalue);
      if (this.alterInput.examvalue == "") {
        this.$message({
          type: "error",
          message: "请选择导出场次",
        });
      } else {
        //   window.open(
        //   '/api/exam/exportEx?examinationPlan=' + this.alterInput.examvalue
        // )
        // this.$axios.post('/logger/export', sendData, { responseType: 'blob' })

        //         .then((response) => {

        //             console.log(response)

        //             const blob = new Blob([response.data], { type: 'application/vnd.ms-excel' })

        //             if (window.navigator.msSaveOrOpenBlob) {

        //                 // 如果需要改名字，前台可以自动生成

        //                 navigator.msSaveBlob(blob, '用户操作日志')

        //             } else {

        //                 const link = document.createElement('a')

        //                 const evt = document.createEvent('HTMLEvents')

        //                 evt.initEvent('click', false, false)

        //                 link.href = URL.createObjectURL(blob)

        //                 link.download = '用户操作日志' + new Date().getTime() + '.xlsx'

        //                 link.style.display = 'none'

        //                 document.body.appendChild(link)

        //                 link.click()

        //                 window.URL.revokeObjectURL(link.href)

        //             }

        //         }).catch((e) => { console.log(e) })

        // null,
        //     {
        //       //responseType: 'blob',
        //       "Content-Type": "application/vnd.ms-excel",
        //     }
        fetch(
          `/api/exam/exportEx?examinationPlan=${this.alterInput.examvalue}`,
          {
            responseType: "blob",
            method: "post",
            headers: {
              token:  window.localStorage.getItem('user')
            }
          }
        )
        .then(res => res.blob())
          .then((res) => {
            console.log(res, "dd");
            const blob = new Blob([res], {
              type: "application/vnd.ms-excel",
            });
            console.log(blob);
            if (window.navigator.msSaveOrOpenBlob) {
              navigator.msSaveOrOpenBlob(blob, this.alterInput.examvalue);
            } else {
              const link = document.createElement("a");
              // const evt = document.createEvent('HTMLEvents')
              // evt.initEvent('click', false, false)
              link.href = URL.createObjectURL(blob);
              link.download = this.alterInput.examvalue;
              link.style.display = "none";
              document.body.appendChild(link);
              link.click();
              window.URL.revokeObjectURL(link.href);
            }
          })
          .catch((e) => {
            console.log("e: ", e);
          });
      }
    },
    // 获取考试计划
    findExamPlan() {
      this.$axios.get("/api/exam/screenKeywords").then((res) => {
        // console.log(res.data.data.examinationPlans)
        if (res.data.code === 200) {
          this.alterInput.exam = res.data.data.examinationPlans;
        } else {
          this.$message({
            type: "error",
            message: "考试计划获取失败",
          });
        }
      });
    },
    //导入学生信息文件
    postFile() {
      if (this.file) {
        let arr = [];
        // const that = this;
        // let filename = this.file.name;
        let formData = new FormData();
        formData.append("file", this.file);
        let config = {
          headers: { "Content-Type": "multipart/form-data", x: "y" },
        };
        this.$axios
          .post("/api/exam/importExaminee", formData, config)
          .then((res) => {
            console.log(res.data.data);
            if (res.data.code === 200) {
              if (res.data.data === 1) {
                this.$message({
                  type: "success",
                  message: res.data.message,
                });
                this.getTableData();
                this.postFlag = false;
              }
              if (res.data.data === 0) {
                console.log(res.data.data);
                console.log(res.data.message);
                this.$message({
                  type: "error",
                  message: res.data.message,
                });
              }
              if (res.data.data !== 0 && res.data.data !== 1) {
                let dataobj = res.data.data;
                for (let key in dataobj) {
                  let str = "第" + key + "行:" + dataobj[key] + "有重复";
                  arr.push(str);
                }
                console.log(arr.join());
                this.$message({
                  type: "error",
                  message: arr.join(),
                });
              }
            } else {
              this.$message({
                type: "error",
                message: "导入失败",
              });
            }
          });
      } else {
        this.$message({
          type: "warning",
          message: "请选择文件！",
        });
      }
    },
    // 确定删除
    delOne() {
      this.$axios
        .post(`/api/exam/deleteExaminee?id=${this.delId}`)
        .then((res) => {
          // console.log(res.data)
          if (res.data.code === 200) {
            this.$message({
              type: "success",
              message: "删除成功！",
            });
            this.delflag = false;
            this.getTableData();
          } else {
            this.$message({
              type: "error",
              message: "删除失败！",
            });
          }
        });
    },
    // 删除
    del(id) {
      this.delflag = true;
      this.delId = id;
    },
    // 修改数据
    alterData() {
      let data = {
        examineeName: this.alterInput.examineeName,
        examNumber: this.alterInput.examNumber,
        inspectionSite: this.alterInput.inspectionSite,
        pilotCollege: this.alterInput.pilotCollege,
        roomName: this.alterInput.roomName,
        status: this.alterInput.status,
        examinationPlan: this.alterInput.examinationPlan,
        seatNumber: this.alterInput.seatNumber,
        grade: this.alterInput.grade,
        subjects: this.alterInput.subjects,
        score: this.alterInput.score,
        certificate: this.alterInput.certificate,
        examineeNumber: this.alterInput.examineeNumber,
        assessmentUnit: this.alterInput.assessmentUnit,
        idNumber: this.alterInput.idNumber,
        startTime: this.alterInput.startTime,
        endTime: this.alterInput.endTime,

        id: this.alterInput.id,
      };
      this.$axios.post("/api/exam/updateExaminee", data).then((res) => {
        // console.log(res);
        if (res.data.code === 200) {
          this.$message({
            type: "success",
            message: "修改成功！",
          });
          this.alterFlag = false;
          this.getTableData();
        } else {
          this.$message({
            type: "error",
            message: "修改失败！",
          });
        }
      });
    },
    //回显
    alter(data) {
      this.alterFlag = true;
      this.alterInput.examineeName = data.examineeName;
      this.alterInput.examNumber = data.examNumber;
      this.alterInput.inspectionSite = data.inspectionSite;
      this.alterInput.pilotCollege = data.pilotCollege;
      this.alterInput.roomName = data.roomName;
      this.alterInput.status = data.status;
      this.alterInput.examinationPlan = data.examinationPlan;
      this.alterInput.seatNumber = data.seatNumber;
      this.alterInput.grade = data.grade;
      this.alterInput.subjects = data.subjects;
      this.alterInput.score = data.score;
      this.alterInput.certificate = data.certificate;
      this.alterInput.examineeNumber = data.examineeNumber;
      this.alterInput.assessmentUnit = data.assessmentUnit;
      this.alterInput.idNumber = data.idNumber;
      this.alterInput.startTime = data.startTime;
      this.alterInput.endTime = data.endTime;
      this.alterInput.id = data.id;
    },
    // 查看单个考新详情
    detailItem(data) {
      this.detail = true;
      // console.log(data)
      this.detailData = data;
    },
    //
    getTableData() {
      let data = {
        current: this.currentPage3,
        size: this.pageSize,
      };
      this.$axios.post("/api/exam/findExaminee", data).then((res) => {
        if (res.data.code === 200) {
          // console.log(res.data);
          this.tableData = res.data.data.records;
          this.total = res.data.data.total;
        }
        // if(res.data)
      });
    },
    handleChansge(file) {
      this.file = file.raw;
    },
    handleRemove() {},
    handlePictureCardPreview() {},
    //删除选中
    delAll() {
      // console.log(this.multipleSelection);
      if (this.multipleSelection.length < 1) {
        this.$message.warning({ message: "请选择要删除的考生！" });
        return;
      }
      let idData = [];
      this.multipleSelection.map((v) => {
        idData.push(v.id);
      });
      this.$axios
        .delete(`/api/exam/batchDeleteExamines?ids=${idData}`)
        .then((res) => {
          // console.log(res);
          if (res.data.code === 200) {
            this.$message.success({ message: "删除成功" });
            this.delFilter = false;
            this.multipleSelection = [];
            this.getTableData();
          } else {
            this.$message.error({ message: "删除失败" });
          }
        });
    },
    handleSelectionChange(val) {
      console.log(val);
      this.multipleSelection = val;
    },
    // 取消全选
    // toggleSelection(){
    // toggleSelection(rows) {
    //   if (rows) {
    //     rows.forEach(row => {
    //       this.$refs.multipleTable.toggleRowSelection(row);
    //     });
    //   } else {
    //     this.$refs.multipleTable.clearSelection();
    //   }
    // },
    // },
    //筛选
    filterChange(filterObj) {
      // console.log(filterObj)
      //  let data = {
      //   pilotCollege:'',
      //   examinationPlan:'',
      //   subjects:''
      // }
      if (filterObj) {
        if (Object.keys(filterObj)[0] == "pilotColleges") {
          // this.ckickRole = filterObj.pilotColleges[0]
          this.findDatas.pilotCollege = filterObj.pilotColleges[0];
        }
        if (Object.keys(filterObj)[0] == "examinationPlan") {
          // this.ckickRole = filterObj.pilotColleges[0]
          this.findDatas.examinationPlan = filterObj.examinationPlan[0];
        }
        if (Object.keys(filterObj)[0] == "subjects") {
          // this.ckickRole = filterObj.pilotColleges[0]
          this.findDatas.subjects = filterObj.subjects[0];
        }
      }

      // if (filterObj) {
      //   if (filterObj.pilotColleges) {
      //     if (filterObj.pilotColleges.length > 0) {
      //       this.findDatas.pilotCollege = filterObj.pilotColleges[0]
      //     }
      //     // else {
      //     //   this.findDatas.pilotCollege = ''
      //     // }
      //   }
      //   if (filterObj.examinationPlan) {
      //     if (filterObj.examinationPlan.length > 0) {
      //       this.findDatas.examinationPlan = filterObj.examinationPlan[0]
      //     }
      //     // else {
      //     //   this.findDatas.examinationPlan = ''
      //     // }
      //   }
      //   if (filterObj.subjects) {
      //     if (filterObj.subjects.length > 0) {
      //       this.findDatas.subjects = filterObj.subjects[0]
      //     }
      //     // else {
      //     // this.findDatas.subjects = ''
      //     // }
      //   }
      // }

      // console.log(this.findDatas);
      this.findDatas.current = this.currentPage3;
      this.findDatas.size = this.pageSize;
      this.$axios.post("/api/exam/findExaminee", this.findDatas).then((res) => {
        // console.log(res.data);
        if (res.data.code === 200) {
          this.$message.success({ message: "筛选成功" });
          this.tableData = res.data.data.records;
          this.total = res.data.data.total;
        } else {
          this.$message.error({ message: "筛选失败" });
        }
      });
    },
    // 过滤筛选数据
    filterData() {
      // this.input
      if (this.input === "") {
        this.getTableData();
        return;
      }
      let data = {
        examNumber: this.input,
        current: this.currentPage3,
        size: this.pageSize,
      };
      this.$axios.post("/api/exam/findExaminee", data).then((res) => {
        // console.log(res.data);
        if (res.data.code === 200) {
          this.$message.success({ message: "筛选成功" });
          this.tableData = res.data.data.records;
          this.total = res.data.data.total;
        } else {
          this.$message.error({ message: "筛选失败" });
        }
      });
      // this.$axios.
    },
    handleCurrentChange(val) {
      this.currentPage3 = val;
      // this.getTableData()
      this.filterChange();
    },
    handleSizeChange(e) {
      console.log(e);
      this.pageSize = e;
      this.currentPage3 = 1;
      this.getTableData();
    },
    // 表格内容居中
    thStyleFun() {
      return "text-align:center;background:#DCDFE6;color:#303133";
    },
    cellStyleFun() {
      return "text-align:center";
    },
  },
};
</script>

<style scoped lang="less">
.examinee {
  width: 100%;
  height: 100%;
}
// 搜索框
.inputs {
  margin: 10px 20px;
}
.oinput {
  width: 100%;
  .el-input {
    width: calc(100% - 490px);
  }
}
.block {
  margin: 30px auto;
  text-align: center;
}
.content {
  height: 200px;
  // margin: 0 16px 16px 16px;
  line-height: 20px;
  .left {
    width: 50%;
    float: left;
  }
  .right {
    width: 50%;
    float: left;
  }
}
.alter {
  height: 250px;
  margin: 0 16px 16px 16px;
  line-height: 20px;
  .left {
    width: 50%;
    float: left;
    div {
      span {
        display: inline-block;
        width: 65px;
      }

      margin: 5px 0;
    }
  }
  .right {
    width: 50%;
    float: left;
    div {
      span {
        display: inline-block;
        width: 65px;
      }

      margin: 5px 0;
    }
  }
  .el-input {
    width: 300px;
  }
}
/deep/ .el-upload-dragger {
  background: rgb(64, 158, 255) !important;
  .el-upload__text {
    color: #fff;
    margin-top: 60px;
  }
}
/deep/ .el-table__column-filter-trigger {
  opacity: 0;
}
</style>
<style lang='less'>
.el-table-filter {
  max-height: 500px;
  overflow: auto;
}
// /deep/ .el-table-filter__list{
//   height: 500px !important;
// }
</style>