<template>
  <div class="box">
    <div class="oinput">
      <el-input
        placeholder="请输入用户名"
        prefix-icon="el-icon-search"
        v-model="input"
        @change="getProductionList"
      >
      </el-input>
      <!-- 上传题集按钮 -->
      <el-button
        size="medium"
        @click="(outerVisible = true), (btnFlag = 1);"
        style="height: 40px"
        type="primary"
        >上传题集</el-button
      >
      <!-- <el-button size='medium' @click="deriveperformance = true" style="width:6.25%;height:40px
    " type="primary">导出成绩  </el-button> -->
    </div>

    <!-- 表格 -->
    <el-main>
      <el-table
        @filter-change="filterChange"
        v-loading="loading"
        stripe
        :data="
          tableData.slice(
            (currentPage3 - 1) * pageSize,
            currentPage3 * pageSize
          )
        "
        :header-cell-style="thStyleFun"
        :cell-style="cellStyleFun"
      >
        <el-table-column
          prop="label"
          label="证书类型"
          :filters="[
            { text: '数字创意建模', value: '数字创意建模' },
            { text: '文创产品数字化设计', value: '文创产品数字化设计' },
            { text: '互动媒体应用开发', value: '互动媒体应用开发' },
          ]"
          column-key="label"
          filter-placement="bottom-end"
          :filter-multiple="false"
        >
          <template slot="header">
            证书类型
            <i style="font-size: 12px" class="icon iconfont icon-jiantou1"></i>
          </template>
          <template slot-scope="scope">
            <span>{{ scope.row.label }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="type"
          label="题型"
          column-key="type"
          :filters="[
            { text: '判断', value: '判断' },
            { text: '单选', value: '单选' },
          ]"
          filter-placement="bottom-end"
          :filter-multiple="false"
        >
          <template slot="header">
            题型
            <i style="font-size: 12px" class="icon iconfont icon-jiantou1"></i>
          </template>
          <template slot-scope="scope">
            <span>{{ scope.row.type }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="question" show-overflow-tooltip label="问题">
        </el-table-column>
        <el-table-column prop="operation" label="操作">
          <template slot-scope="scope">
            <el-link
              :underline="false"
              type="primary"
              @click="(xiangqing = true), getParticulars(scope.row.id);"
              >详情</el-link
            >
            <span
              style="
                font-size: 12px;
                margin: 0 15.6px;
                color: #000000;
                opacity: 0.3;
              "
              >|</span
            >
            <el-link
              :underline="false"
              type="primary"
              @click="
                (outerVisible = true),
                  (btnFlag = 2),
                  getParticulars(scope.row.id);
              "
              >修改</el-link
            >
            <span
              style="
                font-size: 12px;
                margin: 0 15.6px;
                color: #000000;
                opacity: 0.3;
              "
              >|</span
            >
            <el-link
              :underline="false"
              type="danger"
              @click="(del = true), getParticulars(scope.row.id);"
              >删除</el-link
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 详情对话框 -->
      <el-dialog
        width="700px"
        title="题目详情"
        :visible.sync="xiangqing"
        @close="guanbi()"
        append-to-body
      >
        <div class="xiangqing_box">
          <div class="type">
            <span> 证书类型： </span>
            {{ productionData.label }}
          </div>
          <div class="type">
            <span> 题型： </span>
            {{ productionData.type }}
          </div>
          <div class="type">
            <span> 问题： </span>
            {{ productionData.question }}
          </div>
          <div class="type">
            <span> 选项A： </span>
            {{ productionData.optionA }}
          </div>
          <div class="type">
            <span> 选项B： </span>
            {{ productionData.optionB }}
          </div>
          <div class="type" v-if="productionData.type === '单选'">
            <span> 选项C： </span>
            {{ productionData.optionC }}
          </div>
          <div class="type" v-if="productionData.type === '单选'">
            <span> 选项D： </span>
            {{ productionData.optionD }}
          </div>
          <div class="type">
            <span> 正确答案： </span>
            {{ productionData.answer }}
          </div>
          <div class="type">
            <span> 解析： </span>
            {{
              productionData.analysis === null
                ? "暂无"
                : productionData.analysis
            }}
          </div>
          <div class="type">
            <span> 难度： </span>
            {{ productionData.difficulty }}
          </div>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="xiangqing = false">确 定</el-button>
          <el-button @click="xiangqing = false">取 消</el-button>
        </div>
      </el-dialog>
      <!-- 上传题集对话框 -->
      <el-dialog
        @close="guanbi()"
        center
        :visible.sync="outerVisible"
        :close-on-click-modal="false"
        width="1000px"
      >
        <div class="add_box">
          <!-- 证书下拉菜单 -->
          <div class="pull_down">
            <el-select
              v-model="value"
              placeholder="请选择证书类型"
              @change="checkedCard(value)"
            >
              <el-option
                v-for="item in option"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <!-- 题型下拉菜单 -->
            <el-select
              v-model="values"
              placeholder="请选择题目类型"
              @change="checkedTopic(values)"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <!-- 选中相应的题型展示相应的上传区域 -->
          <div class="type_box">
            <!-- 选择 -->
            <div class="select" v-show="flag === 1">
              <div>
                <span> 问题： </span>
                <el-input
                  style="width: 94.7%"
                  placeholder="请输入问题"
                  v-model="select.problem"
                >
                </el-input>
              </div>
              <div>
                <span> A </span>
                <el-input
                  style="width: 94.7%"
                  placeholder="请输入选项A的答案"
                  v-model="select.inputA"
                >
                </el-input>
              </div>
              <div>
                <span> B </span>
                <el-input
                  style="width: 94.7%"
                  placeholder="请输入选项B的答案"
                  v-model="select.inputB"
                >
                </el-input>
              </div>
              <div>
                <span> C </span>
                <el-input
                  style="width: 94.7%"
                  placeholder="请输入选项C的答案"
                  v-model="select.inputC"
                >
                </el-input>
              </div>
              <div>
                <span> D </span>
                <el-input
                  style="width: 94.7%"
                  placeholder="请输入选项D的答案"
                  v-model="select.inputD"
                >
                </el-input>
              </div>
              <div>
                正确答案:
                <el-select
                  v-model="select.answer"
                  placeholder="请输入正确答案"
                  @change="checkedCard(select.answer)"
                >
                  <el-option
                    v-for="item in select.answers"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
              <div>
                <span>解析:</span>
                <el-input
                  style="width: 94.7%"
                  placeholder="请输入解析"
                  v-model="select.analysis"
                >
                </el-input>
              </div>
              <div>
                <span>难度:</span>
                <el-select
                  v-model="select.difficulty"
                  placeholder="请输入题目难度"
                  @change="checkeddifficultys(select.difficulty)"
                >
                  <el-option
                    v-for="item in select.difficultys"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
              <div slot="footer" v-show="btnFlag === 1" class="dialog-footer">
                <el-button type="primary" @click="innerVisible = true"
                  >确 定</el-button
                >
                <el-button
                  @click="(outerVisible = false), (value = ''), (values = '');"
                  >取 消</el-button
                >
              </div>
              <div slot="footer" v-show="btnFlag === 2" class="dialog-footer">
                <el-button type="warning" @click="xiugai = true"
                  >修 改</el-button
                >
                <el-button type="danger" @click="del = true">删 除</el-button>
                <el-button
                  @click="(outerVisible = false), (value = ''), (values = '');"
                  >取 消</el-button
                >
              </div>
            </div>
            <!-- 判断 -->
            <div class="judge" v-show="flag === 2">
              <div>
                <span> 问题： </span>
                <el-input
                  style="width: 94.7%"
                  placeholder="请输入问题"
                  v-model="select.problem"
                >
                </el-input>
              </div>
              <div>
                <span> A </span>
                <el-input
                  style="width: 94.7%"
                  placeholder="请输入选项A的答案"
                  v-model="select.inputA"
                >
                </el-input>
              </div>
              <div>
                <span> B </span>
                <el-input
                  style="width: 94.7%"
                  placeholder="请输入选项B的答案"
                  v-model="select.inputB"
                >
                </el-input>
              </div>
              <div>
                正确答案:
                <el-select
                  v-model="select.answer"
                  placeholder="请输入正确答案"
                  @change="checkedCard(select.value)"
                >
                  <el-option
                    v-for="item in select.answers"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
              <div>
                <span>解析:</span>
                <el-input
                  style="width: 94.7%"
                  placeholder="请输入解析"
                  v-model="select.analysis"
                >
                </el-input>
              </div>
              <div>
                <span>难度:</span>
                <el-select
                  v-model="select.difficulty"
                  placeholder="请输入题目难度"
                  @change="checkeddifficultys(select.difficulty)"
                >
                  <el-option
                    v-for="item in select.difficultys"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
              <div slot="footer" v-show="btnFlag === 1" class="dialog-footer">
                <el-button type="primary" @click="innerVisibles = true"
                  >确 定</el-button
                >
                <el-button
                  @click="(outerVisibles = false), (value = ''), (values = '');"
                  >取 消</el-button
                >
              </div>
              <div slot="footer" v-show="btnFlag === 2" class="dialog-footer">
                <el-button type="warning" @click="xiugai = true"
                  >修 改</el-button
                >
                <!-- <el-button type="danger" @click="del=true">删 除</el-button> -->
                <el-button
                  @click="(outerVisible = false), (value = ''), (values = '');"
                  >取 消</el-button
                >
              </div>
            </div>
            <!-- 上传文件 -->
            <div
              v-loading="fileLoading"
              class="upload_files"
              v-show="flag === 3"
            >
              <el-upload
                ref="upload"
                name="file"
                :limit="limit"
                :auto-upload="false"
                action="接口地址"
                :on-exceed="handleExceed"
                :file-list="filelist"
                :on-change="handleChansge"
              >
                <el-button slot="trigger" size="small" type="primary"
                  >选取文件</el-button
                >

                <div slot="tip" class="el-upload__tip">
                  上传文件只能为excel文件，且为xlsx,xls格式
                </div>
                <el-button
                  style="margin-left: 10px"
                  size="small"
                  type="success"
                  @click="postfile"
                  :disabled="btn.disable"
                  >{{ btn.message }}</el-button
                >
              </el-upload>
            </div>
          </div>
        </div>
        <el-dialog
          width="30%"
          title="确定上传单选吗？"
          :visible.sync="innerVisible"
          append-to-body
        >
          <div slot="footer" class="dialog-footer">
            <el-button
              type="primary"
              @click="
                (innerVisible = false),
                  (outerVisible = false),
                  addMultipleChoice();
              "
              >确 定</el-button
            >
            <el-button @click="innerVisible = false">取 消</el-button>
          </div>
        </el-dialog>
        <el-dialog
          width="30%"
          title="确定上传判断吗？"
          :visible.sync="innerVisibles"
          append-to-body
        >
          <div slot="footer" class="dialog-footer">
            <el-button
              type="primary"
              @click="
                (innerVisibles = false),
                  (outerVisible = false),
                  addMultipleChoice();
              "
              >确 定</el-button
            >
            <el-button @click="innerVisibles = false">取 消</el-button>
          </div>
        </el-dialog>
        <el-dialog
          width="30%"
          title="确定修改吗？"
          :visible.sync="xiugai"
          append-to-body
        >
          <div slot="footer" class="dialog-footer">
            <el-button
              type="primary"
              @click="
                (xiugai = false),
                  (outerVisible = false),
                  alterTopic(productionData.id);
              "
              >确 定</el-button
            >
            <el-button @click="xiugai = false">取 消</el-button>
          </div>
        </el-dialog>
      </el-dialog>
      <el-dialog
        width="50%"
        title="确定删除吗？"
        :visible.sync="del"
        @close="guanbi()"
        append-to-body
      >
        <div slot="footer" class="dialog-footer">
          <el-button
            type="primary"
            @click="
              (del = false),
                (outerVisible = false),
                delTopic(productionData.id);
            "
            >确 定</el-button
          >
          <el-button @click="del = false">取 消</el-button>
        </div>
      </el-dialog>
      <!-- 导出成绩 -->
      <!-- <el-dialog
      width="1000px"
      title="导出成绩"
      :visible.sync="deriveperformance"
      @open="findExamPlan"
      @close='closeExamPlan'
      append-to-body>
      <span class="examTitle">请选择考试场次:</span>
      <template>
        <el-select class="exam" v-model="examvalue" placeholder="请选择考试场次">
          <el-option
            v-for="item in exam"
            :key="item.value"
            :label="item"
            :value="item">
          </el-option>
        </el-select>
      </template>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="derive">确 定</el-button>
        <el-button @click="deriveperformance = false">取 消</el-button>
      </div>
    </el-dialog>   -->

      <!-- 分页 -->
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage3"
          :page-size="pageSize"
          layout="prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </el-main>
  </div>
</template>
<script>
export default {
  data() {
    return {
      importDialogVisible: false,
      //文件
      file: "",
      filename: "",
      limit: 1,
      filelist: [],
      errmesg: [],
      btn: {
        disable: false,
        message: "上传服务器",
      },
      // 上传文件的列表
      fileList: [],
      fileLoading: false,
      // 根据题型展示不同的上传模块
      flag: 0,
      flags: 0,
      //  普通管理员修改密码框
      oldInput: "",
      newInput: "",
      affirmNewInput: "",
      // 表格数据
      tableData: [],
      // 对话框
      outerVisible: false,
      outerVisibles: false,
      innerVisible: false,
      innerVisibles: false,
      xiangqing: false,
      xiugai: false,
      del: false,
      // 上传题目证书类型的下拉菜单
      option: [
        {
          value: "数字创意建模",
          label: "数字创意建模",
        },
        {
          value: "文创产品数字化设计",
          label: "文创产品数字化设计",
        },
        {
          value: "互动媒体应用开发",
          label: "互动媒体应用开发",
        },
      ],
      value: "",
      // 上传/选择题/判断题
      options: [
        {
          value: "单选",
          label: "单选",
        },
        {
          value: "判断",
          label: "判断",
        },
        {
          value: "3",
          label: "上传excel题表",
        },
      ],
      values: "",
      // 分数
      score: 0,
      // 选择题的数据  /选择题的题目/答案/选项
      select: {
        // 选择题的答案
        answers: [
          {
            value: "A",
            label: "A",
          },
          {
            value: "B",
            label: "B",
          },
          {
            value: "C",
            label: "C",
          },
          {
            value: "D",
            label: "D",
          },
        ],
        // 难度选项
        difficultys: [
          {
            value: "1",
            label: "简单",
          },
          {
            value: "2",
            label: "一般",
          },
          {
            value: "3",
            label: "困难",
          },
        ],
        // 答案
        answer: "",
        // 解析
        analysis: "",
        // 难度
        difficulty: "1",
        // 选择题选项
        problem: "",
        inputA: "",
        inputB: "",
        inputC: "",
        inputD: "",
      },
      btnFlag: 0,
      loading: 0,
      // 搜索框
      input: "",
      // 页数
      currentPage3: 1,
      total: 0, // 数据的总条数
      pageSize: 14,
      productionList: [],
      productionData: [],
      // 筛选的字段
      filterTypes: "",
      filterLabels: "",
      filterFlag: "",
      filterArr: null,
      //导出成绩对话框
      //       deriveperformance:false,
      // //选择考试场次的下拉菜单
      //       exam:[],
      //       examvalue:'',
      //       excel:null
    };
  },
  created() {
    this.getProductionList();
  },

  methods: {
    // 关闭导出成绩对话框的时候把选中的证书清空
    // closeExamPlan(){
    //   this.examvalue = ''
    // },
    // // 导出成绩单
    // derive(){
    //   window.open ('/api/exam/exportEx?examinationPlan='+this.examvalue)
    // },
    // // 获取考试计划
    // findExamPlan(){
    //   this.$axios.get('/api/exam/getExaminationPlans').then((res)=>{
    //     console.log(res.data)
    //     if(res.data.code === 200 ){
    //       this.exam = res.data.data
    //     }else{
    //       this.$message({
    //         type:'error',
    //         message:'考试计划获取失败'
    //       })
    //     }
    //   })
    // },
    // 证书筛选
    async filterChange(filterObj) {
      this.currentPage3 = 1;
      const that = this;
      await this.$axios.get(`/api/exam/findExam`).then(function (res) {
        // console.log(res.data.data)
        that.tableData = res.data.data;
        that.total = res.data.data.length;
        that.loading = false;
      });
      // console.log(filterObj);
      // 筛选字段的key值
      let keys = Object.keys(filterObj)[0];
      // 处理筛选字段
      if (keys == "label") {
        if (filterObj.label.length > 0) {
          this.filterLabels = filterObj.label[0];
        } else {
          this.filterLabels = "";
          this.filterArr = this.tableData;
        }
      }
      if (keys == "type") {
        // this.filterTypes = filterObj.type[0]
        if (filterObj.type.length > 0) {
          this.filterTypes = filterObj.type[0];
        } else {
          this.filterTypes = "";
          this.filterArr = this.tableData;
        }
      }
      // console.log(this.filterTypes);
      // console.log(this.filterLabels);
      // 如果筛选了两种
      if (this.filterTypes && this.filterLabels) {
        console.log("two");
        if (this.filterFlag == "type") {
          let filterData = this.filterArr.filter(
            (item) => item.label == this.filterLabels
          );
          this.total = filterData.length;
          this.tableData = filterData;
        } else {
          let filterData = this.filterArr.filter(
            (item) => item.type == this.filterTypes
          );
          this.total = filterData.length;
          this.tableData = filterData;
        }
      } else if (this.filterTypes || this.filterLabels) {
        console.log("one");
        if (keys == "label") {
          if (filterObj.label.length < 1) {
            if (!this.filterTypes) {
              return;
            }
            let bb = this.tableData.filter(
              (item) => item.type == this.filterTypes
            );
            // console.log(this.tableData);
            // console.log(bb);
            // console.log(filterObj.type);
            this.filterFlag = "type";
            this.total = bb.length;
            this.tableData = bb;
            this.filterArr = bb;
          } else {
            let aa = this.tableData.filter(
              (item) => item.label == filterObj.label[0]
            );
            // console.log(this.tableData);
            this.filterFlag = "label";
            this.total = aa.length;
            this.tableData = aa;
            this.filterArr = aa;
          }
        } else if (keys == "type") {
          // console.log(filterObj.type.length);
          // console.log(filterObj.label.length);
          if (filterObj.type.length < 1) {
            if (!this.filterLabels) {
              return;
            }
            // console.log('AAAAAAA');
            let aa = this.tableData.filter(
              (item) => item.label == this.filterLabels
            );
            // console.log(this.tableData);
            this.filterFlag = "label";
            this.total = aa.length;
            this.tableData = aa;
            this.filterArr = aa;
          } else {
            var bb = this.tableData.filter(
              (item) => item.type == filterObj.type[0]
            );
            // console.log(this.tableData);
            // console.log(bb);
            console.log(filterObj.type);
            this.filterFlag = "type";
            this.total = bb.length;
            this.tableData = bb;
            this.filterArr = bb;
          }
        }
      }
      // 如果只筛选了一种
      // if(this.filterTypes || this.filterLabels){
      //   console.log('one');
      //   if(keys=='label'){
      //     var aa = this.tableData.filter(
      //       (item) => item.label == filterObj.label[0]
      //     )
      //     console.log(this.tableData);
      //     this.filterFlag='label'
      //     this.total = aa.length
      //     this.tableData = aa
      //     this.filterArr = aa
      //   }else if(keys == 'type'){
      //     if(filterObj.type==[]){
      //       console.log('AAAAAAA');
      //     }else{
      //       var bb = this.tableData.filter(
      //         (item) => item.type == filterObj.type[0]
      //       )
      //       // console.log(this.tableData);
      //       // console.log(bb);
      //       console.log(filterObj.type);
      //       this.filterFlag='type'
      //       this.total = bb.length
      //       this.tableData = bb
      //       this.filterArr = bb
      //     }

      //   }
      // }
      // const that = this
      // await this.$axios.get(`/api/exam/findExam`).then(function(res) {
      //   // console.log(res.data.data)
      //   that.tableData = res.data.data
      //   that.total = res.data.data.length
      //   that.loading = false
      // })
      // if (filterObj.label === undefined) {
      //   if (filterObj.type[0] === undefined) {
      //     return
      //   } else {
      //     var aa = this.tableData.filter(
      //       (item) => item.type == filterObj.type[0]
      //     )
      //     this.total = aa.length
      //     this.tableData = aa
      //   }
      // } else {
      //   if (filterObj.label[0] === undefined) {
      //     return
      //   } else {
      //     var bb = this.tableData.filter(
      //       (item) => item.label == filterObj.label[0]
      //     )
      //     this.total = bb.length
      //     this.tableData = bb
      //   }
      // }
    },
    // 修改试题
    alterTopic(id) {
      // console.log(id)
      const that = this;
      const list = {
        id: id,
        analysis: this.select.analysis,
        answer: this.select.answer,
        difficulty: this.select.difficulty,
        label: this.value,
        optionA: this.select.inputA,
        optionB: this.select.inputB,
        optionC: this.select.inputC,
        optionD: this.select.inputD,
        question: this.select.problem,
        score: this.score,
        type: this.values,
      };
      this.$axios.put("/api/exam/updateExam", list).then(function (res) {
        // console.log(res.data)
        if (res.data.code === 200) {
          that.$message({
            type: "success",
            message: "修改成功!",
          });
        } else {
          that.$message({
            type: "error",
            message: "修改失败!",
          });
        }
        that.getProductionList();
      });
    },
    // 删除试题
    delTopic(id) {
      const that = this;
      // console.log(id)
      this.$axios.delete(`/api/exam/deleteExam?ids=${id}`).then(function (res) {
        // console.log(res.data)
        that.getProductionList();
        if (res.data.code === 200) {
          that.$message({
            type: "success",
            message: "删除成功!",
          });
        } else {
          that.$message({
            type: "error",
            message: "修改失败!",
          });
        }
      });
    },
    // 当对话框关闭的时候
    guanbi() {
      this.productionData = "";
      this.value = "";
      this.values = "";
      this.select.problem = "";
      this.select.inputA = "";
      this.select.inputB = "";
      this.select.inputC = "";
      this.select.inputD = "";
      this.select.answer = "";
      this.select.analysis = "";
      this.flag = 0;
    },
    // 查看详情
    getParticulars(id) {
      // console.log(this.btnFlag)
      const that = this;
      // console.log(id)
      this.$axios.get(`/api/exam/findById?id=${id}`).then(function (res) {
        // console.log(res.data.data)
        const list = res.data.data;
        that.productionData = res.data.data;
        that.value = list.label;
        that.values = list.type;
        that.select.problem = list.question;
        that.select.inputA = list.optionA;
        that.select.inputB = list.optionB;
        that.select.inputC = list.optionC;
        that.select.inputD = list.optionD;
        that.select.answer = list.answer;
        that.select.analysis = list.analysis;
        that.select.difficulty = list.difficulty;
        if (res.data.data.type === "单选") {
          that.flag = 1;
          that.select.answers = [
            {
              value: "A",
              label: "A",
            },
            {
              value: "B",
              label: "B",
            },
            {
              value: "C",
              label: "C",
            },
            {
              value: "D",
              label: "D",
            },
          ];
        } else if (res.data.data.type === "判断") {
          that.flag = 2;
          that.select.answers = [
            {
              value: "A",
              label: "A",
            },
            {
              value: "B",
              label: "B",
            },
          ];
        }
      });
    },
    // checkeddifficultys (e) {
    //   console.log(e)
    // },
    // 上传单选
    addMultipleChoice() {
      const list = {
        analysis: this.select.analysis,
        answer: this.select.answer,
        difficulty: this.select.difficulty,
        label: this.value,
        optionA: this.select.inputA,
        optionB: this.select.inputB,
        optionC: this.select.inputC,
        optionD: this.select.inputD,
        question: this.select.problem,
        score: this.score,
        type: this.values,
      };
      // console.log(list)
      const that = this;
      if (
        this.select.analysis === "" ||
        this.select.answer === "" ||
        this.select.difficulty === "" ||
        this.value === "" ||
        this.select.inputA === "" ||
        this.select.inputB === "" ||
        this.select.problem === "" ||
        this.values === ""
      ) {
        that.$message({
          type: "error",
          message: "请将内容补充完整!",
        });
      } else {
        // console.log('哈哈哈哈哈')
        this.$axios.post("/api/exam/addExam", list).then(function (res) {
          // console.log(res.data)
          if (res.data.code === 200) {
            that.$message({
              type: "success",
              message: "添加成功!",
            });
            that.getProductionList();
          } else {
            that.$message({
              type: "error",
              message: "添加失败!",
            });
          }
        });
      }
    },
    // 上传试题
    postfile() {
      this.fileLoading = true;
      let that = this;
      if (this.file == "") {
        that.$message({
          type: "error",
          message: "请选择上传文件!",
        });
        return false;
      } else {
        // 文件形式的需要用 formData形式
        let formData = new FormData();
        formData.append("file", this.file);
        let config = {
          headers: { "Content-Type": "multipart/form-data" },
        };
        this.btn.disable = true;
        this.btn.message = "上传中，请等待";
        this.$axios
          .post(`/api/exam/importExam`, formData, config)
          .then(function (response) {
            console.log(response);
            that.fileLoading = false;
            that.$message({
              type: "success",
              message: "上传成功!",
            });
            that.filelist = [];
            that.btn.disable = false;
            that.btn.message = "上传服务器";
          })
          .catch((err) => {
            console.log(err);
            that.btn.disable = false;
            that.btn.message = "上传服务器";
            that.$message({
              type: "warning",
              message: "上传过程出错啦!",
            });
          });
      }
    },
    handleExceed(e) {
      // 判断是否只能上传一个文件，超过提示报错
      console.log(e);
      this.$message({
        type: "warning",
        message: "只能上传一个文件哦!",
      });
    },
    handleChansge(file, fileList) {
      // console.log(file)
      console.log(fileList);
      // let name = file.name
      // console.log(name)
      if (!/\.(xlsx|xls|XLSX|XLS)$/.test(file.name)) {
        this.$message({
          type: "warning",
          message: "上传文件只能为excel文件，且为xlsx,xls格式",
        });
        this.filelist = [];
        this.file = "";
        return false;
      }
      this.file = file.raw;
      this.filename = file.name;
    },
    // 上传改变证书下拉菜单
    checkedCard() {},
    // 上传改变题型下拉菜单
    checkedTopic(e) {
      const that = this;
      console.log(e);
      if (e === "单选") {
        that.flag = 1;
        that.score = 4;
        that.select.answers = [
          {
            value: "A",
            label: "A",
          },
          {
            value: "B",
            label: "B",
          },
          {
            value: "C",
            label: "C",
          },
          {
            value: "D",
            label: "D",
          },
        ];
        // console.log(that.flag)
      } else if (e === "判断") {
        that.flag = 2;
        that.score = 2;
        that.select.inputA = "正确";
        that.select.inputB = "错误";
        that.select.answers = [
          {
            value: "A",
            label: "A",
          },
          {
            value: "B",
            label: "B",
          },
        ];
      } else if (e === "3") {
        that.flag = 3;
      }
    },
    // 获取题目列表
    async getProductionList() {
      this.loading = true;
      const that = this;
      let data = this.input;
      await this.$axios
        .get(`/api/exam/findExam?keyword=${data}`)
        .then(function (res) {
          // console.log(res.data.data)
          // console.log(res.data.data.length)
          that.tableData = res.data.data;
          // that.productionList = res.data.data
          // console.log(that.tableData)
          // console.log(that.productionList)
          that.total = res.data.data.length;
          // console.log(that.total)
          that.loading = false;
        });
    },
    // 页码大小变动的时候触发
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`)
      this.currentPage3 = 1;
      this.pageSize = val;
    },
    // 当前页变动的时候触发
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`)
      this.currentPage3 = val;
      // this.getProductionList()
    },
    // 下架作品
    soldOutProduction() {},
    filterType(value, row) {
      console.log(value);
      console.log(row);
      // return row.label === value
      // if (value === '数字创意建模') {
      //   const szcyjm = this.tableData.filter((item)=>item.label === '数字创意建模')
      //   console.log(szcyjm)
      //   return row === szcyjm
      // }
    },
    filterTags(value, row) {
      console.log(value);
      console.log(row);
      // return row.type === value
    },
    filterHandler(value, row, column) {
      const property = column["property"];
      return row[property] === value;
    },
    thStyleFun() {
      return "text-align:center;background:#DCDFE6;color:#303133";
    },
    cellStyleFun() {
      return "text-align:center";
    },
    // 保持修改的密码
    userSavePsw() {},
  },
};
</script>
<style lang="less" scoped>
.box {
  width: 100%;
}
.input_box {
  div {
    float: none;
    padding: 0px 20px;
    div {
      padding: 0;
    }
  }
}
.oinput {
  width: 100%;
  margin: 0;
  padding: 0;
  .el-input {
    margin: 8px 8px 8px 1.5%;
    width: calc(100% - 175px);
  }
  .el-button {
    width: 120px;
    height: 40px;
    font-size: 14px;
    text-align: center;
  }
}
.type_box {
  .select,
  .judge,
  .upload_files {
    div {
      margin: 10px 0;
      span {
        display: inline-block;
        width: 45px;
        text-align: center;
      }
    }
  }
}
.pull_down {
  .el-select {
    margin: 0 20px;
  }
  .el-select:last-child {
    width: 150px;
  }
}
.block {
  margin: 30px auto;
  text-align: center;
}
.btn {
  margin-top: 20px;
  width: 68px;
  height: 36px;
  background: #1989fa;
  border-radius: 4px;
  text-align: center;
  line-height: 36px;
  margin: 20px 20px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.btn:hover {
  background: #449df7;
}
.btn:active {
  background: #166bc0;
}
.xiangqing_box {
  div {
    margin: 5px 0;
    span {
      font-weight: 700;
      font-size: 18px;
    }
  }
}
.examTitle {
  margin-right: 20px;
}
.exam {
  margin: 0 auto;
}
/deep/ .el-table__column-filter-trigger {
  opacity: 0;
}
</style>
