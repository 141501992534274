<template>
    <div id="workCenter">
        <super-work v-if="role === 'super'"></super-work>
        <financial-work v-else-if="role === 'financial'"></financial-work>
        <take-work v-else-if="role === 'take'"></take-work>
        <operating-work v-else-if="role === 'operating'"></operating-work>
        <service-work v-else></service-work>
    </div>
</template>

// 工作台页面
<script>
import SuperWork from '@/components/center/super/super.vue'
import FinancialWork from '@/components/center/financial/financial.vue'
import TakeWork from '@/components/center/take/take.vue'
import OperatingWork from '@/components/center/operating/operating.vue'
import ServiceWork from '@/components/center/service/service.vue'
export default {
    components: {
        SuperWork,
        FinancialWork,
        TakeWork,
        OperatingWork,
        ServiceWork
    },
    data () {
        return {
            role: ''
        }
    },
    created () {
        this.role = this.$store.getters.role
    },
    mounted () {

    },
    methods: {

    }
}
</script>

<style lang="less" scoped>
#workCenter {
    color: #fff;
}
</style>
