<template>
    <div id="mess">
        <router-view></router-view>
    </div>
</template>

<script>
export default {
    data () {
        return {

        }
    },
    created () {

    },
    mounted () {

    },
    methods: {

    }
}
</script>

<style lang="less" scoped>
</style>
