<template>
  <div class="resource">
    <Table type='resource' :tableDatas='tableData' :updateTableData="getTableData"></Table>
  </div>
</template>

<script>
import Table from '@/components/illegal/common/table'
export default {
  components:{
    Table
  },
 data(){
    return{
      tableData:[],
      type:'resource',
      currentPage3: 1,
      total: 0, // 数据的总条数
      pageSize: 7,
    }
  },
  created(){
    this.getTableData()
  },
  methods:{
    getTableData(){
      const that = this
      this.$axios
        .get(
          `/api/report/findReportTypeStatus?current=${this.currentPage3}&size=${this.pageSize}&type=${this.type}`
        )
        .then(function(res) {
          if (res.data.data) {
            that.tableData = res.data.data.records
            // that.total = res.data.data[0].count
          }
        })
    }
  }
}
</script>

<style lang='less' scoped>
.resource{
  width: 100%;
  height: 100%;
}
</style>