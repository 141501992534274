<!--
 * @Author: your name
 * @Date: 2021-12-08 19:21:40
 * @LastEditTime: 2021-12-13 11:12:50
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \art-society-admin\src\components\c-rich-text\src\c-rich-text.vue
-->
<template>
  <div class="new_not">
    <div :id="editRef"></div>
    <!-- <CProgress :show="show" :percentage="percentage"></CProgress> -->
  </div>
</template>

<script>
// 引入 wangEditor
import WangEditor from 'wangeditor'

import { Indent } from './extendsIndent'
// import { Color } from './extendsColor'
// import { BgColor } from './extendsBgColor'
// import { upLoadFileApi, getUploadTokenApi } from '../../../api/api-common'
// import { objectname } from '../../../utils/objectname'
// import OSS from 'ali-oss'
// import CProgress from '../../c-progress'
export default {
  // components: { CProgress },
  data () {
    return {
      editor: null,
      editorData: '',
      show: false,
      percentage: 0
    }
  },
  props: ['value', 'editRef', 'content'],
  mounted () {
    // + this.editRef
    console.log(this.editRef)
    const editor = new WangEditor('#' + this.editRef)
    // console.log('editor: ', editor)
    // 配置 onchange 回调函数，将数据同步到 vue 中
    // 提示
    // 隐藏菜单栏提示
    // editor.config.showMenuTooltips = true
    // editor.config.menuTooltipPosition = 'up'
    editor.config.onchange = newHtml => {
      this.editorData = newHtml
    }
    editor.config.menus = [
      'head',
      'bold',
      'fontSize',
      'fontName',
      'italic',
      'underline',
      'strikeThrough',
      'indent',
      'lineHeight',
      'foreColor',
      'backColor',
      'link',
      'list',
      'todo',
      'justify',
      'quote',
      // 不打开
      // 'emoticon',
      'image',
      // 'video',
      // 不打开
      // 'table',
      // 不打开
      // 'code',
      'splitLine',
      'undo',
      'redo'
      // 自定义
      // 'alertMenuKey2'
    ]
    /* 上传图片到阿里云 */
    editor.config.customUploadImg = async (resultFiles, insertImgFn) => {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      try {
        // const res = await upLoadFileApi(
        //   'info',
        //   resultFiles[0],
        //   resultFiles[0].name
        // )
        // insertImgFn(res.url)
        const fd = new FormData()
        var configs = {
          headers: { 'Content-Type': 'multipart/form-data' }
        }
        console.log(11)
        fd.append('imgFile', resultFiles[0])
        this.$axios.post('/api/common/myupload', fd, configs).then((res) => {
          if (res.data.code) {
            insertImgFn(res.data.data.img)
          }
        })
      } catch (error) {
        this.$message.error('上传图片失败')
      } finally {
        loading.close()
      }
    }

    /* 上传视频到阿里云 */
    // 设置富文本图片文件的大小
    // editor.config.uploadImgMaxSize = 10 * 1024 * 1024
    // 配置网络图片
    // editor.config.showLinkImg = false

    // 自定义扩展
    editor.menus.extend('indent', Indent)
    // editor.menus.extend('foreColor', Color)
    // editor.menus.extend('backColor', BgColor)
    // editor.config.menus = editor.config.menus.concat('alertMenuKey2')

    // 创建编辑器
    editor.create()
    editor.txt.html(this.content)
    this.editor = editor
  },
  watch: {
    // 监听数据变化，传递给父组件currentVal, oldVal
    editorData () {
      this.noteDetailChange()
    }
  },
  methods: {
    // 子组件传值给父组件
    noteDetailChange () {
      // 调用父组件传递的方法
      this.$emit('input', this.editorData)
    },
    deleteEditor () {
      this.editor.$txt.html('<p><br></p>')
    },
    clearTxt () {
      this.editor.txt.clear()
    },
    // 数据更新
    updateEditorData (text) {
      this.editor.txt.html(text)
    }
  },
  beforeDestroy () {
    // 调用销毁 API 对当前编辑器实例进行销毁
    this.editor.destroy()
    this.editor = null
  }
}
</script>

<style lang="less">
.el-loading-mask {
  z-index: 9999999 !important;
}
</style>
