<template>
  <div class="box">
    <div v-if="flag === true">
      <div class="input_box">
        <el-col :span="4">
          <p>*请输入验证码</p>
          <el-input placeholder="请输入验证码" v-model="verifyCode"></el-input>
          <el-button
            size="medium"
            @click="getVerifyCode()"
            :disabled="disabled"
            type="primary"
            >{{ valiBtn }}</el-button
          >
        </el-col>
      </div>
      <div class="input_box">
        <el-col :span="4">
          <p>*新密码</p>
          <el-input
            placeholder="您的新密码"
            v-model="newInput"
            show-password
          ></el-input>
        </el-col>
      </div>
      <div class="input_box">
        <el-col :span="4">
          <p>*确认新密码</p>
          <el-input
            placeholder="您的新密码"
            v-model="affirmNewInput"
            show-password
          ></el-input>
        </el-col>
      </div>

      <div style="margin-left: 20px">
        <el-button size="medium" @click="userSavePsw()" type="primary"
          >保存</el-button
        >
      </div>
    </div>
    <div v-else>
      <div class="oinput">
        <el-input
          placeholder="请输入用户名"
          prefix-icon="el-icon-search"
          v-model="input"
          @change="getProductionList()"
        >
        </el-input>
        <el-button size="medium" @click="outerVisible = true" type="primary"
          >新增管理员</el-button
        >
      </div>

      <!-- 表格 -->
      <el-main>
        <!-- 删除管理员的二次确认 -->
        <el-dialog
          title="提示"
          :visible.sync="centerDialogVisibles"
          width="30%"
          center
        >
          <span>确定上下架此管理员吗？</span>
          <span slot="footer" class="dialog-footer">
            <el-button @click="centerDialogVisibles = false">取 消</el-button>
            <el-button
              type="primary"
              @click="(centerDialogVisibles = false), delAdmin()"
              >确 定</el-button
            >
          </span>
        </el-dialog>

        <el-table
          v-loading="loading"
          stripe
          style="min-height: 750px"
          :data="tableData"
          :header-cell-style="thStyleFun"
          :cell-style="cellStyleFun"
        >
          <el-table-column prop="username" label="管理员用户名">
          </el-table-column>
          <el-table-column prop="realname" label="真实姓名"></el-table-column>
          <el-table-column label="性别">
              <template slot-scope="scope">
                  {{scope.row.sex==1?'男':'女'}}
              </template>
          </el-table-column>
           <el-table-column  label="角色">
              <template slot-scope="scope">
                {{
                  scope.row.role==='super'?'超级管理员':
                  scope.row.role==='financial'?'财务':
                   scope.row.role==='take'?'考务':
                    scope.row.role==='operating'?'运营':
                     scope.row.role==='service'?'客服':''
                }}
              </template>

           </el-table-column>
          <el-table-column prop="operation" label="操作">
            <template slot-scope="scope">
              <el-button @click="(centerDialogVisibles = true), getDelId(scope.row)"> 
                {{scope.row.status==1?'上架':'下架'}}
              </el-button>
              <el-button @click="goEditPassword(scope.row.id)"> 
               修改密码
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- 添加管理员 -->
        <el-dialog
          title="添加管理员"
          center
          :visible.sync="outerVisible"
          width="1000px"
        >
          <div class="add_box">
            <div class="input_box">
              <el-col :span="6">
                <p>用户名</p>
                <el-input
                  @blur="checkoutUsername()"
                  :span="1"
                  placeholder="请输入用户名/账号"
                  v-model="val.username"
                ></el-input>
              </el-col>
            </div>
              <div class="input_box">
              <el-col :span="6">
                <p>真名</p>
                <el-input
                  placeholder="请输入手机号"
                  v-model="val.realname"
                  maxlength="11"
                ></el-input>
              </el-col>
            </div>
            <div class="input_box">
              <el-col :span="6">
                <p>管理员密码</p>
                <el-input
                  placeholder="请输入密码"
                  v-model="val.password"
                  show-password
                ></el-input>
              </el-col>
            </div>
          
              <div class="input_box">
              <el-col :span="6">
                <p>角色</p>
               <el-select v-model="val.role" placeholder="请选择">
                  <el-option label="财务" value="financial"></el-option>
                   <el-option label="考务" value="take"></el-option>
                    <el-option label="运营" value="operating"></el-option>
                     <el-option label="客服" value="service"></el-option>
               </el-select>
              </el-col>
            </div>
              <div class="input_box">
              <el-col :span="6">
                <p>性别</p>
               <el-select v-model="val.sex" placeholder="请选择">
                  <el-option label="男" value="0"></el-option>
                    <el-option label="女" value="1"></el-option>
               </el-select>
              </el-col>
            </div>
          </div>
          <el-dialog
            width="30%"
            title="确定添加此管理员吗？"
            :visible.sync="innerVisible"
            append-to-body
          >
            <div slot="footer" class="dialog-footer">
              <el-button
                type="primary"
                @click="
                  (innerVisible = false), (outerVisible = false), addAdmin(val)
                "
                >确 定</el-button
              >
              <el-button @click="innerVisible = false">取 消</el-button>
            </div>
          </el-dialog>
          <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click="innerVisible = true"
              >确 定</el-button
            >
            <el-button @click="outerVisible = false">取 消</el-button>
          </div>
        </el-dialog>
        <div class="block">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage3"
            :page-size="pageSize"
            layout="prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </el-main>
    </div>
  </div>
</template>
<script>
// import tab from '../topic/tab.vue';
export default {
  // components: { tab },
  data() {
    return {
      delId: "",
      // 添加管理员二次确认框对话框
      centerDialogVisible: false,
      // 删除二次确认
      centerDialogVisibles: false,
      // 不同权限看到不同的页面
      flag: false,
      // 普通管理员修改密码
      oldInput: "",
      newInput: "",
      affirmNewInput: "",
      verifyCode: "",
      phone: "",
      // 添加管理员
      val: {
        username: "",
        password: "",
        realname: "",
        role:'',
        sex:''
      },
      // 渲染的数据
      tableData: [],
      // 搜索框
      input: "",
      // 输入框
      input1: "",
      // 控制对话框的显示和隐藏
      outerVisible: false,
      innerVisible: false,
      // 页数
      currentPage3: 1,
      total: 0, // 数据的总条数
      pageSize: 14,
      // 管理员列表信息
      productionList: [],
      valiBtn: "获取验证码",
      disabled: false,
      loading: false,
      // 点击删除当前管理员的信息
      delOne: [],
    };
  },
  created() {
    this.getProductionList();
    this.checkoutUsername();
  },
  methods: {
    // 倒计时
    tackBtn() {
      //验证码倒数60秒
      let time = 60;
      let timer = setInterval(() => {
        if (time == 0) {
          clearInterval(timer);
          this.valiBtn = "获取验证码";
          this.disabled = false;
        } else {
          this.disabled = true;
          this.valiBtn = time + "秒后重试";
          time--;
        }
      }, 1000);
    },
    // 验证用户信息展示不同页面
    async checkoutUsername() {
      const that = this;
      await this.$axios.post(`/api/admin/currentRole`).then(function (res) {
        // console.log(res.data)
        if (res.data.data.role === "super") {
          that.flag = false;
        } else {
          alert("请先登录");
        }
      });
    },
    // 添加管理员
    addAdmin(val) {
      const that = this;
      // console.log(val)
      if (
        this.val.phone === "" ||
        this.val.password === "" ||
        this.val.username === ""
      ) {
        that.$message({
          type: "error",
          message: "请将信息填写完整",
        });
      } else {
        that.$axios.post("/api/admin/addAdmin", val).then(function (res) {
          // console.log(res)
          if (res.data.code === 200) {
            that.getProductionList();
            that.$message({
              type: "success",
              message: "添加成功!",
            });
          } else {
            that.$message({
              type: "error",
              message: "添加失败!",
            });
          }
        });
      }
    },
     goEditPassword(data) {
      this.$router.push("/alterPsw?id="+data);
    },
    // 获取管理员信息列表
    async getProductionList() {
      this.loading = true;
      const that = this;
      await this.$axios
        .get(
          `/api/admin/findAllAdmin?current=${this.currentPage3}&size=${
            this.pageSize
          }&keyword=${this.input == "" ? "" : this.input}`
        )
        .then(function (res) {
          // console.log(res.data)
          that.tableData = res.data.data.records;
          // console.log( that.tableData)
          that.productionList = res.data.data;
          // console.log(that.tableData)
          // console.log(that.productionList)
          // console.log(that.total)
          that.loading = false;
        });
    },
    // 删除管理员
    delAdmin() {
      const that = this;
      if (this.delOne.username === "admin") {
        that.$message({
          type: "error",
          message: "无法操作超级管理员哦!",
        });
      } else {
        this.$axios
          .delete(`/api/admin/deleteAdmin?id=${this.delOne.id}`)
          .then(function (res) {
            that.getProductionList();
            // console.log(res.data)
            that.delId = "";
            if (res.data.code === 200) {
              that.$message({
                type: "success",
                message: "操作成功!",
              });
            } else {
              that.$message({
                type: "error",
                message: "操作失败!",
              });
            }
          });
      }
    },
    // 删除管理员
    getDelId(item) {
      this.delOne = [];
      this.delOne = item;
   
    },
    // 页码大小变动的时候触发
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`)
      this.currentPage3 = 1;
      this.pageSize = val;
    },
    // 当前页变动的时候触发
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`)
      this.currentPage3 = val;
      this.getProductionList();
    },
    // 下架作品
    soldOutProduction() {},
    filterTag(value, row) {
      return row.tag === value;
    },
    filterHandler(value, row, column) {
      const property = column["property"];
      return row[property] === value;
    },
    thStyleFun() {
      return "text-align:center;background:#DCDFE6;color:#303133";
    },
    cellStyleFun() {
      return "text-align:center";
    },
    // 获取验证码等验证
    getVerifyCode() {
      this.tackBtn();
      this.$axios.post(`/api/admin/sendMsg`).then(function (res) {
        // console.log(res.data)
        if (res.data.code === null) {
          this.$message({
            type: "success",
            message: "发送验证码成功!",
          });
        } else {
          this.$message({
            type: "success",
            message: "发送验证码失败!",
          });
        }
      });
    },
    // 保存修改的密码
    userSavePsw() {
      // this.$axios.put(`/api/admin/resetPassword`)
      // console.log(this.oldInput,this.newInput,this.affirmNewInput)
      if (
        this.oldInput === "" ||
        this.newInput === "" ||
        this.affirmNewInput === "" ||
        this.verifyCode === ""
      ) {
        this.$message({
          type: "error",
          message: "请将内容补充完整!",
        });
      } else if (this.newInput !== this.affirmNewInput) {
        this.$message({
          type: "error",
          message: "新密码和确认密码不符!",
        });
      } else {
        const that = this;
        const data = {
          code: this.verifyCode,
          password: this.affirmNewInput,
        };
        this.$axios.put("/api/admin/setPassword", data).then(function (res) {
          // console.log(res.data)
          if (res.data.data === null) {
            that.$message({
              type: "success",
              message: "密码修改成功",
            });
            localStorage.removeItem("user");
            that.$router.push("/");
          } else if (res.data.data === -1) {
            that.$message({
              type: "error",
              message: "验证码错误!",
            });
          }
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
.box {
  width: 100%;
}

.input_box {
  div {
    float: none;
    padding: 0px 20px;
    div {
      padding: 0;
    }
  }
  .el-button {
    position: absolute;
    left: 550px;
    top: 123px;
  }
}
.el-input {
  margin: 8px 8px 8px 1.5%;
}
.oinput {
  width: 100%;
  margin: 0;
  padding: 0;
  .el-input {
    width: 89%;
  }
  .el-button {
    width: 120px;
    height: 40px;
    font-size: 14px;
    text-align: center;
  }
}
.block {
  margin: 30px auto;
  margin-bottom: 0;
  text-align: center;
}
.btn {
  margin-top: 20px;
  width: 68px;
  height: 36px;
  background: #1989fa;
  border-radius: 4px;
  text-align: center;
  line-height: 36px;
  margin: 20px 20px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.btn:hover {
  background: #449df7;
}
.btn:active {
  background: #166bc0;
}
</style>
