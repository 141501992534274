<template>
<div class="message">
<!-- 搜索框 -->
    <el-input
      placeholder="请输入用户名"
      prefix-icon="el-icon-search"
      v-model="input">
    </el-input>
<!-- 表格 -->
    <el-main>
      <el-table stripe :data="tableData" :header-cell-style="thStyleFun" :cell-style="cellStyleFun">
        <el-table-column prop="userId" label="用户名">
          <template slot-scope="scope">
          <div class="userName">
            <img src="@/assets/images/xiaoxin.svg" alt="">
            <span> {{ scope.row.userId }} </span>
          </div>
            </template>
        </el-table-column>
        <el-table-column prop="content" show-overflow-tooltip label="反馈内容">
        </el-table-column>
        <el-table-column prop="time" label="时间">
        </el-table-column>
        <el-table-column
              prop="tag"
              label="状态"
              :filters="[{ text: '下架', value: -2 }, { text: '正常', value: 1 }]"
              :filter-method="filterTag"
              filter-placement="bottom-end">
              <template slot-scope="scope">
                  <span>{{ scope.row.isPublic==-2 ? '下架' : '正常' }}</span>
              </template>
        </el-table-column>
        <el-table-column prop="operation" label="操作">
          <template slot-scope="scope">
           <el-link :underline="false" type="primary" @click="outerVisible = true,getParticulars(scope.row.id)">详情</el-link>
          </template>
        </el-table-column>
      </el-table>
<!-- 分页 -->
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage3"
          :page-size="pageSize"
          layout="prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
    </el-main>
<!-- 对话框 -->
    <el-dialog :title="String(this.particularsData.userId)" center :visible.sync="outerVisible">
    <div class="suggest_box">
      <div class="suggest">
        {{ particularsData.content }}
      </div>
    </div>
    <div class="reply_box">
      <textarea placeholder="回复反馈用户" v-model="replyContent" rows="行数" cols="列数" value="取值"></textarea>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button type="success" @click="replyInformer(particularsData)">回 复</el-button>
      <el-button type="info" @click="outerVisible = false">取 消</el-button>
    </div>
  </el-dialog>
</div>
</template>
<script>

export default ({
  data () {
    return {
      tableData: [],
// 搜索框
      input: '',
// 请求接口传递的类型
      type: 'notify',
// 页数
      currentPage3: 1,
      total:0, // 数据的总条数
      pageSize : 14,
// 对话框
      outerVisible: false,
      innerVisible: false,
      dialogVisible: false,
// 绑定的输入框
      replyContent:'',
// 数据数组
      productionList:[],
// 详情数据
      particularsData: []
    }
  },
  created () {
    this.getProductionList()
  },
  methods: {
// 点击获取当条详情
    getParticulars (e) {
      const that = this
      console.log(e)
      this.$axios.get(`/api/detail/detail?id=${e}&type=${this.type}`).then(function(res) {
        console.log(res.data.data)
        if (res.data.data[0]){
          that.particularsData = res.data.data[0]
          console.log(that.particularsData)
        }
      })
    },
// 获取当页的反馈信息
    async getProductionList () {
      // const that = this
      await this.$axios.get(`/api/message/findFeedback?size=${this.pageSize}&keyword=${this.input}&current=${this.currentPage3}`).then(function (res) {
        console.log(res.data.data)
        // that.tableData = res.data.data
        // that.productionList = res.data.data
        // console.log(that.tableData)
        // console.log(that.productionList)
        // that.total = res.data.data[0].page
        // console.log(that.total)
      })
    },
// 回复反馈人
    replyInformer (e) {
      const that = this
      if (this.replyContent==='') {
         this.$message({
          message: '请输入回复内容!',
          type: 'warning'
        })
      } else {
        console.log(this.replyContent)
        console.log(e)
        this.$axios.get(`/api/report/reply?sender=${0}&target=${e.id}&type="${e.type}"&user=${e.userId}&content="${this.replyContent}"`).then(function (res) {
          console.log(res.data.data)
          if (res.data.code===200) {
            that.$message({
            message: '回复成功!',
            type: 'success'
            })
            that.replyContent=''
          } else {
            that.$message.error('回复评论失败!')
          }
        })
      }
    },
// 页码大小变动的时候触发
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
      this.currentPage3 = 1
      this.pageSize = val
    },
// 当前页变动的时候触发
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
      this.currentPage3 = val
      this.getProductionList()
    },
// 状态下拉菜单过滤
      filterTag(value, row) {
        console.log(value, row)
        return row.isPublic === value
      },
      filterHandler(value, row, column) {
        console.log(value, row, column)
        const property = column['property']
        return row[property] === value
      },
// 表格内容居中
    thStyleFun() {
        return 'text-align:center;background:#DCDFE6;color:#303133'
    },
    cellStyleFun() {
        return 'text-align:center'
    }
  }
})
</script>
<style lang="less" scoped>
// 整个页面
.message{
  width: 100%;
  height: 100%;
}
// 搜索框
.el-input{
  margin: 8px 1.5%;
  width: 97%;
}
// 分页
.block{
    margin: 30px auto;
    text-align: center;
  }
// 用户名和头像
  .userName{
    height: 100%;
    // width: 300px;
    overflow: hidden;
    white-space:nowrap;
    text-overflow: ellipsis;
    img{
      width: 32px;
      height: 32px;
      border-radius: 50%;
    }
    span{
      position: relative;
      top: -10px;
      margin-left: 10px;
    }
  }
// 反馈内容区域
  .suggest_box{
    width: 100%;
    height: 132px;
    background: #DCDFE6;
    // margin-bottom: 20px;
    padding: 20px 0;
    .suggest{
      width: 96%;
      height: 132px;
      margin: 0 2%;
      overflow-y: auto;
    }
  }
// 回复反馈区域
  .reply_box{
    margin-top: 20px;
    width: 100%;
    height: 172px;
    background: #DCDFE6;
    textarea{
      width: 96%;
      min-height: 132px;
      border: none;
      margin: 2% 2%;
      padding: 0;
      outline:none;
      resize: none;
      background: #DCDFE6;
      font-size: 16px;
    }
  }
</style>
