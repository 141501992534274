<template>
  <div>
    <el-input
      placeholder="请输入搜索内容内容"
      v-model="common.word"
      style="width: 70%"
      clearable
    >
    </el-input>
    <el-button
      type="primary"
      style="margin: 0 0 0 15px"
      @click="
        addMessShow = true;
        title = '新增消息素材';
      "
      icon="el-icon-edit"
      >写消息素材</el-button
    >
    <el-table
      :data="page.records"
      stripe
      style="width: 100%; margin: 70px 0 70px 0"
    >
      <el-table-column label="序号" width="80">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column prop="name" label="消息标题" width="180">
      </el-table-column>
      <el-table-column label="消息主题" width="150">
        <template slot-scope="scope">
          {{
            scope.row.type === "system"
              ? "消息主体"
              : scope.row.type === "creator"
              ? "创造者客服"
              : scope.row.type === "social"
              ? "社区客服"
              : ""
          }}
        </template>
      </el-table-column>
      <el-table-column prop="firstContent" label="消息前言" width="380">
      </el-table-column>
      <el-table-column label="消息封面" width="180">
        <template slot-scope="scope">
          <el-image
            style="width: 100px; height: 100px"
            :src="scope.row.img"
            fit="fill"
          ></el-image>
        </template>
      </el-table-column>
      <el-table-column label="发送对象" width="180">
        <template slot-scope="scope">
          <el-tag v-for="item in scope.row.userType" :key="item">
            {{
              item === 0
                ? "学生"
                : item === 1
                ? "老师"
                : item === 2
                ? "职业用户"
                : item === 3
                ? "企业用户"
                : item === 4
                ? "创造者"
                : item === 5
                ? "未认证"
                : ""
            }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="状态" width="100">
        <template slot-scope="scope">
          {{ scope.row.status === 0 ? "未发布" : "发布中" }}
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button @click="del(scope.row.id)">删除</el-button>
          <el-button @click="OpenDetail(scope.row.content)">详情</el-button>
          <el-button @click="edit(scope.row)">编辑</el-button>
          <el-button
            v-if="scope.row.status === 0"
            @click="(upShow = true), (id = scope.row.id)"
            >发布</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="common.current"
      :page-size="common.size"
      layout="prev, pager, next, jumper"
      :total="page.total"
      :page-count="page.pages"
    >
    </el-pagination>
    <!-- edit -->
    <el-dialog :title="title" :visible.sync="addMessShow" @close="closeWind">
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="消息名称">
          <el-input type="textarea" v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="消息主体">
          <el-select v-model="form.type" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="发送对象">
          <el-checkbox-group v-model="form.userType">
            <el-checkbox
              v-for="role in roles"
              :key="role.key"
              :label="role.key"
            >
              {{ role.value }}
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="封面">
          <el-upload
            class="avatar-uploader"
            action=""
            name="imgFile"
            :show-file-list="false"
            :http-request="upload"
          >
            <img v-if="img" :src="img" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="消息前言">
          <el-input type="textarea" v-model="form.firstContent"></el-input>
        </el-form-item>
        <el-form-item label="发送内容">
          <editer ref="editer" v-model="text" :editRef="'editer'"></editer>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="onSubmit">立即创建</el-button>
          <el-button>取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog title="详情" :visible.sync="showText">
      <div v-html="textDetail"></div>
    </el-dialog>
    <el-dialog title="发布" :visible.sync="upShow">
      <el-input
        placeholder="请输入验证码"
        v-model="code"
        clearable
        style="width: 50%"
      >
      </el-input>
      <el-button style="margin: 0 0 0 15px" type="primary" @click="up">{{
        valiBtn
      }}</el-button>
      <el-button @click="getCheck" style="margin: 0 0 0 20px">确认</el-button>
    </el-dialog>
  </div>
</template>
<style lang="less">
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
<script>
import editer from "../c-rich-text/src/c-rich-text.vue";
export default {
  components: {
    editer,
  },
  data() {
    return {
      page: {},
      title: "",
      addMessShow: false,
      common: {
        word: "",
        size: 10,
        current: 1,
      },

      form: {
        userType: [],
        content: "",
        type: "",
        firstContent: "",
        img: "",
        name: "",
      },
      roles: [
        {
          key: 0,
          value: "学生",
        },
        {
          key: 1,
          value: "老师",
        },
        {
          key: 2,
          value: "职业用户",
        },
        {
          key: 3,
          value: "企业用户",
        },
        {
          key: 4,
          value: "创造者",
        },
        {
          key: 5,
          value: "未认证",
        },
      ],
      options: [
        {
          value: "system",
          label: "系统主体",
        },
        {
          value: "creator",
          label: "创造者客服",
        },
        {
          value: "social",
          label: "社区客服",
        },
      ],
      text: "",
      img: "",
      showText: false,
      textDetail: "",
      id: "",
      code: "",
      upShow: false,
      valiBtn: "获取验证码",
      flag: false,
    };
  },
  created() {
    this.getMessage();
  },
  methods: {
    closeWind() {
      this.form = {};
      this.$refs.editer.clearTxt();
      this.img = "";
      this.form.userType = [];
    },
    tackBtn() {
      //验证码倒数60秒
      let time = 60;
      let timer = setInterval(() => {
        if (time == 0) {
          clearInterval(timer);
          this.valiBtn = "获取验证码";
          this.disabled = false;
        } else {
          this.disabled = true;
          this.valiBtn = time + "秒后重试";
          time--;
        }
      }, 1000);
    },
    handleSizeChange(val) {
      this.currentPage3 = 1;
      this.pageSize = val;
    },

    // 当前页变动的时候触发
    handleCurrentChange(val) {
      this.currentPage3 = val;
      this.findALLItems();
    },
    upload(file) {
      const fd = new FormData();
      var configs = {
        headers: { "Content-Type": "multipart/form-data" },
      };
      fd.append("imgFile", file.file);
      this.$axios.post("/api/common/myupload", fd, configs).then((res) => {
        if (res.data.code === 200) {
          this.form.img = res.data.data.name;
          this.img = res.data.data.img;
        }
      });
    },
    async onSubmit() {
      var newtext = new File([this.text], "foo.txt", {
        type: "text/plain",
      });
      await this.uploadtext(newtext);

      const oldMessage = JSON.parse(JSON.stringify(this.form));
      if (this.img === this.form.img) {
        delete oldMessage.img;
      }
      if (this.flag === true) {
        delete oldMessage.content;
      }

      this.$axios.post("/api/message/addMessage", oldMessage).then((res) => {
        if (res.data.code === 200) {
          this.$message.success(res.data.message);
          this.addMessShow = false;
          this.getMessage();
        }
        this.form = {};
        this.$refs.editer.clearTxt();

        this.img = "";
        this.form.userType = [];
      });
    },
    async uploadtext(file) {
      if (this.text === this.form.content) {
        this.flag = true;
      } else {
        const fd = new FormData();
        var configs = {
          headers: { "Content-Type": "multipart/form-data" },
        };
        fd.append("imgFile", file);
        const res = await this.$axios.post("/api/common/myupload", fd, configs);
        if (res.data.code === 200) {
          this.form.content = res.data.data.name;
        }
      }
    },
    getMessage() {
      this.$axios
        .get(
          "/api/message/getMessage?current=" +
            this.common.current +
            "&size=" +
            this.common.size +
            "&word=" +
            this.common.word
        )
        .then((res) => {
          if (res.data.code === 200) {
            this.page = res.data.data;
          }
        });
    },
    OpenDetail(data) {
      fetch(data, {
        method: "GET",
        mode: "cors",
      })
        .then((res) => {
          // that.loadingAll = fals
          return res.text();
        })
        .then((result) => {
          console.log(data, "ff");
          console.log(result);
          this.textDetail = result;

          this.$nextTick(() => {
            this.showText = true;
            // console.log(that.richLoading)
            // that.$refs.editorRef.updateEditorData()
          });
        });
    },
    up() {
      this.tackBtn();
      this.$axios.post("/api/message/getCode").then((res) => {
        if (res.data.code === 200) {
          this.$message.success(res.data.message);
        }
      });
    },
    getCheck() {
      this.$axios
        .post("/api/message/getCheck?id=" + this.id + "&code=" + this.code)
        .then((res) => {
          if (res.data.code === 200) {
            this.$message.success(res.data.message);
            this.upShow = false;
            this.getMessage();
          }
        });
    },
    del(data) {
      this.$axios.delete("/api/message/del?id=" + data).then((res) => {
        if (res.data.code) {
          this.getMessage();
          this.$message.success(res.data.data);
        }
      });
    },
    edit(data) {
      this.form = data;
      this.addMessShow = true;
      this.img = data.img;
      this.text = data.content;

      fetch(data.content, {
        method: "GET",
        mode: "cors",
      })
        .then((res) => {
          // that.loadingAll = false

          return res.text();
        })
        .then((result) => {
          this.$nextTick(() => {
            console.log(result);
            this.$refs.editer.updateEditorData(result);
          });
        });

      console.log(this.form);
    },
  },
};
</script>