/*
 * @Author: your name
 * @Date: 2021-12-08 19:22:39
 * @LastEditTime: 2021-12-08 19:22:39
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \art-society-admin\src\components\c-rich-text\src\extendsIndent.js
 */

import E from 'wangeditor'

const { $, DropListMenu } = E

export class Indent extends DropListMenu {
  constructor (editor) {
    // 菜单栏中，标题菜单的 DOM 元素
    // 注意，这里的 $ 不是 jQuery ，是 E.$ （wangEditor 自带的 DOM 操作工具，类似于 jQuery）
    // data-title属性表示当鼠标悬停在该按钮上时提示该按钮的功能简述
    const $elem = $(`<div class="w-e-menu" data-title="缩进" style="z-index:auto;">
                      <i class="w-e-icon-indent-increase"></i>
                      <div class="w-e-droplist" style="margin-top:40px; width:130px; display:none;">
                        <p class="w-e-dp-title">设置缩进</p>
                        <ul class="w-e-list">
                          <li class="w-e-item">
                            <p><i class="w-e-icon-indent-increase w-e-drop-list-item"></i>增加缩进</p><p></p>
                          </li>
                          <li class="w-e-item">
                            <p><i class="w-e-icon-indent-decrease w-e-drop-list-item"></i>减少缩进</p><p></p>
                          </li>
                          <li class="w-e-item">
                            <p><i class="w-e-icon-indent-increase w-e-drop-list-item"></i>增加首航缩进</p><p></p>
                          </li>
                          <li class="w-e-item">
                            <p><i class="w-e-icon-indent-decrease w-e-drop-list-item"></i>取消首航缩进</p><p></p>
                          </li>
                        </ul>
                      </div>
                    </div>`)

    // droplist 配置
    const dropListConf = {
      width: 150,
      title: '设置标题',
      type: 'list',
      list: [
        {
          $elem: $(`<li class="w-e-item"><p>
        <i class="w-e-icon-indent-increase w-e-drop-list-item"></i>
        增加缩进
    </p><p></p></li>`),
          value: '+2em'
        },
        {
          $elem: $(`<li class="w-e-item"><p>
        <i class="w-e-icon-indent-decrease w-e-drop-list-item"></i>
        减少缩进
    </p><p></p></li>`),
          value: '-2em'
        },
        {
          $elem: $(` <li class="w-e-item">
        <p><i class="w-e-icon-indent-increase w-e-drop-list-item"></i>增加首航缩进</p><p></p>
      </li>`),
          value: '2em'
        },
        {
          $elem: $(`<li class="w-e-item">
        <p><i class="w-e-icon-indent-decrease w-e-drop-list-item"></i>减少首航缩进</p><p></p>
      </li>`),
          value: '0'
        }
      ],
      // droplist 每个 item 的点击事件
      clickHandler: (value) => {
        // value 参数即 dropListConf.list 中配置的 value

        // 选择文本
        // const selectionText = editor.selection.getSelectionText()
        const SelectionContainerElem = editor.selection.getSelectionEndElem().elems[0]

        const padding = SelectionContainerElem.style.paddingLeft
        const indent = SelectionContainerElem.style.textIndent

        if (value === '0' && ~~indent.replace('em', '') >= 1) {
          SelectionContainerElem.style.textIndent = ~~indent.replace('em', '') - 2 + 'em'
        } else if (value === '2em') {
          SelectionContainerElem.style.textIndent = ~~indent.replace('em', '') + 2 + 'em'
        } else if (value === '+2em') {
          SelectionContainerElem.style.paddingLeft = ~~padding.replace('em', '') + 2 + 'em'
        } else if (value === '-2em' && ~~padding.replace('em', '') >= 0) {
          SelectionContainerElem.style.paddingLeft = ~~padding.replace('em', '') - 2 + 'em'
        }
      }
    }

    super($elem, editor, dropListConf)
  }

  command (value) {
    // 设置标题
    this.editor.cmd.do('formatBlock', value)
  }

  // 菜单是否需要激活
  tryChangeActive () {
    const reg = /^h/i
    const cmdValue = this.editor.cmd.queryCommandValue('formatBlock')
    if (reg.test(cmdValue)) {
      // 选区处于标题内，激活菜单
      this.active()
    } else {
      // 否则，取消激活
      this.unActive()
    }
  }
}
